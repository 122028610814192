import {
  EDIT_PROFILE,
  GET_DASHBOARD_PROFILE,
  GET_DASHBOARD_PROFILE_FAIL,
  GET_DASHBOARD_PROFILE_SUCCESS,
  GET_PROFILE,
  GET_PROFILE_FAIL,
  GET_PROFILE_SUCCESS,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  RESET_PROFILE_FLAG,
} from "./actionTypes"

export const editProfile = (user, token) => {
  return {
    type: EDIT_PROFILE,
    payload: { user, token },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const getProfile = token => {
  return {
    type: GET_PROFILE,
    payload: {
      token,
    },
  }
}

export const getProfileSuccess = data => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: { data },
  }
}

export const getProfileFail = data => {
  return {
    type: GET_PROFILE_FAIL,
    payload: { data },
  }
}

export const getDashboardProfile = token => {
  return {
    type: GET_DASHBOARD_PROFILE,
    payload: {
      token,
    },
  }
}

export const getDashboardProfileSuccess = data => {
  return {
    type: GET_DASHBOARD_PROFILE_SUCCESS,
    payload: { data },
  }
}

export const getDashboardProfileFail = data => {
  return {
    type: GET_DASHBOARD_PROFILE_FAIL,
    payload: { data },
  }
}
