import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import { toaster } from "helpers/Custom/Toaster"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Alert, Col, Row } from "reactstrap"
import {
  getSingleFAQType,
  getSingleFAQTypeSuccess,
  updateFaqType,
} from "store/actions"
import InnerLayer from "../../Layout/InnerLayer"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Faq type",
    link: "/faqType",
  },
  {
    //   title: `${id ? "Update" : "Create"} FAQ type`,
    title: `Create FAQ type`,
    link: "#",
  },
]
const EditTypeFaq = ({ history }) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [selectedvalue, setselectedvalue] = useState("")
  const { authToken, error, adding, singleFAQType } = useSelector(state => ({
    authToken: state.Login.token,
    singleFAQType: state.FAQReducer.singleFAQType,
    adding: state.FAQReducer.adding,
  }))
  console.log("singleFAQType", singleFAQType)

  useEffect(() => {
    if (id) {
      dispatch(getSingleFAQType(authToken, id))
    }
    return () => {
      dispatch(getSingleFAQTypeSuccess({}))
    }
  }, [])
  useEffect(() => {
    if (id) {
      setselectedvalue(singleFAQType?.typeName)
    }
  }, [singleFAQType])
  const handleSubmit = (event, errors, values) => {
    event.preventDefault()
    if (errors.length > 0) {
      toaster("warning", "Please Fill Up all the values")
      return
    }
    let body = {
      typeName: values?.typeName,
    }
    dispatch(updateFaqType(body, history, authToken, id))

    console.log("hello", values)
  }

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        // title={id ? "Edit FAQ Type" : "Create FAQ type"}
        title={"Update FAQ type"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row className="mt-3">
          <Col md="6" className="offset-md-3">
            <CardComponent>
              {/* <h5>{id ? "Update" : "Create"} FAQ type</h5> */}
              <h5>Update FAQ type</h5>
              {error ? <Alert color="danger">{error}</Alert> : null}

              <AvForm className="mt-4" onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <AvField
                    name="typeName"
                    label="FAQ type"
                    type="text"
                    errorMessage="Type FAQ type"
                    placeholder="Type FAQ type"
                    value={selectedvalue}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Row>

                <div>
                  <button
                    disabled={adding}
                    type="submit"
                    className="btn btn-primary w-md"
                  >
                    {adding ? "Submitting..." : "Update FAQ type"}
                  </button>
                </div>
              </AvForm>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default EditTypeFaq
