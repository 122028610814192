import React from "react"
import { Dropdown } from "react-bootstrap"

const TableDropdown = ({ children }) => {
  return (
    <React.Fragment>
      <Dropdown>
        <Dropdown.Toggle
          className="remove_shadow"
          variant="none"
          style={{
            background: "transparent",
            border: "none",
            paddingLeft: "0px",
          }}
        >
          <i className="bx bx-dots-vertical-rounded custom-dot"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className="tableDropdownMenu border-0 shadow-sm">
          {children}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  )
}

export default TableDropdown
