import {
  ADD_PACKAGE,
  ADD_PACKAGE_FAIL,
  ADD_PACKAGE_SUCCESS,
  DELETE_PACKAGE,
  DELETE_PACKAGE_FAIL,
  DELETE_PACKAGE_SUCCESS,
  GET_PACKAGES,
  GET_PACKAGES_FAIL,
  GET_PACKAGES_SUCCESS,
  GET_SINGLE_PACKAGE,
  GET_SINGLE_PACKAGE_FAIL,
  GET_SINGLE_PACKAGE_SUCCESS,
} from "./actionTypes"

export const addPackage = (data, history, authtoken, id) => ({
  type: ADD_PACKAGE,
  payload: { data, history, authtoken, id },
})

export const addPackageSuccess = data => ({
  type: ADD_PACKAGE_SUCCESS,
  payload: data,
})

export const addPackageFail = error => ({
  type: ADD_PACKAGE_FAIL,
  payload: error,
})

export const removePackage = (authtoken, id) => ({
  type: DELETE_PACKAGE,
  payload: { authtoken, id },
})

export const removePackageSuccess = data => ({
  type: DELETE_PACKAGE_SUCCESS,
  payload: data,
})

export const removePackageFail = error => ({
  type: DELETE_PACKAGE_FAIL,
  payload: error,
})

export const getPackages = authtoken => ({
  type: GET_PACKAGES,
  payload: { authtoken },
})

export const getPackagesSuccess = (data, authtoken) => ({
  type: GET_PACKAGES_SUCCESS,
  payload: { data, authtoken },
})

export const getPackagesFail = error => ({
  type: GET_PACKAGES_FAIL,
  payload: error,
})

export const getSinglePackage = (id, authtoken) => ({
  type: GET_SINGLE_PACKAGE,
  payload: { authtoken, id },
})

export const getSinglePackageSuccess = data => ({
  type: GET_SINGLE_PACKAGE_SUCCESS,
  payload: { data },
})

export const getSinglePackageFail = error => ({
  type: GET_SINGLE_PACKAGE_FAIL,
  payload: error,
})
