import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import moment from "moment"
import React, { useEffect } from "react"
import { Button, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import { Col } from "reactstrap"
import { getFacilitatorSlot } from "store/Facilitator/actions"
import { getSingleTeacher } from "store/teacher/actions"
import InnerLayer from "../../Layout/InnerLayer"
import editIcon from "./img/edit.svg"
import empTyImage from "./img/emaptyImge.svg"
import speakingIcon from "./img/microphone.svg"
import notVerified from "./img/not-verified-image.png"
import "./speaking.scss"

const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Teacher List",
    link: `/teachers-list`,
  },
  {
    title: "List time slot",
    link: "#",
  },
]

const FacilitatorMain = ({ history }) => {
  const [month, setMonth] = React.useState(moment().format("MM"))
  const [year, setYear] = React.useState(moment().format("YYYY"))
  // console.log(`FacilitatorMain ~ month`, month, year)
  const dispatch = useDispatch()
  const { teacherId } = useParams()
  const { authToken, teacher, loading, facilitatorSlots, role } = useSelector(
    state => ({
      authToken: state.Login.token,
      role: state.Login.userrole,
      loading: state.teacherReducer.loading,
      teacher: state.teacherReducer.teacher,
      speakingSet: state.speakingSet.speakingSet,
      facilitatorSlots: state.FacilitatorReducer.facilitatorSlots,
    })
  )
  console.log("facilitatorSlots", facilitatorSlots)
  console.log(`loading`, teacher, loading)

  const handleChangeMonth = e => {
    const { value } = e.target
    if (value !== "") {
      setMonth(value)
    }
  }
  const handleChangeYear = e => {
    const { value } = e.target
    if (value !== "") {
      setYear(value)
    }
  }

  useEffect(() => {
    dispatch(getSingleTeacher(teacherId, authToken))
  }, [])

  useEffect(() => {
    dispatch(
      getFacilitatorSlot(authToken, {
        teacherId,
        startDate: `${year}-${month}-01`,
        endDate: `${year}-${month}-31`,
        role: role,
      })
    )
  }, [year, month])

  return (
    <div>
      <InnerLayer
        wrapperClass="py-3 users"
        isMultiple
        title={""}
        breadcrumbItems={breadcrumbItems}
      >
        {teacher?.request !== "active" ? null : (
          <Row>
            <CardComponent>
              <div className="d-flex justify-content-between align-items-center">
                <h4>Time Slot</h4>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <div>
                    <div className="position-relative">
                      <Link
                        to={
                          loading
                            ? "#"
                            : `/facilitator/${teacherId}/create-time-slot`
                        }
                      >
                        <Button
                          type="button"
                          color="primary"
                          className="btn-circle me-2"
                        >
                          <i className="mdi mdi-plus me-1" />
                          Create Time Slot
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div>
                    <AvForm className="d-flex gap-2">
                      <AvField
                        type="select"
                        name="year"
                        onChange={handleChangeYear}
                        value={year}
                      >
                        <option value="">Select Year</option>
                        <option value="2026">2026</option>
                        <option value="2025">2025</option>
                        <option value="2024">2024</option>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                      </AvField>
                      <AvField
                        name="month"
                        type="select"
                        onChange={handleChangeMonth}
                        value={month}
                      >
                        <option value="">Select Month</option>
                        <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </AvField>
                    </AvForm>
                  </div>
                </div>
              </div>
            </CardComponent>{" "}
          </Row>
        )}
        <Row>
          {loading ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : teacher?.request !== "active" ? (
            <div className="empty-time-slot-main-wrap py-5">
              <div>
                <img src={notVerified} alt="" />
                <p>Teacher email not verified.</p>
              </div>
            </div>
          ) : facilitatorSlots.length > 0 ? (
            facilitatorSlots?.map((speakingSet, i) => (
              <Col lg={3} className="mb-3" key={i}>
                <Link
                  to={`/facilitator/${teacherId}/view-class/${speakingSet?._id}`}
                >
                  <div className="single-exam-schedule">
                    <div className="single-exam-schedule-left-edit-icon">
                      <Link to={`/facilitator/${teacherId}/create-time-slot`}>
                        <img src={editIcon} alt="" />
                      </Link>
                    </div>{" "}
                    <div className="single-exam-schedule-left-icon">
                      <img src={speakingIcon} alt="" />
                    </div>
                    <div className="single-exam-schedule-left-content">
                      <h6>{speakingSet?._id}</h6>
                      <p>{speakingSet?.count} time slot</p>
                    </div>
                  </div>
                </Link>
              </Col>
            ))
          ) : (
            <CardComponent>
              <div className="empty-time-slot-main-wrap">
                <div>
                  <img src={empTyImage} alt="" />
                  <p>
                    Currently you have no question. <br /> For next step first{" "}
                    <Link to={`/facilitator/${teacherId}/create-time-slot`}>
                      Create time slot.
                    </Link>
                  </p>
                  <Link
                    to={`/facilitator/${teacherId}/create-time-slot`}
                    className="btn"
                    style={{
                      display: "inline-block",
                      background: "#0052CC",
                      color: "#FFFFFF",
                    }}
                  >
                    Create Time Slot
                  </Link>
                </div>
              </div>
            </CardComponent>
          )}
        </Row>
      </InnerLayer>
    </div>
  )
}

export default withRouter(FacilitatorMain)
