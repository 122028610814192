export const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '200px',
  alignItems: 'center',
  width: '100%',
  padding: '20px',
  borderRadius: '8px',
  borderWidth: 1.5,
  borderColor: 'gray',
  borderStyle: 'dashed',
  backgroundColor: 'transparent',
  color: 'rgba(248, 248, 248, 0.6)',
  // cursor: 'pointer',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

export const activeStyle = {
  borderColor: '#2196f3',
};

export const acceptStyle = {
  borderColor: '#00e676',
};

export const rejectStyle = {
  borderColor: '#ff1744',
};
