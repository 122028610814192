/* eslint-disable eqeqeq */
import { Box } from "@material-ui/core"
import { Rating } from "@material-ui/lab"
import Loader from "components/Common/Loader"
import React, { useEffect, useMemo } from "react"
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"

import { getSinglePackageData } from "store/PackageResult/actions"

import { getResult, getResultSuccess } from "store/Result/actions"
import packageProfile from "../../assets/images/Frame.svg"

import { imageBaseUrl } from "../../helpers/api_helper"

import CustomInput from "components/Common/CustomInput"
import { Field, Form, Formik } from "formik"
import { useState } from "react"
import { Modal } from "react-bootstrap"
import {
  addFacilitatorReview,
  getFacilitatorReview,
} from "store/Facilitator/actions"
import packageImage from "./img/01-3.png"
import "./ResultPage.scss"
import ReadingAns from "./ViewAnswer"
import ViewListeningResult from "./ViewListening/ViewListening"
import ReviewAnswerList from "./Writing/ReviewAnswerList"
const initialValues = {
  section: "",
  review: "",
  isDisable: false,
}

// const score = [
//   9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1, 0,
// ];
const imageHelper = data => {
  switch (data?.toLowerCase()?.trim()) {
    case "reading":
      return "reading.svg"
    case "speaking":
      return "speaking.svg"
    case "writing":
      return "writing.svg"
    case "listening":
      return "listening.svg"
    default:
      return "reading.svg"
  }
}
let data = [
  {
    id: 0,
    value: "section One",
    section: "section One",
  },
  {
    id: 1,
    value: "section Two",
    section: "section Two",
  },
  {
    id: 2,
    value: "section Three",
    section: "section Three",
  },
]
let data1 = [
  {
    id: 0,
    value: "section One",
    section: "section One",
  },
  {
    id: 1,
    value: "section Two",
    section: "section Two",
  },
]
let data2 = [
  {
    id: 0,
    value: "section One",
    section: "section One",
  },
  {
    id: 1,
    value: "section Two",
    section: "section Two",
  },
  {
    id: 2,
    value: "section Three",
    section: "section Three",
  },
  {
    id: 3,
    value: "section Four",
    section: "section Four",
  },
]
const FacilitatorResult = ({ history }) => {
  const { studentId, packageId, type, setId } = useParams()
  const [detailShow, setDetailShow] = useState(false)
  const dispatch = useDispatch()
  const [listValue, setListValue] = useState(null)
  const [values, setValues] = useState(null)
  const [review, setReview] = useState([])
  const {
    authToken,
    results,
    singlePackage,
    profile,
    adding,
    loading,
    questions,
    listeningQuestion,
    facilitatorReview,
  } = useSelector(state => ({
    authToken: state.Login.token,
    facilitatorReview: state.FacilitatorReducer.facilitatorReview,
    adding: state.FacilitatorReducer.adding,
    results: state.resultReducer.results,
    loading: state.resultReducer.loading,
    singlePackage: state.FacilitatorPackageReducer.singlePackage?.singlePackage,
    singlePackageLoading: state.FacilitatorPackageReducer.singlePackageLoading,
    profile: state.Profile?.data,
    currentIndex: state.FacilitatorQuesReducer.currentIndex,
    questions: state.FacilitatorQuesReducer.questions,
    listeningQuestion:
      state.resultReducer.results?.data?.setId?.listeningAudioModel,
  }))
  console.log("results", results)
  console.log("adding", adding)
  console.log("type", type)

  useEffect(() => {
    dispatch(getResultSuccess({}))
    dispatch(getSinglePackageData(authToken, packageId))
  }, [])

  useEffect(() => {
    let url = ""
    switch (type) {
      case "reading":
        url = `package/get-answer-by-facilitator/${setId}/${studentId}`
        break
      case "listening":
        url = `package/facilitator/get-answer/listening/${setId}/${studentId}`
        break
      case "writing":
        url = `/package/writting/get-answer-of/${studentId}/in/${setId}`
        break
      default:
        break
    }
    dispatch(getResult(authToken, url, type))
  }, [])

  useEffect(() => {
    return () => {
      dispatch(getResultSuccess({}))
    }
  }, [])

  useEffect(() => {
    dispatch(getFacilitatorReview(authToken, setId, studentId, type))
  }, [])

  const ansData = useMemo(() => {
    let answer = []
    for (const obj in results?.result) {
      answer.push({
        questionNumber: obj,
        ...results?.result[obj],
        view: false,
      })
    }
    return answer
  }, [results])

  const getData = () => {
    if (Object?.keys(results)?.length === 0 || results?.result === null) {
      return null
    }
    switch (type) {
      case "reading":
        return <ReadingAns ans={results?.result} />
      case "listening":
        return <ViewListeningResult ans={results?.result} />
      default:
        return null
    }
  }
  // console.log(ansData());
  function scroll(element, number) {
    // console.log(`element`, element);
    const ele = document.getElementById(element)
    let question
    if (type === "reading") {
      question = questions
    } else if (type === "listening") {
      question = listeningQuestion
    }
    let passage = 0
    question?.forEach((data, index) => {
      let questionPosition = 0
      if (index > 0) {
        for (let i = 0; i < index; i++) {
          const previous = question?.[i]
          const lastQuestion =
            previous?.questions?.[previous?.questions?.length - 1]
          questionPosition +=
            lastQuestion?.Que?.question?.[
              lastQuestion?.Que?.question?.length - 1
            ]?.questionNumber
        }
      }
      data?.questions?.forEach(ques => {
        ques?.Que?.question?.forEach(list => {
          const questionNum = list?.questionNumber + questionPosition
          if (questionNum == number) {
            passage = index
          }
        })
      })
    })
    dispatch(storeQuestion("currentIndex", passage))
    // console.log(`passage`, passage);
    ele?.scrollIntoView()
    // window.scroll({ top: ele?.offsetTop - offset, left: 0, behavior: 'smooth' });
    // } else {
    //   ele?.scrollIntoView();
    // console.log(`question`, question);
    // }
  }
  const handleReview = () => {
    setDetailShow(true)
  }
  const onSubmit = values => {
    console.log("type", type)
    const { isDisable, ...rest } = values
    let prevData = facilitatorReview?.values?.getFacilitatorReview
      ? facilitatorReview?.values?.getFacilitatorReview?.review
      : []
    console.log(facilitatorReview?.values?.getFacilitatorReview?.review, values)
    console.log("values", values)
    let sample = [...prevData, { ...rest }]

    console.log("sample", sample)

    if (type === "reading") {
      let body = {
        studentId: studentId,
        readingSet: setId,
        review: sample,
      }

      dispatch(addFacilitatorReview(body, { history, token: authToken, type }))
    }

    if (type === "listening") {
      let body = {
        studentId: studentId,
        listeningSet: setId,
        review: sample,
      }

      dispatch(addFacilitatorReview(body, { history, token: authToken, type }))
    }
    if (type === "writing") {
      let body = {
        studentId: studentId,
        writingSet: setId,
        review: sample,
      }

      dispatch(addFacilitatorReview(body, { history, token: authToken, type }))
    }

    setDetailShow(false)
  }
  const handleReviewInfo = (e, setFieldValue) => {
    console.log("sas", facilitatorReview?.values?.getFacilitatorReview?.review)
    console.log("e.target.value", e.target.value)
    const findSection =
      facilitatorReview?.values?.getFacilitatorReview?.review.find(
        data => data?.section === e.target.value
      )
    if (findSection?.review) {
      setFieldValue("review", findSection?.review)
      setFieldValue("isDisable", true)
    } else {
      setFieldValue("review", "")
      setFieldValue("isDisable", false)
    }
    console.log("findSection", findSection)
  }
  const getPhotoUrl = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return packageProfile
  }
  const getPhotoUrl1 = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return packageImage
  }
  return (
    <React.Fragment>
      <div className="result-main-area">
        <Container className="g-0">
          <div className="result-inner-area">
            {/* <button onClick={() => scroll('target1')}>go</button> */}
            <h3 className="mt-5">
              <span onClick={() => history.goBack()}>
                <i className="bx bx-arrow-back cursor_pointers"></i>
              </span>
              Your Result
            </h3>
            {loading ? (
              <div className="text-center">
                <Loader />
              </div>
            ) : (
              <div className="result-main-area-inner-box">
                {type === "listening" ||
                type === "reading" ||
                (type === "writing" && results?.status === 2) ? (
                  <React.Fragment>
                    <h4>Package</h4>
                    <div className="course-result--profile-area first-result-box">
                      <Row>
                        <Col lg={3}>
                          <div
                            className="exem-course-result-package rounded-5 m-0"
                            style={{
                              boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.08)",
                              overflow: "hidden",
                            }}
                          >
                            <Card className="course-result--profile-box rounded-5 m-0">
                              <Card.Img
                                variant="top"
                                src={getPhotoUrl1(singlePackage?.photo)}
                              />
                              <Card.Body
                                style={{
                                  borderRadius: "0 0 10px 10px",
                                  overflow: "hidden",
                                }}
                              >
                                <div className="course-review-area-exam-page d-flex">
                                  <Box
                                    component="fieldset"
                                    borderColor="transparent"
                                  >
                                    <span>
                                      <Rating
                                        name="simple-controlled"
                                        precision={0.1}
                                        defaultValue={
                                          singlePackage?.aveRatings || 0
                                        }
                                        readOnly
                                      />
                                    </span>
                                  </Box>
                                  <span style={{ color: "black" }}>
                                    {singlePackage?.aveRatings}(
                                    {singlePackage?.ratings?.length})
                                  </span>
                                  {/* <span>
                                    <i className="bx bxs-star"></i>
                                  </span>
                                  <span>
                                    <i className="bx bxs-star"></i>
                                  </span>
                                  <span>
                                    <i className="bx bxs-star"></i>
                                  </span>
                                  <span>
                                    <i className="bx bxs-star"></i>
                                  </span>
                                  <span>
                                    <i className="bx bxs-star"></i>
                                  </span>
                                  <span className="count-review-exam-page">
                                    5.00 (3)
                                  </span> */}
                                </div>
                                <Card.Title
                                  className="course-review-area-exam-course-title"
                                  style={{
                                    fontSize: "18px",
                                  }}
                                >
                                  {singlePackage?.packageName}
                                </Card.Title>
                                {/* <Button variant="primary">Go somewhere</Button> */}
                              </Card.Body>
                            </Card>
                          </div>
                        </Col>
                        <Col lg={9}>
                          <div className="exam-result-profile-feedback-view ps-3">
                            <div className="exam-result-profile---content">
                              <img
                                style={{
                                  maxWidth: "100px",
                                  minHeight: "100px",
                                  objectFit: "cover",
                                }}
                                className="rounded-circle"
                                src={getPhotoUrl(profile?.photo)}
                                alt=""
                              />
                              <span>{profile?.fullName}</span>
                            </div>
                            <div
                              className={
                                type === "writing"
                                  ? "d-flex justify-content-center text-center gap-5 border-bottom py-4"
                                  : "exam-result-feedback---content"
                              }
                            >
                              {type === "writing" ? null : (
                                <div className="exam-result-feedback---item">
                                  <p>Correct Answers</p>
                                  <h6>
                                    {results?.mark}
                                    /40
                                  </h6>
                                </div>
                              )}
                              <div className="exam-result-feedback---item">
                                <p>Your Band Score</p>
                                <h6>
                                  {type === "writing"
                                    ? Math.ceil(results?.score)
                                    : Math.ceil(results?.mark)}
                                </h6>
                              </div>
                              <div className="exam-result-feedback---item">
                                <p>Time Spent</p>
                                <h6>{results?.timeTaken || 0} min</h6>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="exam-ques-your-position">
                            {/* <p>Review Brand Score</p>
                      <ul>
                        {score.map((list, idx, item) => {
                          return (
                            <li
                              key={idx}
                              className={
                                handleActiveClass(list, idx, item) === list
                                  ? 'your-exam-answer'
                                  : ''
                              }
                            >
                              {list}
                            </li>
                          );
                        })}
                      </ul> */}
                          </div>
                        </Col>
                      </Row>
                      <div className="pt-2 d-flex align-items-center">
                        <img
                          src={require(`./img/${imageHelper(type)}`).default}
                          alt="set"
                          width={"40px"}
                        />
                        Set : <strong>{results?.setId?.setName}</strong>
                      </div>
                      <div className="text-end">
                        <Button
                          className="btn btn-primary"
                          onClick={() => handleReview()}
                        >
                          Add Review
                        </Button>
                      </div>
                    </div>
                    <h4>Your answers keys</h4>
                  </React.Fragment>
                ) : null}
                {type !== "writing" ? (
                  <div>
                    {loading ? (
                      <div className="text-center">
                        <Loader />
                      </div>
                    ) : (
                      <div className="course-result--profile-area">
                        <Table borderless hover>
                          <thead>
                            <tr>
                              <th>Question no</th>
                              <th>Right answer</th>
                              <th>Your answer</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ansData?.map((ans, idx) => (
                              <tr
                                key={idx}
                                onClick={() =>
                                  scroll(
                                    `question${ans?.questionNumber}`,
                                    ans?.questionNumber
                                  )
                                }
                              >
                                <td>
                                  <span className="ques-id-serial">
                                    {ans?.questionNumber}
                                  </span>
                                </td>
                                <td>
                                  {ans?.rightAnswer?.map(
                                    (list, index) =>
                                      `${list} ${index === 0 ? "" : ","} `
                                  )}
                                </td>
                                <td>
                                  {ans?.answer?.map(
                                    (list, index) =>
                                      `${list} ${index === 0 ? "" : ","}`
                                  )}
                                </td>
                                <td>
                                  {ans.mark === 1 ? (
                                    <span className="correct-ans">
                                      <i className="bx bx-check"></i>
                                    </span>
                                  ) : (
                                    <span className="false-ans">
                                      <i className="bx bx-x"></i>
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    )}
                    <h4 id="target1" className="mt-5">
                      Review your answers and explanation
                    </h4>
                    {/* ans preview */}
                    {loading ? (
                      <div className="text-center">
                        <Loader />
                      </div>
                    ) : (
                      getData()
                    )}
                  </div>
                ) : (
                  <ReviewAnswerList results={results} />
                )}
                {type === "listening" ||
                type === "reading" ||
                (type === "writing" && results?.status === 2) ? (
                  <div className="p-3">
                    <h4 id="target1" className="mt-5">
                      Share your score
                    </h4>
                    <div className="border d-flex justify-content-center p-4">
                      <div className="d-flex gap-2">
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            backgroundColor: "#D2ECFA",
                            color: "#0073B1",
                            lineHeight: "60px",
                          }}
                          className="rounded-circle text-center"
                        >
                          <i className="bx bxl-linkedin fs-4"></i>
                        </div>
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            backgroundColor: "#BECFF7",
                            color: "#3D6AD6",
                            lineHeight: "60px",
                          }}
                          className="rounded-circle text-center"
                        >
                          <i className="bx bxl-facebook fs-4"></i>
                        </div>
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            backgroundColor: "#CCEEFC",
                            color: "#03A9F4",
                            lineHeight: "60px",
                          }}
                          className="rounded-circle text-center"
                        >
                          <i className="bx bxl-twitter fs-4"></i>
                        </div>
                        <div
                          style={{
                            width: "50px",
                            height: "50px",
                            backgroundColor: "#BECFF7",
                            color: "#3D6AD6",
                            lineHeight: "60px",
                          }}
                          className="rounded-circle text-center"
                        >
                          <i className="bx bx-link fs-4"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </Container>
      </div>
      <Modal
        size="md"
        show={detailShow}
        onHide={() => setDetailShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "none" }}
        ></Modal.Header>
        <Modal.Body style={{ padding: "3px 25px" }}>
          <Formik
            initialValues={values || initialValues}
            // validationSchema={createPackageSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {({ values, errors, setFieldValue }) => (
              <Form className="mt-2">
                <Row className="mb-3">
                  <Col md="12">
                    <React.Fragment>
                      <Row>
                        <Col md="12">
                          {type === "writing" ? (
                            <Field
                              name={`section`}
                              label={"section"}
                              type="select"
                              component={CustomInput}
                              placeholder="Select Section"
                              // options={typeLoading ? data : []}
                              defaultText="Course Type"
                              isRequired
                              onChangeCallback={e =>
                                handleReviewInfo(e, setFieldValue)
                              }
                              id={values.section || "Section"}
                            >
                              <option value="">Select Section</option>
                              {data1?.length > 0
                                ? data1?.map((option, idx) => (
                                    <option
                                      key={idx}
                                      value={option?.value}
                                      id={idx}
                                    >
                                      {option?.section}
                                    </option>
                                  ))
                                : null}
                            </Field>
                          ) : type === "reading" ? (
                            <Field
                              name={`section`}
                              label={"section"}
                              type="select"
                              component={CustomInput}
                              placeholder="Select Section"
                              // options={typeLoading ? data : []}
                              defaultText="Course Type"
                              isRequired
                              onChangeCallback={e =>
                                handleReviewInfo(e, setFieldValue)
                              }
                              id={values.section || "Section"}
                            >
                              <option value="">Select Section</option>
                              {data?.length > 0
                                ? data?.map((option, idx) => (
                                    <option
                                      key={idx}
                                      value={option?.value}
                                      id={idx}
                                    >
                                      {option?.section}
                                    </option>
                                  ))
                                : null}
                            </Field>
                          ) : (
                            <Field
                              name={`section`}
                              label={"section"}
                              type="select"
                              component={CustomInput}
                              placeholder="Select Section"
                              // options={typeLoading ? data : []}
                              defaultText="Course Type"
                              isRequired
                              onChangeCallback={e =>
                                handleReviewInfo(e, setFieldValue)
                              }
                              id={values.section || "Section"}
                            >
                              <option value="">Select Section</option>
                              {data2?.length > 0
                                ? data2?.map((option, idx) => (
                                    <option
                                      key={idx}
                                      value={option?.value}
                                      id={idx}
                                    >
                                      {option?.section}
                                    </option>
                                  ))
                                : null}
                            </Field>
                          )}
                        </Col>
                        <Col md="12" className="position-relative mt-3">
                          <Field
                            name={`review`}
                            label={"review"}
                            placeholder="Write..."
                            type="textarea"
                            disabled={values.isDisable}
                            component={CustomInput}
                            isRequired
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col md="4"></Col>
                  <Col md="4">
                    <Button
                      color="primary"
                      type="submit"
                      disabled={values.isDisable || adding}
                    >
                      {adding === true ? "Submitting" : "Add Review"}
                    </Button>
                  </Col>
                  <Col md="4"></Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(FacilitatorResult)
