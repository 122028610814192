import { ADD_AUTHOR, ADD_AUTHOR_FAIL, ADD_AUTHOR_SUCCESS, ADD_BLOG, ADD_BLOG_FAIL, ADD_BLOG_SUCCESS, GET_AUTHOR, GET_AUTHOR_FAIL, GET_AUTHOR_SUCCESS, GET_BLOG_POST, GET_BLOG_POST_FAIL, GET_BLOG_POST_SUCCESS } from "./actionTypes"

  
  const INIT_STATE = {
    loading: true,
    error: "",
    data: [],
    adding: false,
    authorList: [],
    blogList: [],
    authorLoading:true
  }
  
  const BlogReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case ADD_AUTHOR:
        return {
          ...state,
          adding: true,
        }
      case ADD_AUTHOR_SUCCESS:
        return {
          ...state,
          adding: false,
        }
      case ADD_AUTHOR_FAIL:
        return {
          ...state,
          adding: false,
          error: action.payload.data,
        }
        case GET_AUTHOR:
          return {
            ...state,
            authorLoading: true,
          };
        case GET_AUTHOR_SUCCESS:
          return {
            ...state,
            authorLoading: false,
            authorList: action.payload.data,
          };
        case GET_AUTHOR_FAIL:
          return {
            ...state,
            error: action.payload,
            authorLoading: false,
          };
          case ADD_AUTHOR:
            return {
              ...state,
              adding: true,
            }
          case ADD_AUTHOR_SUCCESS:
            return {
              ...state,
              adding: false,
            }
          case ADD_AUTHOR_FAIL:
            return {
              ...state,
              adding: false,
              error: action.payload.data,
            }
            case ADD_BLOG:
              return {
                ...state,
                adding: true,
              }
            case ADD_BLOG_SUCCESS:
              return {
                ...state,
                adding: false,
              }
            case ADD_BLOG_FAIL:
              return {
                ...state,
                adding: false,
                error: action.payload.data,
              }
              case GET_BLOG_POST:
                return {
                  ...state,
                  loading: true,
                };
              case GET_BLOG_POST_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  blogList: action.payload.data,
                };
              case GET_BLOG_POST_FAIL:
                return {
                  ...state,
                  error: action.payload,
                  loading: false,
                };
      
    
  
      default:
        return state
    }
  }
  
  export default BlogReducer
  