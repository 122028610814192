/* eslint-disable react/react-in-jsx-scope */
const ListeningIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5406_39822)">
        <path
          d="M19.9923 1.98096C8.96867 2.03214 0 11.042 0 22.0652C0 25.4128 0.839312 28.713 2.42646 31.6373V26.3273C2.42646 25.3487 2.77478 24.4409 3.38536 23.6622C3.33483 23.1326 3.30881 22.5996 3.30881 22.0652C3.30881 12.8579 10.7998 5.33258 19.9923 5.28983C29.2 5.33243 36.6909 12.8579 36.6909 22.0652C36.6909 22.6571 36.6581 23.2469 36.5962 23.8327C37.1268 24.5722 37.4265 25.4195 37.4265 26.3273V31.9066C39.1097 28.9166 40.0001 25.5156 40.0001 22.0652C40 11.042 31.0313 2.03214 19.9923 1.98096Z"
          fill="#04B300"
        />
        <path
          d="M3.65877 25.4698C3.57428 25.7467 3.5293 26.0334 3.5293 26.3274V31.4451V33.8273C3.5293 36.1419 6.28599 38.0185 9.68677 38.0185H12.2793V22.1362H9.68677C6.71786 22.1362 4.24044 23.5663 3.65877 25.4698Z"
          fill="#04B300"
        />
        <path
          d="M36.3242 33.8278V31.3623V26.3279C36.3242 26.1472 36.3055 25.9694 36.2728 25.7947C35.8879 23.7318 33.3019 22.1367 30.1668 22.1367H27.5742V38.0189H30.1668C33.5676 38.0189 36.3242 36.1424 36.3242 33.8278Z"
          fill="#04B300"
        />
      </g>
      <defs>
        <clipPath id="clip0_5406_39822">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ListeningIcon
