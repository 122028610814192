import {
  GET_SINGLE_PURCHASE,
  GET_SINGLE_PURCHASE_FAIL,
  GET_SINGLE_PURCHASE_SUCCESS,
  GET_SINGLE_STUDENT,
  GET_SINGLE_STUDENT_FAIL,
  GET_SINGLE_STUDENT_SUCCESS,
  GET_STUDENTS,
  GET_STUDENTS_SUCCESS,
  GET_STUDENT_COUNT,
  GET_STUDENT_COUNT_FAIL,
  GET_STUDENT_COUNT_SUCCESS,
  GET_STUDENT_LIST,
  GET_STUDENT_LIST_FAIL,
  GET_STUDENT_LIST_SUCCESS,
  GET_STUDENT_ORDER,
  GET_STUDENT_ORDER_FAIL,
  GET_STUDENT_ORDER_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: false,
  singlePurchase:[],
  singlePurchaseLoading: false,
  students: [],
  studentOrder: [],
  studentList: [],
  studentCount: [],
  studentCountLoading: false,
  studentOrderLoading: false,
  studentListLoading: false,
  student: {},
  message: "",
  error: "",
}

const studentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENTS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_STUDENTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        students: action.payload.students,
      }
      break

    case GET_SINGLE_STUDENT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SINGLE_STUDENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        student: action.payload.student,
      }
      break
    case GET_SINGLE_STUDENT_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      }
      break
      case GET_SINGLE_PURCHASE:
        state = {
          ...state,
          singlePurchaseLoading: true,
        }
        break
      case GET_SINGLE_PURCHASE_SUCCESS:
        state = {
          ...state,
          singlePurchaseLoading: false,
          singlePurchase: action.payload,
        }
        break
  
      case GET_SINGLE_PURCHASE_FAIL:
        state = {
          ...state,
          singlePurchaseLoading: true,
        }
        break
        case GET_STUDENT_ORDER:
          state = {
            ...state,
            studentOrderLoading: true,
          }
          break
        case GET_STUDENT_ORDER_SUCCESS:
          state = {
            ...state,
            studentOrderLoading: false,
            studentOrder: action.payload,
          }
          break
    
        case GET_STUDENT_ORDER_FAIL:
          state = {
            ...state,
            studentOrderLoading: true,
          }
          break
          case GET_STUDENT_LIST:
            state = {
              ...state,
              studentListLoading: true,
            }
            break
          case GET_STUDENT_LIST_SUCCESS:
            state = {
              ...state,
              studentListLoading: false,
              studentList: action.payload?.student,
            }
            break
      
          case GET_STUDENT_LIST_FAIL:
            state = {
              ...state,
              studentListLoading: false,
            }
            break
            case GET_STUDENT_COUNT:
              state = {
                ...state,
                studentCountLoading: true,
              }
              break
            case GET_STUDENT_COUNT_SUCCESS:
              state = {
                ...state,
                studentCountLoading: false,
                studentCount: action.payload.student,
              }
              break
        
            case GET_STUDENT_COUNT_FAIL:
              state = {
                ...state,
                studentCountLoading: false,
              }
              break
  }
  return state
}

export default studentReducer
