import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import { Button, Card, Col, Container, Label, Row } from "reactstrap"
import InnerLayer from "../../Layout/InnerLayer"
import CardComponent from "../../components/Common/CardComponent"
import TextError from "../../components/InputComponent/TextError"
import { image } from "../../helpers/api_helper"
import Upload from "../../pages/Package/Upload"
import { addAuthor } from "../../store/actions"

const AuthorRegister = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedFiles, setSelectedFiles] = useState([])
  const [fileError, setFileError] = useState("")
  const [imageUp, setImageUp] = useState("")
  const { authToken, adding } = useSelector(state => ({
    authToken: state.Login.token,
    adding: state.BlogReducer.adding,
  }))
  console.log("authToken", authToken)
  function handleSubmit(event, errors, values) {
    let body = {}
    if (
      values?.authorName === "" ||
      values?.authorPosition === "" ||
      values?.email === "" ||
      imageUp === ""
    ) {
      toast("Please fill up all the fields")
    } else {
      body.authorName = values?.authorName
      body.authorPosition = values?.authorPosition
      body.email = values?.email
      body.photo = imageUp
      dispatch(addAuthor(body, authToken, history))
    }

    console.log("values", values)
  }
  const handlePhoto = async file => {
    console.log("file", file)
    try {
      const url = `/image-upload`
      const formData = new FormData()
      formData.append("photo", file)
      const response = await image(url, formData, authToken)
      console.log("response", response)
      setImageUp(response?.photo)
    } catch (err) {
      toast("error", "Upload Image failed")
    }
  }
  console.log("imageUp", imageUp)
  return (
    <div>
      <InnerLayer>
        <div style={{ maxWidth: "500px" }} className="mx-auto">
          <CardComponent>
            <Container>
              <div className="profile-view-inner-wrap">
                <div className="profile-view-area">
                  <h4></h4>
                  <div className="profile-picture-area mt-4">
                    <div className="profile-picture-wrap"></div>
                    <AvForm onSubmit={handleSubmit}>
                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <AvField
                              name="authorName"
                              label="Author Name"
                              type="text"
                              errorMessage="Enter Full Name"
                              placeholder="Enter Full Name"
                              validate={{ required: { value: true } }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <AvField
                              name="authorPosition"
                              label="Designation"
                              type="text"
                              placeholder="Type designation"
                              errorMessage="Type designation"
                              validate={{
                                required: { value: true },
                              }}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="Email"
                              type="email"
                              placeholder="Enter Email"
                              errorMessage="Enter Email"
                              validate={{
                                required: { value: true },
                                email: {
                                  value: true,
                                  errorMessage: "Invalid Email format!",
                                },
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Label>Photo</Label>
                        <Col>
                          <Upload
                            uploadMessage={
                              selectedFiles.length > 0
                                ? "Drop files here or click to change."
                                : "Drop files here or click to upload."
                            }
                            setSelectedFiles={setSelectedFiles}
                            onDropCallBack={(files, values) => {
                              // console.log(`UploadContent ~ file`, files);
                              if (files.length > 0) {
                                handlePhoto(files?.[0])
                              }
                            }}
                          />
                          {selectedFiles?.length === 0 && fileError !== "" ? (
                            <TextError>{fileError}</TextError>
                          ) : null}
                          <Row>
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {selectedFiles?.length
                                ? selectedFiles.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                      >
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                              >
                                                {f.name}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {f.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    )
                                  })
                                : null}
                            </div>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <React.Fragment>
                          <div className="flex-wrap gap-2 col-md-12">
                            <Button
                              disabled={adding}
                              type="submit"
                              color="primary"
                              className="w-100"
                            >
                              {adding ? "Submitting" : "Add author"}
                            </Button>
                          </div>
                        </React.Fragment>
                      </Row>
                    </AvForm>
                  </div>
                </div>
              </div>
            </Container>
          </CardComponent>
        </div>
      </InnerLayer>
    </div>
  )
}

export default AuthorRegister
