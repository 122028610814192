import { css } from "@emotion/css"
import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { image } from "helpers/api_helper"
import React, { useEffect, useState } from "react"
import { Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import ScrollToBottom from "react-scroll-to-bottom"
import { Button, Col, Spinner } from "reactstrap"
import {
  assignIssue,
  getSingleChat,
  getUserChat,
  replyToUser,
  seenMessage,
  updateIssue,
} from "store/Chat/actions"
import InnerLayer from "../../Layout/InnerLayer"
import logo from "../../assets/images/logoX.svg"
import AttachmentIcon from "./icon/AttachmentIcon"
import ChatSendIcon from "./icon/ChatSendIcon"
import SearchIcon from "./icon/SearchIcon"
import logoEmpty from "./icon/logo.svg"
import "./support.scss"
const ChatBox = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [search, setSearch] = useState("")
  const [adminChat, setAdminChat] = useState("")
  const [clienChat, setClientChat] = useState("")
  const [adminMessage, setAdminMessage] = useState("")
  const [chatId, setChatId] = useState("")
  const [role, setRole] = useState("")
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [imageUp, setImageUp] = useState({
    localUrl: "",
    serverUrl: "",
    uploading: false,
  })
  const {
    authtoken,
    userListChatLoading,
    userListChat,
    singleUserMessage,
    singleUserMessageLoading,
    adding,
  } = useSelector(state => ({
    authtoken: state.Login.token,
    userListChat: state.ChatReducer.userListChat,
    userListChatLoading: state.ChatReducer.userListChatLoading,
    singleUserMessageLoading: state.ChatReducer.singleUserMessageLoading,
    singleUserMessage: state.ChatReducer.singleUserMessage,
    adding: state.ChatReducer.adding,
  }))
  console.log("singleUserMessage", singleUserMessage)
  const heightValue = "calc(100% - 25%)"
  const ROOT_CSS = css({
    // height: `${heightValue}`,
    height: 360,
  })
  useEffect(() => {
    dispatch(getUserChat(authtoken, 0))
  }, [])
  const handleUser = data => {
    console.log("klklk", data)
    setChatId(data?._id)
    setRole(data?.lastMessage?.role)
    if (data?.sender) {
      dispatch(getSingleChat(authtoken, data?.sender, "user"))
    }
    if (data?.unkownSender) {
      dispatch(getSingleChat(authtoken, data?.unkownSender, "nonUser"))
    }
    if (
      data?.lastMessage?.seen === false &&
      data?.lastMessage?.role !== "admin"
    ) {
      dispatch(
        seenMessage({}, history, authtoken, data?._id, data?.lastMessage?._id)
      )
    }
  }
  const sendMessage = () => {
    if (imageUp.uploading) return

    let body = {
      role: "admin",
      message: adminMessage ? adminMessage : null,
      photo: imageUp.serverUrl ? imageUp.serverUrl : null,
    }
    dispatch(
      replyToUser(
        body,
        history,
        authtoken,
        singleUserMessage?._id,
        singleUserMessage?.sender
          ? singleUserMessage?.sender
          : singleUserMessage?.unkownSender,
        singleUserMessage?.sender ? "user" : "nonUser"
      )
    )

    setAdminMessage("")
    setImageUp({
      localUrl: "",
      serverUrl: "",
      uploading: false,
    })
  }

  const handleToken = () => {
    setShow(true)
  }
  const handleSubmit = (event, errors, values) => {
    event.preventDefault()
    console.log("values", values)
    let body = {
      senderAdmin: values?.senderAdmin,
      problem: values?.problem,
    }
    dispatch(
      assignIssue(
        body,
        history,
        authtoken,
        singleUserMessage?._id,
        singleUserMessage?.sender
          ? singleUserMessage?.sender
          : singleUserMessage?.unkownSender,
        singleUserMessage?.sender ? false : true,
        setShow
      )
    )
  }
  const handleClose = () => setShow(false)
  const handleClose2 = () => setShow2(false)
  const solvedIssue = () => {
    dispatch(
      updateIssue(
        {},
        history,
        authtoken,
        singleUserMessage?._id,
        singleUserMessage?.sender
          ? singleUserMessage?.sender
          : singleUserMessage?.unkownSender,
        singleUserMessage?.sender ? "user" : "nonUser"
      )
    )
    setShow2(false)
  }
  const handleSolvedModalShow = () => {
    setShow2(true)
  }
  const handlePhoto = async e => {
    console.log("e.target.files", e.target.files)
    if (!e.target.files) return
    try {
      setImageUp(prev => ({
        ...prev,
        localUrl: URL.createObjectURL(e.target.files[0]),
        uploading: true,
      }))
      const url = `/image-upload`
      const formData = new FormData()
      formData.append("name", e.target.files[0]?.name)
      formData.append("photo", e.target.files[0])
      const response = await image(url, formData, authtoken)
      console.log("responseImage", response)

      setImageUp(prev => ({
        ...prev,
        serverUrl: response?.photo,
      }))
    } catch (err) {
      toast("error", "Upload Image failed")
    } finally {
      setImageUp(prev => ({
        ...prev,

        uploading: false,
      }))
    }
  }
  const handleRemove = () => {
    setImageUp({
      localUrl: "",
      serverUrl: "",
      uploading: false,
    })
  }
  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Chat box"
        mainTitle={"Dashboard"}
        subTitle={"chat"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <Modal show={show} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton style={{ padding: "18px 32px" }}>
            <h4 style={{ marginBottom: "0" }}>Token Generate</h4>
          </Modal.Header>

          <Modal.Body>
            <div style={{ padding: "0px 20px 20px 20px" }}>
              <AvForm onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <AvField
                        name="senderAdmin"
                        label="Supporter Email"
                        type="text"
                        placeholder="Type Supporter Email"
                        errorMessage="Type Supporter Email"
                        // value={editBannerData?.link}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <AvField
                        name="problem"
                        label="Problem"
                        type="textarea"
                        placeholder="Write the problem"
                        errorMessage="Write the problem"
                        // value={editBannerData?.link}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <React.Fragment>
                    {adding ? (
                      <div className="d-flex justify-content-end mt-3">
                        <Spinner className="ms-2" color="primary" />
                      </div>
                    ) : (
                      <div className="flex-wrap gap-2 col-md-12">
                        <Button
                          type="submit"
                          color="primary"
                          className="w-100"
                          style={{ background: "#0052CC" }}
                        >
                          Token Generate
                        </Button>
                      </div>
                    )}
                  </React.Fragment>
                </Row>
              </AvForm>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={show2} onHide={handleClose2} centered>
          <Modal.Header
            closeButton
            style={{ borderBottom: "none" }}
          ></Modal.Header>
          <Modal.Body className="text-center">
            <img src={logo} alt="" />
            <div className="mb-4"></div>
            <h5>Do you solve this Issue?</h5>
          </Modal.Body>
          <Modal.Footer
            style={{ borderTop: "none", justifyContent: "center" }}
            className="mb-4"
          >
            <Button
              variant="outline-secondary"
              onClick={handleClose2}
              style={{
                width: "112px",
                background: "#f46a6a",
                borderColor: "#f46a6a",
              }}
            >
              Cancel
            </Button>
            {adding ? (
              <div className="d-flex justify-content-end mt-3">
                <Spinner className="ms-2" color="primary" />
              </div>
            ) : (
              <Button
                className="btn button"
                style={{
                  background: "rgba(0, 82, 204, 1)",
                  borderColor: "rgba(0, 82, 204, 1)",
                }}
                onClick={solvedIssue}
              >
                Yes, I’m Sure
              </Button>
            )}
          </Modal.Footer>
        </Modal>
        <CardComponent className={"admin-chat-box-full-area-wrap"}>
          <div className="admin-chat-box-main-area-wrap">
            <div className="admin-chat-box-left-area">
              <div className="admin-chat-list-search">
                <div className="admin-chat-list-search-box">
                  <SearchIcon />
                  <input
                    type="search"
                    placeholder="Search...."
                    value={search}
                    onChange={e =>
                      setSearch(e.target.value?.toLocaleLowerCase())
                    }
                  />
                </div>
              </div>

              <div className="admin-chat-list-view">
                {userListChatLoading ? (
                  <div className="d-flex justify-content-center">
                    <Loader />
                  </div>
                ) : (
                  userListChat
                    ?.filter(item => {
                      return search?.toLocaleLowerCase() === ""
                        ? item
                        : item?.user?.toLocaleLowerCase().includes(search)
                    })
                    .map((data, idx) => (
                      <>
                        <div
                          className="admin-chat-list-single-persion"
                          style={
                            chatId === data?._id
                              ? { background: "#0052CC" }
                              : null
                          }
                          onClick={() => handleUser(data)}
                        >
                          <h4
                            style={
                              chatId === data?._id ? { color: "#fff" } : null
                            }
                          >
                            {data?.user?.split("@")?.[0]}
                          </h4>
                          <p
                            style={
                              chatId === data?._id
                                ? { background: "#0052CC", color: "#fff" }
                                : { background: "" }
                            }
                          >
                            {data?.lastMessage?.message}
                          </p>
                          {data?.lastMessage?.seen === true ||
                          data?.lastMessage?.role === "admin" ? (
                            ""
                          ) : (
                            <div className="unread-massage-indicator"></div>
                          )}
                        </div>
                      </>
                    ))
                )}
              </div>
            </div>
            {singleUserMessageLoading ? (
              <div className=" m-3 text-center">
                <Spinner />
              </div>
            ) : (
              <>
                {chatId !== "" ? (
                  <div className="admin-chat-box-right-area">
                    <div className="admin-chat-box-header">
                      <div className="chat-profile-info">
                        <h4>{singleUserMessage?.user?.split("@")?.[0]}</h4>
                        <p>Email: {singleUserMessage?.user}</p>
                      </div>
                      <div style={{ display: "flex", gap: "8px" }}>
                        <div className="chat-dec-end-confirm-right">
                          {singleUserMessage?.chat_status === 3 ? (
                            <button style={{ fontSize: "14px" }}>Solved</button>
                          ) : (
                            <button style={{ fontSize: "14px" }}>
                              <i
                                className="bx bx-check"
                                onClick={handleSolvedModalShow}
                              ></i>
                            </button>
                          )}
                        </div>
                        {singleUserMessage?.chat_status === 0 ? (
                          <button
                            style={{
                              border: "none",
                              background: "rgba(0, 82, 204, 1)",
                              color: "#fff",
                              borderRadius: "25px",
                              padding: "8px 20px",
                            }}
                            onClick={handleToken}
                          >
                            Token Generate
                          </button>
                        ) : singleUserMessage?.chat_status === 2 ? (
                          <button
                            style={{
                              border: "none",
                              background: "rgba(0, 82, 204, 1)",
                              color: "#fff",
                              borderRadius: "25px",
                              padding: "8px 20px",
                            }}
                            disabled
                          >
                            Issue Pending
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="admin-chat-box-content-body">
                      <div className="admin-chat-content-inner-wrap">
                        {/* <div className="admin-chat-content-left">
                     
                        <ScrollToBottom className={ROOT_CSS}>
                          <div className="client-chat-content-info-left">
                            <div className="single-chat">
                              <div className="chat-client-profile">
                                <h6>S</h6>
                              </div>
                              <div className="chat-client-content">
                                <p>{clienChat}</p>
                                <span>11:00am</span>
                              </div>
                              <div className="chat-client-issue-token-button">
                                <button>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </ScrollToBottom>
                     
                    </div> */}

                        <ScrollToBottom className={ROOT_CSS}>
                          {singleUserMessage?.chatArr?.map((data, idx) => (
                            <>
                              <div
                                className={
                                  data?.role === "admin"
                                    ? "client-chat-content-right"
                                    : "admin-chat-content-left"
                                }
                              >
                                <div
                                  className={
                                    data?.role === "admin"
                                      ? "client-chat-content-info-right"
                                      : "client-chat-content-info-left"
                                  }
                                >
                                  <div className="single-chat">
                                    {data?.role !== "admin" && (
                                      <div className="chat-client-profile">
                                        <h6>U</h6>
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "baseline",
                                        gap: " 6px",
                                      }}
                                    >
                                      {!!data?.message ? (
                                        <div className="chat-client-content">
                                          <p>{data?.message}</p>
                                          {/* <span>11:00am</span> */}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {data?.photo ? (
                                        <img
                                          src={data?.photo}
                                          style={{
                                            width: "50%",
                                            objectFit: "content",
                                          }}
                                          alt=""
                                        />
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </ScrollToBottom>
                      </div>
                    </div>
                    <div className="admin-chat-box-text-input-bottom">
                      {imageUp.localUrl ? (
                        <div className="chat-image">
                          <div
                            className="chat-image-single-box"
                            style={{ position: "relative" }}
                          >
                            {imageUp.uploading ? (
                              <i className="bx bx-loader-alt bx-spin image-spin"></i>
                            ) : (
                              <i className="bx bx-x" onClick={handleRemove}></i>
                            )}

                            <img src={imageUp.localUrl} alt="" />
                          </div>
                        </div>
                      ) : null}
                      <div className="pre-define-ques-answer-left"></div>
                      <div className="input-massage-type-answer">
                        <input
                          type="text"
                          className="form-control"
                          // placeholder="admin"
                          onKeyPress={event => {
                            event.key === "Enter" && sendMessage()
                          }}
                          onChange={e => setAdminMessage(e.target.value)}
                        />
                      </div>
                      <div className="chat-upload-file">
                        <label htmlFor="file-upload">
                          <AttachmentIcon />
                        </label>
                        <input
                          type="file"
                          name=""
                          id="file-upload"
                          onChange={e => handlePhoto(e)}
                        />
                      </div>
                      <div
                        className="chat-send-massage-button"
                        onClick={sendMessage}
                        disabled={imageUp.uploading}
                      >
                        <ChatSendIcon />
                      </div>
                      {/* <input
                  type="text"
                  className="form-control"
                  placeholder="customer"
                  onChange={e => setClientChat(e.target.value)}
                /> */}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="empty-image-empty-massage">
                      <img src={logoEmpty} alt="" />
                      <h3>Give support to student</h3>
                      <p>
                        Please Provide the necessary support <br /> to students
                      </p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </CardComponent>
      </InnerLayer>
    </React.Fragment>
  )
}

export default ChatBox
