import {
  ADD_REVIEW,
  ADD_REVIEW_FAIL,
  ADD_REVIEW_SUCCESS,
  CHECK_GRAMMAR,
  CHECK_GRAMMAR_FAIL,
  CHECK_GRAMMAR_SUCCESS,
  GET_ALL_ANSWER,
  GET_ALL_ANSWER_FAIL,
  GET_ALL_ANSWER_SUCCESS,
  GET_PARTICULAR_SET,
  GET_PARTICULAR_SET_FAIL,
  GET_PARTICULAR_SET_SUCCESS,
  GET_REVIEW_EXAM,
  GET_REVIEW_EXAM_FAIL,
  GET_REVIEW_EXAM_SUCCESS,
  REMOVE_REVIEW_EXAM,
  REMOVE_REVIEW_EXAM_FAIL,
  REMOVE_REVIEW_EXAM_SUCCESS,
} from "./actionTypes"

export const addReview = (values, options) => {
  return {
    type: ADD_REVIEW,
    payload: { values, options },
  }
}
export const addReviewSuccess = message => {
  return {
    type: ADD_REVIEW_SUCCESS,
    payload: { message },
  }
}

export const addReviewFail = message => {
  return {
    type: ADD_REVIEW_FAIL,
    payload: { message },
  }
}
export const getAllAnswer = token => {
  return {
    type: GET_ALL_ANSWER,
    payload: { token },
  }
}

export const getAllAnswerSuccess = values => {
  return {
    type: GET_ALL_ANSWER_SUCCESS,
    payload: { values },
  }
}

export const getAllAnswerFail = message => {
  return {
    type: GET_ALL_ANSWER_FAIL,
    payload: { message },
  }
}

export const getParticularSet = (token, options) => {
  return {
    type: GET_PARTICULAR_SET,
    payload: { token, options },
  }
}

export const getParticularSetSuccess = values => {
  return {
    type: GET_PARTICULAR_SET_SUCCESS,
    payload: { values },
  }
}

export const getParticularSetFail = message => {
  return {
    type: GET_PARTICULAR_SET_FAIL,
    payload: { message },
  }
}

export const getReviewExam = (token, options) => {
  return {
    type: GET_REVIEW_EXAM,
    payload: { token, options },
  }
}

export const getReviewExamSuccess = values => {
  return {
    type: GET_REVIEW_EXAM_SUCCESS,
    payload: { values },
  }
}

export const getReviewExamFail = message => {
  return {
    type: GET_REVIEW_EXAM_FAIL,
    payload: { message },
  }
}

export const removeReviewExam = (token, options) => {
  return {
    type: REMOVE_REVIEW_EXAM,
    payload: { token, options },
  }
}

export const removeReviewExamSuccess = values => {
  return {
    type: REMOVE_REVIEW_EXAM_SUCCESS,
    payload: { values },
  }
}

export const removeReviewExamFail = message => {
  return {
    type: REMOVE_REVIEW_EXAM_FAIL,
    payload: { message },
  }
}

export const adminCheckGrammar = (authToken, body, setId, studentId, serial, history) => {
  return {
    type: CHECK_GRAMMAR,
    payload: { authToken, body, setId, studentId, serial, history },
  }
}

export const adminCheckGrammarSuccess = values => {
  return {
    type: CHECK_GRAMMAR_SUCCESS,
    payload: { values },
  }
}

export const adminCheckGrammarFail = message => {
  return {
    type: CHECK_GRAMMAR_FAIL,
    payload: { message },
  }
}

