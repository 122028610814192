import React, { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import Select from "react-select"
import { Button, Row } from "reactstrap"
import { getAllVerbalQues } from "store/GMAT/Verbal/actions"
import CustomTable from "../../../Layout/CustomTable"
import InnerLayer from "../../../Layout/InnerLayer"
import NoTableData from "../../../Layout/NoTableData"
import CardComponent from "../../../components/Common/CardComponent"
import Loader from "../../../components/Common/Loader"
import TableDropdown from "../../../components/Common/TableDropdown"
import { imageBaseUrl } from "../../../helpers/api_helper"
const tableHead = ["#", "Question", "Type", "level", "Date", ""]

const quesType = [
  {
    name: "Reading Comprehension",
    value: 1,
  },
  {
    name: "Sentence Equavalance",
    value: 2,
  },
  {
    name: "Text Comprehension",
    value: 3,
  },
]

const levelType = [
  {
    name: "Easy",
    value: 1,
  },
  {
    name: "Medium",
    value: 2,
  },
  {
    name: "Difficult",
    value: 3,
  },
]
const VerbalQuesList = ({ history }) => {
  const dispatch = useDispatch()
  const [type, setType] = useState(1)
  const { section } = useParams()
  const [level, setLevel] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const {
    verbalQuestion,
    verbalQuestionLoading,
    authToken,
    singleVerbalQues,
    singleVerbalQuesLoading,
  } = useSelector(state => ({
    verbalQuestionLoading: state.verbalReducer.verbalQuestionLoading,
    verbalQuestion: state.verbalReducer.verbalQuestion,

    authToken: state.Login.token,
  }))
  console.log("verbalQuestion", verbalQuestion)
  useEffect(() => {
    dispatch(getAllVerbalQues(authToken, section, level))
  }, [section, level])
  const getPhotoUrl = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return logo
  }
  const handleType = data => {
    if (data) {
      setType(data?.value)
    } else {
      setType("")
    }
  }

  const handleLabel = data => {
    if (data) {
      setLevel(data?.value)
    } else {
      setLevel("")
    }
  }
  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Verbal Question"
        mainTitle={"Dashboard"}
        subTitle={"Verbal Question"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <CardComponent>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Question List</h4>
            <div className="d-flex">
              <div className="search-box me-2">
                <div className="position-relative">
                  <input
                    type="search"
                    placeholder="Search..."
                    // value={keyword}
                    // onChange={handleSearchChange}
                    className="form-control"
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
              <div>
                <div className="position-relative">
                  <Link to={`/verbal-ques-create/${section}`}>
                    <Button
                      type="button"
                      color="primary"
                      className="btn-circle me-2"
                    >
                      <i className="mdi mdi-plus me-1" />
                      Create Question
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardComponent>

        {/* list */}
        <Row>
          <CardComponent className="position-relative">
            <div>
              <div
                style={{
                  width: "200px",
                  marginLeft: "auto",
                  marginBottom: "10px",
                  marginBottom: "10px",
                }}
              >
                <Select
                  name="status"
                  className="select-form-width"
                  classNamePrefix="select2-selection"
                  placeholder="Select level"
                  cacheOptions
                  getOptionLabel={e => e.name}
                  getOptionValue={e => e.value}
                  isClearable
                  options={levelType}
                  onChange={handleLabel}
                />
              </div>
            </div>
            <CustomTable
              paginationClass="paginationContainer text-right"
              data={verbalQuestion}
              pageNo={1}
              tableHead={tableHead}
              setCurrentPage={setCurrentPage}
              isPagination
              // setCurrentPage={setCurrentPage}
              // tbodyStyle={{ height: couponData?.length <= 3 ? "300px" : "" }}
            >
              {verbalQuestionLoading ? (
                <NoTableData
                  colSpan="8"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <Loader />
                </NoTableData>
              ) : verbalQuestion?.length > 0 ? (
                verbalQuestion?.map((data, idx) => (
                  <tr key={idx}>
                    <th scope="row" style={{ paddingLeft: "25px" }}>
                      {(currentPage - 1) * 10 + (idx + 1)}
                    </th>

                    {/* <td>{removeHash(data?.q)?.slice(3,-4)}</td> */}
                    <td>{data?.questionTitle?.slice(0, 100)}...</td>
                    <td>
                      {section === "2"
                        ? "Sentence equivalence"
                        : "Text comprehension"}
                    </td>
                    <td>
                      {data?.difficultyLevel === 1
                        ? "easy"
                        : data?.difficultyLevel === 2
                        ? "medium"
                        : "hard"}
                    </td>
                    <td>{data?.createdAt?.slice(0, 10)}</td>
                    <td>
                      <TableDropdown>
                        <Dropdown.Item
                          className="d-flex align-items-center gap-2"
                          onClick={() => {
                            history.push(
                              `/verbal-ques-create/${data?._id}/edit/${section}`
                            )
                          }}
                        >
                          <i className="bx bx-edit fs-5"></i> Edit
                        </Dropdown.Item>
                      </TableDropdown>
                    </td>
                  </tr>
                ))
              ) : (
                <NoTableData
                  colSpan="8"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <span>No data Available</span>
                </NoTableData>
              )}
            </CustomTable>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(VerbalQuesList)
