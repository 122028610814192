import { Field, FieldArray, Form, Formik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Button, Label } from "reactstrap"
import { del } from "../../../helpers/api_helper"

import { toaster } from "helpers/Custom/Toaster"
// import { addAptitudeQuestion } from "../../../Store/Admin/CorporateQuestions/CorporateQuestionSlice";
import "../gmat.scss"

import { QuantativeWritingQuesSchema } from "components/Schemas/QuestionSchemas"
// import QuestionTopCard from "./Common/QuestionTopCard";
import CardComponent from "components/Common/CardComponent"
import QuestionTopCard from "components/Common/QuestionTopCard"
import CustomInput from "components/InputComponent/CustomInput"
import FileUpload from "components/InputComponent/FileUpload"
import { checkImage } from "helpers/Custom/Questions"
import {
  addQuantitativeQues,
  getSingleQuantitativeQues,
} from "store/GMAT/Quantative/actions"
import InnerLayer from "../../../Layout/InnerLayer"
import PageHeader from "../../../Layout/PageHeader"
const section = [
  {
    name: "data Sufficency",
    value: 1,
  },
  {
    name: "problem Solving",
    value: 2,
  },
]
const difficultyLevel = [
  {
    name: "easy",
    value: 1,
  },
  {
    name: "medium",
    value: 2,
  },
  {
    name: "difficult",
    value: 3,
  },
]
const quesType = [
  {
    name: "single",
    value: 1,
  },
  {
    name: "multi",
    value: 2,
  },
]
const initialValues = {
  difficultyLevel: "",
  questype: "",
  explanation: "",
  q: "",
  rightAnswer: [""],
  // explanation: "",
  optionType: "",
  options: [
    {
      value: "",
      isImage: false,
    },
  ],
  image: null,
}
const optionArray = [
  "Statement (1) ALONE is sufficient, but statement (2) alone is not sufficient.",
  "Statement (2) ALONE is sufficient, but statement (1) alone is not sufficient.",
  "BOTH statements TOGETHER are sufficient, but NEITHER statement ALONE is sufficient.",
  "EACH statement ALONE is sufficient.",
  "Statements (1) and (2) TOGETHER are NOT sufficient.",
]

const QuantativeWritingQues = ({ history, api }) => {
  const textAreaRef = useRef(null)
  const { passageId, questionId, serviceId, section, type } = useParams()
  console.log("questionId", questionId)
  const [listValue, setListValue] = useState(null)
  const [isAddMore, setIsAddMore] = useState(false)
  const [request, setRequest] = useState(false)
  const [sectionData, setSection] = useState("")
  const title = ` Question Type`
  const title2 = questionId ? `Edit  Question` : `Create Question`
  const breadcrumbItems = [
    {
      title: title,
      link: `/Quantative-ques-type`,
    },
    {
      title: title2,
      link: `#`,
    },
  ]

  const dispatch = useDispatch()
  const { singleQuantitativeQues, uploading, authToken } = useSelector(
    state => ({
      loading: state.analyticalReducer.loading,
      adding: state.analyticalReducer.adding,
      questionNumber: state.analyticalReducer.questionNumber,
      singleQuantitativeQues: state.QuantitativeReducer.singleQuantitativeQues,
      questionGroup: state.analyticalReducer.questionGroup,
      uploading: state.analyticalReducer.uploading,
      authToken: state.Login.token,
    })
  )
  console.log(`singleQuantitativeQues`, singleQuantitativeQues)

  useEffect(() => {
    if (questionId) {
      dispatch(getSingleQuantitativeQues(authToken, questionId))
    }
  }, [questionId])

  useEffect(() => {
    if (questionId && !!singleQuantitativeQues) {
      const value = { ...listValue }
      value.difficultyLevel = singleQuantitativeQues?.difficultyLevel || ""
      value.q = singleQuantitativeQues?.q || ""
      value.questype = singleQuantitativeQues?.questype || ""
      value.options = singleQuantitativeQues?.options?.map(el => {
        let obj = {}
        obj.value = el
        if (checkImage(el)) {
          obj.isImage = true
        } else {
          obj.isImage = false
        }
        return obj
      }) || [""]
      // value.answer = singleQuantitativeQues?.answer?.[0];
      value.explanation = singleQuantitativeQues?.explanation || ""
      value.image = singleQuantitativeQues?.image || null

      value.rightAnswer = singleQuantitativeQues?.rightAnswer?.map(el =>
        el?.trim()
      )
      setListValue(value)
    }
  }, [singleQuantitativeQues])

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    console.log("values", values)
    const { difficultyLevel, questype, rightAnswer, options, ...rest } = values
    const obj = {
      ...rest,

      options: optionArray?.map(el => el?.trim()),
      rightAnswer: rightAnswer?.map(el => el?.trim()),
      difficultyLevel: difficultyLevel * 1,
      questype: questype * 1,
      section: section * 1,
    }
    const option = {
      isMore: isAddMore,
      resetForm,
      setSubmitting,
      setIsAddMore,
      id: questionId,
      passageId,
      history,
      serviceId,
      type,
    }
    console.log(`obj`, obj, option)
    dispatch(addQuantitativeQues(obj, authToken, option))
    // setSubmitting(false);
  }

  const removeFile = async (id, setFieldValue, name) => {
    let success = false
    setRequest(true)
    try {
      const url = `/data-dump/delete-file/${id}`
      const response = await del(url)
      if (response) {
        success = true
        setFieldValue(name, "")
      }
    } catch (err) {
      toaster("error", "Remove file failed")
    } finally {
      setRequest(false)
    }
    return success
  }

  function copyToClipboard(e) {
    textAreaRef.current.select()
    document.execCommand("copy")
    e.target.focus()
    toaster("success", "Url copied successful")
  }
  const handleSection = data => {
    if (data?.value) {
      setSection(data?.value)
    } else {
      setSection("")
    }
  }
  return (
    <React.Fragment>
      <InnerLayer>
        <PageHeader title={title2} breadcrumbItems={breadcrumbItems} />
        <Row className="create_questions">
          <Col md="10" className="offset-md-1">
            <QuestionTopCard
              history={history}
              title={`${
                questionId ? "Update" : "Create"
              } Quantitative question`}
            />
            <CardComponent style={{ marginTop: "5px" }}>
              <h5>Type question</h5>
              <br />
              <Formik
                initialValues={listValue || initialValues}
                validationSchema={QuantativeWritingQuesSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  setFieldValue,
                  isSubmitting,
                  handleSubmit,
                }) => (
                  <Form>
                    {console.log("values,", values, errors)}

                    <Row className="mb-3">
                      <Col md="12 mb-3">
                        <Field
                          label="Difficulty level"
                          name="difficultyLevel"
                          type="select"
                          id={"difficultyLevel"}
                          component={CustomInput}
                        >
                          <option value="">Select...</option>
                          <option value="1">Easy</option>
                          <option value="2">Medium</option>
                          <option value="3">difficult</option>
                        </Field>
                      </Col>
                      <Col md="12 mb-3">
                        <Field
                          label="Question Type"
                          name="questype"
                          type="select"
                          id={"questype"}
                          component={CustomInput}
                        >
                          <option value="">Select...</option>
                          <option value="1">single</option>
                          <option value="2">multi</option>
                        </Field>
                      </Col>
                      <Col md="12 mb-3">
                        <Field
                          label="Question Title"
                          name="q"
                          type="text"
                          id={"question"}
                          component={CustomInput}
                          placeholder="Type paragraph title"
                        />
                      </Col>
                    </Row>
                    {/* <FormikArray values={values} name="opt" labelName="Option" /> */}
                    {/* <Col md="12" className="mb-3">
                      <FieldArray
                        name="options"
                        render={arrayHelpers => (
                          <Row>
                            {values?.options?.map((value, index) => (
                              <React.Fragment key={index}>
                                <Col md="6" className="position-relative mb-3">
                                  <Label
                                    className="d-flex justify-content-between align-items-center gap-2"
                                    htmlFor={`options.${index}.value`}
                                  >
                                    <span className="text-nowrap">
                                      {`Option ${index + 1}`}
                                    </span>
                                    <Field
                                      name={`options.${index}.isImage`}
                                      id={`options.${index}.isImage`}
                                      label="Image?"
                                      component={CheckBox}
                                      onClick={e => {
                                        !request &&
                                          checkImage(value?.value) &&
                                          removeFile(
                                            getImageUrl(value?.value),
                                            setFieldValue,
                                            `options.${index}.value`
                                          )
                                        setFieldValue(
                                          `options.${index}.value`,
                                          ""
                                        )
                                      }}
                                    />
                                  </Label>
                                  {value?.isImage ? (
                                    <React.Fragment>
                                      <Field
                                        name={`options.${index}.value`}
                                        dropText={`Upload`}
                                        component={FileUpload}
                                        textStartWrapper="img@&#["
                                        textEndWrapper="]#&@img"
                                        // isBrowse={false}
                                        dropZoneClass="dropZoneClass APTDropZone"
                                        uploadFor="Activation"
                                        imageDefault={false}
                                        // imageId={`options.${index}.url`}
                                      />
                                      {value?.value &&
                                      checkImage(value?.value) ? (
                                        <div
                                          className="position-relative"
                                          style={{ width: "100%" }}
                                        >
                                          <i
                                            className="fs-2 text-danger cursor_pointers position-absolute bx bx-trash"
                                            style={{
                                              top: "2%",
                                              right: "2%",
                                            }}
                                            onClick={() =>
                                              !request &&
                                              removeFile(
                                                getImageUrl(value?.value),
                                                setFieldValue,
                                                `options.${index}.value`
                                              )
                                            }
                                          ></i>
                                          <div onClick={copyToClipboard}>
                                            <img
                                              style={{
                                                height: "110px",
                                                objectFit: "cover",
                                              }}
                                              src={getImageUrl(value?.value)}
                                              alt="img"
                                            />
                                            <input
                                              title="click to copy"
                                              className="d-block border-0"
                                              ref={textAreaRef}
                                              value={getImageUrl(value?.value)}
                                            />
                                          </div>
                                        </div>
                                      ) : null}
                                    </React.Fragment>
                                  ) : (
                                    <Field
                                      name={`options.${index}.value`}
                                      type={"text"}
                                      id={`options.${index}.value`}
                                      // label={`Option ${index + 1}`}
                                      component={CustomInput}
                                      placeholder="Type option"
                                      isArray
                                    />
                                  )}
                                  {index > 0 ? (
                                    <div
                                      className="position-absolute"
                                      style={{
                                        top: "25px",
                                        // transform : ""
                                        width: "initial",
                                        right: "1.5%",
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <button
                                        className="btn-sm removeShadow"
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <i className="bx bx-trash text-danger"></i>
                                      </button>
                                    </div>
                                  ) : null}
                                </Col>
                              </React.Fragment>
                            ))}

                            <Col md="12 mb-3">
                              <button
                                type="button"
                                className="d-inline-block btn button-add"
                                onClick={() =>
                                  arrayHelpers.push({
                                    value: "",
                                    image: "",
                                  })
                                }
                              >
                                <i className="bx bx-plus"></i> Add Another
                                Option
                              </button>
                            </Col>
                          </Row>
                        )}
                      />
                    </Col> */}

                    <Col md="12" className="mb-3">
                      <FieldArray
                        name="rightAnswer"
                        render={arrayHelpers => (
                          <Row>
                            {values?.rightAnswer?.map((value, index) => (
                              <React.Fragment key={index}>
                                <Col md="6" className="position-relative mb-3">
                                  <Label
                                    className="d-flex justify-content-between align-items-center gap-2"
                                    htmlFor={`rightAnswer.${index}`}
                                  >
                                    <span className="text-nowrap">
                                      {`Right Answer ${index + 1}`}
                                    </span>
                                  </Label>

                                  <Field
                                    name={`rightAnswer.${index}`}
                                    type={"text"}
                                    id={`rightAnswer.${index}`}
                                    // label={`Option ${index + 1}`}
                                    component={CustomInput}
                                    placeholder="Type Answer"
                                    isArray
                                  />

                                  {index > 0 ? (
                                    <div
                                      className="position-absolute"
                                      style={{
                                        top: "25px",
                                        // transform : ""
                                        width: "initial",
                                        right: "1.5%",
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <button
                                        className="btn-sm removeShadow"
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <i className="bx bx-trash text-danger"></i>
                                      </button>
                                    </div>
                                  ) : null}
                                </Col>
                              </React.Fragment>
                            ))}
                            {values?.questype === "2" ? (
                              <Col md="12">
                                <button
                                  type="button"
                                  className="d-inline-block btn button-add"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  <i className="bx bx-plus"></i> Add Another
                                  Option
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        )}
                      />
                    </Col>

                    <Col md="6 mb-3">
                      <Field
                        label="Explanation"
                        name="explanation"
                        type="text"
                        id={"explanation"}
                        component={CustomInput}
                        placeholder="Type explanation"
                      />
                    </Col>
                    <Col md="12 mb-3">
                      <Field
                        name="image"
                        label={"Upload Image"}
                        type="file"
                        component={FileUpload}
                        isRequired
                        isFull
                      />
                    </Col>
                    <Row className="mb-3">
                      <Col md="12" className="d-flex justify-content-end">
                        <Button
                          color="warning"
                          outline
                          className="me-2 btn btn-outline-blue"
                          disabled={isSubmitting || uploading}
                          type="submit"
                        >
                          {isSubmitting
                            ? "Submitting..."
                            : questionId
                            ? "Update"
                            : "Save"}
                        </Button>
                        {!questionId ? (
                          <button
                            color="primary"
                            style={{ color: "white" }}
                            disabled={isSubmitting || uploading}
                            type="button"
                            onClick={() => {
                              setIsAddMore(true)
                              handleSubmit()
                            }}
                            className="btn button-blue"
                          >
                            {isSubmitting
                              ? "Submitting..."
                              : "Create more question"}
                          </button>
                        ) : null}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default QuantativeWritingQues
