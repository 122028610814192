import {
  GET_AVG_SCORE,
  GET_AVG_SCORE_FAIL,
  GET_AVG_SCORE_SUCCESS,
  GET_ENROLLMENT_STATUS,
  GET_ENROLLMENT_STATUS_FAIL,
  GET_ENROLLMENT_STATUS_SUCCESS,
  GET_NET_AMOUNT,
  GET_NET_AMOUNT_FAIL,
  GET_NET_AMOUNT_SUCCESS,
  GET_NUMBER_STUDENTS,
  GET_NUMBER_STUDENTS_FAIL,
  GET_NUMBER_STUDENTS_SUCCESS,
  GET_PACKAGE_CONTRIBUTION,
  GET_PACKAGE_CONTRIBUTION_FAIL,
  GET_PACKAGE_CONTRIBUTION_SUCCESS,
  CREATE_UNIVERSITY,
  CREATE_UNIVERSITY_SUCCESS,
  CREATE_UNIVERSITY_FAIL,
  STORE_ADMIN_DASHBOARD_DATA,
  GET_UNIVERSITY,
  GET_UNIVERSITY_SUCCESS,
  GET_UNIVERSITY_FAIL,
  GET_GOOGLE_AGE,
  GET_GOOGLE_AGE_SUCCESS,
  GET_GOOGLE_AGE_FAIL,
  GET_GOOGLE_ANALYTICS,
  GET_GOOGLE_ANALYTICS_SUCCESS,
  GET_GOOGLE_ANALYTICS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  studentInfoData: [],
  avgScore: [],
  netAmount: [],
  packageContribution: [],
  studentEnroll: [],
  universityList: [],
  universityLoading: false,
  valueStatus: "",
  studentInfoLoading: false,
  packageContributionLoading: false,
  avgScoreLoading: false,
  netAmountLoading: false,
  studentEnrollLoading: false,
  googleAnalyticsLoading: false,
  googleAnalytics:[],
  adding: false
}

const AdminDashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_UNIVERSITY:
      return {
        ...state,
        adding: true,
      }
    case CREATE_UNIVERSITY_SUCCESS:
      return {
        ...state,
        adding: false,
      }
    case CREATE_UNIVERSITY_FAIL:
      return {
        ...state,
        adding: false,
        error: action.payload.data,
      }
    case GET_NUMBER_STUDENTS:
      return {
        ...state,
        studentInfoLoading: true,
      }
    case GET_NUMBER_STUDENTS_SUCCESS:
      return {
        ...state,
        studentInfoLoading: false,
        studentInfoData: action.payload.data,
      }
    case GET_NUMBER_STUDENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        studentInfoLoading: false,
      }
    case GET_AVG_SCORE:
      return {
        ...state,
        avgScoreLoading: true,
      }
    case GET_AVG_SCORE_SUCCESS:
      return {
        ...state,
        avgScoreLoading: false,
        avgScore: action.payload.data,
      }
    case GET_AVG_SCORE_FAIL:
      return {
        ...state,
        error: action.payload,
        avgScoreLoading: false,
      }
    case GET_NET_AMOUNT:
      return {
        ...state,
        netAmountLoading: true,
      }
    case GET_NET_AMOUNT_SUCCESS:
      return {
        ...state,
        netAmountLoading: false,
        netAmount: action.payload.data,
      }
    case GET_NET_AMOUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        netAmountLoading: false,
      }
    case GET_PACKAGE_CONTRIBUTION:
      return {
        ...state,
        packageContributionLoading: true,
      }
    case GET_PACKAGE_CONTRIBUTION_SUCCESS:
      return {
        ...state,
        packageContributionLoading: false,
        packageContribution: action.payload.data,
      }
    case GET_PACKAGE_CONTRIBUTION_FAIL:
      return {
        ...state,
        error: action.payload,
        packageContributionLoading: false,
      }
      case GET_ENROLLMENT_STATUS:
        return {
          ...state,
          studentEnrollLoading: true,
        }
      case GET_ENROLLMENT_STATUS_SUCCESS:
        return {
          ...state,
          studentEnrollLoading: false,
          studentEnroll: action.payload.data,
        }
      case GET_ENROLLMENT_STATUS_FAIL:
        return {
          ...state,
          error: action.payload,
          studentEnrollLoading: false,
        }
        case GET_UNIVERSITY:
          return {
            ...state,
            universityLoading: true,
          }
        case GET_UNIVERSITY_SUCCESS:
          return {
            ...state,
            universityLoading: false,
            universityList: action.payload.data,
          }
        case GET_UNIVERSITY_FAIL:
          return {
            ...state,
            error: action.payload,
            universityLoading: false,
          }

          case GET_GOOGLE_ANALYTICS:
            return {
              ...state,
              googleAnalyticsLoading: true,
            }
          case GET_GOOGLE_ANALYTICS_SUCCESS:
            return {
              ...state,
              googleAnalyticsLoading: false,
              googleAnalytics: action.payload.data,
            }
          case GET_GOOGLE_ANALYTICS_FAIL:
            return {
              ...state,
              error: action.payload,
              googleAnalyticsLoading: false,
            }
    case STORE_ADMIN_DASHBOARD_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      }
    default:
      return state
  }
}

export default AdminDashboardReducer
