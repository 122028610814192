export const ADD_REVIEW = "ADD_REVIEW"
export const ADD_REVIEW_SUCCESS = "ADD_REVIEW_SUCCESS"
export const ADD_REVIEW_FAIL = "ADD_REVIEW_FAIL"

export const GET_ALL_ANSWER = "GET_ALL_ANSWER"
export const GET_ALL_ANSWER_SUCCESS = "GET_ALL_ANSWER_SUCCESS"
export const GET_ALL_ANSWER_FAIL = "GET_ALL_ANSWER_FAIL"

export const GET_PARTICULAR_SET = "GET_PARTICULAR_SET"
export const GET_PARTICULAR_SET_SUCCESS = "GET_PARTICULAR_SET_SUCCESS"
export const GET_PARTICULAR_SET_FAIL = "GET_PARTICULAR_SET_FAIL"

export const GET_REVIEW_EXAM = "GET_REVIEW_EXAM"
export const GET_REVIEW_EXAM_SUCCESS = "GET_REVIEW_EXAM_SUCCESS"
export const GET_REVIEW_EXAM_FAIL = "GET_REVIEW_EXAM_FAIL"

export const REMOVE_REVIEW_EXAM = "REMOVE_REVIEW_EXAM"
export const REMOVE_REVIEW_EXAM_SUCCESS = "REMOVE_REVIEW_EXAM_SUCCESS"
export const REMOVE_REVIEW_EXAM_FAIL = "REMOVE_REVIEW_EXAM_FAIL"

export const CHECK_GRAMMAR = "CHECK_GRAMMAR"
export const CHECK_GRAMMAR_SUCCESS = "CHECK_GRAMMAR_SUCCESS"
export const CHECK_GRAMMAR_FAIL = "CHECK_GRAMMAR_FAIL"