import React from "react"

const Toggle = ({
  text,
  size = "default",
  checked,
  disabled,
  onChange,
  offstyle = "btn-danger",
  onstyle = "btn-success",
}) => {
  const displayStyle = checked ? onstyle : offstyle
  return (
    <React.Fragment>
      <label className="mb-0">
        <span className={`${size} switch-wrapper`}>
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={e => onChange(e)}
          />
          <span className={`${displayStyle} switch`}>
            <span className="switch-handle" />
          </span>
        </span>
      </label>
    </React.Fragment>
  )
}

export default Toggle
