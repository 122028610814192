import { call, put, takeEvery } from "redux-saga/effects"
import { getData, patchData } from "../../helpers/backend_helper"
import { toaster } from "../../helpers/Custom/Toaster"

import {
  getListeningQuesFail,
  getListeningQuesSuccess,
  getReadingQuesFail,
  getReadingQuesSuccess,
  getSpeakingSetFail,
  getSpeakingSetSuccess,
  getWritingQuesFail,
  getWritingQuesSuccess,
  storeQuestion,
  submitAnswerOneByOneFail,
  submitAnswerOneByOneSuccess,
  submitExamFail,
  submitExamSuccess,
} from "./actions"
import {
  GET_LISTENING_QUES,
  GET_READING_QUES,
  GET_SPEAKING_SET_LINK,
  GET_WRITING_QUES,
  SUBMIT_ANSWER_ONE_BY_ONE,
  SUBMIT_EXAM,
  SUBMIT_LISTENING_EXAM,
  SUBMIT_WRITING_EXAM,
} from "./actionTypes"

function* fetchQuestions({
  payload: { authtoken, serviceID, packageId, result },
}) {
  try {
    const url = `/package/get-passage-under-set/${serviceID}/${packageId}`
    const ansUrl = `/package/get-accurate-answer/${serviceID}`
    const response = yield call(getData, url, authtoken)
    if (!result) {
      const answer = yield call(getData, ansUrl, authtoken)
      yield put(storeQuestion("ans", answer?.getData))
    }
    // console.log(`response`, response);
    yield put(getReadingQuesSuccess(response?.getData?.getData))
    const questions = response?.getData?.getData?.readingPassageModel
    yield put(storeQuestion("questions", questions))
    yield put(storeQuestion("answerId", response?.getData?.answerId))
  } catch (error) {
    const message =
      error.response?.data?.message || "Get reading question failed"
    yield put(getReadingQuesFail(message))
    toaster("error", message)
  }
}

function* onSubmitExam({
  payload: {
    data,
    authtoken,
    options: {
      type,
      id,
      history,
      time,
      submissionReason,
      examStatus,
      packageId,
    },
  },
}) {
  try {
    const url = `/package/submit-answer/${id}/${type}/${packageId}?timeTaken=${time}&examStatus=${examStatus}&submissionReason=${submissionReason}`
    const response = yield call(patchData, url, data, authtoken)
    yield put(submitExamSuccess(response))
    toaster("success", "Answer submitted!")
    yield put(storeQuestion("currentIndex", 0))
    if (response?.ratings === false) {
      yield put(storeQuestion("isDone", true))
    }
    history.goBack()
  } catch (error) {
    const message = error.response?.data?.message || "Submit exam failed"
    yield put(submitExamFail(message))
    toaster("error", message)
  }
}

function* fetchListeningQuestions({
  payload: { authtoken, serviceID, result, packageId },
}) {
  try {
    const url = `/package/get-listening-audio-and-question-under-set/${serviceID}/${packageId}`
    const ansUrl = `/package/get-accurate-answer/listening/${serviceID}`
    const response = yield call(getData, url, authtoken)
    if (!result) {
      const answer = yield call(getData, ansUrl, authtoken)
      yield put(storeQuestion("ans", answer?.getData))
    }
    // console.log(`response`, response);
    yield put(getListeningQuesSuccess(response?.getData?.getData))
    yield put(storeQuestion("currentIndex", 0))
    yield put(storeQuestion("answerId", response?.getData?.ansewrId))
    const questions = response?.getData?.getData
    yield put(storeQuestion("questions", questions))
  } catch (error) {
    const message = error.response?.data?.message || "get Question failed"
    yield put(getListeningQuesFail(message))
    toaster("error", message)
  }
}

function* onSubmitListeningExam({
  payload: {
    data,
    authtoken,
    options: {
      type,
      id,
      history,
      time,
      submissionReason,
      examStatus,
      packageId,
    },
  },
}) {
  try {
    const url = `/package/submit-answer/${id}/${type}/${packageId}?timeTaken=${time}&examStatus=${examStatus}&submissionReason=${submissionReason}`
    const response = yield call(patchData, url, data, authtoken)
    yield put(submitExamSuccess(response))
    toaster("success", "Answer submitted!")
    yield put(storeQuestion("currentIndex", 0))
    if (response?.ratings === false) {
      yield put(storeQuestion("isDone", true))
    }
    history.goBack()
  } catch (error) {
    const message = error.response?.data?.message || "Submit exam failed"
    yield put(submitExamFail(message))
    toaster("error", message)
  }
}

function* fetchWritingQuestions({
  payload: { authtoken, serviceID, result, packageId },
}) {
  try {
    const url = `/package/get-writting-question-under-set/${serviceID}/${packageId}`
    const response = yield call(getData, url, authtoken)
    console.log(`response`, response)
    yield put(getWritingQuesSuccess(response?.getData?.getData))
    const questions = response?.getData?.getData
    yield put(storeQuestion("questions", questions))
    yield put(storeQuestion("answerId", response?.getData?.answerId))
    yield put(storeQuestion("currentIndex", 0))
  } catch (error) {
    const message = error.response?.data?.message || "get Question failed"
    yield put(getWritingQuesFail(message))
    toaster("error", message)
  }
}

function* onSubmitWritingExam({
  payload: {
    data,
    authtoken,
    options: {
      type,
      id,
      history,
      time,
      submissionReason,
      examStatus,
      packageId,
    },
  },
}) {
  try {
    const url = `/package/submit-answer/${id}/${type}/${packageId}?timeTaken=${time}&examStatus=${examStatus}&submissionReason=${submissionReason}`
    const response = yield call(patchData, url, data, authtoken)
    yield put(submitExamSuccess(response))
    yield put(
      storeQuestion("writingAns", {
        1: { answer: "" },
        2: { answer: "" },
      })
    )
    yield put(storeQuestion("currentIndex", 0))
    toaster("success", "Answer submitted!")
    if (response?.ratings === false) {
      yield put(storeQuestion("isDone", true))
    }
    history.goBack()
  } catch (error) {
    const message = error.response?.data?.message || "Submit exam failed"
    yield put(submitExamFail(message))
    toaster("error", message)
  }
}

function* fetchSpeakingQuestionsSetLink({ payload: { authtoken, serviceId } }) {
  try {
    const url = `/get-particular-exam-data/${serviceId}`
    const response = yield call(getData, url, authtoken)
    // console.log(`response`, response);
    yield put(getSpeakingSetSuccess(response?.getData))
  } catch (error) {
    const message =
      error.response?.data?.message || "get speaking set link failed"
    yield put(getSpeakingSetFail(message))
    toaster("error", message)
  }
}

function* onSubmitExamOneByOne({
  payload: {
    data,
    authtoken,
    options: { type, id, history },
  },
}) {
  try {
    const url = `/package/submit-one-by-one-answer/${id}/${type}`
    const response = yield call(patchData, url, data, authtoken)
    // console.log(`response`, response);
    yield put(submitAnswerOneByOneSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "Submit answer failed"
    yield put(submitAnswerOneByOneFail(message))
    toaster("error", message)
  }
}

function* FacilitatorQuestionSaga() {
  yield takeEvery(GET_READING_QUES, fetchQuestions)
  yield takeEvery(GET_LISTENING_QUES, fetchListeningQuestions)
  yield takeEvery(GET_WRITING_QUES, fetchWritingQuestions)
  yield takeEvery(GET_SPEAKING_SET_LINK, fetchSpeakingQuestionsSetLink)
  yield takeEvery(SUBMIT_EXAM, onSubmitExam)
  yield takeEvery(SUBMIT_LISTENING_EXAM, onSubmitListeningExam)
  yield takeEvery(SUBMIT_WRITING_EXAM, onSubmitWritingExam)
  yield takeEvery(SUBMIT_ANSWER_ONE_BY_ONE, onSubmitExamOneByOne)
}

export default FacilitatorQuestionSaga
