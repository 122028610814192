import { call, put, takeEvery } from "redux-saga/effects"
import { toaster } from "../../helpers/Custom/Toaster"
import { getData, patchData } from "../../helpers/backend_helper"
import {
  ASSIGN_ISSUE,
  GET_CHAT_USER,
  GET_ISSUE_LIST,
  GET_SINGLE_USER_MESSAGE,
  REPLY_USER,
  SEEN_MESSAGE,
  UPDATE_ISSUE,
} from "./actionTypes"
import {
  assignIssueFail,
  assignIssueSuccess,
  getIssueListFail,
  getIssueListSuccess,
  getSingleChat,
  getSingleChatFail,
  getSingleChatSuccess,
  getUserChat,
  getUserChatFail,
  getUserChatSuccess,
  replyToUserFail,
  replyToUserSuccess,
  seenMessageFail,
  seenMessageSuccess,
  updateIssueFail,
  updateIssueSuccess,
} from "./actions"

//for main

function* onGetUserListChat({ payload: { token, skipNumber } }) {
  try {
    const url = `chat/admin/get-last-messages?skipNumber=${skipNumber}`
    const response = yield call(getData, url, token)
    yield put(getUserChatSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed"
    toaster("error", message)
    yield put(getUserChatFail(message))
  }
}
function* onGetSingleChat({ payload: { token, chatId, type } }) {
  try {
    const url = `chat/admin/get-single-message?chatUnknown=${
      type === "user" ? false : true
    }&user=${chatId}`
    const response = yield call(getData, url, token)
    yield put(getSingleChatSuccess(response?.getData))
    yield put(getUserChat(token, 0))
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed"
    toaster("error", message)
    yield put(getSingleChatFail(message))
  }
}
function* workerReplyChat({
  payload: { values, history, token, chatId, senderId, type },
}) {
  try {
    console.log("type", type)
    const url = `chat/admin/reply-message/${chatId}`
    const response = yield call(patchData, url, values, token)
    yield put(replyToUserSuccess(response?.message))
    yield put(getSingleChat(token, senderId, type))
    yield put(getUserChat(token, 0))
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`
    toaster("error", message)
    yield put(replyToUserFail(message))
  }
}
function* workerSeenMessage({
  payload: { values, history, token, chatId, messageId },
}) {
  try {
    const url = `/chat/check-by/${chatId}/${messageId}`
    const response = yield call(patchData, url, values, token)
    yield put(seenMessageSuccess(response?.message))
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`
    toaster("error", message)
    yield put(seenMessageFail(message))
  }
}
function* workerAssignIssue({
  payload: { values, history, token, chatId, userId, type, setShow },
}) {
  try {
    const url = `chat/admin/assign-issue/${chatId}/${userId}?chatUnknown=${type}`
    const response = yield call(patchData, url, values, token)

    yield put(assignIssueSuccess(response?.message))
    toaster("success", `Assigned Issue Successfully`)
    setShow(false)
    if (type === true) {
      yield put(getSingleChat(token, userId, "nonUser"))
    } else {
      yield put(getSingleChat(token, userId, "user"))
    }
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`
    toaster("error", message)
    yield put(assignIssueFail(message))
  }
}
function* workerUpdateIssue({
  payload: { values, history, token, chatId, userId, type, setShow },
}) {
  try {
    const url = `chat/admin/solve-issue/${chatId}/${userId}`
    const response = yield call(patchData, url, values, token)
    yield put(updateIssueSuccess(response?.message))
    toaster("success", `Issue Solved Successfully`)
    yield put(getSingleChat(token, userId, type))
  } catch (error) {
    const message = error?.response?.data?.message || `Set time fail`
    toaster("error", message)
    yield put(updateIssueFail(message))
  }
}
function* onGetIssueList({ payload: { token, skipNumber, status } }) {
  try {
    const url = `chat/admin/get-all-issue?skip=${skipNumber}&limit=100&status=${status}`
    const response = yield call(getData, url, token)
    yield put(getIssueListSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.data?.message || "Get failed"
    toaster("error", message)
    yield put(getIssueListFail(message))
  }
}
function* ChatSaga() {
  yield takeEvery(REPLY_USER, workerReplyChat)
  yield takeEvery(GET_CHAT_USER, onGetUserListChat)
  yield takeEvery(GET_SINGLE_USER_MESSAGE, onGetSingleChat)
  yield takeEvery(SEEN_MESSAGE, workerSeenMessage)
  yield takeEvery(ASSIGN_ISSUE, workerAssignIssue)
  yield takeEvery(GET_ISSUE_LIST, onGetIssueList)
  yield takeEvery(UPDATE_ISSUE, workerUpdateIssue)
}
export default ChatSaga
