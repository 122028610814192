import {
  ADD_SPEAKING_SET,
  GET_STUDENT_PROFILE,
  SHOW_RESULT,
  STORE_QUESTION,
  SUBMIT_MARKS,
  TEACHER_START_EXAM,
  UPDATE_ATTENDANCE,
} from "./actionTypes"

const initialState = {
  loading: false,
  adding: false,
  message: "",
  error: "",
  profile: {},
  singleSlot: [],
  singleDay: {},
  result: {},
}

const speakingQues = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SPEAKING_SET:
      state = {
        ...state,
        adding: true,
      }
      break

    case TEACHER_START_EXAM:
      state = {
        ...state,
        adding: true,
      }
      break

    case UPDATE_ATTENDANCE:
      state = {
        ...state,
        adding: true,
      }
      break

    case SUBMIT_MARKS:
      state = {
        ...state,
        adding: true,
      }
      break

    case GET_STUDENT_PROFILE:
      state = {
        ...state,
        loading: true,
      }
      break

    case SHOW_RESULT:
      state = {
        ...state,
        loading: true,
      }
      break

    case STORE_QUESTION:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      }

    default:
      state = { ...state }
      break
  }

  return state
}

export default speakingQues
