import { Field, FieldArray, Form, Formik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Button, Label } from "reactstrap"

import { toaster } from "helpers/Custom/Toaster"
// import { addAptitudeQuestion } from "../../../Store/Admin/CorporateQuestions/CorporateQuestionSlice";
import "../../gmat.scss"

import { TableAnalysisCreateSchema } from "components/Schemas/QuestionSchemas"
// import QuestionTopCard from "./Common/QuestionTopCard";
import CardComponent from "components/Common/CardComponent"
import QuestionTopCard from "components/Common/QuestionTopCard"
import CustomInput from "components/InputComponent/CustomInput"
import QuillComponent from "components/InputComponent/QuillComponent"
import { createHash } from "helpers/Custom/Hash"
import {
  addTableAnalysisQues,
  getSingleTableAnalysisQues,
} from "store/GMAT/IntegratedReasoning/actions"
import InnerLayer from "../../../../Layout/InnerLayer"
import PageHeader from "../../../../Layout/PageHeader"
const section = [
  {
    name: "data Sufficency",
    value: 1,
  },
  {
    name: "problem Solving",
    value: 2,
  },
]

const quesType = [
  {
    name: "single",
    value: 1,
  },
  {
    name: "multi",
    value: 2,
  },
]
const initialValues = {
  description: "",
  descriptionHeader: "",
  questionDifficulties: "",
  selection: "",

  columnNameList: [""],
  rowDataList: [""],
  questionHeading: "",
  questionType: null,
  answerOptions: [""],
  photo: null,
  isColumnDisable: false,
  options: [
    {
      option: "",
      answer: "",
    },
  ],
}

const TableAnalysisCreate = ({ history, api }) => {
  const textAreaRef = useRef(null)
  const { passageId, questionId, serviceId, section, type } = useParams()
  console.log("questionId", questionId)
  const [listValue, setListValue] = useState(null)
  const [isAddMore, setIsAddMore] = useState(false)
  const [request, setRequest] = useState(false)
  const [sectionData, setSection] = useState("")
  const title = ` Question Type`
  const title2 = questionId ? `Edit  Question` : `Create Question`
  const breadcrumbItems = [
    {
      title: title,
      link: `/Quantative-ques-type`,
    },
    {
      title: title2,
      link: `#`,
    },
  ]
  const dispatch = useDispatch()
  const { singleTableAnalysisQues, uploading, authToken } = useSelector(
    state => ({
      singleTableAnalysisQues: state.IRReducer.singleTableAnalysisQues,

      uploading: state.analyticalReducer.uploading,
      authToken: state.Login.token,
    })
  )
  console.log(`singleTableAnalysisQues`, singleTableAnalysisQues)
  console.log("section", section)
  useEffect(() => {
    if (questionId) {
      dispatch(getSingleTableAnalysisQues(authToken, questionId))
    }
  }, [questionId])

  useEffect(() => {
    if (questionId && !!singleTableAnalysisQues) {
      const value = { ...listValue }
      // value.description =  singleTableAnalysisQues?.data?.description ? removeHash(singleTableAnalysisQues?.data?.description) :""
      // value.questionHeading =  singleTableAnalysisQues?.questionHeading
      // setListValue(value)
    }
  }, [singleTableAnalysisQues])

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    console.log("values", values)
    const {
      questionDifficulties,
      isColumnDisable,
      selection,
      description,
      descriptionHeader,
      questionHeading,
      ...rest
    } = values
    const obj = {
      ...rest,
      description: createHash(description),
      descriptionHeader: createHash(descriptionHeader),
      questionHeading: createHash(questionHeading),
      questionDifficulties: questionDifficulties * 1,
      selection: selection * 1,
      section: section * 1,
    }
    const option = {
      isMore: isAddMore,
      resetForm,
      setSubmitting,
      setIsAddMore,
      id: questionId,
      passageId,
      history,
      serviceId,
      type,
    }
    console.log(`obj`, obj, option)
    dispatch(addTableAnalysisQues(obj, authToken, option))
    // setSubmitting(false);
  }

  function copyToClipboard(e) {
    textAreaRef.current.select()
    document.execCommand("copy")
    e.target.focus()
    toaster("success", "Url copied successful")
  }
  const handleSection = data => {
    if (data?.value) {
      setSection(data?.value)
    } else {
      setSection("")
    }
  }
  return (
    <React.Fragment>
      <InnerLayer>
        <PageHeader title={title2} breadcrumbItems={breadcrumbItems} />
        <Row className="create_questions">
          <Col md="12">
            <QuestionTopCard
              history={history}
              title={`${
                questionId ? "Update" : "Create"
              } Table Analysis question`}
            />
            <Row>
              <Col md="6">
                <CardComponent style={{ marginTop: "5px" }}>
                  <h5>Type question</h5>
                  <br />
                  <Formik
                    initialValues={listValue || initialValues}
                    validationSchema={TableAnalysisCreateSchema}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      errors,
                      setFieldValue,
                      isSubmitting,
                      handleSubmit,
                    }) => (
                      <Form>
                        {console.log("values,", values, errors)}

                        <Row className="mb-3">
                          <Col md="12 mb-3">
                            <Field
                              label="Difficulty level"
                              name="questionDifficulties"
                              type="select"
                              id={"difficultyLevel"}
                              component={CustomInput}
                            >
                              <option value="">Select...</option>
                              <option value="1">Easy</option>
                              <option value="2">Medium</option>
                              <option value="3">difficult</option>
                            </Field>
                          </Col>
                          <Col md="12 mb-3">
                            <Field
                              label="Selection"
                              name="selection"
                              type="select"
                              id={"questype"}
                              component={CustomInput}
                            >
                              <option value="">Select...</option>
                              <option value="1">single</option>
                              <option value="2">multi</option>
                            </Field>
                          </Col>
                          <Col md="12 mb-3 ">
                            <Field
                              label="Description"
                              name="description"
                              id={"description"}
                              component={QuillComponent}
                            />
                          </Col>
                          <Col md="12 mb-3">
                            <Field
                              label="Description Header"
                              name="descriptionHeader"
                              id={"descriptionHeader"}
                              component={QuillComponent}
                            />
                          </Col>
                        </Row>

                        <Col md="12 mb-3">
                          <Field
                            label="Question header"
                            name="questionHeading"
                            id={"questionHeading"}
                            component={QuillComponent}
                          />
                        </Col>

                        <Col md="12" className="mb-3">
                          <div className="table-analysis-header-part">
                            <FieldArray
                              name="columnNameList"
                              render={arrayHelpers => (
                                <Row>
                                  {values?.columnNameList?.map(
                                    (value, index) => (
                                      <React.Fragment key={index}>
                                        <Col
                                          md="12"
                                          className="position-relative mb-3"
                                        >
                                          <Label
                                            className="d-flex justify-content-between align-items-center gap-2"
                                            htmlFor={`columnNameList.${index}`}
                                          >
                                            <span className="text-nowrap">
                                              {`Header ${index + 1}`}
                                            </span>
                                          </Label>

                                          <Field
                                            name={`columnNameList.${index}`}
                                            type={"text"}
                                            id={`columnNameList.${index}`}
                                            // label={`Option ${index + 1}`}
                                            component={CustomInput}
                                            placeholder="Type Header"
                                            isArray
                                          />

                                          {index > 0 ? (
                                            <div
                                              className="position-absolute"
                                              style={{
                                                top: "25px",
                                                // transform : ""
                                                width: "initial",
                                                right: "1.5%",
                                              }}
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <button
                                                className="btn-sm removeShadow"
                                                style={{
                                                  backgroundColor:
                                                    "transparent",
                                                  border: "none",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                <i className="bx bx-trash text-danger"></i>
                                              </button>
                                            </div>
                                          ) : null}
                                        </Col>
                                      </React.Fragment>
                                    )
                                  )}
                                  {!values?.isColumnDisable ? (
                                    <Col md="12">
                                      <button
                                        type="button"
                                        className="d-inline-block btn button-add"
                                        onClick={() => {
                                          arrayHelpers.push("")
                                          // Column
                                          let rowArray = [
                                            ...values?.rowDataList,
                                          ]
                                          rowArray.push("")

                                          setFieldValue("rowDataList", rowArray)
                                        }}
                                      >
                                        <i className="bx bx-plus"></i> Add
                                        another header
                                      </button>
                                    </Col>
                                  ) : (
                                    " "
                                  )}
                                </Row>
                              )}
                            />
                          </div>
                        </Col>

                        <Col md="12" className="mb-3">
                          <div className="table-analysis-cell">
                            <FieldArray
                              name="rowDataList"
                              render={arrayHelpers => (
                                <Row>
                                  {values?.rowDataList?.map((value, index) => (
                                    <React.Fragment key={index}>
                                      <Col
                                        md="12"
                                        className="position-relative mb-3"
                                      >
                                        <Label
                                          className="d-flex justify-content-between align-items-center gap-2"
                                          htmlFor={`rowDataList.${index}`}
                                        >
                                          <span className="text-nowrap">
                                            {`Cell ${index + 1}`}
                                          </span>
                                        </Label>

                                        <Field
                                          name={`rowDataList.${index}`}
                                          type={"text"}
                                          id={`rowDataList.${index}`}
                                          // label={`Option ${index + 1}`}
                                          component={CustomInput}
                                          placeholder="Type Cell"
                                          isArray
                                        />

                                        {index > 0 && (index + 1) % 2 !== 0 ? (
                                          <div
                                            className="position-absolute"
                                            style={{
                                              top: "25px",
                                              // transform : ""
                                              width: "initial",
                                              right: "1.5%",
                                            }}
                                            onClick={() => {
                                              arrayHelpers.remove(index + 1)
                                              arrayHelpers.remove(index)
                                            }}
                                          >
                                            <button
                                              className="btn-sm removeShadow"
                                              style={{
                                                backgroundColor: "transparent",
                                                border: "none",
                                                fontSize: "18px",
                                              }}
                                            >
                                              <i className="bx bx-trash text-danger"></i>
                                            </button>
                                          </div>
                                        ) : null}
                                      </Col>
                                    </React.Fragment>
                                  ))}

                                  <Col md="12">
                                    <button
                                      type="button"
                                      className="d-inline-block btn button-add"
                                      onClick={() => {
                                        // arrayHelpers.push("")
                                        let rowArray = [...values?.rowDataList]
                                        values.columnNameList.forEach(
                                          element => {
                                            rowArray.push("")
                                          }
                                        )
                                        // columnArray.push("")
                                        setFieldValue("isColumnDisable", true)
                                        setFieldValue("rowDataList", rowArray)
                                      }}
                                    >
                                      <i className="bx bx-plus"></i> Add another
                                      Row
                                    </button>
                                  </Col>
                                </Row>
                              )}
                            />
                          </div>
                        </Col>

                        {/* <Col md="12" className="mb-3">
                    <div className="table-header-ans-option">
                    <FieldArray
                        name="answerOptions"
                        render={arrayHelpers => (
                          <Row>
                            {values?.answerOptions?.map((value, index) => (
                              <React.Fragment key={index}>
                                <Col md="12" className="position-relative mb-3">
                                  <Label
                                    className="d-flex justify-content-between align-items-center gap-2"
                                    htmlFor={`answerOptions.${index}`}
                                  >
                                    <span className="text-nowrap">
                                      {`Answer option ${index + 1}`}
                                    </span>
                                  </Label>

                                  <Field
                                    name={`answerOptions.${index}`}
                                    type={"text"}
                                    id={`answerOptions.${index}`}
                                    // label={`Option ${index + 1}`}
                                    component={CustomInput}
                                    placeholder="Type Answer option"
                                    isArray
                                  />

                                  {index > 0 ? (
                                    <div
                                      className="position-absolute"
                                      style={{
                                        top: "25px",
                                        // transform : ""
                                        width: "initial",
                                        right: "1.5%",
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <button
                                        className="btn-sm removeShadow"
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <i className="bx bx-trash text-danger"></i>
                                      </button>
                                    </div>
                                  ) : null}
                                </Col>
                              </React.Fragment>
                            ))}
                          
                              <Col md="12">
                                <button
                                  type="button"
                                  className="d-inline-block btn button-add"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  <i className="bx bx-plus"></i> Add Another
                                  Option
                                </button>
                              </Col>
                           
                          </Row>
                        )}
                      />
                    </div>
                    </Col> */}

                        <Col md="12" className="mb-3">
                          <div className="table-analysis-ques-option">
                            <FieldArray
                              name="options"
                              render={arrayHelpers => (
                                <Row>
                                  {values?.options?.map((value, index) => (
                                    <React.Fragment key={index}>
                                      <Col
                                        md="6"
                                        className="position-relative mb-3"
                                      >
                                        <Label
                                          className="d-flex justify-content-between align-items-center gap-2"
                                          htmlFor={`options.${index}.option`}
                                        >
                                          <span className="text-nowrap">
                                            {`option ${index + 1}`}
                                          </span>
                                        </Label>

                                        <Field
                                          name={`options.${index}.option`}
                                          type={"text"}
                                          id={`options.${index}.option`}
                                          // label={`Option ${index + 1}`}
                                          component={CustomInput}
                                          placeholder="Type option"
                                          isArray
                                          className="mb-3"
                                        />
                                        <Field
                                          name={`options.${index}.answer`}
                                          type={"text"}
                                          id={`options.${index}.answer`}
                                          // label={`Option ${index + 1}`}
                                          component={CustomInput}
                                          placeholder="Type Answer option"
                                          isArray
                                        />

                                        {index > 0 ? (
                                          <div
                                            className="position-absolute"
                                            style={{
                                              top: "25px",
                                              // transform : ""
                                              width: "initial",
                                              right: "1.5%",
                                            }}
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <button
                                              className="btn-sm removeShadow"
                                              style={{
                                                backgroundColor: "transparent",
                                                border: "none",
                                                fontSize: "18px",
                                              }}
                                            >
                                              <i className="bx bx-trash text-danger"></i>
                                            </button>
                                          </div>
                                        ) : null}
                                      </Col>
                                    </React.Fragment>
                                  ))}

                                  <Col md="12">
                                    <button
                                      type="button"
                                      className="d-inline-block btn button-add"
                                      onClick={() => arrayHelpers.push("")}
                                    >
                                      <i className="bx bx-plus"></i> Add Another
                                      Option
                                    </button>
                                  </Col>
                                </Row>
                              )}
                            />
                          </div>
                        </Col>
                        <Row className="mb-3">
                          <Col md="12" className="d-flex justify-content-end">
                            <Button
                              color="warning"
                              outline
                              className="me-2 btn btn-outline-blue"
                              disabled={isSubmitting || uploading}
                              type="submit"
                            >
                              {isSubmitting
                                ? "Submitting..."
                                : questionId
                                ? "Update"
                                : "Save"}
                            </Button>
                            {!questionId ? (
                              <button
                                color="primary"
                                style={{ color: "white" }}
                                disabled={isSubmitting || uploading}
                                type="button"
                                onClick={() => {
                                  setIsAddMore(true)
                                  handleSubmit()
                                }}
                                className="btn button-blue"
                              >
                                {isSubmitting
                                  ? "Submitting..."
                                  : "Create more question"}
                              </button>
                            ) : null}
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardComponent>
              </Col>
              <Col md="6"></Col>
            </Row>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default TableAnalysisCreate
