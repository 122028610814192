import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import { getListeningAudioSlice, getSingleListeningSets } from "store/actions"
import CustomTable from "../../../Layout/CustomTable"
import InnerLayer from "../../../Layout/InnerLayer"
import check from "../../../assets/images/Icon/all-done.svg"
import cross from "../../../assets/images/Icon/close.svg"
import CardComponent from "../../../components/Common/CardComponent"
import Loader from "../../../components/Common/Loader"
import CreateSlice from "../../../components/Listening/CreateSlice"

const track =
  "https://staging-ieltsx.sgp1.digitaloceanspaces.com/1649152157808-audio.ogg"

const tableHead = [
  "No",
  "Title",
  //   "Play time",
  "Mark",
  //   "Start Time",
  //   "End Time",
  "Action",
]

const SingleListeningSet = ({ history }) => {
  const [open, setOpen] = useState(false)
  const [editData, setEditData] = useState({})
  const { serviceId, setId } = useParams()
  const dispatch = useDispatch()
  const { authToken, loading, listeningAudio, listeningSet, listSlice } =
    useSelector(state => ({
      listeningAudio: state.listeningSet.listAudio,
      authToken: state.Login.token,
      loading: state.listeningSet.loading,
      adding: state.listeningSet.adding,
      listeningSet: state.listeningSet.listeningSet,
      listSlice: state.listeningSet.listSlice,
    }))
  console.log(`listeningAudio`, listeningAudio, listeningSet, listSlice)
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Service List",
      link: `/services`,
    },
    {
      title: "Listening Set",
      link: `/listening-set/${serviceId}`,
    },
    {
      title: `${listeningSet?.setName || ""}`,
      link: "#",
    },
  ]
  useEffect(() => {
    dispatch(getSingleListeningSets(authToken, setId))
    dispatch(getListeningAudioSlice(authToken, setId))
    //   return () => {
    //     dispatch(getListeningAudioSuccess({}))
    //   }
  }, [])

  const isDone = () => {
    if (
      listSlice?.[listSlice?.length - 1]?.remainingQuestion !==
      listSlice?.[listSlice?.length - 1]?.totalQuestion
    ) {
      return true
    } else false
  }

  const toggle = () => setOpen(!open)

  const handleEdit = data => {
    setEditData(data)
    toggle()
  }

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={listeningSet?.setName || `Set Name`}
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CreateSlice
            toggle={toggle}
            isOpen={open}
            edit={Object.keys(editData)?.length > 0}
            editData={editData}
          />
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>Listening Set</h4>
              <div className="d-flex">
                {/* <div className="search-box me-2">toggle</div> */}
                <div>
                  {listSlice?.length ? (
                    <button
                      style={{
                        backgroundColor: "#0052CC",
                        color: "#fff",
                      }}
                      className=" btn me-1"
                      // disabled={isDone()}
                      onClick={toggle}
                    >
                      Create Set
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </CardComponent>
        </Row>

        <Row>
          <Col md="11" className="mx-auto">
            {loading ? (
              <div>
                <Loader />
              </div>
            ) : (
              <CardComponent>
                <div>
                  {listSlice?.length ? (
                    <div className="my-3">
                      <h5>List Audio</h5>
                      <CustomTable
                        data={[]}
                        tableHead={tableHead}
                        paginationClass={"mt-2"}
                      >
                        {listSlice?.map((slice, idx) => (
                          <tr key={idx}>
                            <th>{idx + 1}</th>
                            <td>{slice?.audioTopicName}</td>
                            <td>{slice?.totalQuestion}</td>
                            <td>
                              <button
                                style={{
                                  backgroundColor: "#CCE0FF ",
                                  color: "#0052CC",
                                }}
                                onClick={() => handleEdit(slice)}
                                className="btn-sm btn"
                              >
                                Edit
                              </button>
                              <button
                                style={{
                                  backgroundColor: "#0052CC",
                                  color: "#fff",
                                }}
                                onClick={() =>
                                  history.push(
                                    `/service/${serviceId}/listening/${setId}/view-slice/${slice?._id}`
                                  )
                                }
                                className="btn-sm btn ms-1"
                              >
                                {slice?.totalQuestion !==
                                slice?.remainingQuestion
                                  ? "Set questions"
                                  : "View Questions"}
                              </button>
                              <span>
                                {slice?.totalQuestion !==
                                slice?.remainingQuestion ? (
                                  <img src={cross} alt="cross" />
                                ) : (
                                  <img src={check} alt="check" />
                                )}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </CustomTable>
                      <div className="mt-2 d-flex justify-content-end">
                        <button
                          style={{
                            backgroundColor: "#0052CC",
                            color: "#fff",
                          }}
                          className=" btn "
                        >
                          See student view
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{ height: "200px" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div className="text-center">
                        <h6>
                          Currently you have no question. <br />
                          For next step first
                          <a className="ms-1" onClick={toggle}>
                            Create set.
                          </a>
                        </h6>
                        <div>
                          <Button
                            type="button"
                            color="primary"
                            className="btn-circle me-2"
                            onClick={toggle}
                          >
                            <i className="mdi mdi-plus me-1" />
                            Create set
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </CardComponent>
            )}
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(SingleListeningSet)
