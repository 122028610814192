export const GET_STUDENTS = "GET_STUDENTS"
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS"
export const GET_STUDENTS_FAIL = "GET_STUDENTS_FAIL"

export const GET_SINGLE_PURCHASE = "GET_SINGLE_PURCHASE"
export const GET_SINGLE_PURCHASE_SUCCESS = "GET_SINGLE_PURCHASE_SUCCESS"
export const GET_SINGLE_PURCHASE_FAIL = "GET_SINGLE_PURCHASE_FAIL"


export const GET_SINGLE_STUDENT = "GET_SINGLE_STUDENT"
export const GET_SINGLE_STUDENT_SUCCESS = "GET_SINGLE_STUDENT_SUCCESS"
export const GET_SINGLE_STUDENT_FAIL = "GET_SINGLE_STUDENT_FAIL"

export const UPDATE_STUDENT = "UPDATE_STUDENT"
export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS"
export const UPDATE_STUDENT_FAIL = "UPDATE_STUDENT_FAIL"

export const GET_STUDENT_ORDER = "GET_STUDENT_ORDER"
export const GET_STUDENT_ORDER_SUCCESS = "GET_STUDENT_ORDER_SUCCESS"
export const GET_STUDENT_ORDER_FAIL = "GET_STUDENT_ORDER_FAIL"


export const GET_STUDENT_LIST = "GET_STUDENT_LIST"
export const GET_STUDENT_LIST_SUCCESS = "GET_STUDENT_LIST_SUCCESS"
export const GET_STUDENT_LIST_FAIL = "GET_STUDENT_LIST_FAIL"

export const GET_STUDENT_COUNT = "GET_STUDENT_COUNT"
export const GET_STUDENT_COUNT_SUCCESS = "GET_STUDENT_COUNT_SUCCESS"
export const GET_STUDENT_COUNT_FAIL = "GET_STUDENT_COUNT_FAIL"