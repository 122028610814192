import * as Yup from "yup";

const createServiceSchema = Yup.object({
  title: Yup.string()
    .matches(/^[a-z\d\-.,_\s]+$/i, "Invalid title")
    .required("Please Enter your name"),
  level: Yup.string().required("Select level is required"),
});

const createReadingPassageSchema = Yup.object({
  topicName: Yup.string()
    // .matches(/^[a-z\d\-.,_\s]+$/i, "Invalid name")
    .required("Please enter topic name"),
  passage: Yup.string().test("passage", "Set info is required", (val) => {
    return val && val !== "<p><br></p>" ? true : false;
  }),
  totalQuestion: Yup.number().required("Total question is required"),
  // level: Yup.string().required("Select level is required"),
});

const createListeningPassageSchema = Yup.object({
  audioTopicName: Yup.string()
    // .matches(/^[a-z\d\-.,_\s]+$/i, "Invalid name")
    .required("Please Enter topic name"),
  audioUrl: Yup.string().required("Audio url is required"),
  totalQuestion: Yup.number().required("Marks is required"),
});

const createWritingPassageSchema = Yup.object({
  name: Yup.string()
    // .matches(/^[a-z\d\-.,_\s]+$/i, "Invalid name")
    .required("Please Enter your name"),
  image: Yup.string().required("Audio url is required"),
  level: Yup.string().required("Select level is required"),
});

const createWritingQuesSchema = Yup.object({
  questionTitle: Yup.string()
    // .matches(/^[a-z\d\-.,_\s]+$/i, "Invalid name")
    .required("Question title is required"),
  q: Yup.string().test("passageInfo", "Set info is required", (val) => {
    return val && val !== "<p><br></p>" ? true : false;
  }),
  image: Yup.string().required("Image is required"),
  difficultyLevel: Yup.string().required("Difficulty level is required"),
  
});

export {
  createServiceSchema,
  createReadingPassageSchema,
  createListeningPassageSchema,
  createWritingPassageSchema,
  createWritingQuesSchema,
};
