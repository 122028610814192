import {
  ADD_LISTENING_SET,
  ADD_LISTENING_SET_FAIL,
  ADD_LISTENING_SET_SUCCESS,
  ADD_LISTENING_SLICE,
  ADD_LISTENING_SLICE_FAIL,
  ADD_LISTENING_SLICE_SUCCESS,
  GET_LISTENING_AUDIO,
  GET_LISTENING_AUDIO_FAIL,
  GET_LISTENING_AUDIO_SINGLE_SLICE,
  GET_LISTENING_AUDIO_SINGLE_SLICE_FAIL,
  GET_LISTENING_AUDIO_SINGLE_SLICE_SUCCESS,
  GET_LISTENING_AUDIO_SLICE,
  GET_LISTENING_AUDIO_SLICE_FAIL,
  GET_LISTENING_AUDIO_SLICE_SUCCESS,
  GET_LISTENING_AUDIO_SUCCESS,
  GET_LISTENING_SETS,
  GET_LISTENING_SETS_FAIL,
  GET_LISTENING_SETS_SUCCESS,
  GET_LISTENING_SET_SINGLE,
  GET_LISTENING_SET_SINGLE_FAIL,
  GET_LISTENING_SET_SINGLE_SUCCESS,
  UPLOAD_AUDIO,
  UPLOAD_AUDIO_FAIL,
  UPLOAD_AUDIO_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: false,
  adding: false,
  message: "",
  error: "",
  listSlice: [],
  singleSlice: {},
  listAudio: {},
  timeSliceList: [],
  listeningSet: {},
  listeningSets: [],
}

const listeningSetReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LISTENING_SET:
      state = {
        ...state,
        adding: true,
      }
      break
    case ADD_LISTENING_SET_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        adding: false,
      }
      break
    case ADD_LISTENING_SET_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      }
      break

    case ADD_LISTENING_SLICE:
      state = {
        ...state,
        adding: true,
      }
      break
    case ADD_LISTENING_SLICE_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        adding: false,
      }
      break
    case ADD_LISTENING_SLICE_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      }
      break

    case GET_LISTENING_AUDIO_SLICE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_LISTENING_AUDIO_SLICE_SUCCESS:
      state = {
        ...state,
        listSlice: action.payload.data,
        loading: false,
      }
      break
    case GET_LISTENING_AUDIO_SLICE_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      }
      break
    case GET_LISTENING_AUDIO_SINGLE_SLICE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_LISTENING_AUDIO_SINGLE_SLICE_SUCCESS:
      state = {
        ...state,
        singleSlice: action.payload.data,
        loading: false,
      }
      break
    case GET_LISTENING_AUDIO_SINGLE_SLICE_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      }
      break

    case UPLOAD_AUDIO:
      state = {
        ...state,
        adding: true,
      }
      break
    case UPLOAD_AUDIO_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        adding: false,
      }
      break
    case UPLOAD_AUDIO_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      }
      break
    case GET_LISTENING_SETS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_LISTENING_SETS_SUCCESS:
      state = {
        ...state,
        loading: false,
        listeningSets: action.payload.data,
      }
      break
    case GET_LISTENING_SETS_FAIL:
      state = {
        ...state,
        loading: false,
        listeningSets: [],
        error: action.payload.message,
      }
      break
    case GET_LISTENING_SET_SINGLE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_LISTENING_SET_SINGLE_SUCCESS:
      state = {
        ...state,
        loading: false,
        listeningSet: action.payload.data,
      }
      break
    case GET_LISTENING_SET_SINGLE_FAIL:
      state = {
        ...state,
        loading: false,
        listeningSet: {},
        error: action.payload.message,
      }
      break

    // / audio
    case GET_LISTENING_AUDIO:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_LISTENING_AUDIO_SUCCESS:
      state = {
        ...state,
        loading: false,
        listAudio: action.payload.data,
      }
      break
    case GET_LISTENING_AUDIO_FAIL:
      state = {
        ...state,
        loading: false,
        listAudio: [],
        error: action.payload.message,
      }
      break
    default:
      state = { ...state }
  }

  return state
}

export default listeningSetReducer
