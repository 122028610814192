/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS"
export const API_FAIL = "API_FAIL"
export const GET_CHARTS_DATA = "GET_CHARTS_DATA"

export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA"
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS"
export const GET_DASHBOARD_DATA_FAIL = "GET_DASHBOARD_DATA_FAIL"

//show student enrollment data
export const GET_STUDENT_ENROLLMENTS_DATA = "GET_STUDENT_ENROLLMENTS_DATA"
export const GET_STUDENT_ENROLLMENTS_DATA_SUCCESSS =
  "GET_STUDENT_ENROLLMENTS_DATA_SUCCESS"

//get month based selling data
export const GET_MONTH_BASED_SELLING_DATA = "GET_MONTH_BASED_SELLING_DATA"
export const GET_MONTH_BASED_SELLING_DATA_SUCCESS =
  "GET_MONTH_BASED_SELLING_DATA_SUCCESS"

//get latest student
export const GET_NEW_STUDENTS = "GET_NEW_STUDENTS"
export const GET_NEW_STUDENTS_SUCCESS = "GET_NEW_STUDENTS_SUCCESS"

//get popular packages
export const GET_POPULAR_PACKAGE = "GET_POPULAR_PACKAGE"
export const GET_POPULAR_PACKAGE_SUCCESS = "GET_POPULAR_PACKAGE_SUCCESS"
