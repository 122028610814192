import * as Yup from "yup"

const tableSchema = Yup.object({
  answerType: Yup.string().required("This value is Required"),
  questionDetails: Yup.string().required("This value is Required"),
  answer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const tableSchema2 = Yup.object({
  answer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

export { tableSchema, tableSchema2 }
