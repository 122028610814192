import React from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getAnalyticalAnsData,
  getNumberOfAnalyticalAns,
} from "store/GMAT/Analytical/actions"
import profile from "../../../../assets/images/profile.png"
import "./Teacher.scss"
import { Col, Row } from "reactstrap"
import CardComponent from "components/Common/CardComponent"
import { useHistory } from "react-router-dom"
import SingleExamDetails from "./SingleExamDetails"

const UserExamList = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { authToken, analyticalNum, analyticalAns } = useSelector(state => ({
    authToken: state.Login.token,
    analyticalNum: state.analyticalReducer.analyticalNum,
    analyticalAns: state.analyticalReducer.analyticalAns,
  }))
  console.log("analyticalNum", analyticalAns)
  useEffect(() => {
    dispatch(getNumberOfAnalyticalAns(authToken, 3))
    dispatch(getAnalyticalAnsData(authToken, 0, 10))
  }, [])

  return (
    <div className="page-content">
      <CardComponent>
        <div className="d-flex justify-content-between align-items-center">
          <h4>Student List</h4>
          <div className="d-flex">
            <div className="search-box me-2">
              <div className="position-relative">
                <input
                  type="search"
                  placeholder="Search..."
                  // value={keyword}
                  // onChange={handleSearchChange}
                  className="form-control"
                />
                <i className="bx bx-search-alt search-icon" />
              </div>
            </div>
            <div>
              <div className="position-relative"></div>
            </div>
          </div>
        </div>
      </CardComponent>

      <Row>
        {analyticalAns?.data?.map((data, key) => (
          <Col key={key} md={3}>
            <div className="gmat-analytical-student-list" onClick={()=> history.push('/aw-exam/answer', { data })}>
              <div>
                <img src={profile} alt="" />
              </div>
              <div>
                <h5>{data?.student?.fullName}</h5>
                <p>{data?.student?.email}</p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default UserExamList
