import {
  GET_SINGLE_TEACHER,
  GET_SINGLE_TEACHER_FAIL,
  GET_SINGLE_TEACHER_SUCCESS,
  GET_TEACHERS,
  GET_TEACHERS_FAIL,
  GET_TEACHERS_SUCCESS,
  REGISTER_TEACHER,
  REGISTER_TEACHER_FAIL,
  REGISTER_TEACHER_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: false,
  adding: false,
  teachers: [],
  teacher: {},
  message: "",
  error: "",
}

const teacherReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_TEACHER:
      state = {
        ...state,
        adding: true,
      }
      break
    case REGISTER_TEACHER_SUCCESS:
      state = {
        ...state,
        adding: false,
        message: action.payload.message,
      }
      break
    case REGISTER_TEACHER_FAIL:
      state = {
        ...state,
        adding: false,
        error: action.payload.message,
      }
      break
    case GET_TEACHERS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_TEACHERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        teachers: action.payload.teachers,
      }
      break
    case GET_TEACHERS_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break

    case GET_SINGLE_TEACHER:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SINGLE_TEACHER_SUCCESS:
      state = {
        ...state,
        loading: false,
        teacher: action.payload.teacher,
      }
      break
    case GET_SINGLE_TEACHER_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      }
  }
  return state
}

export default teacherReducer
