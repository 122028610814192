import {
  ADD_IELTS_READING_PASSAGE,
  ADD_IELTS_READING_PASSAGE_FAIL,
  ADD_IELTS_READING_PASSAGE_SUCCESS,
  ADD_IELTS_READING_SET,
  ADD_IELTS_READING_SET_FAIL,
  ADD_IELTS_READING_SET_SUCCESS,
  GET_IELTS_READING_PASSAGE,
  GET_IELTS_READING_PASSAGE_FAIL,
  GET_IELTS_READING_PASSAGE_SUCCESS,
  GET_IELTS_READING_SET,
  GET_IELTS_READING_SET_FAIL,
  GET_IELTS_READING_SET_SUCCESS,
  GET_IELTS_READING_SINGLE_PASSAGE,
  GET_IELTS_READING_SINGLE_PASSAGE_FAIL,
  GET_IELTS_READING_SINGLE_PASSAGE_SUCCESS,
  GET_IELTS_SINGLE_READING_SET,
  GET_IELTS_SINGLE_READING_SET_FAIL,
  GET_IELTS_SINGLE_READING_SET_SUCCESS,
} from "./actionTypes"

export const addIELTSReadingSet = (
  data,
  history,
  authtoken,
  serviceID,
  id
) => ({
  type: ADD_IELTS_READING_SET,
  payload: { data, history, authtoken, serviceID, id },
})

export const addIELTSReadingSetSuccess = data => ({
  type: ADD_IELTS_READING_SET_SUCCESS,
  payload: data,
})

export const addIELTSReadingSetFail = error => ({
  type: ADD_IELTS_READING_SET_FAIL,
  payload: error,
})

export const addIELTSReadingPassage = (
  data,
  history,
  authtoken,
  readingSetID,
  setId,
  edit
) => ({
  type: ADD_IELTS_READING_PASSAGE,
  payload: { data, history, authtoken, readingSetID, setId, edit },
})

export const addIELTSReadingPassageSuccess = data => ({
  type: ADD_IELTS_READING_PASSAGE_SUCCESS,
  payload: data,
})

export const addIELTSReadingPassageFail = error => ({
  type: ADD_IELTS_READING_PASSAGE_FAIL,
  payload: error,
})
export const getIELTSReadingSet = (authtoken, url) => ({
  type: GET_IELTS_READING_SET,
  payload: { authtoken, url },
})

export const getIELTSReadingSetSuccess = data => ({
  type: GET_IELTS_READING_SET_SUCCESS,
  payload: { data },
})

export const getIELTSReadingSetFail = error => ({
  type: GET_IELTS_READING_SET_FAIL,
  payload: error,
})

export const getIELTSSingleReadingSet = (authtoken, readingSetID) => ({
  type: GET_IELTS_SINGLE_READING_SET,
  payload: { authtoken, readingSetID },
})

export const getIELTSSingleReadingSetSuccess = data => ({
  type: GET_IELTS_SINGLE_READING_SET_SUCCESS,
  payload: { data },
})

export const getIELTSSingleReadingSetFail = error => ({
  type: GET_IELTS_SINGLE_READING_SET_FAIL,
  payload: error,
})

export const getIELTSReadingPassage = (authtoken, readingSetID) => ({
  type: GET_IELTS_READING_PASSAGE,
  payload: { authtoken, readingSetID },
})

export const getIELTSReadingPassageSuccess = (data, authtoken) => ({
  type: GET_IELTS_READING_PASSAGE_SUCCESS,
  payload: { data, authtoken },
})

export const getIELTSReadingPassageFail = error => ({
  type: GET_IELTS_READING_PASSAGE_FAIL,
  payload: error,
})
export const getIELTSReadingSinglePassage = (authtoken, passageID) => ({
  type: GET_IELTS_READING_SINGLE_PASSAGE,
  payload: { authtoken, passageID },
})

export const getIELTSReadingSinglePassageSuccess = data => ({
  type: GET_IELTS_READING_SINGLE_PASSAGE_SUCCESS,
  payload: { data },
})

export const getIELTSReadingSinglePassageFail = error => ({
  type: GET_IELTS_READING_SINGLE_PASSAGE_FAIL,
  payload: error,
})
