/** Get Dashboard Chart data */
export const ADD_COURSE_TYPE = "ADD_COURSE_TYPE"
export const ADD_COURSE_TYPE_SUCCESS = "ADD_COURSE_TYPE_SUCCESS"
export const ADD_COURSE_TYPE_FAIL = "ADD_COURSE_TYPE_FAIL"

export const GET_COURSE_TYPE = "GET_COURSE_TYPE"
export const GET_COURSE_TYPE_SUCCESS = "GET_COURSE_TYPE_SUCCESS"
export const GET_COURSE_TYPE_FAIL = "GET_COURSE_TYPE_FAIL"

export const GET_SINGLE_COURSE_TYPE = "GET_SINGLE_COURSE_TYPE"
export const GET_SINGLE_COURSE_TYPE_SUCCESS = "GET_SINGLE_COURSE_TYPE_SUCCESS"
export const GET_SINGLE_COURSE_TYPE_FAIL = "GET_SINGLE_COURSE_TYPE_FAIL"

export const DELETE_COURSE = "DELETE_COURSE"
