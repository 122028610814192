import {
  GET_CHARTS_DATA,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_FAIL,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_STUDENT_ENROLLMENTS_DATA,
  GET_STUDENT_ENROLLMENTS_DATA_SUCCESSS,
  GET_MONTH_BASED_SELLING_DATA,
  GET_MONTH_BASED_SELLING_DATA_SUCCESS,
  GET_NEW_STUDENTS,
  GET_NEW_STUDENTS_SUCCESS,
  GET_POPULAR_PACKAGE,
  GET_POPULAR_PACKAGE_SUCCESS,
} from "./actionTypes"

// charts data
export const getChartsData = periodType => ({
  type: GET_CHARTS_DATA,
  payload: periodType,
})

export const getDashboardData = (token, startDate, endDate) => ({
  type: GET_DASHBOARD_DATA,
  payload: { token, startDate, endDate },
})

export const getDashboardDataSuccess = data => ({
  type: GET_DASHBOARD_DATA_SUCCESS,
  payload: data,
})

export const getDashboardDataFail = error => ({
  type: GET_DASHBOARD_DATA_FAIL,
  payload: error,
})

export const getStudentEnrollmentsData = (token, year) => ({
  type: GET_STUDENT_ENROLLMENTS_DATA,
  payload: { token, year },
})

export const getStudentEnrollmentDataSuccess = data => ({
  type: GET_STUDENT_ENROLLMENTS_DATA_SUCCESSS,
  payload: { data },
})

export const getMonthBasedSellingData = token => ({
  type: GET_MONTH_BASED_SELLING_DATA,
  payload: { token },
})

export const getMonthBasedSellingDataSuccess = data => ({
  type: GET_MONTH_BASED_SELLING_DATA_SUCCESS,
  payload: { data },
})

export const getNewStudetns = token => ({
  type: GET_NEW_STUDENTS,
  payload: { token },
})

export const getNewStudentsSuccess = data => ({
  type: GET_NEW_STUDENTS_SUCCESS,
  payload: { data },
})

export const getPopularPackages = token => ({
  type: GET_POPULAR_PACKAGE,
  payload: { token },
})

export const getPopularPackagesSuccess = data => ({
  type: GET_POPULAR_PACKAGE_SUCCESS,
  payload: { data },
})
