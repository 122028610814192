import { AvField, AvForm } from "availity-reactstrap-validation"
import React from "react"
import { Button, Col, Container, Row } from "reactstrap"
import ProfileCard from "./ProfileCard"

const UserProfile = ({
  data,
  isAdmin,
  title,
  buttonText,
  submitting,
  view,
  handleData,
  onChangeImage,
  profileImage,
  image,
  isBio,
}) => {
  const { name, designation, phone, email, bio } = data

  function handleSubmit(event, errors, values) {
    event.preventDefault()
    if (errors.length === 0) handleData(values)
  }
  return (
    <React.Fragment>
      <div>
        <Container>
          <div className="profile-view-inner-wrap">
            <div className="profile-view-area">
              <h4>{title}</h4>
              <div className="profile-picture-area mt-4">
                <div className="profile-picture-wrap">
                  <ProfileCard
                    img={
                      !!profileImage ? URL.createObjectURL(profileImage) : image
                    }
                    name={name || "N/A"}
                    outerStyle="border-blue"
                    onChange={onChangeImage}
                    parentClass="settingsProfile"
                    disable={view}
                  />{" "}
                </div>
                <AvForm onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="fullName"
                          label="Full Name"
                          value={name}
                          disabled={view}
                          type="text"
                          errorMessage="Enter Full Name"
                          placeholder="Enter Full Name"
                          validate={{ required: { value: true } }}
                        />
                      </div>
                    </Col>
                  </Row>
                  {!isAdmin ? (
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="designation"
                            label="Designation"
                            value={designation}
                            disabled={view}
                            type="text"
                            placeholder="Type designation"
                            errorMessage="Type designation"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          type="email"
                          value={email}
                          disabled={view}
                          placeholder="Enter Email"
                          errorMessage="Enter Email"
                          validate={{
                            required: { value: true },
                            email: {
                              value: true,
                              errorMessage: "Invalid Email format!",
                            },
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <AvField
                          name="phoneNumber"
                          label="Phone Number"
                          placeholder="Enter Phone Number"
                          type="text"
                          value={phone}
                          disabled={view}
                          errorMessage="Phone Number"
                          validate={{ required: { value: true } }}
                        />
                      </div>
                    </Col>
                  </Row>
                  {isBio ? (
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <AvField
                            name="bio"
                            label=""
                            placeholder="Enter Bio"
                            type="textarea"
                            value={bio}
                            disabled={view}
                            errorMessage="Enter Bio"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                  {!view ? (
                    <Row>
                      <React.Fragment>
                        <div className="flex-wrap gap-2 col-md-12">
                          <Button
                            disabled={submitting}
                            type="submit"
                            color="primary"
                            className="w-100"
                          >
                            {buttonText}
                          </Button>
                        </div>
                      </React.Fragment>
                    </Row>
                  ) : null}
                </AvForm>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserProfile
