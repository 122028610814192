import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { getIELTSReadingQuesMCQ, getIELTSReadingQuesMCQSuccess } from "store/actions"
import ShortAnswer from "./ShortAnswer"
import TableType from "./TableType"

const Questions = ({ edit, history,  }) => {
  const { type, passageId } = useParams()
  const dispatch = useDispatch()
  const { authToken } =
  useSelector(state => ({
    authToken: state.Login.token,
   
  }))
  useEffect(() => {
    dispatch(getIELTSReadingQuesMCQSuccess([]))
    dispatch(getIELTSReadingQuesMCQ(authToken, passageId))
  }, [])
  const getQuestionComponents = type => {
    if (type.match(/table/gi) || type.match(/form/gi) || type.match(/note/gi)) {
      return <TableType edit={edit} history={history} />
    } else if (type.match(/sortAnswer/gi)) {
      return <ShortAnswer edit={edit} history={history} />
    } else {
      return <div className="page-content">No question type available</div>
    }
  }

  return <React.Fragment>{getQuestionComponents(type)}</React.Fragment>
}

export default withRouter(Questions)
