import {
  ADD_FACILITATOR_REVIEW,
  ADD_FACILITATOR_REVIEW_FAIL,
  ADD_FACILITATOR_REVIEW_SUCCESS,
  ADD_FACILITATOR_SLOT,
  ADD_FACILITATOR_SLOT_FAIL,
  ADD_FACILITATOR_SLOT_SUCCESS,
  GET_FACILITATOR_LISTENING_SET_FAIL,
  GET_FACILITATOR_LISTENING_SET_SUCCESS,
  GET_FACILITATOR_REVIEW_FAIL,
  GET_FACILITATOR_REVIEW_SUCCESS,
  GET_FACILITATOR_SET_FAIL,
  GET_FACILITATOR_SET_SUCCESS,
  GET_FACILITATOR_SINGLE_DAY,
  GET_FACILITATOR_SINGLE_DAY_FAIL,
  GET_FACILITATOR_SINGLE_DAY_SUCCESS,
  GET_FACILITATOR_SINGLE_PACKAGE,
  GET_FACILITATOR_SINGLE_PACKAGE_FAIL,
  GET_FACILITATOR_SINGLE_PACKAGE_SUCCESS,
  GET_FACILITATOR_SLOT,
  GET_FACILITATOR_SLOT_FAIL,
  GET_FACILITATOR_SLOT_SUCCESS,
  GET_FACILITATOR_WRITING_SET_FAIL,
  GET_FACILITATOR_WRITING_SET_SUCCESS,
  GET_REVIEW_ATTENDANCE,
  GET_REVIEW_ATTENDANCE_FAIL,
  GET_REVIEW_ATTENDANCE_SUCCESS,
  GET_SINGLE_FACILITATOR_SLOT,
  GET_SINGLE_FACILITATOR_SLOT_FAIL,
  GET_SINGLE_FACILITATOR_SLOT_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: false,
  adding: false,
  message: "",
  error: "",
  singleFacilitatorSlot: {},
  facilitatorSinglePackage: {},
  facilitatorSlots: [],
  FacilitatorSingleDay: [],
  facilitatorSet: [],
  facilitatorListeningSet: [],
  facilitatorReview: [],
  facilitatorWritingSet: [],
  reviewAttendance: [],
  FacilitatorReviewLoading: false,
  attendanceLoading:false
}

const FacilitatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FACILITATOR_SLOT:
      state = {
        ...state,
        adding: true,
      }
      break
    case ADD_FACILITATOR_SLOT_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        adding: false,
      }
      break
    case ADD_FACILITATOR_SLOT_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      }
      break

    case GET_FACILITATOR_SLOT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_FACILITATOR_SLOT_SUCCESS:
      state = {
        ...state,
        loading: false,
        facilitatorSlots: action.payload.values,
      }
      break
    case GET_FACILITATOR_SLOT_FAIL:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case GET_SINGLE_FACILITATOR_SLOT:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_SINGLE_FACILITATOR_SLOT_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleFacilitatorSlot: action.payload.values,
      }
      break
    case GET_SINGLE_FACILITATOR_SLOT_FAIL:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case GET_FACILITATOR_SINGLE_DAY:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_FACILITATOR_SINGLE_DAY_SUCCESS:
      state = {
        ...state,
        loading: false,
        FacilitatorSingleDay: action.payload.values,
      }
      break
    case GET_FACILITATOR_SINGLE_DAY_FAIL:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case GET_FACILITATOR_SINGLE_PACKAGE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_FACILITATOR_SINGLE_PACKAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        facilitatorSinglePackage: action.payload.values,
      }
      break
    case GET_FACILITATOR_SINGLE_PACKAGE_FAIL:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case GET_FACILITATOR_SET_SUCCESS:
      state = {
        ...state,
        error: "",
        facilitatorSet: action.payload,
        loading: false,
      }
      break
    case GET_FACILITATOR_SET_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      }
      break
    case GET_FACILITATOR_REVIEW_SUCCESS:
      state = {
        ...state,
        error: "",
        facilitatorReview: action.payload,
        FacilitatorReviewLoading: false,
      }
      break
    case GET_FACILITATOR_REVIEW_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        FacilitatorReviewLoading: false,
      }
      break
    case ADD_FACILITATOR_REVIEW:
      state = {
        ...state,
        adding: true,
      }
      break
    case ADD_FACILITATOR_REVIEW_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        adding: false,
      }
      break
    case ADD_FACILITATOR_REVIEW_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      }
      break
    case GET_FACILITATOR_LISTENING_SET_SUCCESS:
      state = {
        ...state,
        error: "",
        facilitatorListeningSet: action.payload,
        loading: false,
      }
      break
    case GET_FACILITATOR_LISTENING_SET_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      }
      break
    case GET_FACILITATOR_WRITING_SET_SUCCESS:
      state = {
        ...state,
        error: "",
        facilitatorWritingSet: action.payload,
        loading: false,
      }
      break
    case GET_FACILITATOR_WRITING_SET_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      }
      break
    case GET_REVIEW_ATTENDANCE:
      state = {
        ...state,

        attendanceLoading: true,
      }
      break
    case GET_REVIEW_ATTENDANCE_SUCCESS:
      state = {
        ...state,

        attendanceLoading: false,
      }
      break
    case GET_REVIEW_ATTENDANCE_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        attendanceLoading: false,
      }
      break

    default:
      state = { ...state }
      break
  }

  return state
}

export default FacilitatorReducer
