import { ADD_QUANTITATIVE_QUES, ADD_QUANTITATIVE_QUES_FAIL, ADD_QUANTITATIVE_QUES_SUCCESS, GET_QUANTITATIVE_QUES, GET_QUANTITATIVE_QUES_FAIL, GET_QUANTITATIVE_QUES_SUCCESS, GET_SINGLE_QUANTITATIVE_QUES, GET_SINGLE_QUANTITATIVE_QUES_FAIL, GET_SINGLE_QUANTITATIVE_QUES_SUCCESS, UPDATE_QUANTITATIVE_QUES, UPDATE_QUANTITATIVE_QUES_FAIL, UPDATE_QUANTITATIVE_QUES_SUCCESS } from "./actionTypes";

export const addQuantitativeQues = (obj, authToken, option) => ({
    type: ADD_QUANTITATIVE_QUES,
    payload: { obj, authToken, option },
  })
  
  export const addQuantitativeQuesSuccess = data => ({
    type: ADD_QUANTITATIVE_QUES_SUCCESS,
    payload: { data },
  })

  export const addQuantitativeQuesFail = data => ({
    type: ADD_QUANTITATIVE_QUES_FAIL,
    payload: { data },
  })

  export const getAllQuantitativeQues = (token, section, type) => ({
    type: GET_QUANTITATIVE_QUES,
    payload: { token, section, type },
  });
  
  export const getAllQuantitativeQuesSuccess = (data) => ({
    type: GET_QUANTITATIVE_QUES_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getAllQuantitativeQuesFail = (data) => ({
    type: GET_QUANTITATIVE_QUES_FAIL,
    payload: { data },
  });

  export const getSingleQuantitativeQues = (authToken, questionId) => ({
    type: GET_SINGLE_QUANTITATIVE_QUES,
    payload: { authToken, questionId },
  });
  
  export const getSingleQuantitativeQuesSuccess = (data) => ({
    type: GET_SINGLE_QUANTITATIVE_QUES_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getSingleQuantitativeQuesFail = (data) => ({
    type: GET_SINGLE_QUANTITATIVE_QUES_FAIL,
    payload: { data },
  });
  export const updateQuantitativeQues = (data, token, history, screenId) => ({
    type: UPDATE_QUANTITATIVE_QUES,
    payload: { data, token, history, screenId },
  })
  
  export const updateQuantitativeQuesSuccess = data => ({
    type: UPDATE_QUANTITATIVE_QUES_SUCCESS,
    payload: { data },
  })

  export const updateQuantitativeQuesFail = data => ({
    type: UPDATE_QUANTITATIVE_QUES_FAIL,
    payload: { data },
  })