import { call, put, takeEvery } from "redux-saga/effects"
import { toaster } from "../../helpers/Custom/Toaster"
import { getData, patchData, postData } from "../../helpers/backend_helper"
import { ACTIVE_SCREEN, ADD_SCREEN, GET_SCREEN } from "./actionTypes"
import {
  activeScreenFail,
  activeScreenSuccess,
  addScreenFail,
  addScreenSuccess,
  getScreenList,
  getScreenListFail,
  getScreenListSuccess,
} from "./actions"

function* onAddScreen({ payload: { data, token, history, screenId } }) {
  try {
    console.log("banner", screenId)
    const url = screenId ? `splash-update/${screenId}` : `/splash-create`
    const response = yield call(
      screenId ? patchData : postData,
      url,
      data,
      token
    )
    if (response) {
      yield put(addScreenSuccess({}))
      yield put(getScreenList(token))
      toaster("success", `Screen created Successfully`)
    }
    // history.push("/author-list")
  } catch (err) {
    const message = err?.response?.data?.message || `Author creation failed`
    toaster("error", message)
    yield put(addScreenFail(message))
  }
}
function* onGetScreenList({ payload: { token } }) {
  try {
    const url = "/splash-get-by-admin"
    const response = yield call(getData, url, token)
    yield put(getScreenListSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed"
    yield put(getScreenListFail(message))
    toaster("error", message)
  }
}

function* onActiveScreen({ payload: { data, token, history, id } }) {
  try {
    const url = `splash-visiblity/${id}`
    const response = yield call(patchData, url, data, token)
    if (response) {
      yield put(activeScreenSuccess({}))
      yield put(getScreenList(token))
      toaster("success", `Screen updated Successfully`)
    }

    // history.push("/blog-list")
  } catch (err) {
    const message = err?.response?.data?.message || `Screen Activation failed`
    toaster("error", message)
    yield put(activeScreenFail(message))
  }
}

function* ScreenSaga() {
  yield takeEvery(ADD_SCREEN, onAddScreen)
  yield takeEvery(GET_SCREEN, onGetScreenList)
  yield takeEvery(ACTIVE_SCREEN, onActiveScreen)
}

export default ScreenSaga
