import {
  ADD_WRITTING_SET,
  ADD_WRITTING_SETS_QUESTION,
  ADD_WRITTING_SETS_QUESTION_FAIL,
  ADD_WRITTING_SETS_QUESTION_SUCCESS,
  ADD_WRITTING_SET_FAIL,
  ADD_WRITTING_SET_SUCCESS,
  GET_WRITTING_SETS,
  GET_WRITTING_SETS_FAIL,
  GET_WRITTING_SETS_NUMBER,
  GET_WRITTING_SETS_NUMBER_FAIL,
  GET_WRITTING_SETS_NUMBER_SUCCESS,
  GET_WRITTING_SETS_SUCCESS,
  GET_WRITTING_SET_SINGLE,
  GET_WRITTING_SET_SINGLE_FAIL,
  GET_WRITTING_SET_SINGLE_SUCCESS,
} from "./actionTypes"

export const addWritingSet = (values, options) => {
  return {
    type: ADD_WRITTING_SET,
    payload: { values, options },
  }
}
export const addWritingSetSuccess = message => {
  return {
    type: ADD_WRITTING_SET_SUCCESS,
    payload: { message },
  }
}

export const addWritingSetFail = message => {
  return {
    type: ADD_WRITTING_SET_FAIL,
    payload: { message },
  }
}
export const getWritingSets = (token, serviceid) => {
  return {
    type: GET_WRITTING_SETS,
    payload: { token, serviceid },
  }
}

export const getWritingSetsSuccess = values => {
  return {
    type: GET_WRITTING_SETS_SUCCESS,
    payload: { values },
  }
}

export const getWritingSetsFail = message => {
  return {
    type: GET_WRITTING_SETS_FAIL,
    payload: { message },
  }
}

export const getSingleWritingSets = (token, options) => {
  return {
    type: GET_WRITTING_SET_SINGLE,
    payload: { token, options },
  }
}

export const getSingleWritingSetsSuccess = values => {
  return {
    type: GET_WRITTING_SET_SINGLE_SUCCESS,
    payload: { values },
  }
}

export const getSingleWritingSetsFail = message => {
  return {
    type: GET_WRITTING_SET_SINGLE_FAIL,
    payload: { message },
  }
}

export const getSingleWritingSetsNumber = (token, options) => {
  return {
    type: GET_WRITTING_SETS_NUMBER,
    payload: { token, options },
  }
}

export const getSingleWritingSetsNumberSuccess = values => {
  return {
    type: GET_WRITTING_SETS_NUMBER_SUCCESS,
    payload: { values },
  }
}

export const getSingleWritingSetsNumberFail = message => {
  return {
    type: GET_WRITTING_SETS_NUMBER_FAIL,
    payload: { message },
  }
}

export const addWritingSetQuestion = (values, options) => {
  return {
    type: ADD_WRITTING_SETS_QUESTION,
    payload: { values, options },
  }
}
export const addWritingSetQuestionSuccess = message => {
  return {
    type: ADD_WRITTING_SETS_QUESTION_SUCCESS,
    payload: { message },
  }
}

export const addWritingSetQuestionFail = message => {
  return {
    type: ADD_WRITTING_SETS_QUESTION_FAIL,
    payload: { message },
  }
}
