import { ACTIVE_BANNER, ACTIVE_BANNER_FAIL, ACTIVE_BANNER_SUCCESS, ADD_BANNER, ADD_BANNER_FAIL, ADD_BANNER_SUCCESS, GET_BANNER, GET_BANNER_FAIL, GET_BANNER_SUCCESS, STORE_BANNER_DATA } from "./actionTypes"

  const INIT_STATE = {
    loading: true,
    error: "",
    data: [],
    adding: false,
    activeBannerLoading: false,
    bannerList: [],
    editBannerData: [],
    bannerLoading:false
  }
  
  const bannerReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case ADD_BANNER:
        return {
          ...state,
          adding: true,
        }
      case ADD_BANNER_SUCCESS:
        return {
          ...state,
          adding: false,
        }
      case ADD_BANNER_FAIL:
        return {
          ...state,
          adding: false,
          error: action.payload.data,
        }
        case GET_BANNER:
          return {
            ...state,
            bannerLoading: true,
          };
        case GET_BANNER_SUCCESS:
          return {
            ...state,
            bannerLoading: false,
            bannerList: action.payload.data,
          };
        case GET_BANNER_FAIL:
          return {
            ...state,
            error: action.payload,
            bannerLoading: false,
          };
          case ACTIVE_BANNER:
            return {
              ...state,
              activeBannerLoading: true,
            }
          case ACTIVE_BANNER_SUCCESS:
            return {
              ...state,
              activeBannerLoading: false,
            }
          case ACTIVE_BANNER_FAIL:
            return {
              ...state,
              activeBannerLoading: false,
              error: action.payload.data,
            }  
            case STORE_BANNER_DATA:
              return {
                ...state,
                [action.payload.name]: action.payload.data,
              };
      default:
        return state
    }
  }
  
  export default bannerReducer
  