import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import NoData from "components/Common/NoData"
import Toggle from "components/Common/Switch"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import { Button, CardText, CardTitle, Col, Row } from "reactstrap"
import { getIELTSReadingPassage, getIELTSSingleReadingSet } from "store/actions"
import InnerLayer from "../../../Layout/InnerLayer"

const ListPassage = ({ history }) => {
  const { serviceId, setId } = useParams()
  const [state, setState] = useState({
    checked: false,
    size: "default",
    disabled: false,
  })
  const param = useParams()
  console.log(param)
  const dispatch = useDispatch()
  const { readingPassage, passageLoading, authToken, singleReadingSet } =
    useSelector(state => ({
      authToken: state.Login.token,
      readingPassage: state.readingReducer.IeltsReadingPassage.data,
      passageLoading: state.readingReducer.IeltsReadingPassageLoading,
      singleReadingSet: state.readingReducer.singleReadingSet,
    }))
  console.log(
    `🦄 ~ file: ListPassage.js ~ line 16 ~ ListPassage ~ singleReadingSet`,
    singleReadingSet
  )
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Service List",
      link: `/services`,
    },
    {
      title: "Reading Set",
      link: `/reading-set/${serviceId}`,
    },
    {
      title: `${singleReadingSet?.setName || ""}`,
      link: "#",
    },
  ]

  const handleChange = e => {
    setState({ ...state, checked: e.target.checked })
  }

  console.log(readingPassage)

  useEffect(() => {
    // dispatch({type : LOGIN_USER, payload : {user, authToken}})
    dispatch(getIELTSReadingPassage(authToken, setId))
    dispatch(getIELTSSingleReadingSet(authToken, setId))
  }, [])
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={singleReadingSet?.setName || "Reading"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>{singleReadingSet?.setName || ""}</h4>
              <div className="d-flex align-items-center">
                <div className="search-box me-2">
                  <Toggle
                    checked={state.checked}
                    text="Is Active"
                    size={state.size}
                    disabled={state.disabled}
                    onChange={handleChange}
                    offstyle="btn-danger"
                    onstyle="btn-success"
                  />
                </div>
                <div>
                  <div className="position-relative">
                    <Link
                      to={`/service/${serviceId}/reading/${setId}/create-passage`}
                    >
                      <Button
                        type="button"
                        color="primary"
                        className="btn-circle me-2"
                      >
                        <i className="mdi mdi-plus me-1" />
                        Create Passage
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </CardComponent>
        </Row>
        {passageLoading ? (
          <div className="text-center  pt-5">
            <Loader />
          </div>
        ) : (
          <Row>
            {readingPassage?.passage?.length > 0 ? (
              (readingPassage?.passage || []).map((data, key) => {
                return (
                  <Col md="6" key={key}>
                    <CardComponent>
                      <CardTitle tag="h5">
                        {data.readingPessageTopicName}
                      </CardTitle>
                      <CardText className="passage-description">
                        {data.readingPessageDescription[0].passageInfo}
                      </CardText>
                      <div className="d-flex justify-content-end">
                        <Link
                          to={`/service/${serviceId}/reading/${setId}/view-passage/${data._id}`}
                        >
                          <div>
                            <Button
                              type="button"
                              color="primary"
                              className="btn btn-circle btn-sm m-1"
                            >
                              Add Question
                            </Button>
                          </div>
                        </Link>
                        <Link
                          to={`/service/${serviceId}/reading/${setId}/edit-passage/${data._id}`}
                        >
                          <div>
                            <Button
                              type="button"
                              color="primary"
                              style={{
                                background: "transparent",
                                color: "#485ec4",
                                border: "1px solid #485ec4",
                              }}
                              className="btn btn-sm m-1"
                            >
                              Edit Passage
                            </Button>
                          </div>
                        </Link>
                      </div>
                    </CardComponent>
                  </Col>
                )
              })
            ) : (
              <div>
                <NoData>
                  <p className="mb-0">Currently you have no passage.</p>
                  <p>
                    For next step first{" "}
                    <Link
                      to={`/service/${serviceId}/reading/${setId}/create-passage`}
                    >
                      Create passage
                    </Link>
                  </p>
                </NoData>
              </div>
            )}
          </Row>
        )}
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(ListPassage)
