import React from "react";
import { FormGroup } from "react-bootstrap";
import { FormFeedback, Label } from "reactstrap";
const CheckBox = ({
  field : { onChange, ...rest },
  form: { touched, errors },
  label,
  id,
  labelStyle,
  onChangeCallback,
  ...props
}) => {
  return (
    <FormGroup className={"w-100 customInputWrapper"}>
      <div className="d-flex gap-2 align-items-center">
        <input
          id={id}
          type="checkbox"
          onChange={(e) => {
            const data = e;
            onChangeCallback && onChangeCallback(data);
            onChange(e);
          }}
          {...rest}
          {...props}
        />
        {label ? (
          <Label style={labelStyle} className="mb-0" htmlFor={id}>
            {label}
          </Label>
        ) : null}
      </div>
      {touched[rest.name] && errors[rest.name] && !props.disabled ? (
        <FormFeedback type="invalid" className="d-inline">
          {errors[rest.name]}
        </FormFeedback>
      ) : null}
    </FormGroup>
  );
};
export default CheckBox;






