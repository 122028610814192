import React from "react";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import Editor from "../Common/QuillEditor";

const QuillComponent = ({
  label,
  id,
  field: { name, onChange, value },
  form: { touched, errors, setFieldTouched },
  isRequired,
  ...props
}) => {
  return (
    <React.Fragment>
      <FormGroup className={"w-100"}>
        {label ? (
          <Label htmlFor={id}>
            {label}
            {isRequired ? <span className="text-danger">{" *"}</span> : ""}
          </Label>
        ) : null}
        <Editor
          value={value}
          id={id}
          handleChange={(e) => onChange({ target: { value: e, name } })}
          handleBlur={(e) => setFieldTouched(name, true)}
          {...props}
        />
        {touched[name] && errors[name] && !props.disabled ? (
          <FormFeedback type="invalid" className="d-inline">
            {errors[name]}
          </FormFeedback>
        ) : null}
      </FormGroup>
    </React.Fragment>
  );
};

export default QuillComponent;
