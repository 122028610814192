export const ADD_LISTENING_SET = "ADD_LISTENING_SET"
export const ADD_LISTENING_SET_SUCCESS = "ADD_LISTENING_SET_SUCCESS"
export const ADD_LISTENING_SET_FAIL = "ADD_LISTENING_SET_FAIL"

export const ADD_LISTENING_SLICE = "ADD_LISTENING_SLICE"
export const ADD_LISTENING_SLICE_SUCCESS = "ADD_LISTENING_SLICE_SUCCESS"
export const ADD_LISTENING_SLICE_FAIL = "ADD_LISTENING_SLICE_FAIL"

export const UPLOAD_AUDIO = "UPLOAD_AUDIO"
export const UPLOAD_AUDIO_SUCCESS = "UPLOAD_AUDIO_SUCCESS"
export const UPLOAD_AUDIO_FAIL = "ADD_LISTENING_SET_FAIL"

export const GET_LISTENING_SETS = "GET_LISTENING_SETS"
export const GET_LISTENING_SETS_SUCCESS = "GET_LISTENING_SETS_SUCCESS"
export const GET_LISTENING_SETS_FAIL = "GET_LISTENING_SETS_FAIL"

export const GET_LISTENING_SET_SINGLE = "GET_LISTENING_SET_SINGLE"
export const GET_LISTENING_SET_SINGLE_SUCCESS =
  "GET_LISTENING_SET_SINGLE_SUCCESS"
export const GET_LISTENING_SET_SINGLE_FAIL = "GET_LISTENING_SET_SINGLE_FAIL"

export const GET_LISTENING_AUDIO = "GET_LISTENING_AUDIO"
export const GET_LISTENING_AUDIO_SUCCESS = "GET_LISTENING_AUDIO_SUCCESS"
export const GET_LISTENING_AUDIO_FAIL = "GET_LISTENING_AUDIO_FAIL"

export const GET_LISTENING_AUDIO_SLICE = "GET_LISTENING_AUDIO_SLICE"
export const GET_LISTENING_AUDIO_SLICE_SUCCESS =
  "GET_LISTENING_AUDIO_SLICE_SUCCESS"
export const GET_LISTENING_AUDIO_SLICE_FAIL = "GET_LISTENING_AUDIO_SLICE_FAIL"

export const GET_LISTENING_AUDIO_SINGLE_SLICE =
  "GET_LISTENING_AUDIO_SINGLE_SLICE"
export const GET_LISTENING_AUDIO_SINGLE_SLICE_SUCCESS =
  "GET_LISTENING_AUDIO_SINGLE_SLICE_SUCCESS"
export const GET_LISTENING_AUDIO_SINGLE_SLICE_FAIL =
  "GET_LISTENING_AUDIO_SINGLE_SLICE_FAIL"
