import React from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import '../IntegratedResoning.css'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleTableAnalysisQues } from 'store/GMAT/IntegratedReasoning/actions';
import { useParams } from 'react-router-dom';

const DetailsAns = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const { singleTableAnalysisQues, uploading, authToken } = useSelector(
        state => ({
          singleTableAnalysisQues: state.IRReducer.singleTableAnalysisQues,
       
          uploading: state.analyticalReducer.uploading,
          authToken: state.Login.token,
        })
      )
      console.log('singleTableAnalysisQues', singleTableAnalysisQues);
    useEffect(() => {
        if (id) {
          dispatch(getSingleTableAnalysisQues(authToken, id))
        }
      }, [id])
    return (
        <div>
        <div className="page-content">
          <Container>
            <h5 className="fw-bold">Integrated Reasoning</h5>
            <p className="mt-3">Answer Script</p>
  
            <div className="table-analysis-question-part">
              <Row>
                <Col md={12}>
                <Table >
            <thead>
              <tr >
                <th></th>
                <th>Question</th>
                <th>Answer</th>
               
              </tr>
            </thead>
            <tbody>
            {singleTableAnalysisQues?.options?.map((data, idx) => (
                  <>
              <tr>
                <td key={idx} className='fw-bold'>{idx+1}</td>
           
                    <td >{data?.option}</td>
                    <td>{data?.answer}</td>
            
                
               
              </tr>
              </>
              ))}
             
            </tbody>
          </Table>
                 
                
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    );
};

export default DetailsAns;