/* eslint-disable react/react-in-jsx-scope */
const SpeakingArea = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5406_39794)">
        <path
          d="M20 27.5C16.3384 27.5 13.3594 24.521 13.3594 20.8594V11.4844C13.3594 7.82275 16.3384 4.84377 20 4.84377C23.6616 4.84377 26.6406 7.82275 26.6406 11.4844V20.8594C26.6406 24.521 23.6616 27.5 20 27.5Z"
          fill="#FDAC22"
        />
        <path
          d="M30.9375 15H28.9844V17.3438H29.7656V20.8594C29.7656 26.2441 25.3848 30.625 20 30.625C14.6152 30.625 10.2344 26.2441 10.2344 20.8594V17.3438H11.0156V15H9.0625C8.41531 15 7.89062 15.5247 7.89062 16.1719V20.8594C7.89062 27.5365 13.3228 32.9688 20 32.9688C26.6772 32.9688 32.1094 27.5365 32.1094 20.8594V16.1719C32.1094 15.5247 31.5847 15 30.9375 15Z"
          fill="#FDAC22"
        />
        <path
          d="M24.1016 37.6561H21.1719V35.2647C20.7852 35.2959 20.3945 35.3124 20 35.3124C19.6055 35.3124 19.2148 35.2959 18.8281 35.2647V37.6561H15.8984C15.2512 37.6561 14.7266 38.1808 14.7266 38.828C14.7266 39.4751 15.2512 39.9998 15.8984 39.9998H24.1016C24.7487 39.9998 25.2734 39.4751 25.2734 38.828C25.2734 38.1808 24.7487 37.6561 24.1016 37.6561Z"
          fill="#FDAC22"
        />
        <path
          d="M29.4531 8.9727C30.1003 8.9727 30.625 8.44804 30.625 7.80083C30.625 7.15362 30.1003 6.62895 29.4531 6.62895C28.8059 6.62895 28.2812 7.15362 28.2812 7.80083C28.2812 8.44804 28.8059 8.9727 29.4531 8.9727Z"
          fill="#FDAC22"
        />
        <path
          d="M32.7675 12.2873C32.4676 12.2873 32.1677 12.1729 31.9389 11.9441C31.4813 11.4864 31.4813 10.7445 31.9389 10.2868C33.3096 8.91612 33.3096 6.68565 31.9389 5.31495C31.4813 4.85729 31.4813 4.11534 31.9389 3.65768C32.3966 3.2001 33.1386 3.2001 33.5962 3.65768C35.8807 5.94229 35.8807 9.65948 33.5962 11.9441C33.3674 12.1728 33.0675 12.2873 32.7675 12.2873Z"
          fill="#FDAC22"
        />
        <path
          d="M36.0821 15.602C35.7822 15.602 35.4822 15.4877 35.2535 15.2588C34.7958 14.8011 34.7958 14.0591 35.2535 13.6015C36.8028 12.0521 37.6561 9.99219 37.6561 7.80102C37.6561 5.60993 36.8028 3.54993 35.2534 2.00055C34.7957 1.54298 34.7957 0.800944 35.2534 0.343288C35.711 -0.114368 36.453 -0.114368 36.9107 0.343288C38.9027 2.33532 39.9998 4.98383 39.9998 7.80102C39.9998 10.6182 38.9027 13.2667 36.9107 15.2588C36.6818 15.4876 36.3819 15.602 36.0821 15.602Z"
          fill="#FDAC22"
        />
        <path
          d="M10.5469 8.9727C11.1941 8.9727 11.7188 8.44804 11.7188 7.80083C11.7188 7.15362 11.1941 6.62895 10.5469 6.62895C9.89967 6.62895 9.375 7.15362 9.375 7.80083C9.375 8.44804 9.89967 8.9727 10.5469 8.9727Z"
          fill="#FDAC22"
        />
        <path
          d="M7.23139 12.2874C6.93146 12.2874 6.63154 12.173 6.40279 11.9441C4.11834 9.65951 4.11834 5.94232 6.40279 3.65771C6.86037 3.20013 7.6024 3.20006 8.06006 3.65771C8.51772 4.11537 8.51772 4.85732 8.06006 5.31498C6.68936 6.68568 6.68936 8.91615 8.06006 10.2869C8.51772 10.7445 8.51772 11.4865 8.06006 11.9441C7.83123 12.173 7.53123 12.2874 7.23139 12.2874Z"
          fill="#FDAC22"
        />
        <path
          d="M3.91781 15.602C3.61789 15.602 3.31797 15.4876 3.08922 15.2587C1.09711 13.2667 0 10.6182 0 7.801C0 4.98381 1.09711 2.33529 3.08914 0.343259C3.5468 -0.114398 4.28883 -0.11432 4.74641 0.343259C5.20406 0.800915 5.20406 1.54287 4.74641 2.00053C3.19703 3.5499 2.34375 5.60982 2.34375 7.801C2.34375 9.99217 3.19703 12.0522 4.74641 13.6016C5.20406 14.0591 5.20406 14.8012 4.74641 15.2588C4.51758 15.4876 4.21766 15.602 3.91781 15.602Z"
          fill="#FDAC22"
        />
      </g>
      <defs>
        <clipPath id="clip0_5406_39794">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SpeakingArea
