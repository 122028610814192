import moment from "moment"
import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import Timer from "react-compound-timer/build"
import { useDispatch, useSelector } from "react-redux"
import Modal from "react-responsive-modal"
import { Link, useParams } from "react-router-dom"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap"
import { getFacilitatorSinglePackage } from "store/Facilitator/actions"
import clock from "../../assets/images/Icon/clock.svg"
import image from "../../assets/images/Image.png"

import NoData from "../../components/Common/NoData"

import { calculateTimeCount } from "../../../src/helpers/Custom/timeHelper"
import { toaster } from "../../../src/helpers/Custom/Toaster"
import "./student-package.scss"

// import { addWishList, getWishList } from "../../store/W";
import "./my-package.css"
import { imageBaseUrl } from "helpers/api_helper"
const FacilitatorPackage = () => {
  const [ratingValue, setRatingValue] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [reviewData, setReviewData] = useState({})
  const [open, setOpen] = useState(false)
  const { packageId, studentId } = useParams()

  const dispatch = useDispatch()
  const {
    authtoken,
    userPackageLoading,
    id,
    wishList,
    adding,
    addingRating,
    review,
    loading,
    facilitatorSinglePackage,
  } = useSelector(state => ({
    authtoken: state.Login.token,

    loading: state.FacilitatorReducer.loading,
    facilitatorSinglePackage: state.FacilitatorReducer.facilitatorSinglePackage,
    id: state.Login.id,
    // wishList: state.wishlist.wishList,
    // adding: state.wishlist.adding,
  }))

  console.log("facilitatorSinglePackage", facilitatorSinglePackage)

  useEffect(() => {
    dispatch(getFacilitatorSinglePackage(authtoken, packageId))
  }, [])
  const getPhotoUrl = (photo) => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo;
    }
    return image;
  };

  return (
    <React.Fragment>
      {adding ? <Spinner /> : null}
      <div className="dashboard-main-wrap my-course-page">
        <Container>
          <div className="ieltspackage-main-wrap-all-package pt-3 mt-5">
            <Container>
              {loading ? (
                <div className="text-center  py-5">
                  <Spinner />
                </div>
              ) : (
                <Row>
                  {Object.keys(facilitatorSinglePackage).length > 0 ? (
                    <Col lg={4}>
                      <div className="student-courses-card">
                        <Card className=" position-relative">
                          <div
                            className="bookmark-button-wrap position-absolute"
                            style={{
                              background: "#fff",
                              padding: "5px",
                              lineHeight: "5px",
                              borderRadius: "5px",
                              top: "2%",
                              right: "2%",
                              fontSize: "18px",
                            }}
                            // onClick={() => handleAddWishlist(data)}
                          ></div>
                          <div
                            className="card-img-top package-image-preview"
                            style={{
                              backgroundImage: `url(${getPhotoUrl(facilitatorSinglePackage?.singlePackage?.photo)})`,
                            }}
                          ></div>
                          <Card.Body className="position-relative">
                            <span
                              className="position-absolute bg-white p-2 rounded-1"
                              style={{
                                left: "50%",
                                top: "-30%",
                                transform: "translateX(-50%)",
                                border: "1px solid #004AB8",
                                width: "max-content",
                              }}
                            >
                              <Timer
                            initialTime={
                              calculateTimeCount(facilitatorSinglePackage?.singlePackage?.validity) || 0
                            }
                            // checkpoints={checkpoints}
                            direction="backward"
                          >
                         
                            <span className="ms-2">
                              <Timer.Days />D :
                            </span>
                            <span className="ms-2">
                              <Timer.Hours />H :
                            </span>
                            <span className="ms-2">
                              <Timer.Minutes />M :
                            </span>
                            <span className="ms-2">
                              <Timer.Seconds />S
                            </span>
                          </Timer>
                            </span>
                            {/* {console.log(data)} */}
                            <div className="student-courses-review d-flex align-items-center">
                              {/* <span>
                            {facilitatorSinglePackage?.singlePackage?.aveRatings}(
                            {facilitatorSinglePackage?.singlePackage?.ratings?.length})
                          </span> */}
                            </div>
                            <Link
                              to="#"
                              className="mb-2 "
                              style={{ display: "inline-block" }}
                            >
                              <Card.Title>
                                {
                                  facilitatorSinglePackage?.singlePackage
                                    ?.packageName
                                }
                              </Card.Title>
                            </Link>
                            {/* <Card.Text className="student-courses-timeline">
                          <img src={clock} alt="" />
                          Duration -{" "}
                          {moment(facilitatorSinglePackage?.singlePackage?.validity).format(
                            "DD-MM-YYYY"
                          )}{" "}
                          Days
                        </Card.Text> */}
                            <div className="d-flex">
                              <div className="student-courses-card-button me-2">
                                <Link
                                  to={`/facilitator/set-under-package/${packageId}/${studentId}`}
                                  className="meet-button"
                                >
                                  View Services
                                </Link>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                  ) : (
                    <div>
                      <NoData>
                        <h6>You have no active packages</h6>
                        <p>
                          <Link to={`/packages`}>Buy package</Link>
                        </p>
                      </NoData>
                    </div>
                  )}
                </Row>
              )}
            </Container>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FacilitatorPackage
