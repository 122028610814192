import React from "react"
import noImage from "../../assets/images/noData.png"

const NoData = ({ children }) => {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center align-items-center">
        <div className="text-center">
          <img src={noImage} alt="noData" />
          <p>{children}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NoData
