import CardComponent from "components/Common/CardComponent"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { Button, Container, Input, Row } from "reactstrap"
import { submitAnalyticalMark } from "store/GMAT/Analytical/actions"
import InnerLayer from "../../../../Layout/InnerLayer"
import "./Teacher.scss"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Review Answer",
    link: "/review-answer",
  },
  {
    title: `Writing Review`,
    link: "/review-answer/writing",
  },
  {
    title: `Result`,
    link: "#",
  },
]
const SingleExamDetails = ({ answer }) => {
  const [mark, setMark] = useState()
  const location = useLocation()
  const data = location.state.data
  const dispatch = useDispatch()
  const history = useHistory()
  console.log("data", data)
  const { authToken, adding, analyticalAns } = useSelector(state => ({
    authToken: state.Login.token,
    adding: state.analyticalReducer.adding,
  }))
  const handleSubmit = () => {
    let obj = {
      mark: mark * 1,
      examStatus: 3,
    }
    let option = {
      examId: data?._id,
      history,
    }
    dispatch(submitAnalyticalMark(obj, authToken, option))
  }
  return (
    <div>
      <InnerLayer
        isMultiple
        title={"Analytical writing"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <div className="back-arrow-icon-content-wrap">
                <h4>
                  <span
                    onClick={() => history.goBack()}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bx bx-arrow-back"></i>
                  </span>{" "}
                  Result
                </h4>
              </div>
            </div>
          </CardComponent>
        </Row>
        <Container>
          <div className="analtical-full-ans-page">
            <div>
              <h6>
                <span className="fw-bold me-2">Question title: </span>
                {data?.answer?.[0]?.questionid?.questionTitle}
              </h6>
              <h6>
                <span className="fw-bold me-2">Question: </span>{" "}
                {data?.answer?.[0]?.questionid?.q}
              </h6>
            </div>
          </div>
          <div className="mt-3">
            <h5>
              <span className="fw-bold">Student Answer:</span>
            </h5>
            <div className="single-analytical-student-answer">
              <p className="fw-bold">{data?.answer?.[0]?.answer}</p>
            </div>
          </div>
          <div className="analytical-exam-mark">
            <Input
              type="number"
              onChange={e => setMark(e.target.value)}
              value={mark}
              placeholder="type mark"
            ></Input>
            <Button
              color="primary"
              disabled={adding}
              onClick={() => handleSubmit()}
            >
              {adding ? "Submitting" : "Submit"}{" "}
            </Button>
          </div>
        </Container>
      </InnerLayer>
    </div>
  )
}

export default SingleExamDetails
