import {
  ADD_IELTS_READING_PASSAGE,
  ADD_IELTS_READING_PASSAGE_FAIL,
  ADD_IELTS_READING_PASSAGE_SUCCESS,
  ADD_IELTS_READING_SET,
  ADD_IELTS_READING_SET_FAIL,
  ADD_IELTS_READING_SET_SUCCESS,
  GET_IELTS_READING_PASSAGE_FAIL,
  GET_IELTS_READING_PASSAGE_SUCCESS,
  GET_IELTS_READING_SET,
  GET_IELTS_READING_SET_FAIL,
  GET_IELTS_READING_SET_SUCCESS,
  GET_IELTS_READING_SINGLE_PASSAGE,
  GET_IELTS_READING_SINGLE_PASSAGE_FAIL,
  GET_IELTS_READING_SINGLE_PASSAGE_SUCCESS,
  GET_IELTS_SINGLE_READING_SET,
  GET_IELTS_SINGLE_READING_SET_FAIL,
  GET_IELTS_SINGLE_READING_SET_SUCCESS,
} from "./actionTypes"

const initialState = {
  message: "",
  error: "",
  readingSet: {},
  readingSets: [],
  singleReadingSet: [],
  singleReadingPassage: [],
  IeltsReadingPassage: [],
  loading: false,
  adding: false,
  IeltsReadingPassageLoading: true,
  SingleIeltsReadingPassageLoading: true,
}

const readingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IELTS_READING_SET:
      return {
        ...state,
        loading: true,
      }
    case ADD_IELTS_READING_SET:
      return {
        ...state,
        adding: true,
      }
    case ADD_IELTS_READING_SET_SUCCESS:
      return {
        ...state,
        readingSets: action.payload,
        adding: false,
      }
    case ADD_IELTS_READING_SET_FAIL:
      return {
        ...state,
        error: action.payload,
        adding: false,
      }
    case ADD_IELTS_READING_PASSAGE:
      return {
        ...state,
        adding: true,
      }
    case ADD_IELTS_READING_PASSAGE_SUCCESS:
      return {
        ...state,
        adding: false,
      }
    case ADD_IELTS_READING_PASSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        adding: false,
      }
    case GET_IELTS_READING_SET_SUCCESS:
      return {
        ...state,
        readingSets: action.payload.data,
        loading: false,
      }
    case GET_IELTS_READING_SET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_IELTS_SINGLE_READING_SET:
      return {
        ...state,
        loading: true,
      }
    case GET_IELTS_SINGLE_READING_SET_SUCCESS:
      return {
        ...state,
        singleReadingSet: action.payload.data,
        loading: false,
      }
    case GET_IELTS_SINGLE_READING_SET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_IELTS_READING_PASSAGE_SUCCESS:
      return {
        ...state,
        IeltsReadingPassage: action.payload,
        IeltsReadingPassageLoading: false,
      }
    case GET_IELTS_READING_PASSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        IeltsReadingPassageLoading: true,
      }
    case GET_IELTS_READING_SINGLE_PASSAGE:
      return {
        ...state,
        loading: true,
      }
    case GET_IELTS_READING_SINGLE_PASSAGE_SUCCESS:
      return {
        ...state,
        singleReadingPassage: action.payload.data,
        loading: false,
      }
    case GET_IELTS_READING_SINGLE_PASSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: true,
      }
    default:
      return state
  }
}

export default readingReducer
