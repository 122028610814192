// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect } from "react"
import { Container } from "react-bootstrap"
//redux
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Alert } from "reactstrap"
// actions
import { loginUser, loginUserError } from "../../store/actions"
import "./acoount.css"
import bgImage from "./img/Group.png"
import xamProLogo from "./main-logo.svg"

const Login = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }))

  const handleValidSubmit = (event, values) => {
    if (values) {
      dispatch(loginUser(values, history))
    }
  }

  useEffect(() => {
    dispatch(loginUserError(""))
    return () => {
      dispatch(loginUserError(""))
    }
  }, [])

  return (
    <React.Fragment>
      <div
        className="student-login-main-wrap-main-area-wrap"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <header className="header-top-area-wrap">
          <Container>
            <img src={xamProLogo} alt="" />
          </Container>
        </header>
        <div className="xam-pro-login-body-area-wrapper">
          <div className="student-account-form-wrap">
            <div className="login-form">
              <div className="acoount-email-password-area">
                <div className="account-form-title teacher">
                  <h3>Lets get in</h3>
                </div>
                {error ? <Alert color="danger">{error}</Alert> : null}
                <AvForm
                  // className="form-horizontal"
                  onValidSubmit={handleValidSubmit}
                >
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      // value="admin@gmail.com"

                      placeholder="Enter email"
                      type="email"
                      required
                    />
                  </div>
                  <div className="mb-3  passwordrggfgf">
                    <AvField
                      name="password"
                      label="Password"
                      // value="abcdef12"
                      type="password"
                      required
                      placeholder="Enter Password"
                    />
                  </div>

                  <div className="mt-3 d-grid">
                    <button
                      className="btn btn-primary btn-block login-form-submit-button"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Logging In..." : "Log In"}
                    </button>
                  </div>
                  {/* <div className="mt-4 text-center">
                  <Link to="/forgot-password" className="text-muted">
                    <i className="mdi mdi-lock me-1" />
                    Forgot your password?
                  </Link>
                </div> */}
                </AvForm>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer-main-area-wrap-bottom">
          <Container>
            <div className="footer-copy-right-area-inner-wrap">
              <p> ©XamPro. 2022</p>
              <p>Design & Develop by MAAC</p>
            </div>{" "}
          </Container>
        </footer>
      </div>
    </React.Fragment>
  )
}

export default Login
