import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import InnerLayer from "../../Layout/InnerLayer"
import logo from "../../assets/images/Icon/Frame.svg"
import CardComponent from "../../components/Common/CardComponent"
import Loader from "../../components/Common/Loader"
import { toCapitalize } from "../../helpers/Custom/toCapitalize"
import { imageBaseUrl } from "../../helpers/api_helper"
import { getAuthorList } from "../../store/actions"
import "./teacher.scss"
const AuthorList = ({ history }) => {
  const dispatch = useDispatch()
  const { authToken, authorList, authorLoading } = useSelector(state => ({
    authToken: state.Login.token,
    authorLoading: state.BlogReducer.authorLoading,
    authorList: state.BlogReducer.authorList,
  }))
  console.log(`teachers, loading`, authorList, authorLoading)

  useEffect(() => {
    dispatch(getAuthorList(authToken))
  }, [])
  const getPhotoUrl = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return logo
  }

  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Author List"
        mainTitle={"Dashboard"}
        subTitle={"Author List"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <CardComponent>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Author List</h4>
            <div className="d-flex">
              <div className="search-box me-2">
                <div className="position-relative">
                  <input
                    type="search"
                    placeholder="Search..."
                    // value={keyword}
                    // onChange={handleSearchChange}
                    className="form-control"
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
              <div>
                <div className="position-relative">
                  <Link to="/register-author">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-circle me-2"
                    >
                      <i className="mdi mdi-plus me-1" />
                      Register
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardComponent>

        {/* list */}

        <Row>
          {/* {JSON.stringify(teachers)} */}
          {authorLoading ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : authorList?.length > 0 ? (
            authorList?.map((data, idx) => (
              <Col mg={6} xl={3} key={idx} style={{ cursor: "pointer" }}>
                <Card className="text-center pt-4 position-relative">
                  <div
                    className="position-absolute"
                    style={{ top: "4%", right: "4%" }}
                  ></div>
                  <div className="mx-auto">
                    <img
                      className="d-flex rounded-circle avatar-sm"
                      src={getPhotoUrl(data?.photo)}
                      alt="XamPro"
                    />
                  </div>
                  <CardBody style={{ textAlign: "center" }}>
                    <h5 className="font-size-15">
                      {toCapitalize(data?.authorName)}
                    </h5>
                    <h6>{data?.authorPosition}</h6>

                    {/* <p className="mb-0 font-size-12">
                      {toCapitalize(data?.role)}
                    </p> */}
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <div className="text-center">
              <h5>No author available</h5>
            </div>
          )}
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(AuthorList)
