/* eslint-disable eqeqeq */
import Loader from "components/Common/Loader"
import { toCapitalize } from "helpers/Custom/toCapitalize"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import { getFacilitatorListeningSet } from "store/Facilitator/actions"
import CardComponent from "../../../components/Common/CardComponent"
import "./services.scss"
const FacilitatorListeningService = ({ history }) => {
  const { packageId, studentId } = useParams()

  const dispatch = useDispatch()
  const {
    authtoken,
    facilitatorListeningSet,
    loading,
    profile,
    serviceReducer,
    facilitatorListening,
  } = useSelector(state => ({
    facilitatorListeningSet: state.FacilitatorReducer.facilitatorListeningSet,
    authtoken: state.Login.token,
    loading: state.FacilitatorReducer.loading,
    facilitatorListening: state.FacilitatorReducer,
  }))
  console.log("dff", facilitatorListening)
  console.log(`profile`, facilitatorListeningSet)

  useEffect(() => {
    dispatch(getFacilitatorListeningSet(authtoken, packageId, studentId))
  }, [])
  const [startXam, setStartXam] = useState(false)
  const [xam, setXam] = useState("")
  return (
    <React.Fragment>
      <div className="view-all-single-package-main-wrap">
        <Container>
          <div className="page-title-wrap">
            <div className="">
              <h4 className="mt-5">Package Set</h4>
            </div>
            <div className="">
              {/* <span>Time Expire</span> */}
              {/* <span>30D : 24H : 58M : 56S</span> */}
            </div>
          </div>
          {loading ? (
            <div className="text-center  pt-5">
              <Loader />
            </div>
          ) : (
            <div className="single-package-inner-main-wrap">
              <Row>
                {(facilitatorListeningSet?.values?.getData?.getData || []).map(
                  (data, key) => (
                    <Col lg={4} md={6} key={key}>
                      <div className="card-active-deactive-content">
                        <div className="active-pending-top-right-massage">
                          <div className="course-active-btn">
                            <span className="color-success-text">Active</span>
                          </div>
                        </div>
                        <CardComponent className={"text-center py-4"}>
                          <div className="mx-auto">
                            <img
                              src={require(`../img/listening.svg`).default}
                              alt="examImage"
                              className="mb-3"
                            />
                          </div>
                          <div>
                            <h4>{toCapitalize(data?.setName)}</h4>

                            <button
                              className="meet-button"
                              onClick={() => {
                                history.push(
                                  `/facilitator/${packageId}/result/${studentId}/listening/${data._id}`
                                )
                              }}
                            >
                              View Answer
                            </button>
                          </div>
                        </CardComponent>
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(FacilitatorListeningService)
