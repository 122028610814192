import TextError from "components/InputComponent/TextError";
import { ErrorMessage } from "formik";
import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const CustomInput = ({
  field: { onChange, ...rest },
  form: { touched, errors },
  label,
  id,
  isPassword,
  handleViewPassword,
  onChangeCallback,
  isRequired,
  isArray,
  isTel,
  ...props
}) => {
  const isValid =
    touched[rest.name] && errors[rest.name] && !props.disabled ? true : false;
  return (
    <React.Fragment>
      <FormGroup className={"w-100 customInputWrapper"}>
        {label ? (
          <Label htmlFor={id}>
            {label}
            {isRequired ? <span style={{ color: "#EB2027" }}>{" *"}</span> : ""}
          </Label>
        ) : null}
        <div
          className={
            isPassword || isTel
              ? `d-flex align-items-center isPassword ${
                  isValid && "is-invalid"
                }`
              : "d-flex align-items-center"
          }
        >
          {isTel ? (
            <span
              className="ms-3"
              style={{
                backgroundColor: "white",
                border: isTel ? "none" : "",
              }}
            >
              (+880)
            </span>
          ) : null}
          <Input
            id={id}
            style={{
              border: isPassword || isTel ? "none" : "",
              boxShadow: isPassword || isTel ? "none" : "",
            }}
            className="customInput"
            invalid={isValid}
            onChange={(e) => {
              const data = e;
              onChangeCallback && onChangeCallback(data);
              onChange && onChange(e);
            }}
            {...rest}
            {...props}
          />
          {isPassword ? (
            <span
              className="me-2"
              style={{
                backgroundColor: "white",
                cursor: "pointer",
                border: isPassword ? "none" : "",
              }}
              onClick={handleViewPassword}
            >
              <i
                className={
                  props.type === "text" ? "far fa-eye-slash" : "far fa-eye"
                }
              ></i>
            </span>
          ) : null}
        </div>
        {isArray ? (
          <ErrorMessage name={rest.name} component={TextError} />
        ) : touched[rest.name] && errors[rest.name] && !props.disabled ? (
          <FormFeedback type="invalid" className="d-inline">
            {errors[rest.name]}
          </FormFeedback>
        ) : null}
      </FormGroup>
    </React.Fragment>
  );
};
export default CustomInput;