import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import Select from "react-select"
import { Alert, Col, Label, Row } from "reactstrap"
import {
  addCoupon,
  getPackages,
  getSingleCoupon,
  getSingleCouponSuccess,
} from "store/actions"
import InnerLayer from "../../Layout/InnerLayer"
import CardComponent from "../../components/Common/CardComponent"
import Loader from "../../components/Common/Loader"
import { toaster } from "../../helpers/Custom/Toaster"
const ManageCoupon = ({ history }) => {
  const { id } = useParams()
  const [selectedDate, setSelectedDate] = useState("")
  const [selectedvalue, setsetselectedvalue] = useState([])
  const [hiddenValue, setHiddenValue] = useState(true)
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Coupon List",
      link: "/coupon",
    },
    {
      title: `${id ? "Update" : "Create"} Coupon`,
      link: "#",
    },
  ]
  const dispatch = useDispatch()
  const { authToken, loading, error, adding, singleCoupon, packageData } =
    useSelector(state => ({
      authToken: state.Login.token,
      loading: state.coupon.loading,
      adding: state.coupon.adding,
      singleCoupon: state.coupon.singleCoupon,
      packageData: state.packageReducer.allPackage?.AllPackages,
      error: state.coupon.error,
    }))

  useEffect(() => {
    if (id) {
      dispatch(getSingleCoupon(authToken, id))
    }
    return () => {
      dispatch(getSingleCouponSuccess({}))
    }
  }, [])
  useEffect(() => {
    dispatch(getPackages(authToken))
  }, [])
  console.log("seeee", selectedvalue)
  useEffect(() => {
    if (singleCoupon?.packages?.length && id) {
      setsetselectedvalue(singleCoupon?.packages)
    }
  }, [singleCoupon])
  const handleSubmit = (event, errors, values) => {
    event.preventDefault()
    if (errors.length > 0) {
      toaster("warning", "Please Fill Up all the values")
      return
    } else if (values?.couponPercentage * 1 > 100) {
      toaster("error", "Value can not be bigger than 100")
    } else if (values?.couponPercentage * 1 < 0) {
      toaster("error", "Value can not be less than 0")
    } else {
      const { couponCode, hidden, ...rest } = values
      const trimCode = couponCode?.trim()
      dispatch(
        addCoupon(
          {
            ...rest,
            couponCode: trimCode,
            packages: selectedvalue.map(data => data?._id),
            hidden: hiddenValue,
          },
          authToken,
          history,
          id
        )
      )
    }
  }
  const handleMultiChange = e => {
    setsetselectedvalue(e)
  }
  const handleChange = e => {
    console.log("hello", e.target.value)
    if (e.target.value === "true") {
      setHiddenValue(true)
    } else {
      setHiddenValue(false)
    }
  }
  console.log("singleCoupon", singleCoupon)
  console.log("hiddenValue", hiddenValue)
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={id ? "Edit Coupon" : "Create Coupon"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row className="mt-3">
          <Col md="6" className="offset-md-3">
            <CardComponent>
              <h5>{id ? "Update" : "Create"} Coupon</h5>
              {error ? <Alert color="danger">{error}</Alert> : null}
              {id && loading ? (
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              ) : (
                <AvForm className="mt-4" onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <AvField
                      name="couponCode"
                      label="Coupon Code"
                      type="text"
                      errorMessage="Type Coupon Code"
                      placeholder="Type Code"
                      value={singleCoupon?.couponCode}
                      disabled={id}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Row>
                  <Row className="mb-3">
                    <AvField
                      name="couponPercentage"
                      type="text"
                      label="Coupon Percentage"
                      errorMessage="Type Coupon Percentage"
                      placeholder="Type Percentage"
                      value={singleCoupon?.couponPercentage}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Row>
                  <Row className="mb-3">
                    <AvField
                      name="lifeTime"
                      type="number"
                      label="Total count"
                      errorMessage="Type total count"
                      placeholder="Type total count"
                      value={singleCoupon?.lifeTime}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Row>
                  <Row className="mb-3">
                    <AvField
                      name="hidden"
                      label="Hidden"
                      id="formrow-InputState"
                      className="form-control"
                      type="select"
                      errorMessage="Select status"
                      value={singleCoupon?.hidden}
                      onChange={e => handleChange(e)}
                      validate={{ required: { value: true } }}
                    >
                      <option value="">Select Status</option>

                      <option value={true}>True</option>
                      <option value={false}>False</option>
                    </AvField>
                  </Row>
                  <Row>
                    <div className="mb-3">
                      <Label className="control-label">Package Select</Label>
                      <Select
                        cacheOptions
                        getOptionLabel={e => e.packageName}
                        getOptionValue={e => e._id}
                        options={packageData}
                        isClearable
                        onChange={handleMultiChange}
                        value={selectedvalue}
                        isDisabled={hiddenValue === false}
                        isMulti
                      />
                    </div>
                  </Row>
                  <Row className="mb-3">
                    {/* <p>Coupon Starting Date</p> */}
                    <AvField
                      name="couponStartingDate"
                      label="Coupon Starting Date"
                      type="date"
                      errorMessage="Select Coupon Starting Date"
                      //   placeholder="Type package name"
                      value={
                        singleCoupon?.couponStartingDate
                          ? moment(singleCoupon?.couponStartingDate).format(
                              "YYYY-MM-DD"
                            )
                          : ""
                      }
                      onChange={e => setSelectedDate(e.target.value)}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Row>
                  <Row className="mb-3">
                    {/* <p>Coupon Ending Date</p> */}
                    <AvField
                      name="couponEndingDate"
                      type="date"
                      disabled={!selectedDate && !id}
                      min={selectedDate}
                      label="Coupon Ending Date"
                      errorMessage="Select Coupon Ending Date"
                      //   placeholder="Type package name"
                      value={
                        singleCoupon?.couponEndingDate
                          ? moment(singleCoupon?.couponEndingDate).format(
                              "YYYY-MM-DD"
                            )
                          : ""
                      }
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </Row>
                  <div>
                    <button
                      disabled={adding}
                      type="submit"
                      className="btn btn-primary w-md"
                    >
                      {adding
                        ? "Submitting..."
                        : id
                        ? "Update Coupon"
                        : "Create Coupon"}
                    </button>
                  </div>
                </AvForm>
              )}
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default ManageCoupon
