import Loader from "components/Common/Loader"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { addSpeakingSet, getSingleSpeakingSets } from "store/Speaking/actions"
import "./speaking-create.scss"
// import googleMeetIcon from "./img/Google_Meet 1.png"
// import confirmIcon from "./img/illustration.svg"
const timeSlice = [
  { time: 10, value: `10:00 - 10:30` },
  { time: 1030, value: `10:30 - 11:00` },
  { time: 11, value: `11:00 - 11:30` },
  { time: 1130, value: `11:30 - 12:00` },
  { time: 12, value: `12:00 - 12:30` },
  { time: 1230, value: `12:30 - 01:00` },
  { time: 1, value: `01:00 - 01:30` },
  { time: 130, value: `01:30 - 02:00` },
  { time: 2, value: `02:00 - 02:30` },
  { time: 230, value: `02:30 - 03:00` },
  { time: 3, value: `03:00 - 03:30` },
  { time: 330, value: `03:30 - 04:00` },
  { time: 4, value: `04:00 - 04:30` },
  { time: 430, value: `04:30 - 05:00` },
  { time: 5, value: `05:00 - 05:30` },
  { time: 530, value: `05:30 - 06:00` },
  { time: 6, value: `06:00 - 06:30` },
  { time: 630, value: `06:30 - 07:00` },
  { time: 7, value: `07:00 - 07:30` },
  { time: 730, value: `07:30 - 08:00` },
]
const SpeakingPage = ({ history, teacherId }) => {
  const dispatch = useDispatch()
  const { authToken, loading, speakingSet, adding } = useSelector(state => ({
    authToken: state.Login.token,
    loading: state.speakingSet.loading,
    adding: state.speakingSet.adding,
    speakingSet: state.speakingSet.speakingSet?.obj1,
  }))
  const [value, onChange] = useState(new Date())
  const [changed, setOnChanged] = useState("")
  const [selectedTime, setSelectedTime] = useState({})
  console.log(`loading,speakingSet,`, changed, value, speakingSet)

  useEffect(() => {
    dispatch(
      getSingleSpeakingSets(authToken, {
        teacherId,
        date: moment(value).format("YYYY-MM-DD"),
      })
    )
  }, [changed])

  const handleChange = value => {
    setOnChanged(value)
    onChange(value)
  }
  const handleData = data => {
    // console.log(data)
    setSelectedTime(data)
  }

  const handleAdd = () => {
    const data = {
      date: moment(value).format("YYYY-MM-DD"),
      meetingTime: selectedTime.time,
    }
    dispatch(addSpeakingSet(data, { history, token: authToken, teacherId }))
  }
  const activeClass = data => {
    // console.log(`speakingSet?.[data.time] > 0`, speakingSet?.[data.time] > 0)
    if (speakingSet?.[data.time] > 0) {
      return " added"
    }
    if (data.time === selectedTime.time) {
      return " selected"
    }
    return ""
  }
  return (
    <div className="speaking-main-container-wrap">
      {!changed ? (
        <div className="speaking-exam-main-wrap">
          <div className="speaking-exam-date-bottom-wrap">
            <Row className="justify-content-center">
              <Col lg={6}>
                <div className="speaking-exam-date-content-wrap">
                  <Calendar onChange={handleChange} value={value} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="speaking-exam-main-wrap">
          <div className="speaking-exam-date-bottom-wrap">
            <Row className="justify-content-center">
              <Col lg={6}>
                <div className="speaking-exam-date-content-wrap">
                  <Calendar onChange={handleChange} value={value} />
                </div>
              </Col>{" "}
              <Col lg={6} className="my-auto">
                {loading ? (
                  <div className="position-absolute w-100 text-center">
                    <Loader />
                  </div>
                ) : null}
                <div className="form-submit-main-time-wrap">
                  <div className="speaking-exam-selected-time">
                    {timeSlice.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            if (speakingSet?.[item?.time] === 0) {
                              handleData(item)
                            }
                          }}
                          className={
                            "speaking-selected-time-button" + activeClass(item)
                          }
                        >
                          {item.value}
                        </div>
                      )
                    })}
                  </div>
                  {selectedTime?.value ? (
                    <div className="time-selected-submit-btn mt-3">
                      <button disabled={adding} onClick={handleAdd}>
                        {adding ? "Submitting..." : "Done"}
                      </button>
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  )
}

export default withRouter(SpeakingPage)