import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { printToLetter } from '../../../helpers/Custom/printNumberToLetter';
import { storeQuestion } from '../../../store/Question/actions';

const FillGapsWithClue = ({
  questions,
  handleOnChange,
  ans,
  questionNumber,
  view,
  options,
  clue,
  idx,
  notePad,
  handleChangeNotePad,
  newIdx,
}) => {
  // console.log(`🦄 ~ file: FillGapsWithClue.js ~ line 17 ~ options`, options);
  const dispatch = useDispatch();
  // console.log(`🦄 ~ file: FillGapsWithClue.js ~ line 15 ~ clue`, clue);
  const onDrop = (ev, list, serial, questionIndex) => {
    const id = ev.dataTransfer.getData('text');
    // console.log(`onDrop ~ id`, id);
    const [value, idx] = id.split('/');
    const data = {
      target: {
        value: value,
      },
    };
    handleOnChange(data, list, serial, idx, serial);
  };

  const replaceGap = (word, serial, list, idx) => {
    const newWord = word.split('$gap$');

    return (
      <React.Fragment>
        {newWord.shift()}
        <span className="checkbox-ques-serial-no d-inline ">
          <h5 className="d-inline-block">{serial}</h5>
        </span>
        <p
          className="d-inline-block mb-0 ml-2"
          title={ans?.[serial]?.answer?.[0] || ''}
          // draggable={!view}
          // onDragStart={(e) => {
          //   if (ans?.[serial]?.answer?.[0]) {
          //     onDragStart(e, ans?.[serial]?.answer?.[0] || '', serial);
          //   }
          // }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            if (!ans?.[serial]?.answer?.[0]) {
              onDrop(e, list, serial, idx);
            }
          }}
          style={{
            border: '0',
            borderBottom: '2px dashed',
            cursor: view ? 'default' : 'grab',
            maxWidth: 'max-content',
            minWidth:
              ans?.[serial]?.answer?.[0]?.length > 0 ? 'max-content' : '70px',
            height: '25px',
            marginLeft: '10px',
          }}
        >
          {ans?.[serial]?.answer?.[0] || ''}
        </p>
        {/* <input
          type="text"
          className="gapsInput"
          draggable
          onDragStart={(e) => {
            if (ans?.[serial]?.answer?.[0]) {
              onDragStart(e, ans?.[serial]?.answer?.[0] || '', serial);
            }
          }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            if (!ans?.[serial]?.answer?.[0]) {
              onDrop(e, list, serial, idx);
            }
          }}
          disabled={view}
          style={{ border: '0', borderBottom: '2px dashed', maxWidth: '70px' }}
          onChange={(e) => handleOnChange(e, list, serial)}
          value={ans?.[serial]?.answer?.[0] || ''}
        /> */}
        {newWord.pop()}
      </React.Fragment>
    );
  };

  const findQuestionsNumberRange = `${
    questions?.[0]?.questionNumber + questionNumber
  }-
      ${questions?.[questions?.length - 1]?.questionNumber + questionNumber}`;

  const onDragStart = (e, data, idx) => {
    e.dataTransfer.setData('text/plain', data + '/' + idx);
  };

  const onDropItem = (e) => {
    const id = e.dataTransfer.getData('text');
    const [, idx] = id.split('/');
    const newClues = { ...clue };
    Object.keys(clue).forEach((data, i) => {
      if (clue[data] === idx * 1) {
        newClues[data] = 0;
      }
    });
    dispatch(storeQuestion('clues', newClues));
    handleOnChange({ target: { value: '' } }, { answer: '' }, idx);
  };
  const [viewExplain, setViewExplain] = useState('');
  const [toggle, setToggle] = useState();
  return (
    <React.Fragment>
      <div className="ques-serial">
        <h5>Question {findQuestionsNumberRange}</h5>
        {/* edit form area wrap  */}
        {!view ? (
          <div className="notepade-area-wrap-write-text">
            <div
              className="student-note-btn mt-3"
              onClick={() => setToggle(!toggle)}
            >
              {toggle ? (
                <p>
                  <i className="fa-solid fa-pen"></i>
                  hide Notepad
                </p>
              ) : (
                <p>
                  <i className="fa-solid fa-pen"></i>
                  Show Notepad
                </p>
              )}
            </div>
            <Form
              className={
                toggle
                  ? 'navbar_mobile_menu show my-2'
                  : 'navbar_mobile_menu hide my-2'
              }
            >
              <Form.Group>
                <Form.Control
                  as="textarea"
                  value={notePad?.[newIdx]}
                  onChange={(e) => handleChangeNotePad(e, newIdx)}
                  rows={3}
                />
              </Form.Group>
            </Form>
          </div>
        ) : null}
        {/* edit form area wrap  */}
        <p>
          Complete the summary below. Choose one word only from the passage for
          each answer. Write your answers in boxes {findQuestionsNumberRange} on
          your answer sheet.
        </p>
        <div className="">
          {questions?.map((list, idx) => (
            <React.Fragment  key={list.questionNumber + questionNumber}>
              <span
                key={list.questionNumber + questionNumber}
                id={`question${list.questionNumber + questionNumber}`}
              >
                {replaceGap(
                  list.q,
                  list.questionNumber + questionNumber,
                  list,
                  idx
                )}
                .
              </span>
              {view ? (
                <React.Fragment>
                  <div className="d-flex align-items-center py-2">
                    <span>
                      Answer :{' '}
                      <strong>
                        {ans?.[
                          list.questionNumber + questionNumber
                        ]?.answer?.map(
                          (data, idx) => `${data}  ${idx === 1 ? '' : ','}`
                        )}
                      </strong>
                    </span>
                    <div>
                      <Button
                        type="button"
                        onClick={() => {
                          if (
                            viewExplain !==
                            `view${list.questionNumber + questionNumber}`
                          ) {
                            setViewExplain(
                              `view${list.questionNumber + questionNumber}`
                            );
                          } else {
                            setViewExplain('');
                          }
                        }}
                        style={{
                          background: '#cce1ff',
                          color: '#485ec4',
                          border: 'none',
                        }}
                        className="btn btn-sm m-1 gapsInput"
                      >
                        {viewExplain ===
                        `view${list.questionNumber + questionNumber}`
                          ? 'Hide'
                          : 'See Explanation'}
                      </Button>
                    </div>
                    {ans?.[list.questionNumber + questionNumber]?.mark === 1 ? (
                      <span className="correct-ans">
                        <i className="bx bx-check"></i>
                      </span>
                    ) : (
                      <span className="false-ans">
                        <i className="bx bx-x"></i>
                      </span>
                    )}
                  </div>
                  {viewExplain ===
                  `view${list.questionNumber + questionNumber}` ? (
                    <div className="border rounded-2 p-3 my-2">
                      {list?.explanation}
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ))}
        </div>
        <div
          className="border rounded-3 d-flex flex-wrap p-3"
          style={{ backgroundColor: '#F1F4FE' }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => onDropItem(e)}
        >
          {options?.map((list, idx) => {
            // if (clue?.[idx] > 0) {
            //   return null;
            // }
            return (
              <div
                draggable={!view}
                onDragStart={(e) => onDragStart(e, list, idx)}
                // disabled={clue?.[idx]}
                onDragEnd={(e) => {
                  e.target.style.background = '';
                }}
                className="py-2"
                style={{ width: '33%', cursor: view ? 'default' : 'grab' }}
                key={idx}
              >
                {printToLetter(idx + 1)} .{list}
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default FillGapsWithClue;
