export const ADD_SPEAKING_SET = "ADD_SPEAKING_SET"
export const ADD_SPEAKING_SET_SUCCESS = "ADD_SPEAKING_SET_SUCCESS"
export const ADD_SPEAKING_SET_FAIL = "ADD_SPEAKING_SET_FAIL"

export const GET_SPEAKING_SETS = "GET_SPEAKING_SETS"
export const GET_SPEAKING_SETS_SUCCESS = "GET_SPEAKING_SETS_SUCCESS"
export const GET_SPEAKING_SETS_FAIL = "GET_SPEAKING_SETS_FAIL"

export const GET_SPEAKING_SET_SINGLE = "GET_SPEAKING_SET_SINGLE"
export const GET_SPEAKING_SET_SINGLE_SUCCESS = "GET_SPEAKING_SET_SINGLE_SUCCESS"
export const GET_SPEAKING_SET_SINGLE_FAIL = "GET_SPEAKING_SET_SINGLE_FAIL"

export const GET_SINGLE_SPEAKING_SET_SINGLEDAY =
  "GET_SINGLE_SPEAKING_SET_SINGLEDAY"
export const GET_SINGLE_SPEAKING_SET_SINGLEDAY_SUCCESS =
  "GET_SINGLE_SPEAKING_SET_SINGLEDAY_SUCCESS"
export const GET_SINGLE_SPEAKING_SET_SINGLEDAY_FAIL =
  "GET_SINGLE_SPEAKING_SET_SINGLEDAY_FAIL"

export const GET_SPEAKING_SETS_NUMBER = "GET_SPEAKING_SETS_NUMBER"
export const GET_SPEAKING_SETS_NUMBER_SUCCESS =
  "GET_SPEAKING_SETS_NUMBER_SUCCESS"
export const GET_SPEAKING_SETS_NUMBER_FAIL = "GET_SPEAKING_SETS_NUMBER_FAIL"

export const GET_SINGLE_SPEAKING_SLOT = "GET_SINGLE_SPEAKING_SLOT"
export const GET_SINGLE_SPEAKING_SLOT_SUCCESS =
  "GET_SINGLE_SPEAKING_SLOT_SUCCESS"
export const GET_SINGLE_SPEAKING_SLOT_FAIL = "GET_SINGLE_SPEAKING_SLOT_FAIL"
