import _clone from "lodash/clone";
import _escapeRegExp from "lodash/escapeRegExp";

export const mcqType = "mcq";
export const trueFalseQues = "trueFalse";
export const comprehensionQues = "comprehension";
export const YesNoQues = "YesNoQues";
export const HeadingMatching = "headingMatching";
export const multipleChoice = "multipleSelection";
export const fillGapsWords = "fillGapsWords";
export const fillGapsWithoutWords = "fillGaps";
export const fillGapsOne = "fillGapsOne";
export const fillGapsTwo = "fillGapsTwo";
export const fillGapsThree = "fillGapsThree";
export const tableOne = "tableOne";
export const tableTwo = "tableTwo";
export const tableThree = "tableThree";
export const formOne = "formOne";
export const formTwo = "formTwo";
export const formThree = "formThree";
export const noteOne = "noteOne";
export const noteTwo = "noteTwo";
export const noteThree = "noteThree";
export const sortAnswerOne = "sortAnswerOne";
export const sortAnswerTwo = "sortAnswerTwo";
export const sortAnswerThree = "sortAnswerThree";

function printToLetter(number) {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let result = "";
  let charIndex = number % alphabet.length;
  let quotient = number / alphabet.length;
  if (charIndex - 1 === -1) {
    charIndex = alphabet.length;
    quotient--;
  }
  result = alphabet.charAt(charIndex - 1) + result;
  if (quotient >= 1) {
    printToLetter(parseInt(quotient));
  }
  return result;
}
const calculateTime = (secs) => {
  const minutes = Math.floor(secs / 60);
  const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const seconds = Math.floor(secs % 60);
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${returnedMinutes}:${returnedSeconds}`;
};

const helpers = {
  YesNoQues: { status: 0, qLength: 0 },
  comprehension: { status: 0, qLength: 0 },
  fillGaps: { status: 0, qLength: 0 },
  fillGapsOne: { status: 0, qLength: 0 },
  fillGapsThree: { status: 0, qLength: 0 },
  fillGapsTwo: { status: 0, qLength: 0 },
  fillGapsWords: { status: 0, qLength: 0 },
  formOne: { status: 0, qLength: 0 },
  formThree: { status: 0, qLength: 0 },
  formTwo: { status: 0, qLength: 0 },
  headingMatching: { status: 0, qLength: 0 },
  mcq: { status: 0, qLength: 0 },
  multipleSelection: { status: 0, qLength: 0 },
  noteOne: { status: 0, qLength: 0 },
  noteThree: { status: 0, qLength: 0 },
  noteTwo: { status: 0, qLength: 0 },
  sortAnswerOne: { status: 0, qLength: 0 },
  sortAnswerThree: { status: 0, qLength: 0 },
  sortAnswerTwo: { status: 0, qLength: 0 },
  tableOne: { status: 0, qLength: 0 },
  tableThree: { status: 0, qLength: 0 },
  tableTwo: { status: 0, qLength: 0 },
  trueFalse: { status: 0, qLength: 0 },
};

const nameHelpers = {
  YesNoQues: "Yes No",
  comprehension: "Comprehension",
  fillGaps: "Fill in the Gap",
  fillGapsOne: "Fill in the Gap",
  fillGapsThree: "Fill in the Gap",
  fillGapsTwo: "Fill in the Gap",
  fillGapsWords: "Fill in the Gap with words",
  formOne: "Form",
  formThree: "Form",
  formTwo: "Form",
  headingMatching: "Heading Matching",
  mcq: "MCQ",
  multipleSelection: "MCQ multiple",
  noteOne: "Note",
  noteThree: "Note",
  noteTwo: "Note",
  sortAnswerOne: "Short Answer",
  sortAnswerThree: "Short Answer",
  sortAnswerTwo: "Short Answer",
  tableOne: "Table",
  tableThree: "Table",
  tableTwo: "Table",
  trueFalse: "True/False",
};

// const helpers = {
//   "yes-no": { status: 0, qLength: 0 },
//   comprehension: { status: 0, qLength: 0 },
//   "fill-gaps": { status: 0, qLength: 0 },
//   "fill-gaps-one": { status: 0, qLength: 0 },
//   "fill-gaps-two": { status: 0, qLength: 0 },
//   "fill-gaps-three": { status: 0, qLength: 0 },
//   "fill-gaps-with-words": { status: 0, qLength: 0 },
//   "form-one": { status: 0, qLength: 0 },
//   "form-two": { status: 0, qLength: 0 },
//   "form-three": { status: 0, qLength: 0 },
//   "heading-matching": { status: 0, qLength: 0 },
//   "mcq-single": { status: 0, qLength: 0 },
//   "mcq-multiple": { status: 0, qLength: 0 },
//   "note-one": { status: 0, qLength: 0 },
//   "note-two": { status: 0, qLength: 0 },
//   "note-three": { status: 0, qLength: 0 },
//   "sort-answer-one": { status: 0, qLength: 0 },
//   "sort-answer-two": { status: 0, qLength: 0 },
//   "sort-answer-three": { status: 0, qLength: 0 },
//   "table-one": { status: 0, qLength: 0 },
//   "table-two": { status: 0, qLength: 0 },
//   "table-three": { status: 0, qLength: 0 },
//   "true-false": { status: 0, qLength: 0 },
// };

const fill = (value) => {
  const tags = value?.match(/\$(.*?)\$+/gi) || [];
  if (tags.length === 1) {
    return true;
  } else {
    return false;
  }
};

const checkImage = (value) => {
  const tags = value?.match(/img@&#\[(.*?)\]#&@img+/gi) || [];
  if (tags.length === 1) {
    return true;
  } else {
    return false;
  }
};

const getImageUrl = (value) => {
  let displayText = _clone(value);
  const tags = value?.match(/img@&#\[(.*?)\]#&@img+/gi) || [];
  if (tags?.length) {
    tags.forEach((myTag, idx) => {
      const tagData = myTag?.slice(7, -7);
      displayText = displayText?.replace(
        new RegExp(_escapeRegExp(myTag), "gi"),
        tagData
      );
    });
  }
  return displayText;
};
export {
  printToLetter,
  calculateTime,
  helpers,
  fill,
  nameHelpers,
  checkImage,
  getImageUrl,
};

// export const mcqType = "mcq-single";
// export const trueFalseQues = "true-false";
// export const comprehensionQues = "comprehension";
// export const YesNoQues = "yes-no";
// export const HeadingMatching = "heading-matching";
// export const multipleChoice = "mcq-multiple";
// export const fillGapsWords = "fill-gaps-with-words";
// export const fillGapsWithoutWords = "fill-gaps";
// export const fillGapsOne = "fill-gaps-one";
// export const fillGapsTwo = "fill-gaps-two";
// export const fillGapsThree = "fill-gaps-three";
// export const tableOne = "table-one";
// export const tableTwo = "table-two";
// export const tableThree = "table-three";
// export const formOne = "form-one";
// export const formTwo = "form-two";
// export const formThree = "form-three";
// export const noteOne = "note-one";
// export const noteTwo = "note-two";
// export const noteThree = "note-three";
// export const sortAnswerOne = "sort-answer-one";
// export const sortAnswerTwo = "sort-answer-two";
// export const sortAnswerThree = "sort-answer-three";
