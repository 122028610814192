export const GET_FACILITATOR_SLOT = "GET_FACILITATOR_SLOT"
export const GET_FACILITATOR_SLOT_SUCCESS = "GET_FACILITATOR_SLOT_SUCCESS"
export const GET_FACILITATOR_SLOT_FAIL = "GET_FACILITATOR_SLOT_FAIL"

export const GET_SINGLE_FACILITATOR_SLOT = "GET_SINGLE_FACILITATOR_SLOT"
export const GET_SINGLE_FACILITATOR_SLOT_SUCCESS = "GET_SINGLE_FACILITATOR_SLOT_SUCCESS"
export const GET_SINGLE_FACILITATOR_SLOT_FAIL = "GET_SINGLE_FACILITATOR_SLOT_FAIL"

export const ADD_FACILITATOR_SLOT = "ADD_FACILITATOR_SLOT"
export const ADD_FACILITATOR_SLOT_SUCCESS = "ADD_FACILITATOR_SLOT_SUCCESS"
export const ADD_FACILITATOR_SLOT_FAIL = "ADD_FACILITATOR_SLOT_FAIL"


export const GET_FACILITATOR_SINGLE_DAY = "GET_FACILITATOR_SINGLE_DAY"
export const GET_FACILITATOR_SINGLE_DAY_SUCCESS = "GET_FACILITATOR_SINGLE_DAY_SUCCESS"
export const GET_FACILITATOR_SINGLE_DAY_FAIL = "GET_FACILITATOR_SINGLE_DAY_FAIL"


export const GET_FACILITATOR_SINGLE_PACKAGE = "GET_FACILITATOR_SINGLE_PACKAGE";
export const GET_FACILITATOR_SINGLE_PACKAGE_SUCCESS ="GET_FACILITATOR_SINGLE_PACKAGE_SUCCESS";
export const GET_FACILITATOR_SINGLE_PACKAGE_FAIL ="GET_FACILITATOR_SINGLE_PACKAGE_FAIL";

export const GET_FACILITATOR_SET = "GET_FACILITATOR_SET";
export const GET_FACILITATOR_SET_SUCCESS ="GET_FACILITATOR_SET_SUCCESS";
export const GET_FACILITATOR_SET_FAIL ="GET_FACILITATOR_SET_FAIL";

export const GET_FACILITATOR_REVIEW = "GET_FACILITATOR_REVIEW";
export const GET_FACILITATOR_REVIEW_SUCCESS ="GET_FACILITATOR_REVIEW_SUCCESS";
export const GET_FACILITATOR_REVIEW_FAIL ="GET_FACILITATOR_REVIEW_FAIL";

export const ADD_FACILITATOR_REVIEW = "ADD_FACILITATOR_REVIEW"
export const ADD_FACILITATOR_REVIEW_SUCCESS = "ADD_FACILITATOR_REVIEW_SUCCESS"
export const ADD_FACILITATOR_REVIEW_FAIL = "ADD_FACILITATOR_REVIEW_FAIL"


export const GET_FACILITATOR_LISTENING_SET = "GET_FACILITATOR_LISTENING_SET";
export const GET_FACILITATOR_LISTENING_SET_SUCCESS ="GET_FACILITATOR_LISTENING_SET_SUCCESS";
export const GET_FACILITATOR_LISTENING_SET_FAIL ="GET_FACILITATOR_LISTENING_SET_FAIL";

export const GET_FACILITATOR_WRITING_SET = "GET_FACILITATOR_WRITING_SET";
export const GET_FACILITATOR_WRITING_SET_SUCCESS ="GET_FACILITATOR_WRITING_SET_SUCCESS";
export const GET_FACILITATOR_WRITING_SET_FAIL ="GET_FACILITATOR_WRITING_SET_FAIL";

export const GET_REVIEW_ATTENDANCE = "GET_REVIEW_ATTENDANCE";
export const GET_REVIEW_ATTENDANCE_SUCCESS ="GET_REVIEW_ATTENDANCE_SUCCESS";
export const GET_REVIEW_ATTENDANCE_FAIL ="GET_REVIEW_ATTENDANCE_FAIL";