import { fillInTheBlanksListening } from "components/Schemas/QuestionSchemas"
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik"
import { toaster } from "helpers/Custom/Toaster"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import {
  addIELTSReadingQuesMCQ,
  getIELTSReadingQuesMCQ,
  getIELTSReadingQuesNum,
} from "store/actions"
import InnerLayer from "../../../../Layout/InnerLayer"
import CardComponent from "../../../../components/Common/CardComponent"
import CustomInput from "../../../../components/Common/CustomInput"
import Loader from "../../../../components/Common/Loader"
import Editor from "../../../../components/Common/QuillEditor"
import TextError from "../../../../components/InputComponent/TextError"

const initialValues = {
  q: "",
  answer: [""],
  explanation: "",
}

const FillInTheBlanksOne = ({ history, edit }) => {
  const { serviceId, setId, passageId, questionId, id } = useParams()
  const [value, setValue] = useState("")
  console.log(`passageID`, value)
  const [listValue, setListValue] = useState(null)
  const [isAddMore, setIsAddMore] = useState(false)
  const dispatch = useDispatch()
  const { authToken, loading, adding, questionNumber, singleQuestion, ques } =
    useSelector(state => ({
      authToken: state.Login.token,
      loading: state.QuesReducer.loading,
      adding: state.QuesReducer.adding,
      services: state.serviceReducer.services,
      questionNumber: state.QuesReducer.questionNumber,
      singleQuestion: state.QuesReducer.singleQuestion,
      ques: state.QuesReducer.ques,
    }))
  // const findData =
  //   ques?.find(dt => dt?.Que?.questype === fillGapsWithoutWords) || {}
  // console.log(`singleQuestion`, singleQuestion, questionNumber, ques)

  useEffect(() => {
    if (edit && !!singleQuestion) {
      const value = { ...listValue }
      // const option = findData?.Que?.options
      value.q = singleQuestion?.q
      // value.opt = singleQuestion?.opt
      value.answer = singleQuestion?.answer
      value.explanation = singleQuestion?.explanation
      // value.options = option
      setValue(singleQuestion?.q || "")
      setListValue(value)
      // setQuestionNumber(singleQuestion?.questionNumber)
    }
  }, [singleQuestion])

  useEffect(() => {
    const type = location.pathname.split("/").pop()
    if (edit) {
      const data = {
        type,
        objectId: id,
        questionId,
      }
      dispatch(getIELTSReadingQuesMCQ(authToken, passageId, data))
    } else {
      dispatch(getIELTSReadingQuesNum(authToken, passageId, type))
      dispatch(getIELTSReadingQuesMCQ(authToken, passageId))
    }
  }, [])

  const fill = value => {
    const tags = value?.match(/\$(.*?)\$+/gi) || []
    if (tags.length === 1) {
      return true
    } else {
      return false
    }
  }

  const onSubmit = (values, { resetForm }) => {
    // console.log(`onSubmit ~ values`, values, fill(values.q))
    const { q, answer, options, ...rest } = values
    if (fill(value) === false) {
      return toaster("warning", "At least input one word named gap")
    }
    const type = location.pathname.split("/").pop()
    let obj = {
      ...rest,
      q: value,
      answer: answer,
      readingPessage: passageId,
      questionSerial: questionNumber,
      questype: type,
      options: [],
    }
    // console.log(`onSubmit ~ obj`, obj)
    const option = {
      isMore: isAddMore,
      resetForm,
      setIsAddMore,
      questionId,
      type,
      passageId,
    }
    if (edit) {
      dispatch(addIELTSReadingQuesMCQ(obj, history, authToken, option))
    } else {
      dispatch(addIELTSReadingQuesMCQ(obj, history, authToken, option))
    }
  }
  return (
    <React.Fragment>
      <InnerLayer isBreadCrumb={false} title="Create fill in the gaps Question">
        <Row className="mt-3">
          <Col md="10" className="offset-md-1">
            <CardComponent className={"mb-0"}>
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-left-arrow-alt me-2"
                  onClick={() => history.goBack()}
                  style={{ fontSize: "18px" }}
                ></i>
                <h6 className="mb-0">Create fill in the gaps</h6>
              </div>
            </CardComponent>
            <CardComponent style={{ marginTop: "5px" }}>
              <Row>
                <Col md="1">
                  <div className="question_number d-flex justify-content-center align-items-start">
                    {questionNumber}
                  </div>
                </Col>
                <Col md="11">
                  <h5>Type question</h5>
                  <br />
                  {loading && edit ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    <Formik
                      initialValues={listValue || initialValues}
                      validationSchema={fillInTheBlanksListening}
                      onSubmit={onSubmit}
                      enableReinitialize={true}
                    >
                      {({ values, setFieldValue, errors }) => (
                        <Form>
                          {/* {console.log(errors, setFieldValue)} */}
                          <Row className="mb-3">
                            <Col md="12">
                              <div style={{ height: "170px" }}>
                                <Editor
                                  placeholder={"Type"}
                                  name="q"
                                  handleChange={e => {
                                    // setFieldValue("q", e)
                                    setValue(e)
                                  }}
                                  value={value}
                                  height={"130px"}
                                />
                              </div>
                              {/* <Field
                                label="Type Paragraph"
                                name="q"
                                type="textarea"
                                rows="5"
                                id={"question"}
                                component={CustomInput}
                                placeholder="Type paragraph title"
                              /> */}
                              <ErrorMessage name={`q`} component={TextError} />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <FieldArray
                              name="answer"
                              render={arrayHelpers => (
                                <Row>
                                  {values?.answer?.map((value, index) => (
                                    <Col md="6" key={index}>
                                      <div className="mb-2 position-relative">
                                        <Field
                                          name={`answer.${index}`}
                                          type={"text"}
                                          id={index}
                                          label={`Right answer ${
                                            index > 0 ? index + 1 : ""
                                          }`}
                                          component={CustomInput}
                                          placeholder="Type Right answer"
                                        />
                                        {index > 0 ? (
                                          <div
                                            className="position-absolute"
                                            style={{ top: "0", right: "0" }}
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <Button
                                              className="btn-danger btn-sm "
                                              style={{
                                                background: "transparent",
                                                border: "none",
                                                fontSize: "18px",
                                              }}
                                            >
                                              <i
                                                className="bx bx-trash"
                                                style={{ color: "#999" }}
                                              ></i>
                                            </Button>
                                          </div>
                                        ) : null}
                                        <ErrorMessage
                                          name={`answer.${index}`}
                                          component={TextError}
                                        />
                                      </div>
                                    </Col>
                                  ))}
                                  {/* <Col md="12">
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="d-inline-block"
                                      style={{
                                        background: "#E5F0FF",
                                        color: "#485ec4",
                                        border: "none",
                                        width: "initial",
                                      }}
                                      onClick={() => arrayHelpers.push("")}
                                    >
                                      Add more answer
                                    </Button>
                                  </Col> */}
                                </Row>
                              )}
                            />
                          </Row>
                          <Row className="mb-3">
                            <Col md="12">
                              <Field
                                name="explanation"
                                id={"Explanation"}
                                label="Explanation"
                                placeholder="Type explanation"
                                type="textarea"
                                rows={"5"}
                                component={CustomInput}
                                //   disabled={edit}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="12" className="d-flex justify-content-end">
                              <Button
                                color="primary"
                                className="me-2"
                                disabled={adding}
                                type="submit"
                                style={{
                                  background: "transparent",
                                  color: "#485ec4",
                                  border: "1px solid #485ec4",
                                }}
                              >
                                {adding
                                  ? "Submitting..."
                                  : edit
                                  ? "Update"
                                  : "Save"}
                              </Button>
                              {!edit ? (
                                <Button
                                  color="primary"
                                  disabled={adding}
                                  type="submit"
                                >
                                  {adding
                                    ? "Submitting..."
                                    : "Create more question"}
                                </Button>
                              ) : null}
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  )}
                </Col>
              </Row>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(FillInTheBlanksOne)
