import React, { Component } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

export default class Editor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: "",
    }
  }

  modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    // keyboard: {
    //   bindings: {
    //     tab: {
    //       key: 9,
    //       handler: function () {
    //         // Handle tab
    //       },
    //     },
    //   },
    // },
  }

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ]
  quillRef = React.createRef()

  componentDidMount = () => {
    // // console.log(this.quillRef.current.editor);
    if (this.props.disable) {
      this.quillRef.current.editor.enable(false) // undefined
    } else {
      this.quillRef.current.editor.enable(true) // undefined
    }
  }

  render() {
    const { placeholder, handleChange, value, height, disable, handleBlur } =
      this.props
    // // console.log(this.props.value);

    return (
      <div className="drTextareaDiv" style={{ height: height }}>
        <ReactQuill
          theme="snow"
          ref={this.quillRef}
          modules={this.modules}
          formats={this.formats}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          readOnly={disable}
        ></ReactQuill>
      </div>
    )
  }
}
