import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { Redirect, Route } from "react-router-dom"

const AuthMiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  roles,
  ...rest
}) => {
  const { token, userrole } = useSelector(state => ({ ...state.Login }))
  // console.log(`token, userrole `, token, userrole)
  return (
    <React.Fragment>
      <Route
        {...rest}
        render={props => {
          if (isAuthProtected && token && roles.includes(userrole)) {
            return (
              <Layout>
                <Component {...props} />
              </Layout>
            )
          }
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }}
      />
    </React.Fragment>
  )
}

AuthMiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default AuthMiddleware
