import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import InnerLayer from "../../Layout/InnerLayer"
import CardComponent from "../../components/Common/CardComponent"
import Loader from "../../components/Common/Loader"
import { getCourseType } from "../../store/CourseType/actions"

const CourseType = ({ history }) => {
  const dispatch = useDispatch()
  const { authToken, data, loading } = useSelector(state => ({
    authToken: state.Login.token,
    loading: state.courseType?.loading,
    data: state?.courseType?.data,
    // error: state.courseType.error,
  }))
  //   console.log(`Course ~ data`, data)

  useEffect(() => {
    dispatch(getCourseType(authToken))
  }, [])

  const handleEdit = data => {
    history.push(`/edit-coursetype/${data._id}`)
  }
  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Course Type"
        mainTitle={"Dashboard"}
        subTitle={"Course Type"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>Course Type List</h4>
              <div className="d-flex">
                <div className="search-box me-2">
                  <div className="position-relative">
                    <input
                      type="search"
                      placeholder="Search..."
                      // value={keyword}
                      // onChange={handleSearchChange}
                      className="form-control"
                    />
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>
                <div>
                  <div className="position-relative">
                    <Link to="/create-coursetype">
                      <Button
                        type="button"
                        color="primary"
                        className="btn-circle me-2"
                      >
                        <i className="mdi mdi-plus me-1" />
                        Create Course Type
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </CardComponent>
        </Row>

        {/*  */}
        <Row>
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : (
            data?.map((data, idx) => (
              <Col
                mg={6}
                xl={3}
                key={idx}
                // onClick={() => history.push(`/student/${idx + 1}`)}
              >
                <Card className="position-relative">
                  <span
                    style={{
                      color: "#999",
                      position: "absolute",
                      top: "8%",
                      right: "5%",
                      cursor: "pointer",
                    }}
                    onClick={e => {
                      e.stopPropagation()
                      handleEdit(data)
                    }}
                  >
                    <i className="bx bxs-edit"></i>
                  </span>
                  <CardBody>
                    <p className="mb-0 font-size-12">Course Type Name</p>
                    <h5 className="font-size-15">{data?.courseTypeName}</h5>
                  </CardBody>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(CourseType)
