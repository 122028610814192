export const ADD_IELTS_READING_MCQ_QUES = "ADD_IELTS_READING_MCQ_QUES"
export const ADD_IELTS_READING_MCQ_QUES_SUCCESS =
  "ADD_IELTS_READING_MCQ_QUES_SUCCESS"
export const ADD_IELTS_READING_MCQ_QUES_FAIL = "ADD_IELTS_READING_MCQ_QUES_FAIL"

export const GET_IELTS_READING_MCQ_QUES = "GET_IELTS_READING_MCQ_QUES"
export const GET_IELTS_READING_MCQ_QUES_SUCCESS =
  "GET_IELTS_READING_MCQ_QUES_SUCCESS"
export const GET_IELTS_READING_MCQ_QUES_FAIL = "GET_IELTS_READING_MCQ_QUES_FAIL"

export const GET_IELTS_READING_QUES_NUM = "GET_IELTS_READING_QUES_NUM"
export const GET_IELTS_READING_QUES_NUM_SUCCESS =
  "GET_IELTS_READING_QUES_NUM_SUCCESS"
export const GET_IELTS_READING_QUES_NUM_FAIL = "GET_IELTS_READING_QUES_NUM_FAIL"

export const GET_IELTS_READING_QUES_STATUS = "GET_IELTS_READING_QUES_STATUS"
export const GET_IELTS_READING_QUES_STATUS_SUCCESS =
  "GET_IELTS_READING_QUES_STATUS_SUCCESS"
export const GET_IELTS_READING_QUES_STATUS_FAIL =
  "GET_IELTS_READING_QUES_STATUS_FAIL"

export const UPDATE_IELTS_READING_QUES_STATUS =
  "UPDATE_IELTS_READING_QUES_STATUS"
export const UPDATE_IELTS_READING_QUES_STATUS_SUCCESS =
  "UPDATE_IELTS_READING_QUES_STATUS_SUCCESS"
export const UPDATE_IELTS_READING_QUES_STATUS_FAIL =
  "UPDATE_IELTS_READING_QUES_STATUS_FAIL"

export const STORE_QUESTION = "STORE_QUESTION"
