export const ADD_SPEAKING_SET = "ADD_SPEAKING_SET"
export const ADD_SPEAKING_SET_SLOT = "ADD_SPEAKING_SET_SLOT"

export const GET_SINGLE_SPEAKING_SLOT = "GET_SINGLE_SPEAKING_SLOT"

export const GET_STUDENT_PROFILE = "GET_STUDENT_PROFILE"

export const GET_SPEAKING_SETS = "GET_SPEAKING_SETS"

export const TEACHER_START_EXAM = "TEACHER_START_EXAM"

export const UPDATE_ATTENDANCE = "UPDATE_ATTENDANCE"

export const SUBMIT_MARKS = "SUBMIT_MARKS"

export const SHOW_RESULT = "SHOW_RESULT"

export const STORE_QUESTION = "STORE_QUESTION"
