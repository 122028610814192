import {
  ADD_WRITTING_SET,
  ADD_WRITTING_SETS_QUESTION,
  ADD_WRITTING_SETS_QUESTION_FAIL,
  ADD_WRITTING_SETS_QUESTION_SUCCESS,
  ADD_WRITTING_SET_FAIL,
  ADD_WRITTING_SET_SUCCESS,
  GET_WRITTING_SETS,
  GET_WRITTING_SETS_FAIL,
  GET_WRITTING_SETS_NUMBER,
  GET_WRITTING_SETS_NUMBER_FAIL,
  GET_WRITTING_SETS_NUMBER_SUCCESS,
  GET_WRITTING_SETS_SUCCESS,
  GET_WRITTING_SET_SINGLE,
  GET_WRITTING_SET_SINGLE_FAIL,
  GET_WRITTING_SET_SINGLE_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: false,
  adding: false,
  message: "",
  error: "",
  writingSet: {},
  writingSets: [],
  questionNumber: 1,
}

const writingSetReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_WRITTING_SET:
      state = {
        ...state,
        adding: true,
      }
      break
    case ADD_WRITTING_SET_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        adding: false,
      }
      break
    case ADD_WRITTING_SET_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      }
      break
    case GET_WRITTING_SETS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_WRITTING_SETS_SUCCESS:
      state = {
        ...state,
        loading: false,
        writingSets: action.payload.values,
      }
      break
    case GET_WRITTING_SETS_FAIL:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break
    case GET_WRITTING_SET_SINGLE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_WRITTING_SET_SINGLE_SUCCESS:
      state = {
        ...state,
        loading: false,
        writingSet: action.payload.values,
      }
      break
    case GET_WRITTING_SET_SINGLE_FAIL:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break

    case GET_WRITTING_SETS_NUMBER:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_WRITTING_SETS_NUMBER_SUCCESS:
      state = {
        ...state,
        loading: false,
        questionNumber: action.payload.values,
      }
      break
    case GET_WRITTING_SETS_NUMBER_FAIL:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      }
      break

    case ADD_WRITTING_SETS_QUESTION:
      state = {
        ...state,
        adding: true,
      }
      break
    case ADD_WRITTING_SETS_QUESTION_SUCCESS:
      state = {
        ...state,
        adding: false,
      }
      break
    case ADD_WRITTING_SETS_QUESTION_FAIL:
      state = {
        ...state,
        adding: false,
        message: action.payload.message,
      }
      break
    default:
      state = { ...state }
      break
  }

  return state
}

export default writingSetReducer
