import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { toCapitalize } from "helpers/Custom/toCapitalize"
import React from "react"
import { useDispatch } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Col, Media, Row } from "reactstrap"
import InnerLayer from "../../../Layout/InnerLayer"
const QuantitativeTypeList = [
  {
    name: "analyze an argument",
    value: 1,
  },
  {
    name: "analyze an issue",
    value: 2,
  },
]
const AnalyticalType = ({ history }) => {
  const dispatch = useDispatch()

  const handleSet = type => {
    console.log("csxczc")
    switch (type.name?.trim()?.toLowerCase()) {
      case "analyze an argument":
        history.push({
          pathname: `/analyze-argument/${type?.value}`,
        })
        break
      case "analyze an issue":
        history.push({
          pathname: `/analyze-issue/${type?.value}`,
        })
        break

      default:
        console.log("Not found")
        break
    }
  }
  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Analytical Question Type"
        mainTitle={"Dashboard"}
        subTitle={"Analytical Question Type"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>Analytical question type </h4>
              <div className="d-flex">
                <div className="search-box me-2">
                  <div className="position-relative">
                    <input
                      type="search"
                      placeholder="Search..."
                      // value={keyword}
                      // onChange={handleSearchChange}
                      className="form-control"
                    />
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>
                <div>
                  <div className="position-relative">
                    <Link to="/create-service">
                      <Button
                        type="button"
                        color="primary"
                        className="btn-circle me-2"
                      >
                        <i className="mdi mdi-plus me-1" />
                        Create Type
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </CardComponent>
        </Row>
        <Row>
          {false ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : (
            QuantitativeTypeList?.map((type, idx) => (
              <Col
                md="3"
                key={idx}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleSet(type)
                }}
              >
                <CardComponent>
                  <Media>
                    <Media body>
                      <p className="text-muted fw-medium mb-2">Type Name</p>
                      <h4 className="mb-1 font-size-15">
                        {toCapitalize(type?.name)}
                      </h4>
                    </Media>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-primary">
                        <i className={"bx bx-bulb font-size-24"}></i>
                      </span>
                    </div>
                  </Media>
                </CardComponent>
              </Col>
            ))
          )}
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(AnalyticalType)
