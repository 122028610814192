import { ADD_VERBAL_QUES, ADD_VERBAL_QUES_FAIL, ADD_VERBAL_QUES_SUCCESS, GET_SINGLE_VERBAL_QUES, GET_SINGLE_VERBAL_QUES_FAIL, GET_SINGLE_VERBAL_QUES_SUCCESS, GET_VERBAL_PASSAGE_DETAILS, GET_VERBAL_PASSAGE_DETAILS_FAIL, GET_VERBAL_PASSAGE_DETAILS_SUCCESS, GET_VERBAL_QUES, GET_VERBAL_QUES_FAIL, GET_VERBAL_QUES_SUCCESS, GET_VERBAL_READING_SINGLE_QUES, GET_VERBAL_READING_SINGLE_QUES_FAIL, GET_VERBAL_READING_SINGLE_QUES_SUCCESS, STORE_VERBAL_DATA, UPDATE_VERBAL_QUES, UPDATE_VERBAL_QUES_FAIL, UPDATE_VERBAL_QUES_SUCCESS } from "./actionTypes"

  const INIT_STATE = {
    loading: true,
    error: "",
    data: [],
    adding: false,
    verbalQuestion: [],
    verbalQuestionLoading:false,
    singleVerbalQues: [],
    singleVerbalQuesLoading: false,
    singleVerbalPassageDetails: [],
    singleVerbalPassageDetailsLoading: false,
    singleVerbalReadingQuesLoading: false,
    singleVerbalReadingQues: []
  }
  
  const verbalReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case ADD_VERBAL_QUES:
        return {
          ...state,
          adding: true,
        }
      case ADD_VERBAL_QUES_SUCCESS:
        return {
          ...state,
          adding: false,
        }
      case ADD_VERBAL_QUES_FAIL:
        return {
          ...state,
          adding: false,
          error: action.payload.data,
        }
        case UPDATE_VERBAL_QUES:
          return {
            ...state,
            adding: true,
          }
        case UPDATE_VERBAL_QUES_SUCCESS:
          return {
            ...state,
            adding: false,
          }
        case UPDATE_VERBAL_QUES_FAIL:
          return {
            ...state,
            adding: false,
            error: action.payload.data,
          }
        case GET_VERBAL_QUES:
          return {
            ...state,
            verbalQuestionLoading: true,
          };
        case GET_VERBAL_QUES_SUCCESS:
          return {
            ...state,
            verbalQuestionLoading: false,
            verbalQuestion: action.payload.data,
          };
        case GET_VERBAL_QUES_FAIL:
          return {
            ...state,
            error: action.payload,
            verbalQuestionLoading: false,
          };
          case GET_SINGLE_VERBAL_QUES:
            return {
              ...state,
              singleVerbalQuesLoading: true,
            };
          case GET_SINGLE_VERBAL_QUES_SUCCESS:
            return {
              ...state,
              singleVerbalQuesLoading: false,
              singleVerbalQues: action.payload.data,
            };
          case GET_SINGLE_VERBAL_QUES_FAIL:
            return {
              ...state,
              error: action.payload,
              singleVerbalQuesLoading: false,
            };
           
            case GET_VERBAL_PASSAGE_DETAILS:
              return {
                ...state,
                singleVerbalPassageDetailsLoading: true,
              };
            case GET_VERBAL_PASSAGE_DETAILS_SUCCESS:
              return {
                ...state,
                singleVerbalPassageDetailsLoading: false,
                singleVerbalPassageDetails: action.payload.data,
              };
            case GET_VERBAL_PASSAGE_DETAILS_FAIL:
              return {
                ...state,
                error: action.payload,
                singleVerbalPassageDetailsLoading: false,
              };

              case GET_VERBAL_READING_SINGLE_QUES:
                return {
                  ...state,
                  singleVerbalReadingQuesLoading: true,
                };
              case GET_VERBAL_READING_SINGLE_QUES_SUCCESS:
                return {
                  ...state,
                  singleVerbalReadingQuesLoading: false,
                  singleVerbalReadingQues: action.payload.data,
                };
              case GET_VERBAL_READING_SINGLE_QUES_FAIL:
                return {
                  ...state,
                  error: action.payload,
                  singleVerbalReadingQuesLoading: false,
                };
               
            case STORE_VERBAL_DATA:
              return {
                ...state,
                [action.payload.name]: action.payload.data,
              };
      default:
        return state
    }
  }
  
  export default verbalReducer
  