import Loader from "components/Common/Loader"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import { addFacilitatorSlot, getSingleFacilitatorSlot } from "store/Facilitator/actions"
import { addSpeakingSet, getSingleSpeakingSets } from "store/Speaking/actions"
import "./speaking-create.scss"
// import googleMeetIcon from "./img/Google_Meet 1.png"
// import confirmIcon from "./img/illustration.svg"
const timeSlice = [
  { time: 10, value: `10:00 - 10:59` },

  { time: 11, value: `11:00 - 11:59` },

  { time: 12, value: `12:00 - 12:59` },

  { time: 1, value: `01:00 - 01:59` },

  { time: 2, value: `02:00 - 02:59` },

  { time: 3, value: `03:00 - 03:59` },

  { time: 4, value: `04:00 - 04:59` },
  { time: 5, value: `05:00 - 05:59` },

  { time: 6, value: `06:00 - 06:59` },

  { time: 7, value: `07:00 - 07:59` },
  
];
const FacilitatorTimeSlotCreate = ({ history, teacherId }) => {
  const dispatch = useDispatch()
  const { authToken, loading, singleFacilitatorSlot, adding } = useSelector(state => ({
    authToken: state.Login.token,
    loading: state.FacilitatorReducer.loading,
    adding: state.FacilitatorReducer.adding,
    singleFacilitatorSlot: state.FacilitatorReducer.singleFacilitatorSlot?.obj1,
  }))
  const [value, onChange] = useState(new Date())
  const [changed, setOnChanged] = useState("")
  const [selectedTime, setSelectedTime] = useState({})


  useEffect(() => {
    dispatch(
        getSingleFacilitatorSlot(authToken, {
        teacherId,
        date: moment(value).format("YYYY-MM-DD"),
      })
    )
  }, [changed])

  const handleChange = value => {
    setOnChanged(value)
    onChange(value)
  }
  const handleData = data => {
    // console.log(data)
    console.log('dasa', data);
    setSelectedTime(data)
  }

  const handleAdd = () => {
    
    const data = {
      date: moment(value).format("YYYY-MM-DD"),
      meetingTime: selectedTime.time,
    }

    dispatch(addFacilitatorSlot(data, { history, token: authToken, teacherId }))
     history.push(`/facilitator/${teacherId}`)
  
  }
  const activeClass = data => {
    // console.log(`speakingSet?.[data.time] > 0`, speakingSet?.[data.time] > 0)
    if (singleFacilitatorSlot?.[data.time] > 0) {
      return " added"
    }
    if (data.time === selectedTime.time) {
      return " selected"
    }
    return ""
  }
  return (
    <div className="speaking-main-container-wrap">
      {!changed ? (
        <div className="speaking-exam-main-wrap">
          <div className="speaking-exam-date-bottom-wrap">
            <Row className="justify-content-center">
              <Col lg={6}>
                <div className="speaking-exam-date-content-wrap">
                  <Calendar onChange={handleChange} value={value} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="speaking-exam-main-wrap">
          <div className="speaking-exam-date-bottom-wrap">
            <Row className="justify-content-center">
              <Col lg={6}>
                <div className="speaking-exam-date-content-wrap">
                  <Calendar onChange={handleChange} value={value} />
                </div>
              </Col>{" "}
              <Col lg={6} className="my-auto">
                {loading ? (
                  <div className="position-absolute w-100 text-center">
                    <Loader />
                  </div>
                ) : null}
                <div className="form-submit-main-time-wrap">
                  <div className="speaking-exam-selected-time">
                    {timeSlice.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            console.log('item?.time', item?.time);
                            if (singleFacilitatorSlot?.[item?.time] === 0) {
                             
                              handleData(item)
                            }
                          }}
                          className={
                            "speaking-selected-time-button" + activeClass(item)
                          }
                        >
                          {item.value}
                        </div>
                      )
                    })}
                  </div>
                  {selectedTime?.value ? (
                    <div className="time-selected-submit-btn">
                      <button disabled={adding} onClick={handleAdd}>
                        {adding ? "Submitting..." : "Done"}
                      </button>
                    </div>
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  )
}

export default withRouter(FacilitatorTimeSlotCreate)
