import React from "react";
import { Link } from "react-router-dom";
import { BreadcrumbItem, Col, Row } from "reactstrap";
// import SearchIcon from "../Common/AllIcon/SearchIcon";

const PageHeader = ({
  handleAdd = () => {},
  buttonText,
  handleSearch = () => {},
  value,
  title,
  search,
  buttonClass,
  disableButton,
  breadcrumbItems = [],
  children,
}) => {
  const itemLength = breadcrumbItems?.length;
  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="content-file-upload-top mb-4">
            <div>
              <h3>{title}</h3>
              {breadcrumbItems && (
                <div className="breadcrumb-main-wrap p-0">
                  <div>
                    {breadcrumbItems?.length ? (
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0 p-0">
                          {/* d-none d-sm-block */}
                          {breadcrumbItems?.map((item, key) => (
                            <BreadcrumbItem
                              key={key}
                              active={key + 1 === itemLength}
                            >
                              <Link to={item.link}>{item.title}</Link>
                            </BreadcrumbItem>
                          ))}
                        </ol>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center">
              {/* {search ? (
                <div className="search-box me-4">
                  <div className="header-topbar-right-content-wrap-left-area">
                    <div className="search-box-header-top-wrap">
                      <i className="fas fa-search"></i>
                      <input
                        type="text"
                        name=""
                        id=""
                        placeholder="Search..."
                        onKeyDown={(e) => handleSearch(e)}
                      />
                    </div>
                  </div>
                </div>
              ) : null} */}
              {search ? (
                <div className="content-file-search-area me-4">
                  <label htmlFor="file-search">
                    {/* <SearchIcon /> */}
                  </label>
                  <input
                    type="text"
                    name=""
                    // value={value}
                    placeholder="Type search keyword and hit enter"
                    onKeyDown={handleSearch}
                    id="file-search"
                  />
                </div>
              ) : null}
              {children}
              {buttonText ? (
                <button
                  onClick={handleAdd}
                  disabled={disableButton}
                  className={`btn ${
                    buttonClass || "btn-outline-default btn-outline-blue"
                  }`}
                >
                  {buttonText}
                </button>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PageHeader;
