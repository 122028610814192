import { ADD_QUANTITATIVE_QUES, ADD_QUANTITATIVE_QUES_FAIL, ADD_QUANTITATIVE_QUES_SUCCESS, GET_QUANTITATIVE_QUES, GET_QUANTITATIVE_QUES_FAIL, GET_QUANTITATIVE_QUES_SUCCESS, GET_SINGLE_QUANTITATIVE_QUES, GET_SINGLE_QUANTITATIVE_QUES_FAIL, GET_SINGLE_QUANTITATIVE_QUES_SUCCESS, STORE_QUANTITATIVE_DATA, UPDATE_QUANTITATIVE_QUES, UPDATE_QUANTITATIVE_QUES_FAIL, UPDATE_QUANTITATIVE_QUES_SUCCESS } from "./actionTypes"

  const INIT_STATE = {
    loading: true,
    error: "",
    data: [],
    adding: false,
    quantitativeQuestion: [],
    QuantitativeQuestionLoading:false,
    singleQuantitativeQues: [],
    singleQuantitativeQuesLoading: false
  }
  
  const QuantitativeReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case ADD_QUANTITATIVE_QUES:
        return {
          ...state,
          adding: true,
        }
      case ADD_QUANTITATIVE_QUES_SUCCESS:
        return {
          ...state,
          adding: false,
        }
      case ADD_QUANTITATIVE_QUES_FAIL:
        return {
          ...state,
          adding: false,
          error: action.payload.data,
        }
        case UPDATE_QUANTITATIVE_QUES:
          return {
            ...state,
            adding: true,
          }
        case UPDATE_QUANTITATIVE_QUES_SUCCESS:
          return {
            ...state,
            adding: false,
          }
        case UPDATE_QUANTITATIVE_QUES_FAIL:
          return {
            ...state,
            adding: false,
            error: action.payload.data,
          }
        case GET_QUANTITATIVE_QUES:
          return {
            ...state,
            QuantitativeQuestionLoading: true,
          };
        case GET_QUANTITATIVE_QUES_SUCCESS:
          return {
            ...state,
            QuantitativeQuestionLoading: false,
            quantitativeQuestion: action.payload.data,
          };
        case GET_QUANTITATIVE_QUES_FAIL:
          return {
            ...state,
            error: action.payload,
            QuantitativeQuestionLoading: false,
          };
          case GET_SINGLE_QUANTITATIVE_QUES:
            return {
              ...state,
              singleQuantitativeQuesLoading: true,
            };
          case GET_SINGLE_QUANTITATIVE_QUES_SUCCESS:
            return {
              ...state,
              singleQuantitativeQuesLoading: false,
              singleQuantitativeQues: action.payload.data,
            };
          case GET_SINGLE_QUANTITATIVE_QUES_FAIL:
            return {
              ...state,
              error: action.payload,
              singleQuantitativeQuesLoading: false,
            };
           
         
            case STORE_QUANTITATIVE_DATA:
              return {
                ...state,
                [action.payload.name]: action.payload.data,
              };
      default:
        return state
    }
  }
  
  export default QuantitativeReducer
  