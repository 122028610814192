import { ADD_ANALYTICAL_WRITING, ADD_ANALYTICAL_WRITING_FAIL, ADD_ANALYTICAL_WRITING_SUCCESS, GET_ANALYTICAL_ANS_DATA, GET_ANALYTICAL_ANS_DATA_FAIL, GET_ANALYTICAL_ANS_DATA_SUCCESS, GET_ANALYTICAL_WRITING, GET_ANALYTICAL_WRITING_FAIL, GET_ANALYTICAL_WRITING_SUCCESS, GET_NUMBER_OF_ANALYTICAL_EXAM, GET_NUMBER_OF_ANALYTICAL_EXAM_FAIL, GET_NUMBER_OF_ANALYTICAL_EXAM_SUCCESS, GET_SINGLE_ANALYTICAL_QUES, GET_SINGLE_ANALYTICAL_QUES_FAIL, GET_SINGLE_ANALYTICAL_QUES_SUCCESS, STORE_ANALYTICAL_DATA, SUBMIT_ANALYTICAL_MARK, SUBMIT_ANALYTICAL_MARK_FAIL, SUBMIT_ANALYTICAL_MARK_SUCCESS } from "./actionTypes"

  const INIT_STATE = {
    loading: true,
    error: "",
    data: [],
    adding: false,
    analyticalWriting: [],
    analyticalWritingLoading:false,
    singleAnalyticalQuesLoading: false,
    singleAnalyticalQues: [],
    analyticalNumLoading: true,
    analyticalNum: [],
    analyticalAnsLoading: true,
    analyticalAns: [],

  }
  
  const analyticalReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case ADD_ANALYTICAL_WRITING:
        return {
          ...state,
          adding: true,
        }
      case ADD_ANALYTICAL_WRITING_SUCCESS:
        return {
          ...state,
          adding: false,
        }
      case ADD_ANALYTICAL_WRITING_FAIL:
        return {
          ...state,
          adding: false,
          error: action.payload.data,
        }

        case SUBMIT_ANALYTICAL_MARK:
          return {
            ...state,
            adding: true,
          }
        case SUBMIT_ANALYTICAL_MARK_SUCCESS:
          return {
            ...state,
            adding: false,
          }
        case SUBMIT_ANALYTICAL_MARK_FAIL:
          return {
            ...state,
            adding: false,
            error: action.payload.data,
          }
        case GET_ANALYTICAL_WRITING:
          return {
            ...state,
            analyticalWritingLoading: true,
          };
        case GET_ANALYTICAL_WRITING_SUCCESS:
          return {
            ...state,
            analyticalWritingLoading: false,
            analyticalWriting: action.payload.data,
          };
        case GET_ANALYTICAL_WRITING_FAIL:
          return {
            ...state,
            error: action.payload,
            analyticalWritingLoading: false,
          };
          case GET_SINGLE_ANALYTICAL_QUES:
            return {
              ...state,
              singleAnalyticalQuesLoading: true,
            };
          case GET_SINGLE_ANALYTICAL_QUES_SUCCESS:
            return {
              ...state,
              singleAnalyticalQuesLoading: false,
              singleAnalyticalQues: action.payload.data,
            };
          case GET_SINGLE_ANALYTICAL_QUES_FAIL:
            return {
              ...state,
              error: action.payload,
              singleAnalyticalQuesLoading: false,
            };
            case GET_NUMBER_OF_ANALYTICAL_EXAM:
              return {
                ...state,
                analyticalNumLoading: true,
              };
            case GET_NUMBER_OF_ANALYTICAL_EXAM_SUCCESS:
              return {
                ...state,
                analyticalNumLoading: false,
                analyticalNum: action.payload,
              };
            case GET_NUMBER_OF_ANALYTICAL_EXAM_FAIL:
              return {
                ...state,
                error: action.payload,
                analyticalNumLoading: false,
              };
              case GET_ANALYTICAL_ANS_DATA:
                return {
                  ...state,
                  analyticalAnsLoading: true,
                };
              case GET_ANALYTICAL_ANS_DATA_SUCCESS:
                return {
                  ...state,
                  analyticalAnsLoading: false,
                  analyticalAns: action.payload,
                };
              case GET_ANALYTICAL_ANS_DATA_FAIL:
                return {
                  ...state,
                  error: action.payload,
                  analyticalAnsLoading: false,
                };
            case STORE_ANALYTICAL_DATA:
              return {
                ...state,
                [action.payload.name]: action.payload.data,
              };
      default:
        return state
    }
  }
  
  export default analyticalReducer
  