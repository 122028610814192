import React, { useRef } from "react"

const ProfileCard = ({
  img,
  name,
  outerStyle,
  parentClass,
  onChange,
  disable,
}) => {
  const ref = useRef()
  return (
    <React.Fragment>
      <div
        className={`profileCard relative ${outerStyle} ${parentClass} d-flex cursor-pointer`}
        onClick={() => {
          ref.current.click()
        }}
      >
        {!img ? (
          <div className="nonProfile ">
            <span
              className={
                "avatar-title rounded-circle bg-soft-primary text-primary font-size-16"
              }
            >
              {name.charAt(0)}
            </span>
          </div>
        ) : (
          <div className="profile">
            <img className="rounded-circle avatar-sm" src={img} alt="Profile" />
          </div>
        )}
      </div>
      {!disable ? (
        <input
          className="w-full h-full cursor-pointer"
          type="file"
          ref={ref}
          onChange={onChange}
          disabled={disable}
          style={{ fontSize: "12px" }}
          accept="image/png, image/jpg, image/jpeg"
        />
      ) : null}
    </React.Fragment>
  )
}

export default ProfileCard
