import {
  ADD_IELTS_LISTENING_MCQ_QUES,
  ADD_IELTS_LISTENING_MCQ_QUES_FAIL,
  ADD_IELTS_LISTENING_MCQ_QUES_SUCCESS,
  GET_IELTS_LISTENING_MCQ_QUES,
  GET_IELTS_LISTENING_MCQ_QUES_FAIL,
  GET_IELTS_LISTENING_MCQ_QUES_SUCCESS,
  GET_IELTS_LISTENING_QUES_NUM,
  GET_IELTS_LISTENING_QUES_NUM_FAIL,
  GET_IELTS_LISTENING_QUES_NUM_SUCCESS,
  GET_IELTS_LISTENING_QUES_STATUS,
  GET_IELTS_LISTENING_QUES_STATUS_FAIL,
  GET_IELTS_LISTENING_QUES_STATUS_SUCCESS,
  STORE_QUESTION,
  UPDATE_IELTS_LISTENING_QUES_STATUS,
  UPDATE_IELTS_LISTENING_QUES_STATUS_FAIL,
  UPDATE_IELTS_LISTENING_QUES_STATUS_SUCCESS,
} from "./actionTypes"

export const addIELTSListeningQuesMCQ = (
  data,
  history,
  authtoken,
  options
) => ({
  type: ADD_IELTS_LISTENING_MCQ_QUES,
  payload: { data, history, authtoken, options },
})

export const addIELTSListeningQuesMCQSuccess = data => ({
  type: ADD_IELTS_LISTENING_MCQ_QUES_SUCCESS,
  payload: data,
})

export const addIELTSListeningQuesMCQFail = error => ({
  type: ADD_IELTS_LISTENING_MCQ_QUES_FAIL,
  payload: error,
})
export const getIELTSListeningQuesMCQ = (authtoken, id, data) => ({
  type: GET_IELTS_LISTENING_MCQ_QUES,
  payload: { authtoken, id, data },
})

export const getIELTSListeningQuesMCQSuccess = data => ({
  type: GET_IELTS_LISTENING_MCQ_QUES_SUCCESS,
  payload: { data },
})

export const getIELTSListeningQuesMCQFail = error => ({
  type: GET_IELTS_LISTENING_MCQ_QUES_FAIL,
  payload: error,
})

export const getIELTSListeningQuesNum = (authtoken, id, type) => ({
  type: GET_IELTS_LISTENING_QUES_NUM,
  payload: { authtoken, id, type },
})

export const getIELTSListeningQuesNumSuccess = data => ({
  type: GET_IELTS_LISTENING_QUES_NUM_SUCCESS,
  payload: { data },
})

export const getIELTSListeningQuesNumFail = error => ({
  type: GET_IELTS_LISTENING_QUES_NUM_FAIL,
  payload: error,
})

export const updateIELTSListeningQuesStatus = (authtoken, id, type) => ({
  type: UPDATE_IELTS_LISTENING_QUES_STATUS,
  payload: { authtoken, id, type },
})

export const updateIELTSListeningQuesStatusSuccess = data => ({
  type: UPDATE_IELTS_LISTENING_QUES_STATUS_SUCCESS,
  payload: { data },
})

export const updateIELTSListeningQuesStatusFail = error => ({
  type: UPDATE_IELTS_LISTENING_QUES_STATUS_FAIL,
  payload: error,
})

export const getIELTSListeningQuesStatus = (authtoken, id, type) => ({
  type: GET_IELTS_LISTENING_QUES_STATUS,
  payload: { authtoken, id, type },
})

export const getIELTSListeningQuesStatusSuccess = data => ({
  type: GET_IELTS_LISTENING_QUES_STATUS_SUCCESS,
  payload: { data },
})

export const getIELTSListeningQuesStatusFail = error => ({
  type: GET_IELTS_LISTENING_QUES_STATUS_FAIL,
  payload: error,
})

export const storeQuestion = (name, data) => ({
  type: STORE_QUESTION,
  payload: { name, data },
})
