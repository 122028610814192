import { useEffect, useRef, useState } from "react"

const useAudioPlayer = () => {
  // state
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [timeJump, setTimeJump] = useState(0)
  const [mute, setMute] = useState(false)

  // references
  const audioPlayer = useRef(null) // reference our audio component
  const progressBar = useRef(null) // reference our progress bar
  const animationRef = useRef() // reference the animation

  // handle time jumps
  // useEffect(() => {
  //   timeTravel(timeJump)
  //   // setIsPlaying(true)
  //   // play()
  // }, [timeJump])

  // grabs the loaded metadata
  useEffect(() => {
    if (audioPlayer.current?.duration) {
      const seconds = Math.floor(audioPlayer.current?.duration)
      setDuration(seconds)
      progressBar.current.max = seconds?.toString()
    }
  }, [audioPlayer?.current?.onloadedmetadata, audioPlayer?.current?.readyState])

  // when you get to the end
  useEffect(() => {
    if (Number(duration) > 1 && Number(currentTime) === Number(duration)) {
      togglePlayPause()
      timeTravel(0)
    }
  }, [currentTime, duration])

  const play = () => {
    audioPlayer.current?.play()
    animationRef.current = requestAnimationFrame(whilePlaying)
  }

  const toggleMute = () => {
    setMute(!mute)
    audioPlayer.current.muted = !audioPlayer.current.muted
  }

  const togglePlayPause = () => {
    const prevValue = isPlaying
    setIsPlaying(!prevValue)
    if (!prevValue) {
      play()
    } else {
      audioPlayer.current?.pause()
      cancelAnimationFrame(animationRef.current)
    }
  }

  const whilePlaying = () => {
    progressBar.current.value = String(audioPlayer.current?.currentTime)
    changePlayerCurrentTime()
    animationRef.current = requestAnimationFrame(whilePlaying)
  }

  const changeRange = () => {
    audioPlayer.current.currentTime = Number(progressBar.current?.value)
    changePlayerCurrentTime()
  }

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      "--seek-before-width",
      `${(Number(progressBar.current?.value) / duration) * 100}%`
    )
    setCurrentTime(Number(progressBar.current?.value))
  }

  const backward = time => {
    timeTravel(Number(progressBar.current?.value) - time)
  }

  const forward = time => {
    timeTravel(Number(progressBar.current?.value) + time)
  }

  const timeTravel = newTime => {
    progressBar.current.value = String(newTime)
    changeRange()
  }

  return {
    isPlaying,
    duration,
    currentTime,
    audioPlayer,
    progressBar,
    mute,
    togglePlayPause,
    changeRange,
    backward,
    forward,
    timeTravel,
    setDuration,
    setIsPlaying,
    setTimeJump,
    toggleMute,
    play,
  }
}

export { useAudioPlayer }
