export const ADD_BANNER = "ADD_BANNER"
export const ADD_BANNER_SUCCESS = "ADD_BANNER_SUCCESS"
export const ADD_BANNER_FAIL = "ADD_BANNER_FAIL"


export const GET_BANNER= "GET_BANNER"
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS"
export const GET_BANNER_FAIL = "GET_BANNER_FAIL"

export const ACTIVE_BANNER= "ACTIVE_BANNER"
export const ACTIVE_BANNER_SUCCESS = "ACTIVE_BANNER_SUCCESS"
export const ACTIVE_BANNER_FAIL = "ACTIVE_BANNER_FAIL"


export const STORE_BANNER_DATA = "STORE_BANNER_DATA"