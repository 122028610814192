import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import TextError from "components/InputComponent/TextError"
import { toaster } from "helpers/Custom/Toaster"
import { image, imageBaseUrl } from "helpers/api_helper"
import moment from "moment"
import Upload from "pages/Package/Upload"
import React, { useEffect, useState } from "react"
import { Modal, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { Button, Card, Col } from "reactstrap"
import {
  activeScreen,
  getScreenList,
  storeScreenData,
} from "store/Splash/actions"
import InnerLayer from "../../Layout/InnerLayer"
import uploadIcon from "./img/send-square.svg"
import deleteIcon from "./img/trash.svg"
import "./splash.scss"

const SplashArea = () => {
  const history = useHistory()
  const [show, setShow] = useState(false)

  const [fileError, setFileError] = useState("")
  const [visible, setVisible] = useState(true)
  const [splashId, setSplashId] = useState("")
  const dispatch = useDispatch()
  const [orderStartDate, setOrderStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [orderEndDate, setOrderEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedMobileView, setSelectedMobileView] = useState([])
  const [selectedTabView, setSelectedTabView] = useState([])
  const [imageUp, setImageUp] = useState("")
  const [mobileImage, setMobileImage] = useState("")
  const [tabImage, setTabImage] = useState("")
  const { authToken, splashLoading, splashList, editSplashData } = useSelector(
    state => ({
      authToken: state.Login.token,
      splashList: state.splashReducer.splashList,
      splashLoading: state.splashReducer.splashLoading,
      editSplashData: state.splashReducer.editSplashData,
    })
  )
  console.log("splashList", splashList)

  const handlePhoto = async (file, data) => {
    console.log("file", file, data)
    try {
      const url = `/image-upload`
      const formData = new FormData()
      formData.append("photo", file)
      const response = await image(url, formData, authToken)
      console.log("response", response)
      if (data === "web") {
        setImageUp(response?.photo)
      }
      if (data === "mobile") {
        setMobileImage(response?.photo)
      }
      if (data === "tab") {
        setTabImage(response?.photo)
      }
    } catch (err) {
      toaster("error", "Upload Image failed")
    }
  }
  useEffect(() => {
    setImageUp(editSplashData?.splash || "")
    setMobileImage(editSplashData?.splashMobile || "")
    setTabImage(editSplashData?.splashTab || "")
  }, [editSplashData])

  const handleSubmit = (event, errors, values) => {
    event.preventDefault()
    console.log(
      imageUp,
      mobileImage,
      tabImage,
      values?.startDate,
      values?.endDate,
      values?.link
    )
    if (
      imageUp &&
      mobileImage &&
      tabImage &&
      values?.startDate &&
      values?.endDate
    ) {
      let body = {
        startDate: values?.startDate,
        endDate: values?.endDate,
        link: values?.link,
        splash: imageUp,
        splashMobile: mobileImage,
        splashTab: tabImage,
      }
      console.log("body", body)
      if (splashId) {
        dispatch(addScreen(body, authToken, history, splashId))
      } else {
        dispatch(addScreen(body, authToken, history))
      }
    } else {
      toaster("error", "Please fill up all the field")
    }
    setShow(false)
  }

  const handleStartDate = e => {
    const value = e.target.value

    setOrderStartDate(value)
  }
  const handleEndDate = e => {
    const value = e.target.value

    setOrderEndDate(value)
  }
  useEffect(() => {
    dispatch(getScreenList(authToken))
  }, [])
  const getPhotoUrl = photo => {
    console.log("photo", photo)
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return ""
  }
  const handleVisible = data => {
    console.log("datat", data)
    if (data) {
      // setVisible(!data?.visible)
      let body = {
        visible: !data?.visible,
      }
      console.log("body", body)
      dispatch(activeScreen(body, authToken, history, data?._id))
    }
  }
  const handleScreenData = data => {
    console.log("data", data)
    setShow(true)
    setSplashId(data?._id)
    dispatch(storeScreenData("editSplashData", data))
  }
  const handleClose = () => setShow(false)
  const handleShow = () => {
    setSplashId("")
    setSelectedFiles([])
    setSelectedTabView([])
    setSelectedMobileView([])
    dispatch(storeScreenData("editSplashData", []))
    setShow(true)
  }
  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Splash Screen"
        mainTitle={"Dashboard"}
        subTitle={"Splash Screen"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <Modal show={show} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton />

          <Modal.Body>
            <div className="">
              <AvForm onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md="4">
                    <Upload
                      uploadMessage={
                        selectedFiles.length > 0
                          ? "Drop files for web here or click to change."
                          : "Drop files for web here or click to upload."
                      }
                      setSelectedFiles={setSelectedFiles}
                      onDropCallBack={(files, values) => {
                        console.log(files, "zndncj mncsanc jsnc")
                        // console.log(`UploadContent ~ file`, files);
                        if (files.length > 0) {
                          handlePhoto(files?.[0], "web")
                        }
                      }}
                    />
                    {selectedFiles?.length === 0 && fileError !== "" ? (
                      <TextError>{fileError}</TextError>
                    ) : null}
                    <Row>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles?.length ? (
                          selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })
                        ) : editSplashData?.splash ? (
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="100"
                                  className="avatar-sm rounded bg-light"
                                  alt={"photo"}
                                  src={getPhotoUrl(editSplashData?.splash)}
                                />
                              </Col>
                            </Row>
                          </div>
                        ) : null}
                      </div>
                    </Row>
                  </Col>
                  <Col md="4">
                    <Upload
                      uploadMessage={
                        selectedMobileView.length > 0
                          ? "Drop files for mobile here or click to change."
                          : "Drop files for mobile here or click to upload."
                      }
                      setSelectedFiles={setSelectedMobileView}
                      onDropCallBack={(files, values) => {
                        console.log(files, "zndncj mncsanc jsnc")
                        // console.log(`UploadContent ~ file`, files);
                        if (files.length > 0) {
                          handlePhoto(files?.[0], "mobile")
                        }
                      }}
                    />
                    {selectedMobileView?.length === 0 && fileError !== "" ? (
                      <TextError>{fileError}</TextError>
                    ) : null}
                    <Row>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedMobileView?.length ? (
                          selectedMobileView.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })
                        ) : editSplashData?.splash ? (
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="100"
                                  className="avatar-sm rounded bg-light"
                                  alt={"photo"}
                                  src={getPhotoUrl(
                                    editSplashData?.splashMobile
                                  )}
                                />
                              </Col>
                            </Row>
                          </div>
                        ) : null}
                      </div>
                    </Row>
                  </Col>
                  <Col md="4">
                    <Upload
                      uploadMessage={
                        selectedTabView.length > 0
                          ? "Drop files for tab here or click to change."
                          : "Drop files for tab here or click to upload."
                      }
                      setSelectedFiles={setSelectedTabView}
                      onDropCallBack={(files, values) => {
                        console.log(files, "zndncj mncsanc jsnc")
                        // console.log(`UploadContent ~ file`, files);
                        if (files.length > 0) {
                          handlePhoto(files?.[0], "tab")
                        }
                      }}
                    />
                    {selectedTabView?.length === 0 && fileError !== "" ? (
                      <TextError>{fileError}</TextError>
                    ) : null}
                    <Row>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedTabView?.length ? (
                          selectedTabView.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })
                        ) : editSplashData?.splash ? (
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="100"
                                  className="avatar-sm rounded bg-light"
                                  alt={"photo"}
                                  src={getPhotoUrl(editSplashData?.splashTab)}
                                />
                              </Col>
                            </Row>
                          </div>
                        ) : null}
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="startDate"
                        id="example-time-input"
                        className="form-control mb-3"
                        type="date"
                        label="Start Date"
                        // value={moment(new Date()).format("YYYY-MM-DD")}
                        value={
                          editSplashData?.startDate
                            ? moment(editSplashData?.startDate).format(
                                "YYYY-MM-DD"
                              )
                            : ""
                        }
                        onChange={handleStartDate}
                        errorMessage="Enter Start Time"
                        validate={{ required: { value: true } }}
                      ></AvField>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <AvField
                        name="endDate"
                        id="example-time-input"
                        className="form-control mb-3"
                        type="date"
                        label="End Date"
                        // value={moment(new Date()).format("YYYY-MM-DD")}
                        value={
                          editSplashData?.endDate
                            ? moment(editSplashData?.endDate).format(
                                "YYYY-MM-DD"
                              )
                            : ""
                        }
                        onChange={handleEndDate}
                        errorMessage="Enter Start Time"
                        validate={{ required: { value: true } }}
                      ></AvField>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <AvField
                        name="link"
                        label="Link to details page"
                        type="link"
                        placeholder="Type link"
                        errorMessage="Type link"
                        value={editSplashData?.link}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <React.Fragment>
                    <div className="flex-wrap gap-2 col-md-12">
                      <Button type="submit" color="primary" className="w-100">
                        Submit
                      </Button>
                    </div>
                  </React.Fragment>
                </Row>
              </AvForm>
            </div>
          </Modal.Body>
        </Modal>
        <CardComponent className="position-relative">
          <div className="splash-add-modal-button-top">
            <Button
              type="button"
              color="primary"
              className="btn-circle me-2"
              onClick={handleShow}
            >
              <img src={uploadIcon} alt="" />
              Create Splash Screen
            </Button>
          </div>
        </CardComponent>

        <Row className="mt-3">
          {splashLoading ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : splashList?.length > 0 ? (
            splashList?.map((data, idx) => (
              <Col lg="4 mb-3" key={idx}>
                <div
                  className={
                    data?.visible === true
                      ? "banner-single-card-wrap show"
                      : "banner-single-card-wrap hide  "
                  }
                  style={{
                    backgroundImage: `url(${getPhotoUrl(data?.splash)})`,
                  }}
                >
                  <div className="banner-single-card-header">
                    <div className="banner-single-card-header-left">
                      <button onClick={() => handleScreenData(data)}>
                        <img src={deleteIcon} alt="" />
                      </button>
                    </div>
                    <div className="banner-single-card-header-right">
                      <button onClick={() => handleVisible(data)}>
                        {data?.visible === true ? (
                          <>
                            <i className="bx bx-hide"></i>
                            Visible
                          </>
                        ) : (
                          <>
                            <i className="bx bx-show"></i>
                            <span>Hide</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div className="text-center">
              <h5>No Splash Screen List</h5>
            </div>
          )}
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default SplashArea
