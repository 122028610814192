import {
  ADD_FACILITATOR_REVIEW,
  ADD_FACILITATOR_REVIEW_FAIL,
  ADD_FACILITATOR_REVIEW_SUCCESS,
  ADD_FACILITATOR_SLOT,
  ADD_FACILITATOR_SLOT_FAIL,
  ADD_FACILITATOR_SLOT_SUCCESS,
  GET_FACILITATOR_LISTENING_SET,
  GET_FACILITATOR_LISTENING_SET_FAIL,
  GET_FACILITATOR_LISTENING_SET_SUCCESS,
  GET_FACILITATOR_REVIEW,
  GET_FACILITATOR_REVIEW_FAIL,
  GET_FACILITATOR_REVIEW_SUCCESS,
  GET_FACILITATOR_SET,
  GET_FACILITATOR_SET_FAIL,
  GET_FACILITATOR_SET_SUCCESS,
  GET_FACILITATOR_SINGLE_DAY,
  GET_FACILITATOR_SINGLE_DAY_FAIL,
  GET_FACILITATOR_SINGLE_DAY_SUCCESS,
  GET_FACILITATOR_SINGLE_PACKAGE,
  GET_FACILITATOR_SINGLE_PACKAGE_FAIL,
  GET_FACILITATOR_SINGLE_PACKAGE_SUCCESS,
  GET_FACILITATOR_SLOT,
  GET_FACILITATOR_SLOT_FAIL,
  GET_FACILITATOR_SLOT_SUCCESS,
  GET_FACILITATOR_WRITING_SET,
  GET_FACILITATOR_WRITING_SET_FAIL,
  GET_FACILITATOR_WRITING_SET_SUCCESS,
  GET_REVIEW_ATTENDANCE,
  GET_REVIEW_ATTENDANCE_FAIL,
  GET_REVIEW_ATTENDANCE_SUCCESS,
  GET_SINGLE_FACILITATOR_SLOT,
  GET_SINGLE_FACILITATOR_SLOT_FAIL,
  GET_SINGLE_FACILITATOR_SLOT_SUCCESS,
} from "./actionTypes"

export const getFacilitatorSlot = (token, options) => {
  return {
    type: GET_FACILITATOR_SLOT,
    payload: { token, options },
  }
}

export const getFacilitatorSlotSuccess = values => {
  return {
    type: GET_FACILITATOR_SLOT_SUCCESS,
    payload: { values },
  }
}

export const getFacilitatorSlotFail = message => {
  return {
    type: GET_FACILITATOR_SLOT_FAIL,
    payload: { message },
  }
}
export const getSingleFacilitatorSlot = (token, options) => {
  return {
    type: GET_SINGLE_FACILITATOR_SLOT,
    payload: { token, options },
  }
}

export const getSingleFacilitatorSlotSuccess = values => {
  return {
    type: GET_SINGLE_FACILITATOR_SLOT_SUCCESS,
    payload: { values },
  }
}

export const getSingleFacilitatorSlotFail = message => {
  return {
    type: GET_SINGLE_FACILITATOR_SLOT_FAIL,
    payload: { message },
  }
}
export const addFacilitatorSlot = (values, options) => {
  return {
    type: ADD_FACILITATOR_SLOT,
    payload: { values, options },
  }
}
export const addFacilitatorSlotSuccess = message => {
  return {
    type: ADD_FACILITATOR_SLOT_SUCCESS,
    payload: { message },
  }
}

export const addFacilitatorSlotFail = message => {
  return {
    type: ADD_FACILITATOR_SLOT_FAIL,
    payload: { message },
  }
}

export const getFacilitatorSingleDay = (token, options) => {
  return {
    type: GET_FACILITATOR_SINGLE_DAY,
    payload: { token, options },
  }
}

export const getFacilitatorSingleDaySuccess = values => {
  return {
    type: GET_FACILITATOR_SINGLE_DAY_SUCCESS,
    payload: { values },
  }
}

export const getFacilitatorSingleDayFail = message => {
  return {
    type: GET_FACILITATOR_SINGLE_DAY_FAIL,
    payload: { message },
  }
}

export const getFacilitatorSinglePackage = (token, packageId) => {
  return {
    type: GET_FACILITATOR_SINGLE_PACKAGE,
    payload: { token, packageId },
  }
}

export const getFacilitatorSinglePackageSuccess = values => {
  return {
    type: GET_FACILITATOR_SINGLE_PACKAGE_SUCCESS,
    payload: { values },
  }
}

export const getFacilitatorSinglePackageFail = message => {
  return {
    type: GET_FACILITATOR_SINGLE_PACKAGE_FAIL,
    payload: { message },
  }
}
export const getFacilitatorSet = (token, packageId, studentId) => {
  return {
    type: GET_FACILITATOR_SET,
    payload: { token, packageId, studentId },
  }
}

export const getFacilitatorSetSuccess = values => {
  return {
    type: GET_FACILITATOR_SET_SUCCESS,
    payload: { values },
  }
}

export const getFacilitatorSetFail = message => {
  return {
    type: GET_FACILITATOR_SET_FAIL,
    payload: { message },
  }
}
export const getFacilitatorReview = (token, setId, studentId, type) => {
  return {
    type: GET_FACILITATOR_REVIEW,
    payload: { token, setId, studentId, type },
  }
}

export const getFacilitatorReviewSuccess = values => {
  return {
    type: GET_FACILITATOR_REVIEW_SUCCESS,
    payload: { values },
  }
}

export const getFacilitatorReviewFail = message => {
  return {
    type: GET_FACILITATOR_REVIEW_FAIL,
    payload: { message },
  }
}
export const addFacilitatorReview = (values, options) => {
  return {
    type: ADD_FACILITATOR_REVIEW,
    payload: { values, options },
  }
}
export const addFacilitatorReviewSuccess = message => {
  return {
    type: ADD_FACILITATOR_REVIEW_SUCCESS,
    payload: { message },
  }
}

export const addFacilitatorReviewFail = message => {
  return {
    type: ADD_FACILITATOR_REVIEW_FAIL,
    payload: { message },
  }
}
export const getFacilitatorListeningSet = (token, packageId, studentId) => {
  return {
    type: GET_FACILITATOR_LISTENING_SET,
    payload: { token, packageId, studentId },
  }
}

export const getFacilitatorListeningSetSuccess = values => {
  return {
    type: GET_FACILITATOR_LISTENING_SET_SUCCESS,
    payload: { values },
  }
}

export const getFacilitatorListeningSetFail = message => {
  return {
    type: GET_FACILITATOR_LISTENING_SET_FAIL,
    payload: { message },
  }
}
export const getFacilitatorWritingSet = (token, packageId, studentId) => {
  return {
    type: GET_FACILITATOR_WRITING_SET,
    payload: { token, packageId, studentId },
  }
}

export const getFacilitatorWritingSetSuccess = values => {
  return {
    type: GET_FACILITATOR_WRITING_SET_SUCCESS,
    payload: { values },
  }
}

export const getFacilitatorWritingSetFail = message => {
  return {
    type: GET_FACILITATOR_WRITING_SET_FAIL,
    payload: { message },
  }
}

export const getReviewAttendance = (token, value, examId, options) => {
  return {
    type: GET_REVIEW_ATTENDANCE,
    payload: { token, value, examId, options },
  }
}

export const getReviewAttendanceSuccess = values => {
  return {
    type: GET_REVIEW_ATTENDANCE_SUCCESS,
    payload: { values },
  }
}

export const getReviewAttendanceFail = message => {
  return {
    type: GET_REVIEW_ATTENDANCE_FAIL,
    payload: { message },
  }
}




