import {
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: false,
  username: "",
  token: "",
  email: "",
  userrole: "",
  id: "",
  error: "",
}

const login = (state = initialState, action) => {
  // console.log(`login ~ action`, action.payload)
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
        error: "",
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        username: action.payload.username,
        token: action.payload.token,
        email: action.payload.email,
        id: action.payload.id,
        userrole: action.payload.role,
        loading: false,
        error: "",
      }
      break
    case LOGIN_USER_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      }
      break
    case LOGOUT_USER:
      state = {
        loading: false,
        username: "",
        token: "",
        email: "",
        userrole: "",
        id: "",
        error: "",
      }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state }
      break

    default:
      state = state
      break
  }
  return state
}

export default login
