import React from "react"
import "./result-under-process.scss"
import emptyImage from "./serviceImg.svg"
const ResultUnderProcess = () => {
  return (
    <React.Fragment>
      <div className="result-under-process-inner-wrap text-center">
        <div className="">
          <img src={emptyImage} alt="" />
          <h3>Result under process</h3>
          <p>Please wait. It will show when teacher give mark.</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ResultUnderProcess
