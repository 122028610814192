import { ASSIGN_ISSUE, ASSIGN_ISSUE_FAIL, ASSIGN_ISSUE_SUCCESS, GET_CHAT_USER, GET_CHAT_USER_FAIL, GET_CHAT_USER_SUCCESS, GET_ISSUE_LIST, GET_ISSUE_LIST_FAIL, GET_ISSUE_LIST_SUCCESS, GET_SINGLE_USER_MESSAGE, GET_SINGLE_USER_MESSAGE_FAIL, GET_SINGLE_USER_MESSAGE_SUCCESS, REPLY_USER, REPLY_USER_FAIL, REPLY_USER_SUCCESS, SEEN_MESSAGE, SEEN_MESSAGE_FAIL, SEEN_MESSAGE_SUCCESS, UPDATE_ISSUE, UPDATE_ISSUE_FAIL, UPDATE_ISSUE_SUCCESS } from "./actionTypes"

export const replyToUser = (values, history, token, chatId, senderId, type) => {
    return {
      type: REPLY_USER,
      payload: { values, history, token, chatId, senderId, type},
    }
  }
  export const replyToUserSuccess = message => {
    return {
      type: REPLY_USER_SUCCESS,
      payload: { message },
    }
  }
  
  export const replyToUserFail = message => {
    return {
      type: REPLY_USER_FAIL,
      payload: { message },
    }
  }
 

  export const seenMessage = (values, history, token, chatId, messageId) => {
    return {
      type: SEEN_MESSAGE,
      payload: { values, history, token, chatId, messageId},
    }
  }
  export const seenMessageSuccess = message => {
    return {
      type: SEEN_MESSAGE_SUCCESS,
      payload: { message },
    }
  }
  
  export const seenMessageFail = message => {
    return {
      type: SEEN_MESSAGE_FAIL,
      payload: { message },
    }
  }
 
  export const getUserChat = (token, skipNumber) => {
    return {
      type: GET_CHAT_USER,
      payload: { token, skipNumber},
    }
  }
  
  export const getUserChatSuccess = values => {
    return {
      type: GET_CHAT_USER_SUCCESS,
      payload: { values },
    }
  }
  
  export const getUserChatFail = message => {
    return {
      type: GET_CHAT_USER_FAIL,
      payload: { message },
    }
  }

  export const getSingleChat = (token, chatId, type) => {
    return {
      type: GET_SINGLE_USER_MESSAGE,
      payload: { token, chatId, type},
    }
  }
  
  export const getSingleChatSuccess = values => {
    return {
      type: GET_SINGLE_USER_MESSAGE_SUCCESS,
      payload: { values },
    }
  }
  
  export const getSingleChatFail = message => {
    return {
      type: GET_SINGLE_USER_MESSAGE_FAIL,
      payload: { message },
    }
  }

  export const assignIssue = (values, history, token, chatId, userId, type, setShow) => {
    return {
      type: ASSIGN_ISSUE,
      payload: { values, history, token, chatId, userId, type, setShow},
    }
  }
  export const assignIssueSuccess = message => {
    return {
      type: ASSIGN_ISSUE_SUCCESS,
      payload: { message },
    }
  }
  
  export const assignIssueFail = message => {
    return {
      type: ASSIGN_ISSUE_FAIL,
      payload: { message },
    }
  }
  export const getIssueList = (token, skipNumber, status) => {
    return {
      type: GET_ISSUE_LIST,
      payload: { token, skipNumber, status},
    }
  }
  
  export const getIssueListSuccess = values => {
    return {
      type: GET_ISSUE_LIST_SUCCESS,
      payload: { values },
    }
  }
  
  export const getIssueListFail = message => {
    return {
      type: GET_ISSUE_LIST_FAIL,
      payload: { message },
    }
  }
  export const updateIssue = (values, history, token, chatId, userId, type, setShow) => {
    return {
      type: UPDATE_ISSUE,
      payload: { values, history, token, chatId, userId, type, setShow},
    }
  }
  export const updateIssueSuccess = message => {
    return {
      type: UPDATE_ISSUE_SUCCESS,
      payload: { message },
    }
  }
  
  export const updateIssueFail = message => {
    return {
      type: UPDATE_ISSUE_FAIL,
      payload: { message },
    }
  }