import * as Yup from "yup"

const mcqSchema = Yup.object({
  q: Yup.string().required("This value is Required"),
  opt: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  answer: Yup.string()
    // .oneOf([Yup.ref("opt")], "Answer is not matched with options")
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const multipleQuesSchema = Yup.object({
  q: Yup.string().required("This value is Required"),
  opt: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  answer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    // .oneOf([Yup.ref("opt")], "Answer is not matched with options")
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const trueFalseSchema = Yup.object({
  q: Yup.string().required("This value is Required"),
  answer: Yup.string().required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const fillInTheBlanksWithOption = Yup.object({
  q: Yup.string().required("This value is Required"),
  answer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    // .oneOf([Yup.ref("opt")], "Answer is not matched with options")
    .required("This value is Required"),
  option: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const fillInTheBlanksWith = Yup.object({
  q: Yup.string().required("This value is Required"),
  answer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const fillInTheBlanksListening = Yup.object({
  q: Yup.string(),
  answer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const shortAnswer = Yup.object({
  q: Yup.string(),
  answer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})

const headingMatchingSchema = Yup.object({
  q: Yup.string().required("This value is Required"),
  answer: Yup.string()
    // .oneOf([Yup.ref("options")], "Answer is not matched with options")
    .required("This value is Required"),
  options: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
})
const QuantativeWritingQuesSchema = Yup.object({
  q: Yup.string().required("This value is Required"),
  difficultyLevel: Yup.string().required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
  questype: Yup.string().required("This value is Required"),
  // options: Yup.array()
  //   .of(
  //     Yup.object({
  //       value: Yup.string().required("This value is Required"),
  //       isImage: Yup.boolean(),
  //     })
  //   )
  //   .required("This value is Required"),
  rightAnswer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
})

const VerbalQuesSchema = Yup.object({
  q: Yup.string().required("This value is Required"),
  difficultyLevel: Yup.string().required("This value is Required"),
  // section: Yup.string().required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
  questype: Yup.string().required("This value is Required"),
  options: Yup.array()
    .of(
      Yup.object({
        value: Yup.string().required("This value is Required"),
        isImage: Yup.boolean(),
      })
    )
    .required("This value is Required"),
  rightAnswer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
})
const VerbalReadingPassageSchema = Yup.object({
  difficultyLevel: Yup.string().required("This value is Required"),
  questionTitle: Yup.string().required("This value is Required"),
  description: Yup.string().required("This value is Required"),
  questionQuant: Yup.string().required("This value is Required"),
})
const ReadingQuesSchema = Yup.object({
  q: Yup.string().required("This value is Required"),
  questype: Yup.string().required("This value is Required"),
  marks: Yup.string().required("This value is Required"),
  explanation: Yup.string().required("This value is Required"),
  questype: Yup.string().required("This value is Required"),
  options: Yup.array()
    .of(
      Yup.object({
        value: Yup.string().required("This value is Required"),
        isImage: Yup.boolean(),
      })
    )
    .required("This value is Required"),
  rightAnswer: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
})

const TwoPartCreateSchema = Yup.object({
  questionDifficulties: Yup.string().required("This value is Required"),
  description: Yup.string().required("This value is Required"),
  descriptionHeader: Yup.string().required("This value is Required"),
  questionHeading: Yup.string().required("This value is Required"),
  columnNameList: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  rowDataList: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
 
  options: Yup.array()
    .of(
      Yup.object({
        option: Yup.string().required("This value is Required"),
        answer: Yup.string().required("This value is Required"),
      })
    )
    .required("This value is Required"),
})
const TableAnalysisCreateSchema = Yup.object({
  description: Yup.string().required("This value is Required"),
  descriptionHeader: Yup.string().required("This value is Required"),
  questionHeading: Yup.string().required("This value is Required"),
  questionDifficulties: Yup.string().required("This value is Required"),
  selection: Yup.string().required("This value is Required"),
  columnNameList: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  rowDataList: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  // answerOptions: Yup.array()
  //   .of(Yup.string().required("This value is Required"))
  //   .required("This value is Required"),
  options: Yup.array()
    .of(
      Yup.object({
        option: Yup.string().required("This value is Required"),
        answer: Yup.string().required("This value is Required"),
      })
    )
    .required("This value is Required"),
})

const graphQuestionCreateSchema = Yup.object({
  questionDifficulties: Yup.string().required("This value is Required"),
  description: Yup.string().required("This value is Required"),
  descriptionHeader: Yup.string().required("This value is Required"),
  questionHeading: Yup.string().required("This value is Required"),
  selection: Yup.string().required("This value is Required"),


  options: Yup.array()
    .of(
      Yup.object({
        option: Yup.string().required("This value is Required"),
        answer: Yup.string().required("This value is Required"),
      })
    )
    .required("This value is Required"),
})
const multiSourceSchema = Yup.object({
  questionDifficulties: Yup.string().required("This value is Required"),
  questionHeading: Yup.string().required("This value is Required"),
  selection: Yup.string().required("This value is Required"),


  options: Yup.array()
    .of(
      Yup.object({
        option: Yup.string().required("This value is Required"),
        answer: Yup.string().required("This value is Required"),
      })
    )
    .required("This value is Required"),
    multiSource: Yup.array()
    .of(
      Yup.object({
        heading: Yup.string().required("This value is Required"),
        message: Yup.string().required("This value is Required"),
      })
    )
    .required("This value is Required"),
})
export {
  mcqSchema,
  multipleQuesSchema,
  trueFalseSchema,
  fillInTheBlanksWithOption,
  fillInTheBlanksWith,
  headingMatchingSchema,
  fillInTheBlanksListening,
  shortAnswer,
  QuantativeWritingQuesSchema,
  VerbalQuesSchema,
  VerbalReadingPassageSchema,
  ReadingQuesSchema,
  TwoPartCreateSchema,
  TableAnalysisCreateSchema,
  graphQuestionCreateSchema,
  multiSourceSchema
}
