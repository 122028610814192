import {
  ADD_PACKAGE,
  ADD_PACKAGE_FAIL,
  ADD_PACKAGE_SUCCESS,
  DELETE_PACKAGE,
  DELETE_PACKAGE_FAIL,
  DELETE_PACKAGE_SUCCESS,
  GET_PACKAGES,
  GET_PACKAGES_FAIL,
  GET_PACKAGES_SUCCESS,
  GET_SINGLE_PACKAGE,
  GET_SINGLE_PACKAGE_FAIL,
  GET_SINGLE_PACKAGE_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: false,
  singleLoading: false,
  allPackage: [],
  singlePackage: [],
  value: {},
  message: "",
  error: "",
}

const packageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PACKAGE:
      return {
        ...state,
        loading: true,
      }
    case ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case ADD_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case DELETE_PACKAGE:
      return {
        ...state,
        singleLoading: true,
      }
    case DELETE_PACKAGE_SUCCESS:
      return {
        ...state,
        singleLoading: false,
      }
    case DELETE_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        singleLoading: false,
      }
    case GET_PACKAGES_SUCCESS:
      return {
        ...state,
        allPackage: action.payload.data,
        loading: false,
      }
    case GET_PACKAGES:
      return {
        ...state,
        loading: true,
      }
    case GET_PACKAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_SINGLE_PACKAGE_SUCCESS:
      return {
        ...state,
        singlePackage: action.payload.data,
        singleLoading: false,
      }
    case GET_SINGLE_PACKAGE:
      return {
        ...state,
        singleLoading: true,
      }
    case GET_SINGLE_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        singleLoading: false,
      }
    default:
      return state
  }
}

export default packageReducer
