import React from "react";
import { Progress } from "reactstrap";
const Loader = ({
  className,
  text = "",
  isProgress,
  progress,
  onCancelApi,
}) => {
  return (
    <React.Fragment>
      <div className={className}>
        <div className="d-flex justify-content-center align-items-center text-dark gap-2">
          <i
            className="bx bx-loader bx-spin fs-3"
            style={{ color: "#5D5D5D" }}
          ></i>{" "}
          {text || "Loading..."}{" "}
          {/* {isProgress ? (
            <span className="cursor_pointers text_hover" onClick={onCancelApi}>
              Cancel
            </span>
          ) : null} */}
        </div>
        {isProgress ? (
          <Progress
            color={progress === 100 ? "success" : "info"}
            value={progress}
            style={{ width: "100%" }}
          >
            {`${progress}%`}
          </Progress>
        ) : null}
      </div>
    </React.Fragment>
  );
};
export default Loader;
