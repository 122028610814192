import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { calculateTimeCount, findTime } from "helpers/Custom/timeHelper"
import React, { useEffect, useMemo } from "react"
import { Row } from "react-bootstrap"
import Timer from "react-compound-timer/build"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import {
  getFacilitatorSingleDay,
  getReviewAttendance,
} from "store/Facilitator/actions"
import { storeSlot, teacherStartExam } from "store/SpeakingQuestion/actions"
import InnerLayer from "../../Layout/InnerLayer"
import googleMeetIcon from "./img/Google_Meet 1.png"
import "./teacher-review.scss"

const FacilitatorReview = ({ history }) => {
  const { date, slotId, packageId, studentId } = useParams()
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Teacher Exam",
      link: `/teacher-exam`,
    },
    {
      title: "View time slot",
      link: `/teacher-exam/view-class/${date}`,
    },
    {
      title: "Review",
      link: `#`,
    },
  ]

  const dispatch = useDispatch()
  const {
    authToken,
    loading,
    FacilitatorSingleDay,
    adding,
    role,
    profile,
    reviewAttendance,
    attendanceLoading,
  } = useSelector(state => ({
    authToken: state.Login.token,
    role: state.Login.userrole,
    loading: state.FacilitatorReducer.loading,
    FacilitatorSingleDay: state.FacilitatorReducer.FacilitatorSingleDay,
    reviewAttendance: state.FacilitatorReducer.reviewAttendance,
    attendanceLoading: state.FacilitatorReducer.attendanceLoading,
    adding: state.speakingQues.adding,
    profile: state.Profile,
  }))

  console.log("FacilitatorSingleDay", FacilitatorSingleDay)
  console.log(`loading`, profile)

  const findSlot = FacilitatorSingleDay?.find(item => item._id === slotId) || {}
  console.log(`FacilitatorReview ~ findSlot`, findSlot)
  console.log("packageId", packageId)
  useEffect(() => {
    dispatch(storeSlot("adding", false))
    dispatch(getFacilitatorSingleDay(authToken, { date, role }))
  }, [])

  const handleGotoMeet = () => {
    dispatch(teacherStartExam(authToken, slotId, findSlot?.teacherId?.meetLink))
  }

  const updateAttend = data => {
    console.log("data", data)
    const value = { attandance: data }
    if (FacilitatorSingleDay[0]?._id) {
      console.log("hellokkkk")
      dispatch(
        getReviewAttendance(authToken, value, FacilitatorSingleDay[0]?._id, {
          date,
          role,
        })
      )
    }
  }

  const findTimeAvailable = useMemo(
    () => findTime(findSlot?.meetingTime, findSlot, date),
    [findSlot]
  )
  // console.log(`findTimeAvailable`, findTimeAvailable)

  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Facilitator"
        isMultiple
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>Time Slot</h4>
            </div>
          </CardComponent>
        </Row>
        {loading ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : (
          <div className="teacher-speaking-exam-review-wrap">
            <div className="speaking-exam-main-wrap mt-3">
              <div className="speaking-exam-date-bottom-wrap">
                <div className="speaking-package-listed-view-wrap">
                  <h3>Give your Review</h3>
                  <h4>
                    <Timer
                      initialTime={
                        !!findSlot ? calculateTimeCount(findSlot) : 0
                      }
                      direction="backward"
                    >
                      <span>
                        <Timer.Days />D :
                      </span>
                      <span>
                        <Timer.Hours />H :
                      </span>
                      <span>
                        <Timer.Minutes />M :
                      </span>
                      <span>
                        <Timer.Seconds />S
                      </span>
                    </Timer>
                  </h4>
                  {/* <h4>
                    <span>03D</span>
                    <span>:</span>
                    <span>22H</span>
                    <span>:</span>
                    <span>22M</span>
                    <span>:</span>
                    <span>40S</span>
                  </h4> */}
                  {/* <p>
                    sed quia non numquam eius modi tempora incidunt ut labore et
                    dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
                    veniam.
                  </p> */}
                  <div className="exam-google-meet-link-button">
                    {FacilitatorSingleDay[0]?.attandance !== 1 ? (
                      <a
                        href={profile?.data?.meetLink}
                        target="_blank"
                        rel="noreferrer"
                        className="meet-button"
                      >
                        <span>
                          <img src={googleMeetIcon} alt="" />
                        </span>
                        <span>Start meeting</span>
                      </a>
                    ) : (
                      <span></span>
                    )}
                    <button
                      className="meet-button"
                      onClick={() =>
                        history.push(
                          `/facilitator-slot/${packageId}/${studentId}`
                        )
                      }
                    >
                      <span>{/* <img src={googleMeetIcon} alt="" /> */}</span>
                      <span>View Package</span>
                    </button>
                    {FacilitatorSingleDay[0]?.attandance !== 1 ? (
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => updateAttend(1)}
                        disabled={attendanceLoading}
                      >
                        <span>{/* <img src={googleMeetIcon} alt="" /> */}</span>

                        <span>
                          {attendanceLoading === true
                            ? "Submitted"
                            : "Give Attendance"}
                        </span>
                      </button>
                    ) : (
                      <button className="btn btn-outline-success">
                        <span>Present</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(FacilitatorReview)
