import React from "react"

const ChatSendIcon = () => {
  return (
    <React.Fragment>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.1372 2.91155L7.10719 5.86237C1.03719 7.85909 1.03719 11.1148 7.10719 13.1017L9.78719 13.9771L10.6772 16.6132C12.6972 22.5837 16.0172 22.5837 18.0372 16.6132L21.0472 7.74106C22.3872 3.75745 20.1872 1.58368 16.1372 2.91155ZM16.4572 8.20335L12.6572 11.9607C12.5072 12.1083 12.3172 12.1771 12.1272 12.1771C11.9372 12.1771 11.7472 12.1083 11.5972 11.9607C11.3072 11.6755 11.3072 11.2034 11.5972 10.9181L15.3972 7.16073C15.6872 6.87548 16.1672 6.87548 16.4572 7.16073C16.7472 7.44598 16.7472 7.91811 16.4572 8.20335Z"
          fill="#929AA0"
          fillOpacity="0.6"
        />
      </svg>
    </React.Fragment>
  )
}

export default ChatSendIcon
