import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import { toaster } from "helpers/Custom/Toaster"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { Alert, Col, Label, Row } from "reactstrap"
import { addFaqInfo, getFAQtype } from "store/actions"
import InnerLayer from "../../Layout/InnerLayer"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Faq type",
    link: "/faqType",
  },
  {
    //   title: `${id ? "Update" : "Create"} FAQ type`,
    title: `Create FAQ type`,
    link: "#",
  },
]
const CreateListFAQ = ({ history }) => {
  const dispatch = useDispatch()
  const [faqType, setFaqType] = useState("")
  const { authToken, loading, error, adding, singleCoupon, faqTypeList } =
    useSelector(state => ({
      authToken: state.Login.token,
      faqTypeList: state.FAQReducer.faqTypeList,
      faqTypeListLoading: state.FAQReducer.faqTypeListLoading,
      adding: state.FAQReducer.adding,
    }))

  const handleSubmit = (event, errors, values) => {
    event.preventDefault()
    if (errors.length > 0) {
      toaster("warning", "Please Fill Up all the values")
      return
    }
    let body = {
      question: values?.question,
      answer: values?.answer,
      faqType: faqType,
    }
    dispatch(addFaqInfo(body, history, authToken))

    console.log("hello", body)
  }

  useEffect(() => {
    dispatch(getFAQtype(authToken))
  }, [])
  const handleFaqType = data => {
    if (data) {
      setFaqType(data?._id)
    } else {
      setFaqType("")
    }
  }
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        // title={id ? "Edit FAQ Type" : "Create FAQ type"}
        title={"Create FAQ type"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row className="mt-3">
          <Col md="6" className="offset-md-3">
            <CardComponent>
              {/* <h5>{id ? "Update" : "Create"} FAQ type</h5> */}
              <h5>Create FAQ</h5>
              {error ? <Alert color="danger">{error}</Alert> : null}

              <AvForm className="mt-4" onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <AvField
                    name="question"
                    label="Question"
                    type="text"
                    errorMessage="Type Question"
                    placeholder="Type Question"
                    //   value={singleCoupon?.couponCode}
                    //   disabled={id}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Row>
                <Row className="mb-3">
                  <AvField
                    name="answer"
                    label="Answer"
                    type="textarea"
                    errorMessage="Type Answer"
                    placeholder="Type Answer"
                    //   value={singleCoupon?.couponCode}
                    //   disabled={id}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Row>

                <Row>
                  <div className="mb-3">
                    <Label className="control-label">FAQ Type</Label>
                    <Select
                      cacheOptions
                      getOptionLabel={e => e?.typeName}
                      getOptionValue={e => e?._id}
                      options={faqTypeList}
                      isClearable
                      onChange={handleFaqType}
                      // value={faqType}

                      // isDisabled={hiddenValue===false}
                      // isMulti
                    />
                  </div>
                </Row>
                <div>
                  <button
                    disabled={adding}
                    type="submit"
                    className="btn btn-primary w-md"
                    style={{ background: "rgba(0, 82, 204, 1)" }}
                  >
                    {adding ? "Submitting..." : "Create FAQ"}
                  </button>
                </div>
              </AvForm>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default CreateListFAQ
