import {
  ADD_COURSE,
  ADD_COURSE_FAIL,
  ADD_COURSE_SUCCESS,
  GET_COURSE,
  GET_COURSE_FAIL,
  GET_COURSE_SUCCESS,
  GET_SINGLE_COURSE,
  GET_SINGLE_COURSE_FAIL,
  GET_SINGLE_COURSE_SUCCESS,
} from "./actionTypes"

export const addCourse = (data, token, history, id) => ({
  type: ADD_COURSE,
  payload: { data, token, history, id },
})

export const addCourseSuccess = data => ({
  type: ADD_COURSE_SUCCESS,
  payload: { data },
})

// charts data
export const addCourseFail = data => ({
  type: ADD_COURSE_FAIL,
  payload: { data },
})

export const getCourse = token => ({
  type: GET_COURSE,
  payload: { token },
})

export const getCourseSuccess = data => ({
  type: GET_COURSE_SUCCESS,
  payload: { data },
})

// charts data
export const getCourseFail = data => ({
  type: GET_COURSE_FAIL,
  payload: { data },
})

export const getSingleCourse = (token, id) => ({
  type: GET_SINGLE_COURSE,
  payload: { token, id },
})

export const getSingleCourseSuccess = data => ({
  type: GET_SINGLE_COURSE_SUCCESS,
  payload: { data },
})

// charts data
export const getSingleCourseFail = data => ({
  type: GET_SINGLE_COURSE_FAIL,
  payload: { data },
})
