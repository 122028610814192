import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import {
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import { addListeningSlice } from "store/actions"
const CreateSlice = ({ toggle, isOpen, edit, editData }) => {
  console.log(
    `🦄 ~ file: CreateSlice.js ~ line 17 ~ CreateSlice ~ editData`,
    editData
  )
  const [file, setFile] = useState([])
  const [fileError, setFileError] = useState("")
  const dispatch = useDispatch()
  const { setId, id } = useParams()
  const { authToken, adding } = useSelector(state => ({
    listeningAudio: state.listeningSet.listAudio,
    authToken: state.Login.token,
    loading: state.listeningSet.loading,
    adding: state.listeningSet.adding,
    listeningSet: state.listeningSet.listeningSet,
  }))
  const handleSubmit = (e, errors, values) => {
    console.log("error")
    if (edit) {
      return
    }
    if (file.length === 0) {
      setFileError("Upload Audio")
      return
    }
    e.preventDefault()
    if (errors.length > 0) {
      return
    }
    const { totalQuestion, audioTopicName } = values
    const body = {
      audioTopicName,
      totalQuestion: totalQuestion * 1,
      audiofile: file?.[0],
    }
    const fd = new FormData()
    fd.append("audioTopicName", audioTopicName)
    fd.append("totalQuestion", totalQuestion)
    fd.append("audio", file?.[0])
    const options = {
      setId,
      toggle,
      token: authToken,
      audioId: edit ? id : "",
    }
    console.log(body, options)
    dispatch(addListeningSlice(fd, options))
  }
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered
        size="md"
        style={{ minWidth: "600px" }}
      >
        <div
          className="d-flex justify-content-between align-items-center w-100 p-2"
          style={{ background: "#F4F9FF" }}
        >
          <h6>Create Slice</h6>
          <span onClick={toggle}>
            <i className="bx bx-x fs-2"></i>
          </span>
        </div>
        <ModalBody>
          <AvForm className="mt-3" onSubmit={handleSubmit}>
            <Row className="mb-2">
              <Col>
                <AvField
                  type="text"
                  name="audioTopicName"
                  placeholder="Title"
                  label="Title"
                  value={editData?.audioTopicName}
                  required
                />
              </Col>
              {/* <Col sm="1"></Col> */}
              <Col>
                <AvField
                  type="number"
                  name="totalQuestion"
                  placeholder="Total question"
                  readOnly
                  defaultValue="10"
                  label="Total Question"
                  value={editData?.totalQuestion}
                  required
                />
              </Col>
            </Row>

            {/*  */}

            <Row className="mb-2">
              <Col>
                <FormGroup>
                  <Label>Upload Audio</Label>
                  <Input
                    type="file"
                    onChange={e => {
                      setFileError("")
                      setFile(e.target.files)
                    }}
                    // value={editData?.audioUrl}
                    className="d-block"
                    invalid={fileError && file?.length === 0}
                  />
                  {fileError ? (
                    <FormFeedback type="invalid">{fileError}</FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>

            <div className="d-flex justify-content-end">
              <button
                disabled={adding}
                type="submit"
                className="btn btn-primary w-md "
              >
                {adding
                  ? "Submitting..."
                  : edit
                  ? "Update audio"
                  : "Save audio"}
              </button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default CreateSlice
