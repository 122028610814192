
export const ADD_IELTS_READING_SET = "ADD_IELTS_READING_SET"
export const ADD_IELTS_READING_SET_SUCCESS = "ADD_IELTS_READING_SET_SUCCESS"
export const ADD_IELTS_READING_SET_FAIL = "ADD_IELTS_READING_SET_FAIL"

export const GET_IELTS_READING_SET = "GET_IELTS_READING_SET"
export const GET_IELTS_READING_SET_SUCCESS = "GET_IELTS_READING_SET_SUCCESS"
export const GET_IELTS_READING_SET_FAIL = "GET_IELTS_READING_SET_FAIL"

export const GET_IELTS_SINGLE_READING_SET = "GET_IELTS_SINGLE_READING_SET"
export const GET_IELTS_SINGLE_READING_SET_SUCCESS = "GET_IELTS_SINGLE_READING_SET_SUCCESS"
export const GET_IELTS_SINGLE_READING_SET_FAIL = "GET_IELTS_SINGLE_READING_SET_FAIL"

export const GET_READING_SET_SINGLE = "GET_READING_SET_SINGLE"
export const GET_READING_SET_SINGLE_SUCCESS = "GET_READING_SET_SINGLE_SUCCESS"
export const GET_READING_SET_SINGLE_FAIL = "GET_READING_SET_SINGLE_FAIL"

//Reading Passage 

export const ADD_IELTS_READING_PASSAGE = "ADD_IELTS_READING_PASSAGE"
export const ADD_IELTS_READING_PASSAGE_SUCCESS = "ADD_IELTS_READING_PASSAGE_SUCCESS"
export const ADD_IELTS_READING_PASSAGE_FAIL = "ADD_IELTS_READING_PASSAGE_FAIL"

export const GET_IELTS_READING_PASSAGE = "GET_IELTS_READING_PASSAGE"
export const GET_IELTS_READING_PASSAGE_SUCCESS = "GET_IELTS_READING_PASSAGE_SUCCESS"
export const GET_IELTS_READING_PASSAGE_FAIL = "GET_IELTS_READING_PASSAGE_FAIL"


export const GET_IELTS_READING_SINGLE_PASSAGE = "GET_IELTS_READING_SINGLE_PASSAGE"
export const GET_IELTS_READING_SINGLE_PASSAGE_SUCCESS = "GET_IELTS_READING_SINGLE_PASSAGE_SUCCESS"
export const GET_IELTS_READING_SINGLE_PASSAGE_FAIL = "GET_IELTS_READING_SINGLE_PASSAGE_FAIL"

export const GET_READING_PASSAGE_SINGLE = "GET_READING_PASSAGE_SINGLE"
export const GET_READING_PASSAGE_SIGLE_SUCCESS = "GET_READING_PASSAGE_SINGLE_SUCCESS"
export const GET_READING_PASSAGE_SIGLE_FAIL = "GET_READING_PASSAGE_SINGLE_FAIL"