/* eslint-disable react/react-in-jsx-scope */
import Loader from "components/Common/Loader"
import { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { getPackageContribution } from "store/actions"
const StudentEnrollment = () => {
  const [status, setStatus] = useState("value")
  const dispatch = useDispatch()
  const {
    authToken,
    valueStatus,
    packageContribution,
    packageContributionLoading,
  } = useSelector(state => ({
    authToken: state.Login.token,
    role: state.Login.userrole,
    packageContribution: state.AdminDashboardReducer.packageContribution,
    packageContributionLoading:
      state.AdminDashboardReducer.packageContributionLoading,
    valueStatus: state.AdminDashboardReducer.valueStatus,
  }))
  console.log("packageContribution", packageContribution)
  console.log(
    "packageContribution3432",
    Math.ceil(
      (packageContribution?.obj?.["Own Package"]["1"] * 100) /
        packageContribution?.objPermonth?.["1"]
    )
  )
  const series = [
    {
      name: "7+ Guarantee General Package",

      data: [
        Math.ceil(
          (packageContribution?.obj?.["7+ Guarantee General Package"]["12"] *
            100) /
            packageContribution?.objPermonth?.["12"] || 0
        ),
        Math.ceil(
          (packageContribution?.obj?.["7+ Guarantee General Package"]["1"] *
            100) /
            packageContribution?.objPermonth?.["1"] || 0
        ),
        Math.ceil(
          (packageContribution?.obj?.["7+ Guarantee General Package"]["2"] *
            100) /
            packageContribution?.objPermonth?.["2"] || 0
        ),
        Math.ceil(
          (packageContribution?.obj?.["7+ Guarantee General Package"]["3"] *
            100) /
            packageContribution?.objPermonth?.["3"] || 0
        ),
        Math.ceil(
          (packageContribution?.obj?.["7+ Guarantee General Package"]["4"] *
            100) /
            packageContribution?.objPermonth?.["4"] || 0
        ),
        Math.ceil(
          (packageContribution?.obj?.["7+ Guarantee General Package"]["5"] *
            100) /
            packageContribution?.objPermonth?.["5"] || 0
        ),
        Math.ceil(
          (packageContribution?.obj?.["7+ Guarantee General Package"]["6"] *
            100) /
            packageContribution?.objPermonth?.["6"] || 0
        ),
        Math.ceil(
          (packageContribution?.obj?.["7+ Guarantee General Package"]["7"] *
            100) /
            packageContribution?.objPermonth?.["7"] || 0
        ),
        Math.ceil(
          (packageContribution?.obj?.["7+ Guarantee General Package"]["8"] *
            100) /
            packageContribution?.objPermonth?.["8"] || 0
        ),
        Math.ceil(
          (packageContribution?.obj?.["7+ Guarantee General Package"]["9"] *
            100) /
            packageContribution?.objPermonth?.["9"] || 0
        ),
        Math.ceil(
          (packageContribution?.obj?.["7+ Guarantee General Package"]["10"] *
            100) /
            packageContribution?.objPermonth?.["10"] || 0
        ),
        Math.ceil(
          (packageContribution?.obj?.["7+ Guarantee General Package"]["11"] *
            100) /
            packageContribution?.objPermonth?.["11"] || 0
        ),
    
      ],
      color: "#0E8AE3",
    },
    {
      name: "Academic Listening",

      data: [
        Math.ceil(
          (packageContribution?.obj?.["Academic Listening"]["12"] * 100) /
            packageContribution?.objPermonth?.["12"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Listening"]["1"] * 100) /
            packageContribution?.objPermonth?.["1"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Listening"]["2"] * 100) /
            packageContribution?.objPermonth?.["2"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Listening"]["3"] * 100) /
            packageContribution?.objPermonth?.["3"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Listening"]["4"] * 100) /
            packageContribution?.objPermonth?.["4"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Listening"]["5"] * 100) /
            packageContribution?.objPermonth?.["5"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Listening"]["6"] * 100) /
            packageContribution?.objPermonth?.["6"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Listening"]["7"] * 100) /
            packageContribution?.objPermonth?.["7"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Listening"]["8"] * 100) /
            packageContribution?.objPermonth?.["8"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Listening"]["9"] * 100) /
            packageContribution?.objPermonth?.["9"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Listening"]["10"] * 100) /
            packageContribution?.objPermonth?.["10"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Listening"]["11"] * 100) /
            packageContribution?.objPermonth?.["11"]
        ) || 0,
      
      ],
      color: "#F2516C",
    },
    {
      name: "Academic Reading",
      data: [
        Math.ceil(
          (packageContribution?.obj?.["Academic Reading"]["12"] * 100) /
            packageContribution?.objPermonth?.["12"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Reading"]["1"] * 100) /
            packageContribution?.objPermonth?.["1"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Reading"]["2"] * 100) /
            packageContribution?.objPermonth?.["2"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Reading"]["3"] * 100) /
            packageContribution?.objPermonth?.["3"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Reading"]["4"] * 100) /
            packageContribution?.objPermonth?.["4"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Reading"]["5"] * 100) /
            packageContribution?.objPermonth?.["5"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Reading"]["6"] * 100) /
            packageContribution?.objPermonth?.["6"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Reading"]["7"] * 100) /
            packageContribution?.objPermonth?.["7"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Reading"]["8"] * 100) /
            packageContribution?.objPermonth?.["8"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Reading"]["9"] * 100) /
            packageContribution?.objPermonth?.["9"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Reading"]["10"] * 100) /
            packageContribution?.objPermonth?.["10"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Reading"]["11"] * 100) /
            packageContribution?.objPermonth?.["11"]
        ) || 0,
      
      ],
      color: "#80B3FF",
    },
    {
      name: "Academic Writing",
      data: [
        Math.ceil(
          (packageContribution?.obj?.["Academic Writing"]["12"] * 100) /
            packageContribution?.objPermonth?.["12"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Writing"]["1"] * 100) /
            packageContribution?.objPermonth?.["1"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Writing"]["2"] * 100) /
            packageContribution?.objPermonth?.["2"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Writing"]["3"] * 100) /
            packageContribution?.objPermonth?.["3"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Writing"]["4"] * 100) /
            packageContribution?.objPermonth?.["4"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Writing"]["5"] * 100) /
            packageContribution?.objPermonth?.["5"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Writing"]["6"] * 100) /
            packageContribution?.objPermonth?.["6"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Writing"]["7"] * 100) /
            packageContribution?.objPermonth?.["7"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Writing"]["8"] * 100) /
            packageContribution?.objPermonth?.["8"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Writing"]["9"] * 100) /
            packageContribution?.objPermonth?.["9"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Writing"]["10"] * 100) /
            packageContribution?.objPermonth?.["10"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Writing"]["11"] * 100) /
            packageContribution?.objPermonth?.["11"]
        ) || 0,
      
      ],
      color: "#F1B44C",
    },
    {
      name: "Academic Speaking",
      data: [
        Math.ceil(
          (packageContribution?.obj?.["Academic Speaking"]["12"] * 100) /
            packageContribution?.objPermonth?.["12"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Speaking"]["1"] * 100) /
            packageContribution?.objPermonth?.["1"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Speaking"]["2"] * 100) /
            packageContribution?.objPermonth?.["2"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Speaking"]["3"] * 100) /
            packageContribution?.objPermonth?.["3"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Speaking"]["4"] * 100) /
            packageContribution?.objPermonth?.["4"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Speaking"]["5"] * 100) /
            packageContribution?.objPermonth?.["5"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Speaking"]["6"] * 100) /
            packageContribution?.objPermonth?.["6"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Speaking"]["7"] * 100) /
            packageContribution?.objPermonth?.["7"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Speaking"]["8"] * 100) /
            packageContribution?.objPermonth?.["8"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Speaking"]["9"] * 100) /
            packageContribution?.objPermonth?.["9"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Speaking"]["10"] * 100) /
            packageContribution?.objPermonth?.["10"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Academic Speaking"]["11"] * 100) /
            packageContribution?.objPermonth?.["11"]
        ) || 0,
      
      ],
      color: "#34C38F",
    },
    {
      name: "Basic Academic Package",

      data: [
        Math.ceil(
          (packageContribution?.obj?.["Basic Academic Package"]["12"] * 100) /
            packageContribution?.objPermonth?.["12"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic Academic Package"]["1"] * 100) /
            packageContribution?.objPermonth?.["1"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic Academic Package"]["2"] * 100) /
            packageContribution?.objPermonth?.["2"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic Academic Package"]["3"] * 100) /
            packageContribution?.objPermonth?.["3"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic Academic Package"]["4"] * 100) /
            packageContribution?.objPermonth?.["4"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic Academic Package"]["5"] * 100) /
            packageContribution?.objPermonth?.["5"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic Academic Package"]["6"] * 100) /
            packageContribution?.objPermonth?.["6"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic Academic Package"]["7"] * 100) /
            packageContribution?.objPermonth?.["7"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic Academic Package"]["8"] * 100) /
            packageContribution?.objPermonth?.["8"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic Academic Package"]["9"] * 100) /
            packageContribution?.objPermonth?.["9"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic Academic Package"]["10"] * 100) /
            packageContribution?.objPermonth?.["10"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic Academic Package"]["11"] * 100) /
            packageContribution?.objPermonth?.["11"]
        ) || 0,
   
      ],
      color: "#8C6E63",
    },
    {
      name: "Advance Academic Package",
      data: [
        Math.ceil(
          (packageContribution?.obj?.["Advance Academic Package"]["12"] * 100) /
            packageContribution?.objPermonth?.["12"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance Academic Package"]["1"] * 100) /
            packageContribution?.objPermonth?.["1"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance Academic Package"]["2"] * 100) /
            packageContribution?.objPermonth?.["2"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance Academic Package"]["3"] * 100) /
            packageContribution?.objPermonth?.["3"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance Academic Package"]["4"] * 100) /
            packageContribution?.objPermonth?.["4"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance Academic Package"]["5"] * 100) /
            packageContribution?.objPermonth?.["5"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance Academic Package"]["6"] * 100) /
            packageContribution?.objPermonth?.["6"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance Academic Package"]["7"] * 100) /
            packageContribution?.objPermonth?.["7"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance Academic Package"]["8"] * 100) /
            packageContribution?.objPermonth?.["8"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance Academic Package"]["9"] * 100) /
            packageContribution?.objPermonth?.["9"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance Academic Package"]["10"] * 100) /
            packageContribution?.objPermonth?.["10"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance Academic Package"]["11"] * 100) /
            packageContribution?.objPermonth?.["11"]
        ) || 0,
       
      ],
      color: "#D81A60",
    },
    {
      name: "General Listening",
      data: [
        Math.ceil(
          (packageContribution?.obj?.["General Listening"]["12"] * 100) /
            packageContribution?.objPermonth?.["12"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Listening"]["1"] * 100) /
            packageContribution?.objPermonth?.["1"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Listening"]["2"] * 100) /
            packageContribution?.objPermonth?.["2"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Listening"]["3"] * 100) /
            packageContribution?.objPermonth?.["3"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Listening"]["4"] * 100) /
            packageContribution?.objPermonth?.["4"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Listening"]["5"] * 100) /
            packageContribution?.objPermonth?.["5"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Listening"]["6"] * 100) /
            packageContribution?.objPermonth?.["6"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Listening"]["7"] * 100) /
            packageContribution?.objPermonth?.["7"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Listening"]["8"] * 100) /
            packageContribution?.objPermonth?.["8"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Listening"]["9"] * 100) /
            packageContribution?.objPermonth?.["9"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Listening"]["10"] * 100) /
            packageContribution?.objPermonth?.["10"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Listening"]["11"] * 100) /
            packageContribution?.objPermonth?.["11"]
        ) || 0,
      
      ],
      color: "#78909C",
    },
    {
      name: "General Reading",
      data: [
        Math.ceil(
          (packageContribution?.obj?.["General Reading"]["12"] * 100) /
            packageContribution?.objPermonth?.["12"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Reading"]["1"] * 100) /
            packageContribution?.objPermonth?.["1"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Reading"]["2"] * 100) /
            packageContribution?.objPermonth?.["2"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Reading"]["3"] * 100) /
            packageContribution?.objPermonth?.["3"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Reading"]["4"] * 100) /
            packageContribution?.objPermonth?.["4"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Reading"]["5"] * 100) /
            packageContribution?.objPermonth?.["5"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Reading"]["6"] * 100) /
            packageContribution?.objPermonth?.["6"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Reading"]["7"] * 100) /
            packageContribution?.objPermonth?.["7"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Reading"]["8"] * 100) /
            packageContribution?.objPermonth?.["8"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Reading"]["9"] * 100) /
            packageContribution?.objPermonth?.["9"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Reading"]["10"] * 100) /
            packageContribution?.objPermonth?.["10"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Reading"]["11"] * 100) /
            packageContribution?.objPermonth?.["11"]
        ) || 0,
     
      ],
      color: "#FB8C00",
    },
    {
      name: "General Writing",
      data: [
        Math.ceil(
          (packageContribution?.obj?.["General Writing"]["12"] * 100) /
            packageContribution?.objPermonth?.["12"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Writing"]["1"] * 100) /
            packageContribution?.objPermonth?.["1"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Writing"]["2"] * 100) /
            packageContribution?.objPermonth?.["2"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Writing"]["3"] * 100) /
            packageContribution?.objPermonth?.["3"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Writing"]["4"] * 100) /
            packageContribution?.objPermonth?.["4"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Writing"]["5"] * 100) /
            packageContribution?.objPermonth?.["5"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Writing"]["6"] * 100) /
            packageContribution?.objPermonth?.["6"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Writing"]["7"] * 100) /
            packageContribution?.objPermonth?.["7"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Writing"]["8"] * 100) /
            packageContribution?.objPermonth?.["8"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Writing"]["9"] * 100) /
            packageContribution?.objPermonth?.["9"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Writing"]["10"] * 100) /
            packageContribution?.objPermonth?.["10"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Writing"]["11"] * 100) /
            packageContribution?.objPermonth?.["11"]
        ) || 0,
      
      ],
      color: "#64B5F6",
    },
    {
      name: "General Speaking",
      data: [
        Math.ceil(
          (packageContribution?.obj?.["General Speaking"]["12"] * 100) /
            packageContribution?.objPermonth?.["12"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Speaking"]["1"] * 100) /
            packageContribution?.objPermonth?.["1"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Speaking"]["2"] * 100) /
            packageContribution?.objPermonth?.["2"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Speaking"]["3"] * 100) /
            packageContribution?.objPermonth?.["3"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Speaking"]["4"] * 100) /
            packageContribution?.objPermonth?.["4"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Speaking"]["5"] * 100) /
            packageContribution?.objPermonth?.["5"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Speaking"]["6"] * 100) /
            packageContribution?.objPermonth?.["6"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Speaking"]["7"] * 100) /
            packageContribution?.objPermonth?.["7"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Speaking"]["8"] * 100) /
            packageContribution?.objPermonth?.["8"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Speaking"]["9"] * 100) /
            packageContribution?.objPermonth?.["9"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Speaking"]["10"] * 100) /
            packageContribution?.objPermonth?.["10"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["General Speaking"]["11"] * 100) /
            packageContribution?.objPermonth?.["11"]
        ) || 0,
      
      ],
      color: "#7E57C2",
    },
    {
      name: "Basic General Package",
      data: [
        Math.ceil(
          (packageContribution?.obj?.["Basic General Package"]["12"] * 100) /
            packageContribution?.objPermonth?.["12"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic General Package"]["1"] * 100) /
            packageContribution?.objPermonth?.["1"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic General Package"]["2"] * 100) /
            packageContribution?.objPermonth?.["2"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic General Package"]["3"] * 100) /
            packageContribution?.objPermonth?.["3"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic General Package"]["4"] * 100) /
            packageContribution?.objPermonth?.["4"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic General Package"]["5"] * 100) /
            packageContribution?.objPermonth?.["5"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic General Package"]["6"] * 100) /
            packageContribution?.objPermonth?.["6"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic General Package"]["7"] * 100) /
            packageContribution?.objPermonth?.["7"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic General Package"]["8"] * 100) /
            packageContribution?.objPermonth?.["8"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic General Package"]["9"] * 100) /
            packageContribution?.objPermonth?.["9"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic General Package"]["10"] * 100) /
            packageContribution?.objPermonth?.["10"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Basic General Package"]["11"] * 100) /
            packageContribution?.objPermonth?.["11"]
        ) || 0,
     
      ],
      color: "#FFEE58",
    },
    {
      name: "Advance General Package",
      data: [
        Math.ceil(
          (packageContribution?.obj?.["Advance General Package"]["12"] * 100) /
            packageContribution?.objPermonth?.["12"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance General Package"]["1"] * 100) /
            packageContribution?.objPermonth?.["1"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance General Package"]["2"] * 100) /
            packageContribution?.objPermonth?.["2"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance General Package"]["3"] * 100) /
            packageContribution?.objPermonth?.["3"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance General Package"]["4"] * 100) /
            packageContribution?.objPermonth?.["4"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance General Package"]["5"] * 100) /
            packageContribution?.objPermonth?.["5"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance General Package"]["6"] * 100) /
            packageContribution?.objPermonth?.["6"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance General Package"]["7"] * 100) /
            packageContribution?.objPermonth?.["7"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance General Package"]["8"] * 100) /
            packageContribution?.objPermonth?.["8"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance General Package"]["9"] * 100) /
            packageContribution?.objPermonth?.["9"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance General Package"]["10"] * 100) /
            packageContribution?.objPermonth?.["10"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Advance General Package"]["11"] * 100) /
            packageContribution?.objPermonth?.["11"]
        ) || 0,
      
      ],
      color: "#7DB343",
    },
    {
      name: "Own Package",
      data: [
        Math.ceil(
          (packageContribution?.obj?.["Own Package"]["12"] * 100) /
            packageContribution?.objPermonth?.["12"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Own Package"]["1"] * 100) /
            packageContribution?.objPermonth?.["1"]
        ) + "%" || 0,
        Math.ceil(
          (packageContribution?.obj?.["Own Package"]["2"] * 100) /
            packageContribution?.objPermonth?.["2"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Own Package"]["3"] * 100) /
            packageContribution?.objPermonth?.["3"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Own Package"]["4"] * 100) /
            packageContribution?.objPermonth?.["4"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Own Package"]["5"] * 100) /
            packageContribution?.objPermonth?.["5"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Own Package"]["6"] * 100) /
            packageContribution?.objPermonth?.["6"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Own Package"]["7"] * 100) /
            packageContribution?.objPermonth?.["7"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Own Package"]["8"] * 100) /
            packageContribution?.objPermonth?.["8"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Own Package"]["9"] * 100) /
            packageContribution?.objPermonth?.["9"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Own Package"]["10"] * 100) /
            packageContribution?.objPermonth?.["10"]
        ) || 0,
        Math.ceil(
          (packageContribution?.obj?.["Own Package"]["11"] * 100) /
            packageContribution?.objPermonth?.["11"]
        ) || 0,
       
      ],
      color: "#00BCD5",
    },
  ]
  const options = {
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 576,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          enabled: false,
          total: {
            // enabled: false,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      // type: "datetime",
      labels: {
        format: "MMM",
      },
      categories: [
        "Dec",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
       
      ],
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
  }

  const selectOptions = [
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
    { value: "2026", label: "2026" },
    { value: "2027", label: "2027" },
  ]
  const handleStatus = e => {
    console.log("ere", e.target.value)
    setStatus(e.target.value)
  }
  useEffect(() => {
    dispatch(getPackageContribution(authToken, status))
  }, [status])
  return (
    <div className="student-enrollment-chart">
      <div className="student-enrollment-header-inner-wrap">
        <h2>Contribution (%)</h2>
        <Form.Select
          name=""
          id=""
          onChange={e => handleStatus(e)}
          className="year-select-main-wrap"
        >
          <option value="value">Value</option>
          <option value="volume">Volume</option>
        </Form.Select>
      </div>

      {packageContributionLoading ? (
        <Loader />
      ) : (
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      )}
    </div>
  )
}

export default StudentEnrollment
