import { ACTIVE_BANNER, ACTIVE_BANNER_FAIL, ACTIVE_BANNER_SUCCESS, ADD_BANNER, ADD_BANNER_FAIL, ADD_BANNER_SUCCESS, GET_BANNER, GET_BANNER_FAIL, GET_BANNER_SUCCESS, STORE_BANNER_DATA } from "./actionTypes";


export const addBanner = (data, token, history, bannerId) => ({
    type: ADD_BANNER,
    payload: { data, token, history, bannerId },
  })
  
  export const addBannerSuccess = data => ({
    type: ADD_BANNER_SUCCESS,
    payload: { data },
  })
  
  // charts data
  export const addBannerFail = data => ({
    type: ADD_BANNER_FAIL,
    payload: { data },
  })
  export const getBannerList = (token) => ({
    type: GET_BANNER,
    payload: { token },
  });
  
  export const getBannerListSuccess = (data) => ({
    type: GET_BANNER_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getBannerListFail = (data) => ({
    type: GET_BANNER_FAIL,
    payload: { data },
  });

  export const activeBanner = (data, token, history, id) => ({
    type: ACTIVE_BANNER,
    payload: { data, token, history, id },
  })
  
  export const activeBannerSuccess = data => ({
    type: ACTIVE_BANNER_SUCCESS,
    payload: { data },
  })
  
  // charts data
  export const activeBannerFail = data => ({
    type: ACTIVE_BANNER_FAIL,
    payload: { data },
  })

  
export const storeBannerData = (name, data) => ({
  type: STORE_BANNER_DATA,
  payload: { name, data },
});
 