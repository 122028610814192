import { calculateTime } from "helpers/Custom/listening"
import React from "react"
import styles from "./AudioPlayer.module.css"
import backwrd from "./img/undo 1.svg"
import forwrd from "./img/undo 2.svg"

const AudioPlayer = ({ data }) => {
  const {
    audioPlayer,
    backward,
    changeRange,
    currentTime,
    duration,
    forward,
    isPlaying,
    progressBar,
    togglePlayPause,
    track,
    mute,
    toggleMute,
  } = data

  return (
    //   <i class='bx bx-volume-low'></i> <i class='bx bx-volume-full' ></i> <i class='bx bx-volume-mute'></i>
    <div className={styles.audioPlayer}>
      <audio ref={audioPlayer} src={track} preload="metadata"></audio>
      <button onClick={togglePlayPause} className={styles.playPause}>
        {isPlaying ? (
          <i className="bx bx-pause"></i>
        ) : (
          <i className="bx bx-play"></i>
        )}
      </button>
      {/* current time */}
      <div className={styles.currentTime}>{calculateTime(currentTime)}</div> /
      {/* duration */}
      <div className={styles.duration}>
        {duration && !isNaN(duration) ? calculateTime(duration) : "00:00"}
      </div>
      {/* progress bar */}
      <div className={styles.progressBarDiv}>
        <input
          type="range"
          className={styles.progressBar}
          defaultValue="0"
          ref={progressBar}
          onChange={changeRange}
        />
      </div>
      <button className={styles.playPause} onClick={toggleMute}>
        {mute ? (
          <i className="bx bxs-volume-mute fs-3"></i>
        ) : (
          <i className="bx bxs-volume-full fs-3"></i>
        )}
      </button>
      <button className={styles.forwardBackward} onClick={backward}>
        <img src={backwrd} alt="backwrd" />
      </button>
      <button className={styles.forwardBackward} onClick={forward}>
        <img src={forwrd} alt="forwrd" />
      </button>
    </div>
  )
}

export { AudioPlayer }
