import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-responsive-modal';
import { useParams, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Loader from '../../../components/Common/Loader';
import CardComponent from '../../../components/Common/CardComponent';
import { toCapitalize } from '../../../helpers/Custom/toCapitalize';

import alartIcon from '../img/alert-circle.svg';
import { getFacilitatorSet } from 'store/Facilitator/actions';

// import RatingComponent from './RatingComponent';

const FacilitatorReadingService = ({ history }) => {
  const { packageId, studentId } = useParams();
  const dispatch = useDispatch();

  const { authtoken, facilitatorSet, loading, facilitator  } = useSelector(
    (state) => ({
        facilitatorSet: state.FacilitatorReducer.facilitatorSet,
      authtoken: state.Login.token,
    
      loading: state.FacilitatorReducer.loading,
      profile: state.Profile?.data,
      facilitator: state.FacilitatorReducer,
    })
  );

console.log('facilitatorSet', facilitatorSet);
  useEffect(() => {
   
    dispatch(getFacilitatorSet(authtoken, packageId, studentId));
   
  }, []);
  const [startXam, setStartXam] = useState(false);
  const [xam, setXam] = useState('');
  // console.log(xam);
  return (
    <React.Fragment>
    
      {/* <RatingComponent /> */}
      <div className="view-all-single-package-main-wrap">
        <Container>
          <div className="page-title-wrap">
            <div className="mt-5">
              <h4>Package Set</h4>
            </div>
            <div className="">
              {/* <span>Time Expire</span> */}
              {/* <span>30D : 24H : 58M : 56S</span> */}
            </div>
          </div>
          {loading ? (
            <div className="text-center  pt-5">
              <Loader />
            </div>
          ) : (
            <div className="single-package-inner-main-wrap">
              <Row>
                {(facilitatorSet?.values?.getData?.getData || []).map((data, key) => (
                  <Col lg={4} md={6} key={key}>
                    <div className="card-active-deactive-content">
                      {/* <div className="active-pending-top-right-massage">
                        <div className="course-active-btn">
                          <span className="color-success-text">Active</span>
                        </div>
                      </div> */}
                      <CardComponent className={'text-center py-4'}>
                        <div className="mx-auto">
                          <img
                            src={
                              require(`../img/reading.svg`)
                                .default
                            }
                            alt="examImage"
                            className="mb-3"
                          />
                        </div>{' '}
                        <div>
                          <h4>{toCapitalize(data.setName)}</h4>
                          {/* <p>1st out of 7th attempt</p> */}
                          {/* <Link to={`/reading-exam/${data._id}`}> */}
                          {facilitatorSet?.examData?.[data._id] ? (
                            <button
                              className="btn btn-primary ms-1"
                              onClick={() =>
                                history.push(
                                  `/package/${packageId}/result/${data._id}/reading`
                                )
                              }
                            >
                              Result
                            </button>
                          ) : (
                            <button
                            className="meet-button"
                              // disabled={profile?.examDone?.reading !== key}
                              // onClick={() =>
                              //   history.push(`/reading-exam/${data._id}`)
                              // }
                              onClick={() => {
                                setStartXam(true);
                                setXam(data._id);
                                history.push(
                                  `/facilitator/${packageId}/result/${studentId}/reading/${data._id}`
                                )
                              }}
                            >
                              View Answer
                            </button>
                          )}
                          {/* </Link> */}
                        </div>
                      </CardComponent>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(FacilitatorReadingService);
