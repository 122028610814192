import { Field, FieldArray, Form, Formik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Button, Label } from "reactstrap"
import { del } from "../../../helpers/api_helper"

import { toaster } from "helpers/Custom/Toaster"
// import { addAptitudeQuestion } from "../../../Store/Admin/CorporateQuestions/CorporateQuestionSlice";
import "../gmat.scss"

import { VerbalQuesSchema } from "components/Schemas/QuestionSchemas"
// import QuestionTopCard from "./Common/QuestionTopCard";
import CardComponent from "components/Common/CardComponent"
import QuestionTopCard from "components/Common/QuestionTopCard"
import CheckBox from "components/InputComponent/CheckBox"
import CustomInput from "components/InputComponent/CustomInput"
import FileUpload from "components/InputComponent/FileUpload"
import QuillComponent from "components/InputComponent/QuillComponent"
import { createHash, removeHash } from "helpers/Custom/Hash"
import { checkImage, getImageUrl } from "helpers/Custom/Questions"
import { addVerbalQues, getSingleVerbalQues } from "store/GMAT/Verbal/actions"
import InnerLayer from "../../../Layout/InnerLayer"
import PageHeader from "../../../Layout/PageHeader"
const section = [
  {
    name: "data Sufficency",
    value: 1,
  },
  {
    name: "problem Solving",
    value: 2,
  },
]
const difficultyLevel = [
  {
    name: "easy",
    value: 1,
  },
  {
    name: "medium",
    value: 2,
  },
  {
    name: "difficult",
    value: 3,
  },
]
const quesType = [
  {
    name: "single",
    value: 1,
  },
  {
    name: "multi",
    value: 2,
  },
]
const initialValues = {
  difficultyLevel: "",
  questype: "",
  explanation: "",
  questionTitle: "",
  description: "",
  q: "",
  marks: "",
  questionQuant: 1,
  rightAnswer: [""],
  image: null,
  // explanation: "",
  options: [
    {
      value: "",
      isImage: false,
    },
  ],
}

const VerbalQuesCreate = ({ history, api }) => {
  const textAreaRef = useRef(null)
  const { passageId, questionId, serviceId, section, type } = useParams()
  console.log("questionId", questionId)
  const [listValue, setListValue] = useState(null)
  const [isAddMore, setIsAddMore] = useState(false)
  const [request, setRequest] = useState(false)
  const [sectionData, setSection] = useState("")
  const title = ` Question List`
  const title2 = questionId ? `Edit  Question` : `Create Question`
  const breadcrumbItems = [
    {
      title: title,
      link: `/Quantative-ques-list`,
    },
    {
      title: title2,
      link: `#`,
    },
  ]
  const dispatch = useDispatch()
  const { singleVerbalQues, uploading, authToken } = useSelector(state => ({
    loading: state.analyticalReducer.loading,
    adding: state.analyticalReducer.adding,
    questionNumber: state.analyticalReducer.questionNumber,
    singleVerbalQues: state.verbalReducer.singleVerbalQues,
    singleVerbalQuesLoading: state.verbalReducer.singleVerbalQuesLoading,
    questionGroup: state.analyticalReducer.questionGroup,
    uploading: state.analyticalReducer.uploading,
    authToken: state.Login.token,
  }))
  console.log(`singleVerbalQues`, singleVerbalQues)

  useEffect(() => {
    if (questionId) {
      dispatch(getSingleVerbalQues(authToken, questionId))
    }
  }, [questionId])

  useEffect(() => {
    console.log("hgjfvgn")
    if (questionId && !!singleVerbalQues) {
      const value = { ...listValue }
      value.section = singleVerbalQues?.section || ""
      value.marks = singleVerbalQues?.marks || ""
      value.difficultyLevel = singleVerbalQues?.difficultyLevel || ""
      value.questype = singleVerbalQues?.questype || ""
      value.questionTitle = singleVerbalQues?.questionTitle || ""
      value.description = singleVerbalQues?.description || ""
      value.q = singleVerbalQues?.q ? removeHash(singleVerbalQues?.q) : ""
      value.options = singleVerbalQues?.options?.map(el => {
        let obj = {}
        obj.value = el
        if (checkImage(el)) {
          obj.isImage = true
        } else {
          obj.isImage = false
        }
        return obj
      }) || [""]
      value.rightAnswer = singleVerbalQues?.rightAnswer?.map(el => el)
      value.image = singleVerbalQues?.image
      // value.answer = singleQuantitativeQues?.answer?.[0];
      value.explanation = singleVerbalQues?.explanation
      setListValue(value)
    }
  }, [singleVerbalQues])

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    console.log("values", values)
    const {
      difficultyLevel,
      q,
      questype,

      rightAnswer,
      options,
      ...rest
    } = values
    const obj = {
      ...rest,
      q: createHash(q),
      options: options?.map(el => el?.value?.trim()),
      rightAnswer: rightAnswer?.map(el => el?.trim()),
      difficultyLevel: difficultyLevel * 1,
      questype: questype * 1,
      section: section * 1,
    }
    const option = {
      isMore: isAddMore,
      resetForm,
      setSubmitting,
      setIsAddMore,
      id: questionId,
      passageId,
      history,
      serviceId,
      type,
    }
    console.log(`obj`, obj, option)
    dispatch(addVerbalQues(obj, authToken, option))
    setSubmitting(false)
  }

  const removeFile = async (id, setFieldValue, name) => {
    let success = false
    setRequest(true)
    try {
      const url = `/data-dump/delete-file/${id}`
      const response = await del(url)
      if (response) {
        success = true
        setFieldValue(name, "")
      }
    } catch (err) {
      toaster("error", "Remove file failed")
    } finally {
      setRequest(false)
    }
    return success
  }

  function copyToClipboard(e) {
    textAreaRef.current.select()
    document.execCommand("copy")
    e.target.focus()
    toaster("success", "Url copied successful")
  }
  const handleSection = data => {
    if (data?.value) {
      setSection(data?.value)
    } else {
      setSection("")
    }
  }
  return (
    <React.Fragment>
      <InnerLayer>
        <PageHeader title={title2} breadcrumbItems={breadcrumbItems} />
        <Row className="create_questions">
          <Col md="10" className="offset-md-1">
            <QuestionTopCard
              history={history}
              title={`${questionId ? "Update" : "Create"} Verbal question`}
            />
            <CardComponent style={{ marginTop: "5px" }}>
              <h5>Type question</h5>
              <br />
              <Formik
                initialValues={listValue || initialValues}
                validationSchema={VerbalQuesSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  setFieldValue,
                  isSubmitting,
                  handleSubmit,
                }) => (
                  <Form>
                    {console.log("values,", values, errors)}

                    <Row className="mb-3">
                      {/* <Col md="12 mb-2">
                        <Field
                          label="Section"
                          name="section"
                          type="select"
                          id={"section"}
                          component={CustomInput}
                        >
                          <option value="">Select...</option>
                          <option value="1">Reading Comprehension</option>
                          <option value="2">Sentence Equavalance</option>
                          <option value="3">Text Comprehension</option>
                        </Field>
                      </Col> */}
                      <Col md="12 mb-2">
                        <Field
                          label="Difficulty level"
                          name="difficultyLevel"
                          type="select"
                          id={"difficultyLevel"}
                          component={CustomInput}
                        >
                          <option value="">Select...</option>
                          <option value="1">Easy</option>
                          <option value="2">Medium</option>
                          <option value="3">difficult</option>
                        </Field>
                      </Col>
                      <Col md="12 mb-2">
                        <Field
                          label="Question Type"
                          name="questype"
                          type="select"
                          id={"questype"}
                          component={CustomInput}
                        >
                          <option value="">Select...</option>
                          <option value="1">single</option>
                          <option value="2">multi</option>
                        </Field>
                      </Col>
                      <Col md="12 mb-2">
                        <Field
                          label="Question Title"
                          name="questionTitle"
                          type="text"
                          id={"question"}
                          component={CustomInput}
                          placeholder="Type Question title"
                        />
                      </Col>
                      <Col md="12 mb-2">
                        <Field
                          name="q"
                          label={"Write Question"}
                          type="text"
                          component={QuillComponent}
                          // placeholder="Type title"
                        />
                      </Col>

                      <Col md="6 mb-2">
                        <Field
                          label="Description"
                          name="description"
                          type="text"
                          id={"description"}
                          component={CustomInput}
                          placeholder="Type description"
                        />
                      </Col>
                    </Row>
                    {/* <FormikArray values={values} name="opt" labelName="Option" /> */}
                    <Col md="12" className="mb-2">
                      <FieldArray
                        name="options"
                        render={arrayHelpers => (
                          <Row>
                            {values?.options?.map((value, index) => (
                              <React.Fragment key={index}>
                                <Col md="6" className="position-relative mb-3">
                                  <Label
                                    className="d-flex justify-content-between align-items-center gap-2"
                                    htmlFor={`options.${index}.value`}
                                  >
                                    <span className="text-nowrap">
                                      {`Option ${index + 1}`}
                                    </span>
                                    <Field
                                      name={`options.${index}.isImage`}
                                      id={`options.${index}.isImage`}
                                      label="Image?"
                                      component={CheckBox}
                                      onClick={e => {
                                        !request &&
                                          checkImage(value?.value) &&
                                          removeFile(
                                            getImageUrl(value?.value),
                                            setFieldValue,
                                            `options.${index}.value`
                                          )
                                        setFieldValue(
                                          `options.${index}.value`,
                                          ""
                                        )
                                      }}
                                    />
                                  </Label>
                                  {value?.isImage ? (
                                    <React.Fragment>
                                      <Field
                                        name={`options.${index}.value`}
                                        dropText={`Upload`}
                                        component={FileUpload}
                                        textStartWrapper="img@&#["
                                        textEndWrapper="]#&@img"
                                        // isBrowse={false}
                                        dropZoneClass="dropZoneClass APTDropZone"
                                        uploadFor="Activation"
                                        imageDefault={false}
                                        // imageId={`options.${index}.url`}
                                      />
                                      {value?.value &&
                                      checkImage(value?.value) ? (
                                        <div
                                          className="position-relative"
                                          style={{ width: "100%" }}
                                        >
                                          <i
                                            className="fs-2 text-danger cursor_pointers position-absolute bx bx-trash"
                                            style={{
                                              top: "2%",
                                              right: "2%",
                                            }}
                                            onClick={() =>
                                              !request &&
                                              removeFile(
                                                getImageUrl(value?.value),
                                                setFieldValue,
                                                `options.${index}.value`
                                              )
                                            }
                                          ></i>
                                          <div onClick={copyToClipboard}>
                                            <img
                                              style={{
                                                height: "110px",
                                                objectFit: "cover",
                                              }}
                                              src={getImageUrl(value?.value)}
                                              alt="img"
                                            />
                                            <input
                                              title="click to copy"
                                              className="d-block border-0"
                                              ref={textAreaRef}
                                              value={getImageUrl(value?.value)}
                                            />
                                          </div>
                                        </div>
                                      ) : null}
                                    </React.Fragment>
                                  ) : (
                                    <Field
                                      name={`options.${index}.value`}
                                      type={"text"}
                                      id={`options.${index}.value`}
                                      // label={`Option ${index + 1}`}
                                      component={CustomInput}
                                      placeholder="Type option"
                                      isArray
                                    />
                                  )}
                                  {index > 0 ? (
                                    <div
                                      className="position-absolute"
                                      style={{
                                        top: "25px",
                                        // transform : ""
                                        width: "initial",
                                        right: "1.5%",
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <button
                                        className="btn-sm removeShadow"
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <i className="bx bx-trash text-danger"></i>
                                      </button>
                                    </div>
                                  ) : null}
                                </Col>
                              </React.Fragment>
                            ))}

                            <Col md="12">
                              <button
                                type="button"
                                className="d-inline-block btn button-add"
                                onClick={() =>
                                  arrayHelpers.push({
                                    value: "",
                                    image: "",
                                  })
                                }
                              >
                                <i className="bx bx-plus"></i> Add Another
                                Option
                              </button>
                            </Col>
                          </Row>
                        )}
                      />
                    </Col>

                    <Col md="12" className="mb-2">
                      <FieldArray
                        name="rightAnswer"
                        render={arrayHelpers => (
                          <Row>
                            {values?.rightAnswer?.map((value, index) => (
                              <React.Fragment key={index}>
                                <Col md="6" className="position-relative mb-3">
                                  <Label
                                    className="d-flex justify-content-between align-items-center gap-2"
                                    htmlFor={`rightAnswer.${index}`}
                                  >
                                    <span className="text-nowrap">
                                      {`Right Answer ${index + 1}`}
                                    </span>
                                  </Label>

                                  <Field
                                    name={`rightAnswer.${index}`}
                                    type={"text"}
                                    id={`rightAnswer.${index}`}
                                    // label={`Option ${index + 1}`}
                                    component={CustomInput}
                                    placeholder="Type Answer"
                                    isArray
                                  />

                                  {index > 0 ? (
                                    <div
                                      className="position-absolute"
                                      style={{
                                        top: "25px",
                                        // transform : ""
                                        width: "initial",
                                        right: "1.5%",
                                      }}
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <button
                                        className="btn-sm removeShadow"
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <i className="bx bx-trash text-danger"></i>
                                      </button>
                                    </div>
                                  ) : null}
                                </Col>
                              </React.Fragment>
                            ))}
                            {values?.questype === "2" ? (
                              <Col md="12">
                                <button
                                  type="button"
                                  className="d-inline-block btn button-add"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  <i className="bx bx-plus"></i> Add Another
                                  Option
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        )}
                      />
                    </Col>
                    <Col md="6 mb-2">
                      <Field
                        label="Marks"
                        name="marks"
                        type="number"
                        id={"marks"}
                        component={CustomInput}
                        placeholder="Type marks"
                      />
                    </Col>
                    <Col md="6 mb-2">
                      <Field
                        label="Total question"
                        name="questionQuant"
                        type="number"
                        id={"questionQuant"}
                        component={CustomInput}
                        placeholder="Type total question"
                      />
                    </Col>
                    <Col md="6 mb-2">
                      <Field
                        label="Explanation"
                        name="explanation"
                        type="text"
                        id={"explanation"}
                        component={CustomInput}
                        placeholder="Type explanation"
                      />
                    </Col>
                    <Col md="12 mb-2">
                      <Field
                        name="image"
                        label={"Upload Image"}
                        type="file"
                        component={FileUpload}
                        isRequired
                        isFull
                      />
                    </Col>

                    <Row className="mb-3">
                      <Col md="12" className="d-flex justify-content-end">
                        <Button
                          color="warning"
                          outline
                          className="me-2 btn btn-outline-blue"
                          disabled={isSubmitting || uploading}
                          type="submit"
                        >
                          {isSubmitting
                            ? "Submitting..."
                            : questionId
                            ? "Update"
                            : "Save"}
                        </Button>
                        {!questionId ? (
                          <button
                            color="primary"
                            style={{ color: "white" }}
                            disabled={isSubmitting || uploading}
                            type="button"
                            onClick={() => {
                              setIsAddMore(true)
                              handleSubmit()
                            }}
                            className="btn button-blue"
                          >
                            {isSubmitting
                              ? "Submitting..."
                              : "Create more question"}
                          </button>
                        ) : null}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default VerbalQuesCreate
