import React from "react"
import { Link } from "react-router-dom"

const QuestionButtonHandler = ({
  questionStatus,
  type,
  handleDone,
  createLink,
  previewLink,
  adding,
  findEditAble,
  isDone,
  objQuestion,
  reading
}) => {
  console.log(reading? objQuestion?.[type]?.status:  objQuestion?.[type] > 0, objQuestion, "questionStatus?.[type]?.status");
  return (
    <React.Fragment>
      {isDone ? (
       ( reading? questionStatus?.[type]?.status:  objQuestion?.[type] > 0) ? (
          <Link
            to={previewLink}
            className="btn question-view-btn btn-primary rounded-pill btn-sm"
            // disabled={findEditAble(mcqType) === false}
          >
            Preview
          </Link>
        ) : (
          <button
            className="btn question-view-btn btn-primary rounded-pill btn-sm"
            disabled={true}
            title="Save for add new"
          >
            Create
          </button>
        )
      ) : questionStatus?.[type]?.status === 2 ? (
        <Link
          to={previewLink}
          className="btn question-view-btn btn-primary rounded-pill btn-sm"
          // disabled={findEditAble(mcqType) === false}
        >
          Preview
        </Link>
      ) : findEditAble(type) ? (
        questionStatus?.[type]?.qLength > 0 ? (
          <React.Fragment>
            <Link
              to={previewLink}
              className="btn question-view-btn btn-primary rounded-pill btn-sm"
            >
              Preview
            </Link>

            <button
              className="btn question-view-btn btn-success rounded-pill btn-sm ms-1"
              onClick={() => handleDone(type)}
              disabled={adding}
            >
              {adding ? "Updating.." : "Done"}
            </button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Link
              to={createLink}
              className="btn question-view-btn btn-primary rounded-pill btn-sm"
            >
              Create
            </Link>
          </React.Fragment>
        )
      ) : (
        <button
          className="btn question-view-btn btn-primary rounded-pill btn-sm"
          disabled={true}
          title="Save for add new"
        >
          Create
        </button>
      )}
    </React.Fragment>
  )
}

export default QuestionButtonHandler
