/* eslint-disable react/react-in-jsx-scope */
const OpenBookIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5406_39777)">
        <path
          d="M6.11889 4.72029C6.11781 4.72029 6.1166 4.72029 6.11552 4.72029C5.7372 4.72029 5.38137 4.86777 5.11269 5.13578C4.84104 5.40676 4.69141 5.76757 4.69141 6.15168V27.6151C4.69141 28.4021 5.33424 29.044 6.12454 29.046C9.45627 29.054 15.0382 29.7484 18.889 33.7782V11.3161C18.889 11.0493 18.8209 10.7987 18.6923 10.5913C15.5317 5.50144 9.45816 4.7281 6.11889 4.72029Z"
          fill="#0E8AE3"
        />
        <path
          d="M35.3088 27.6152V6.15168C35.3088 5.76757 35.1592 5.40676 34.8875 5.13578C34.6188 4.86777 34.2627 4.72029 33.8848 4.72029C33.8836 4.72029 33.8824 4.72029 33.8813 4.72029C30.5422 4.72824 24.4686 5.50157 21.308 10.5914C21.1793 10.7988 21.1113 11.0495 21.1113 11.3163V33.7782C24.9621 29.7484 30.5441 29.054 33.8758 29.046C34.666 29.044 35.3088 28.4021 35.3088 27.6152Z"
          fill="#0E8AE3"
        />
        <path
          d="M38.5693 9.67H37.5314V27.615C37.5314 29.6245 35.8941 31.2632 33.8815 31.2682C31.0555 31.2749 26.3958 31.8275 23.0957 34.9509C28.8032 33.5535 34.8199 34.4619 38.2489 35.2433C38.677 35.3408 39.1195 35.2401 39.4625 34.9667C39.8043 34.6939 40.0004 34.2863 40.0004 33.8487V11.1011C40.0005 10.312 39.3584 9.67 38.5693 9.67Z"
          fill="#0E8AE3"
        />
        <path
          d="M2.46896 27.615V9.67H1.43112C0.642157 9.67 0 10.312 0 11.1011V33.8483C0 34.286 0.196095 34.6935 0.537914 34.9663C0.880676 35.2395 1.3227 35.3407 1.75152 35.2429C5.18049 34.4613 11.1973 33.553 16.9046 34.9505C13.6046 31.8273 8.94495 31.2748 6.11894 31.2681C4.10641 31.2632 2.46896 29.6245 2.46896 27.615Z"
          fill="#0E8AE3"
        />
      </g>
      <defs>
        <clipPath id="clip0_5406_39777">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default OpenBookIcon
