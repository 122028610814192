import { call, put, takeEvery } from "redux-saga/effects"
import { toaster } from "../../helpers/Custom/Toaster"
import { getData, patchData, postData } from "../../helpers/backend_helper"
import { getIELTSReadingSinglePassage } from "../actions"
import {
  addIELTSListeningQuesMCQFail,
  addIELTSListeningQuesMCQSuccess,
  getIELTSListeningQuesMCQFail,
  getIELTSListeningQuesMCQSuccess,
  getIELTSListeningQuesNum,
  getIELTSListeningQuesNumFail,
  getIELTSListeningQuesNumSuccess,
  getIELTSListeningQuesStatus,
  getIELTSListeningQuesStatusFail,
  getIELTSListeningQuesStatusSuccess,
  storeQuestion,
  updateIELTSListeningQuesStatusFail,
  updateIELTSListeningQuesStatusSuccess,
} from "./actions"
//for main
import {
  ADD_IELTS_LISTENING_MCQ_QUES,
  GET_IELTS_LISTENING_MCQ_QUES,
  GET_IELTS_LISTENING_QUES_NUM,
  GET_IELTS_LISTENING_QUES_STATUS,
  UPDATE_IELTS_LISTENING_QUES_STATUS,
} from "./actionTypes"

function* AddIeltsListeningMcqQues({
  payload: {
    data,
    history,
    authtoken,
    options: { isMore, resetForm, setIsAddMore, questionId, passageId, type },
  },
}) {
  try {
    const url = questionId
      ? `/listeningquestion/update-question/${passageId}/${questionId}`
      : "/listeningquestion/questioncreate"
    const response = yield call(
      questionId ? patchData : postData,
      url,
      data,
      authtoken
    )
    yield put(addIELTSListeningQuesMCQSuccess(response))
    if (!isMore) {
      history.goBack()
    } else {
      resetForm()
      yield put(getIELTSListeningQuesNum(authtoken, passageId, type))
      setIsAddMore(false)
    }
    toaster(
      "success",
      `Question ${questionId ? "Update" : "added"} successfully!`
    )
    //    history.push("/IeltsSetList")
  } catch (error) {
    setIsAddMore(false)
    const message =
      error?.response?.data?.message ||
      `Question ${id ? "Update" : "added"} failed!`
    yield put(addIELTSListeningQuesMCQFail(message))
    // console.log("hi",message);
    toaster("error", message)
  }
}

function* onGetReadingMcqQues({ payload: { id, authtoken, data } }) {
  try {
    const url = `/listeningquestion/${id}/allquestion`
    const response = yield call(getData, url, authtoken)
    yield put(getIELTSListeningQuesMCQSuccess(response?.allquestion))
    if (data) {
      const findData = response?.allquestion?.find(
        dt => dt._id === data.objectId
      )
      const findQues = findData?.Que?.question.find(
        dt => dt._id === data.questionId
      )
      console.log(`findQues`, findQues, findData)
      yield put(storeQuestion("singleQuestion", findQues))
      yield put(storeQuestion("questionNumber", findQues?.questionNumber))
    }
    // toaster("success", "Question  successfully!")
    //    history.push("/IeltsSetList")
  } catch (error) {
    const message = error?.response?.data?.message
    yield put(getIELTSListeningQuesMCQFail(message))
    // console.log("hi",message);
    toaster("error", message)
  }
}

function* onGetReadingQuesNum({ payload: { id, authtoken, type } }) {
  console.log(
    `🦄 ~ file: saga.js ~ line 98 ~ function*onGetReadingQuesNum ~ id, authtoken, type`,
    id,
    authtoken,
    type
  )
  try {
    const url = `/listeningquestion/get-question-serial/${id}/${type}`
    const response = yield call(getData, url, authtoken)
    console.log(`response`, response)
    yield put(getIELTSListeningQuesNumSuccess(response?.position))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Load Question Number failed"
    yield put(getIELTSListeningQuesNumFail(message))
    toaster("error", message)
  }
}

function* onGetReadingQuesStatus({ payload: { id, authtoken, type } }) {
  try {
    const url = `/listeningquestion/question-type-status/${id}`
    const response = yield call(getData, url, authtoken)
    yield put(getIELTSListeningQuesStatusSuccess(response?.obj))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Load Question status failed"
    yield put(getIELTSListeningQuesStatusFail(message))
    toaster("error", message)
  }
}

function* onUpdateReadingQuesStatus({ payload: { id, authtoken, type } }) {
  console.log(
    `🦄 ~ file: saga.js ~ line 124 ~ function*onUpdateReadingQuesStatus ~ id, authtoken, type`,
    id,
    authtoken,
    type
  )
  try {
    const url = `/listeningquestion/update-question-status/${id}/${type}`
    const response = yield call(patchData, url, {}, authtoken)
    yield put(updateIELTSListeningQuesStatusSuccess(response))
    yield put(getIELTSReadingSinglePassage(authtoken, id))
    yield put(getIELTSListeningQuesStatus(authtoken, id))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Update Question status failed"
    yield put(updateIELTSListeningQuesStatusFail(message))
    toaster("error", message)
  }
}

function* listeningQuesSaga() {
  yield takeEvery(ADD_IELTS_LISTENING_MCQ_QUES, AddIeltsListeningMcqQues)
  yield takeEvery(GET_IELTS_LISTENING_MCQ_QUES, onGetReadingMcqQues)
  yield takeEvery(GET_IELTS_LISTENING_QUES_NUM, onGetReadingQuesNum)
  yield takeEvery(GET_IELTS_LISTENING_QUES_STATUS, onGetReadingQuesStatus)
  yield takeEvery(UPDATE_IELTS_LISTENING_QUES_STATUS, onUpdateReadingQuesStatus)
}
export default listeningQuesSaga
