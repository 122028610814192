
export const GET_CHAT_USER = "GET_CHAT_USER"
export const GET_CHAT_USER_SUCCESS = "GET_CHAT_USER_SUCCESS"
export const GET_CHAT_USER_FAIL = "GET_CHAT_USER_FAIL"


export const GET_SINGLE_USER_MESSAGE = "GET_SINGLE_USER_MESSAGE"
export const GET_SINGLE_USER_MESSAGE_SUCCESS = "GET_SINGLE_USER_MESSAGE_SUCCESS"
export const GET_SINGLE_USER_MESSAGE_FAIL = "GET_SINGLE_USER_MESSAGE_FAIL"


export const REPLY_USER = "REPLY_USER"
export const REPLY_USER_SUCCESS = "REPLY_USER_SUCCESS"
export const REPLY_USER_FAIL = "REPLY_USER_FAIL"


export const ASSIGN_ISSUE = "ASSIGN_ISSUE"
export const ASSIGN_ISSUE_SUCCESS = "ASSIGN_ISSUE_SUCCESS"
export const ASSIGN_ISSUE_FAIL = "ASSIGN_ISSUE_FAIL"

export const UPDATE_ISSUE = "UPDATE_ISSUE"
export const UPDATE_ISSUE_SUCCESS = "UPDATE_ISSUE_SUCCESS"
export const UPDATE_ISSUE_FAIL = "UPDATE_ISSUE_FAIL"

export const GET_ISSUE_LIST = "GET_ISSUE_LIST"
export const GET_ISSUE_LIST_SUCCESS = "GET_ISSUE_LIST_SUCCESS"
export const GET_ISSUE_LIST_FAIL = "GET_ISSUE_LIST_FAIL"

export const SEEN_MESSAGE = "SEEN_MESSAGE"
export const SEEN_MESSAGE_SUCCESS = "SEEN_MESSAGE_SUCCESS"
export const SEEN_MESSAGE_FAIL = "SEEN_MESSAGE_FAIL"