import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import ComparisonType from '../../../components/ReadingExam/QuestionComponent/ComparisonType';
import FillGaps from '../../../components/ReadingExam/QuestionComponent/FillGaps';
import FillGapsOne from '../../../components/ReadingExam/QuestionComponent/FillGapsOne';
import FillGapsWithClue from '../../../components/ReadingExam/QuestionComponent/FillGapsWithClue';
import HeadMatchingType from '../../../components/ReadingExam/QuestionComponent/HeadMatchingType';
import McqType from '../../../components/ReadingExam/QuestionComponent/McqType';
import MultiSelectType from '../../../components/ReadingExam/QuestionComponent/MultiSelectType';
import TrueFalseType from '../../../components/ReadingExam/QuestionComponent/TrueFalseType';
import YesNoType from '../../../components/ReadingExam/QuestionComponent/YesNoType';

const mcqType = 'mcq';
const trueFalseQues = 'trueFalse';
const comprehensionQues = 'comprehension';
const YesNoQues = 'YesNoQues';
const HeadingMatching = 'headingMatching';
const multipleChoice = 'multipleSelection';
const fillGapsWords = 'fillGapsWords';
const fillGapsWithoutWords = 'fillGaps';
export const fillGapsOne = 'fillGapsOne';
export const fillGapsTwo = 'fillGapsTwo';
export const fillGapsThree = 'fillGapsThree';

const RightSide = ({ question, ans }) => {
  const { questions, currentIndex } = useSelector((state) => ({
    questions: state.resultReducer.results?.setId?.readingPassageModel,
    currentIndex: state.FacilitatorQuesReducer.currentIndex,
    ansQuestion: state.FacilitatorQuesReducer.ansQuestion,
  }));

  const findPreviousPassageLastQuestion = useMemo(() => {
    let questionPosition = 0;
    if (currentIndex > 0) {
      for (let i = 0; i < currentIndex; i++) {
        const previous = questions?.[i];
        const lastQuestion =
          previous.questions?.[previous.questions.length - 1];
        questionPosition +=
          lastQuestion?.Que?.question?.[lastQuestion?.Que?.question.length - 1]
            .questionNumber;
      }
    }
    return questionPosition;
  }, [questions, currentIndex]);
  // console.log('ff', findPreviousPassageLastQuestion);

  //   console.log(`useSelector ~ questions>>>>>`, ans);

  const findQuestionsNumberRange = `${
    question?.[0]?.Que?.question?.[0]?.questionNumber +
    findPreviousPassageLastQuestion
  } - 
    ${
      question?.[question?.length - 1]?.Que?.question[
        question?.[question?.length - 1]?.Que?.question.length - 1
      ]?.questionNumber + findPreviousPassageLastQuestion
    }`;
  return (
    <React.Fragment>
      <React.Fragment>
        <div className="ques-right-part-container">
          <div className="ques-section-main-wrapper mb-5">
            <div className="ques-title">
              <p>Section 1 : Question {findQuestionsNumberRange}</p>
            </div>
            {question?.map((data, idx) => {
              if (data?.Que?.questype === multipleChoice) {
                return (
                  <MultiSelectType
                    multipleQuesType={data?.Que?.question}
                    key={idx}
                    idx={idx}
                    ans={ans}
                    view
                    currentIndex={currentIndex}
                    currentPassage={questions?.[currentIndex]}
                    questionNumber={findPreviousPassageLastQuestion}
                  />
                );
              } else if (data?.Que?.questype === trueFalseQues) {
                return (
                  <TrueFalseType
                    TrueFalseQuesType={data?.Que?.question}
                    view
                    idx={idx}
                    key={idx}
                    ans={ans}
                    currentIndex={currentIndex}
                    currentPassage={questions?.[currentIndex]}
                    questionNumber={findPreviousPassageLastQuestion}
                  />
                );
              } else if (data?.Que?.questype === YesNoQues) {
                return (
                  <YesNoType
                    yesNoQues={data?.Que?.question}
                    view
                    idx={idx}
                    key={idx}
                    ans={ans}
                    currentIndex={currentIndex}
                    currentPassage={questions?.[currentIndex]}
                    questionNumber={findPreviousPassageLastQuestion}
                  />
                );
              } else if (data?.Que?.questype === comprehensionQues) {
                return (
                  <ComparisonType
                    comprehensionType={data?.Que?.question}
                    view
                    options={data?.Que?.options}
                    idx={idx}
                    ans={ans}
                    key={idx}
                    currentIndex={currentIndex}
                    currentPassage={questions?.[currentIndex]}
                    questionNumber={findPreviousPassageLastQuestion}
                  />
                );
              } else if (data?.Que?.questype === mcqType) {
                return (
                  <McqType
                    questions={data?.Que?.question}
                    idx={idx}
                    key={idx}
                    view
                    ans={ans}
                    currentIndex={currentIndex}
                    currentPassage={questions?.[currentIndex]}
                    questionNumber={findPreviousPassageLastQuestion}
                  />
                );
              } else if (data?.Que?.questype === HeadingMatching) {
                return (
                  <HeadMatchingType
                    headingMatchingType={data?.Que?.question}
                    options={data?.Que?.options}
                    idx={idx}
                    view
                    key={idx}
                    ans={ans}
                    currentIndex={currentIndex}
                    currentPassage={questions?.[currentIndex]}
                    questionNumber={findPreviousPassageLastQuestion}
                  />
                );
              } else if (
                data?.Que?.questype === fillGapsWithoutWords ||
                // data?.Que?.questype === fillGapsOne ||
                data?.Que?.questype === fillGapsTwo ||
                data?.Que?.questype === fillGapsThree
              ) {
                return (
                  <FillGaps
                    questions={data?.Que?.question}
                    options={data?.Que?.options}
                    idx={idx}
                    key={idx}
                    view
                    ans={ans}
                    currentPassage={questions?.[currentIndex]}
                    currentIndex={currentIndex}
                    questionNumber={findPreviousPassageLastQuestion}
                  />
                );
              } else if (data?.Que?.questype === fillGapsOne) {
                return (
                  <FillGapsOne
                    questions={data?.Que?.question}
                    options={data?.Que?.options}
                    idx={idx}
                    key={idx}
                    ans={ans}
                    currentPassage={questions?.[currentIndex]}
                    currentIndex={currentIndex}
                    questionNumber={findPreviousPassageLastQuestion}
                  />
                );
              } else if (data?.Que?.questype === fillGapsWords) {
                return (
                  <FillGapsWithClue
                    questions={data?.Que?.question}
                    options={data?.Que?.options}
                    idx={idx}
                    key={idx}
                    view
                    ans={ans}
                    currentPassage={questions?.[currentIndex]}
                    currentIndex={currentIndex}
                    questionNumber={findPreviousPassageLastQuestion}
                  />
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default RightSide;
