import {
  ADD_IELTS_READING_MCQ_QUES,
  ADD_IELTS_READING_MCQ_QUES_FAIL,
  ADD_IELTS_READING_MCQ_QUES_SUCCESS,
  GET_IELTS_READING_MCQ_QUES,
  GET_IELTS_READING_MCQ_QUES_FAIL,
  GET_IELTS_READING_MCQ_QUES_SUCCESS,
  GET_IELTS_READING_QUES_NUM,
  GET_IELTS_READING_QUES_NUM_FAIL,
  GET_IELTS_READING_QUES_NUM_SUCCESS,
  GET_IELTS_READING_QUES_STATUS,
  GET_IELTS_READING_QUES_STATUS_FAIL,
  GET_IELTS_READING_QUES_STATUS_SUCCESS,
  STORE_QUESTION,
  UPDATE_IELTS_READING_QUES_STATUS,
  UPDATE_IELTS_READING_QUES_STATUS_FAIL,
  UPDATE_IELTS_READING_QUES_STATUS_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: true,
  adding: false,
  message: "",
  error: "",
  readingSet: {},
  ques: [],
  singleQuestion: {},
  questionNumber: 0,
  questionStatus: {},
}

const QuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_QUESTION:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      }
    case ADD_IELTS_READING_MCQ_QUES:
      return {
        ...state,
        adding: true,
      }
    case ADD_IELTS_READING_MCQ_QUES_SUCCESS:
      return {
        ...state,
        adding: false,
      }
    case ADD_IELTS_READING_MCQ_QUES_FAIL:
      return {
        ...state,
        error: action.payload,
        adding: false,
      }
    case GET_IELTS_READING_MCQ_QUES:
      return {
        ...state,
        loading: true,
      }
    case GET_IELTS_READING_MCQ_QUES_SUCCESS:
      return {
        ...state,
        ques: action.payload.data,
        loading: false,
      }
    case GET_IELTS_READING_MCQ_QUES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_IELTS_READING_QUES_NUM:
      return {
        ...state,
        loading: true,
      }
    case GET_IELTS_READING_QUES_NUM_SUCCESS:
      return {
        ...state,
        questionNumber: action.payload.data,
        loading: false,
      }
    case GET_IELTS_READING_QUES_NUM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_IELTS_READING_QUES_STATUS:
      return {
        ...state,
        loading: true,
      }
    case GET_IELTS_READING_QUES_STATUS_SUCCESS:
      return {
        ...state,
        questionStatus: action.payload.data,
        loading: false,
      }
    case GET_IELTS_READING_QUES_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case UPDATE_IELTS_READING_QUES_STATUS:
      return {
        ...state,
        adding: true,
      }
    case UPDATE_IELTS_READING_QUES_STATUS_SUCCESS:
      return {
        ...state,
        adding: false,
      }
    case UPDATE_IELTS_READING_QUES_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        adding: false,
      }
    default:
      return state
  }
}

export default QuesReducer
