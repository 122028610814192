import React from 'react';
import { Accordion } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import ResultUnderProcess from '../../../components/resultUnderProcess/ResultUnderProcess';
import { imageBaseUrl } from '../../../helpers/api_helper';
import quesImage from './img/130.png';
import './review-answer.scss';
const ReviewAnswerList = ({ history, results }) => {
  // const { studentId, setId } = useParams();
  const particularSet = results;
  console.log(` ReviewAnswerList ~ particularSet`, particularSet);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getParticularSet(authToken, { userId, setId }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const getPhotoUrl = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return quesImage
  }
  return (
    <>
      <div className="ques-answer-list-review-main-wrap">
        <div className="ques-answer-list-review-inner-wrap">
          {particularSet?.status === 2 ? (
            <div className="single-ques-answer-list">
              <Accordion
                defaultActiveKey={['0', '1', '2', '3', '4']}
                alwaysOpen
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="ques-review-header">
                      <h5>Writing Task 1 : Question</h5>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="ques-review-details">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: particularSet?.questions?.[0]?.q,
                        }}
                      ></p>
                      {particularSet?.questions?.[0]?.image ? (
                        <div className=" my-5 text-center">
                          <img
                         
                            src={getPhotoUrl(particularSet?.questions?.[0]?.image)}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                      ) : null}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>{' '}
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <div className="ques-review-header">
                      <h5>Writing Task 1 : Answer</h5>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="ques-review-details">
                      <p>{particularSet?.answer?.[0]?.answer}</p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>{' '}
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <div className="ques-review-header">
                      <h5>Writing Task 1 : Result</h5>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="ques-review-details">
                      <div className="ques-review-answer-result">
                        <div className="ques-review-answer-single-result">
                          <div className="result-answer-title">Grammar</div>
                          <div className="result-answer-dec">
                            <input
                              type="number"
                              value={particularSet?.answer?.[0]?.grammar}
                              readOnly
                              placeholder="8"
                            />
                          </div>
                        </div>{' '}
                        <div className="ques-review-answer-single-result">
                          <div className="result-answer-title">Spelling</div>
                          <div className="result-answer-dec">
                            <input
                              type="number"
                              value={particularSet?.answer?.[0]?.spelling}
                              readOnly
                              placeholder="8.5"
                            />
                          </div>
                        </div>{' '}
                        <div className="ques-review-answer-single-result">
                          <div className="result-answer-title">
                            Admin Evaluation
                          </div>
                          <div className="result-answer-dec">
                            <input
                              type="number"
                              placeholder="Mark"
                              readOnly
                              value={
                                particularSet?.answer?.[0]?.adminMark || ''
                              }
                            />
                          </div>
                        </div>{' '}
                        <div className="ques-review-answer-single-result">
                          <div className="result-answer-title">
                            Task Achievement
                          </div>
                          <div className="result-answer-dec">
                            <input
                              type="number"
                              value={
                                particularSet?.answer?.[0]?.taskAchievement
                              }
                              readOnly
                              placeholder=""
                            />
                          </div>
                        </div>{' '}
                        <div className="ques-review-answer-single-result">
                          <div className="result-answer-title">Coherence</div>
                          <div className="result-answer-dec">
                            <input
                              type="number"
                              value={particularSet?.answer?.[0]?.coherence}
                              readOnly
                              placeholder=""
                            />
                          </div>
                        </div>{' '}
                        <div className="ques-review-answer-single-result">
                          <div className="result-answer-title">
                            Under Length
                          </div>
                          <div className="result-answer-dec">
                            <input
                              type="number"
                              value={particularSet?.answer?.[0]?.underlength}
                              readOnly
                              placeholder=""
                            />
                          </div>
                        </div>{' '}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <hr className="my-5" />
              <Accordion
                defaultActiveKey={['0', '1', '2', '3', '4']}
                alwaysOpen
              >
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="ques-review-header">
                      <h5>Writing Task 2 : Question</h5>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="ques-review-details">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: particularSet?.questions?.[1]?.q,
                        }}
                      ></p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>{' '}
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <div className="ques-review-header">
                      <h5>Writing Task 2 : Answer</h5>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="ques-review-details">
                      <p>{particularSet?.answer?.[1]?.answer}</p>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>{' '}
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <div className="ques-review-header">
                      <h5>Writing Task 2 : Result</h5>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="ques-review-details">
                      <div className="ques-review-answer-result">
                        <div className="ques-review-answer-single-result">
                          <div className="result-answer-title">Grammar</div>
                          <div className="result-answer-dec">
                            <input
                              type="number"
                              value={particularSet?.answer?.[1]?.grammar}
                              readOnly
                              placeholder="8"
                            />
                          </div>
                        </div>{' '}
                        <div className="ques-review-answer-single-result">
                          <div className="result-answer-title">Spelling</div>
                          <div className="result-answer-dec">
                            <input
                              type="number"
                              value={particularSet?.answer?.[1]?.spelling}
                              readOnly
                              placeholder="8.5"
                            />
                          </div>
                        </div>{' '}
                        <div className="ques-review-answer-single-result">
                          <div className="result-answer-title">
                            Admin Evaluation
                          </div>
                          <div className="result-answer-dec">
                            <input
                              type="number"
                              placeholder="Mark"
                              readOnly
                              value={
                                particularSet?.answer?.[1]?.adminMark || ''
                              }
                            />
                          </div>
                        </div>{' '}
                        <div className="ques-review-answer-single-result">
                          <div className="result-answer-title">
                            Task Achievement
                          </div>
                          <div className="result-answer-dec">
                            <input
                              type="number"
                              value={
                                particularSet?.answer?.[1]?.taskAchievement
                              }
                              readOnly
                              placeholder=""
                            />
                          </div>
                        </div>{' '}
                        <div className="ques-review-answer-single-result">
                          <div className="result-answer-title">Coherence</div>
                          <div className="result-answer-dec">
                            <input
                              type="number"
                              value={particularSet?.answer?.[1]?.coherence}
                              readOnly
                              placeholder=""
                            />
                          </div>
                        </div>{' '}
                        <div className="ques-review-answer-single-result">
                          <div className="result-answer-title">
                            Under Length
                          </div>
                          <div className="result-answer-dec">
                            <input
                              type="number"
                              value={particularSet?.answer?.[1]?.underlength}
                              readOnly
                              placeholder=""
                            />
                          </div>
                        </div>{' '}
                        {/* <div className="ques-review-answer-single-result">
                        <div className="result-answer-title">Total Mark</div>
                        <div className="result-answer-dec">
                          <input type="number" readOnly placeholder="Mark" />
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          ) : (
            <ResultUnderProcess />
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(ReviewAnswerList);
