import ComplateQuesForm from 'components/LiteningExam/Question2/ComplateQuesForm';
import ComparisonType from 'components/ReadingExam/QuestionComponent/ComparisonType';
import FillGaps from 'components/ReadingExam/QuestionComponent/FillGaps';
import FillGapsWithClue from 'components/ReadingExam/QuestionComponent/FillGapsWithClue';
import HeadMatchingType from 'components/ReadingExam/QuestionComponent/HeadMatchingType';
import McqType from 'components/ReadingExam/QuestionComponent/McqType';
import MultiSelectType from 'components/ReadingExam/QuestionComponent/MultiSelectType';
import ShortAnswer from 'components/ReadingExam/QuestionComponent/ShortAnswer';
import TrueFalseType from 'components/ReadingExam/QuestionComponent/TrueFalseType';
import YesNoType from 'components/ReadingExam/QuestionComponent/YesNoType';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const mcqType = 'mcq';
const trueFalseQues = 'trueFalse';
const comprehensionQues = 'comprehension';
const YesNoQues = 'YesNoQues';
const HeadingMatching = 'headingMatching';
const multipleChoice = 'multipleSelection';
const fillGapsWords = 'fillGapsWords';
const fillGapsWithoutWords = 'fillGaps';
export const fillGapsOne = 'fillGapsOne';
export const fillGapsTwo = 'fillGapsTwo';
export const fillGapsThree = 'fillGapsThree';
const tableOne = 'tableOne';
const tableTwo = 'tableTwo';
const tableThree = 'tableThree';
const formOne = 'formOne';
const formTwo = 'formTwo';
const formThree = 'formThree';
const noteOne = 'noteOne';
const noteTwo = 'noteTwo';
const noteThree = 'noteThree';
const sortAnswerOne = 'sortAnswerOne';
const sortAnswerTwo = 'sortAnswerTwo';
const sortAnswerThree = 'sortAnswerThree';

const Questions = ({ question, index, handlePaly, data, view, ans }) => {
  const { listeningQuestion } = useSelector((state) => ({
    listeningQuestion: state.resultReducer.results?.setId?.listeningAudioModel,
  }));
  // console.log(`listeningQuestion`, listeningQuestion);

  const findPreviousPassageLastQuestion = useMemo(() => {
    let questionPosition = 0;
    if (index > 0) {
      for (let i = 0; i < index; i++) {
        const previous = listeningQuestion?.[i];
        const lastQuestion =
          previous?.questions?.[previous?.questions?.length - 1];
        questionPosition +=
          lastQuestion?.Que?.question?.[lastQuestion?.Que?.question?.length - 1]
            ?.questionNumber;
      }
    }
    return questionPosition;
  }, [listeningQuestion, index]);

  const findQuestionsNumberRange = `${
    question?.[0]?.Que?.question?.[0]?.questionNumber +
    findPreviousPassageLastQuestion
  } - 
    ${
      question?.[question?.length - 1]?.Que?.question[
        question?.[question?.length - 1]?.Que?.question.length - 1
      ]?.questionNumber + findPreviousPassageLastQuestion
    }`;

  return (
    <React.Fragment>
      <div className="ques-right-part-container p-2">
        <div className="ques-section-main-wrapper mb-5">
          <div className="ques-title">
            <p>
              Section {index + 1} : Question {findQuestionsNumberRange}
            </p>
            <div className="py-2">
              <button
                className="btn"
                style={{
                  background: '#E6EAFD',
                  color: '#0052CC',
                  padding: '2px 5px',
                  fontSize: '13px',
                }}
                onClick={() => handlePaly(data)}
              >
                <i className="bx bx-headphone me-1"></i>
                Listen from here
              </button>
            </div>
          </div>
          {question?.map((data, idx) => {
            if (!data.Que) {
              return null;
            }
            if (data?.Que?.questype === multipleChoice) {
              return (
                <MultiSelectType
                  multipleQuesType={data?.Que?.question}
                  key={idx}
                  idx={idx}
                  ans={ans}
                  currentIndex={index}
                  currentPassage={listeningQuestion?.[index]}
                  questionNumber={findPreviousPassageLastQuestion}
                  view
                />
              );
            } else if (data?.Que?.questype === trueFalseQues) {
              return (
                <TrueFalseType
                  TrueFalseQuesType={data?.Que?.question}
                  idx={idx}
                  key={idx}
                  ans={ans}
                  currentIndex={index}
                  currentPassage={listeningQuestion?.[index]}
                  questionNumber={findPreviousPassageLastQuestion}
                  view
                />
              );
            } else if (data?.Que?.questype === YesNoQues) {
              return (
                <YesNoType
                  yesNoQues={data?.Que?.question}
                  idx={idx}
                  key={idx}
                  ans={ans}
                  currentIndex={index}
                  currentPassage={listeningQuestion?.[index]}
                  view
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (data?.Que?.questype === comprehensionQues) {
              return (
                <ComparisonType
                  comprehensionType={data?.Que?.question}
                  view
                  options={data?.Que?.options}
                  idx={idx}
                  ans={ans}
                  key={idx}
                  currentIndex={index}
                  currentPassage={listeningQuestion?.[index]}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (data?.Que?.questype === mcqType) {
              return (
                <McqType
                  questions={data?.Que?.question}
                  idx={idx}
                  key={idx}
                  ans={ans}
                  view
                  currentIndex={index}
                  currentPassage={listeningQuestion?.[index]}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (data?.Que?.questype === HeadingMatching) {
              return (
                <HeadMatchingType
                  headingMatchingType={data?.Que?.question}
                  options={data?.Que?.options}
                  idx={idx}
                  key={idx}
                  ans={ans}
                  currentIndex={index}
                  view
                  currentPassage={listeningQuestion?.[index]}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (
              data?.Que?.questype === fillGapsWithoutWords ||
              data?.Que?.questype === fillGapsOne ||
              data?.Que?.questype === fillGapsTwo ||
              data?.Que?.questype === fillGapsThree
            ) {
              return (
                <FillGaps
                  questions={data?.Que?.question}
                  options={data?.Que?.options}
                  idx={idx}
                  key={idx}
                  view
                  ans={ans}
                  currentPassage={listeningQuestion?.[index]}
                  currentIndex={index}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (data?.Que?.questype === fillGapsWords) {
              return (
                <FillGapsWithClue
                  questions={data?.Que?.question}
                  options={data?.Que?.options}
                  idx={idx}
                  key={idx}
                  ans={ans}
                  view
                  currentPassage={listeningQuestion?.[index]}
                  currentIndex={index}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (
              data?.Que?.questype === tableOne ||
              data?.Que?.questype === tableTwo ||
              data?.Que?.questype === tableThree ||
              data?.Que?.questype === formOne ||
              data?.Que?.questype === formTwo ||
              data?.Que?.questype === formThree ||
              data?.Que?.questype === noteOne ||
              data?.Que?.questype === noteTwo ||
              data?.Que?.questype === noteThree
            ) {
              return (
                <ComplateQuesForm
                  questions={data?.Que}
                  options={data?.Que?.options}
                  idx={idx}
                  key={idx}
                  ans={ans}
                  view
                  currentPassage={listeningQuestion?.[index]}
                  currentIndex={index}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else if (
              data?.Que?.questype === sortAnswerOne ||
              data?.Que?.questype === sortAnswerTwo ||
              data?.Que?.questype === sortAnswerThree
            ) {
              return (
                <ShortAnswer
                  questions={data?.Que?.question}
                  question={data?.Que}
                  options={data?.Que?.options}
                  idx={idx}
                  key={idx}
                  view
                  ans={ans}
                  currentPassage={listeningQuestion?.[index]}
                  currentIndex={index}
                  questionNumber={findPreviousPassageLastQuestion}
                />
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Questions;
