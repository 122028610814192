import PropTypes from "prop-types"
import React from "react"
import { withRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"

const NonAuthLayout = props => {
  return (
    <React.Fragment>
      <ToastContainer />
      {props.children}
    </React.Fragment>
  )
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
}

export default withRouter(NonAuthLayout)
