export const ADD_AUTHOR = "ADD_AUTHOR"
export const ADD_AUTHOR_SUCCESS = "ADD_AUTHOR_SUCCESS"
export const ADD_AUTHOR_FAIL = "ADD_AUTHOR_FAIL"


export const GET_AUTHOR = "GET_AUTHOR"
export const GET_AUTHOR_SUCCESS = "GET_AUTHOR_SUCCESS"
export const GET_AUTHOR_FAIL = "GET_AUTHOR_FAIL"

export const ADD_BLOG = "ADD_BLOG"
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCCESS"
export const ADD_BLOG_FAIL = "ADD_BLOG_FAIL"
export const GET_BLOG_POST = "GET_BLOG_POST";
export const GET_BLOG_POST_SUCCESS = "GET_BLOG_POST_SUCCESS";
export const GET_BLOG_POST_FAIL = "GET_BLOG_POST_FAIL";