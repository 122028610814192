import { Field, FieldArray, Form, Formik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Button, Label } from "reactstrap"

import { toaster } from "helpers/Custom/Toaster"
// import { addAptitudeQuestion } from "../../../Store/Admin/CorporateQuestions/CorporateQuestionSlice";
import "../../gmat.scss"

import { graphQuestionCreateSchema } from "components/Schemas/QuestionSchemas"
// import QuestionTopCard from "./Common/QuestionTopCard";
import CardComponent from "components/Common/CardComponent"
import QuestionTopCard from "components/Common/QuestionTopCard"
import CustomInput from "components/InputComponent/CustomInput"
import FileUpload from "components/InputComponent/FileUpload"
import QuillComponent from "components/InputComponent/QuillComponent"
import { createHash } from "helpers/Custom/Hash"
import { checkImage } from "helpers/Custom/Questions"
import { del } from "helpers/api_helper"
import { addGraphicalQues } from "store/GMAT/IntegratedReasoning/actions"
import { getSingleQuantitativeQues } from "store/GMAT/Quantative/actions"
import InnerLayer from "../../../../Layout/InnerLayer"
import PageHeader from "../../../../Layout/PageHeader"
const section = [
  {
    name: "data Sufficency",
    value: 1,
  },
  {
    name: "problem Solving",
    value: 2,
  },
]
const difficultyLevel = [
  {
    name: "easy",
    value: 1,
  },
  {
    name: "medium",
    value: 2,
  },
  {
    name: "difficult",
    value: 3,
  },
]
const quesType = [
  {
    name: "single",
    value: 1,
  },
  {
    name: "multi",
    value: 2,
  },
]
const initialValues = {
  questionDifficulties: "",
  description: "",
  descriptionHeader: "",
  photo: null,
  selection: "",
  columnNameList: null,
  rowDataList: null,
  questionHeading: "",
  questionType: null,
  answerOptions: null,
  options: [
    {
      option: "",
      opt: [""],
      answer: "",
    },
  ],
}

const GraphQuesCreate = ({ history, api }) => {
  const textAreaRef = useRef(null)
  const { passageId, questionId, serviceId, section, type } = useParams()
  console.log("questionId", questionId)
  const [listValue, setListValue] = useState(null)
  const [isAddMore, setIsAddMore] = useState(false)
  const [request, setRequest] = useState(false)
  const [sectionData, setSection] = useState("")
  const title = ` Question Type`
  const title2 = questionId ? `Edit  Question` : `Create Question`
  const breadcrumbItems = [
    {
      title: title,
      link: `/Quantative-ques-type`,
    },
    {
      title: title2,
      link: `#`,
    },
  ]
  const dispatch = useDispatch()
  const { singleQuantitativeQues, uploading, authToken } = useSelector(
    state => ({
      loading: state.analyticalReducer.loading,
      adding: state.analyticalReducer.adding,
      questionNumber: state.analyticalReducer.questionNumber,
      singleQuantitativeQues: state.QuantitativeReducer.singleQuantitativeQues,
      questionGroup: state.analyticalReducer.questionGroup,
      uploading: state.analyticalReducer.uploading,
      authToken: state.Login.token,
    })
  )
  console.log(`singleQuantitativeQues`, singleQuantitativeQues)

  useEffect(() => {
    if (questionId) {
      dispatch(getSingleQuantitativeQues(authToken, questionId))
    }
  }, [questionId])

  useEffect(() => {
    if (questionId && !!singleQuantitativeQues) {
      const value = { ...listValue }
      value.difficultyLevel = singleQuantitativeQues?.difficultyLevel || ""
      value.q = singleQuantitativeQues?.q || ""
      value.questype = singleQuantitativeQues?.questype || ""
      value.options = singleQuantitativeQues?.options?.map(el => {
        let obj = {}
        obj.value = el
        if (checkImage(el)) {
          obj.isImage = true
        } else {
          obj.isImage = false
        }
        return obj
      }) || [""]
      // value.answer = singleQuantitativeQues?.answer?.[0];
      value.explanation = singleQuantitativeQues?.explanation || ""
      value.image = singleQuantitativeQues?.image || null

      value.rightAnswer = singleQuantitativeQues?.rightAnswer?.map(el =>
        el?.trim()
      )
      setListValue(value)
    }
  }, [singleQuantitativeQues])

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    console.log("values", values)
    const {
      questionDifficulties,
      selection,
      description,
      descriptionHeader,
      questionHeading,
      ...rest
    } = values
    const obj = {
      ...rest,
      selection: selection * 1,
      questionDifficulties: questionDifficulties * 1,
      description: createHash(description),
      descriptionHeader: createHash(descriptionHeader),
      questionHeading: createHash(questionHeading),
      section: section * 1,
    }
    const option = {
      isMore: isAddMore,
      resetForm,
      setSubmitting,
      setIsAddMore,
      id: questionId,
      passageId,
      history,
      serviceId,
      type,
    }
    console.log(`obj`, obj, option)
    dispatch(addGraphicalQues(obj, authToken, option))
    // setSubmitting(false);
  }

  const removeFile = async (id, setFieldValue, name) => {
    let success = false
    setRequest(true)
    try {
      const url = `/data-dump/delete-file/${id}`
      const response = await del(url)
      if (response) {
        success = true
        setFieldValue(name, "")
      }
    } catch (err) {
      toaster("error", "Remove file failed")
    } finally {
      setRequest(false)
    }
    return success
  }

  function copyToClipboard(e) {
    textAreaRef.current.select()
    document.execCommand("copy")
    e.target.focus()
    toaster("success", "Url copied successful")
  }
  const handleSection = data => {
    if (data?.value) {
      setSection(data?.value)
    } else {
      setSection("")
    }
  }
  return (
    <React.Fragment>
      <InnerLayer>
        <PageHeader title={title2} breadcrumbItems={breadcrumbItems} />
        <Row className="create_questions">
          <Col md="12">
            <QuestionTopCard
              history={history}
              title={`${questionId ? "Update" : "Create"} Graphical question`}
            />
            <Row>
              <Col md="6">
                {" "}
                <CardComponent style={{ marginTop: "5px" }}>
                  <h5>Type question</h5>
                  <br />
                  <Formik
                    initialValues={listValue || initialValues}
                    validationSchema={graphQuestionCreateSchema}
                    onSubmit={onSubmit}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      errors,
                      setFieldValue,
                      isSubmitting,
                      handleSubmit,
                    }) => (
                      <Form>
                        {console.log("values,", values, errors)}

                        <Row className="mb-3">
                          <Col md="12 mb-3">
                            <Field
                              label="Difficulty level"
                              name="questionDifficulties"
                              type="select"
                              id={"questionDifficulties"}
                              component={CustomInput}
                            >
                              <option value="">Select...</option>
                              <option value="1">Easy</option>
                              <option value="2">Medium</option>
                              <option value="3">difficult</option>
                            </Field>
                          </Col>
                          <Col md="12 mb-3">
                            <Field
                              label="Description"
                              name="description"
                              component={QuillComponent}
                            />
                          </Col>
                          <Col md="12 mb-3">
                            <Field
                              label="Description Header"
                              name="descriptionHeader"
                              component={QuillComponent}
                            />
                          </Col>
                          <Col md="12 mb-3">
                            <Field
                              label="Question header"
                              name="questionHeading"
                              id={"questionHeading"}
                              component={QuillComponent}
                            />
                          </Col>
                          <Col md="12 mb-3">
                            <Field
                              label="Question type"
                              name="questionType"
                              type="text"
                              id={"question"}
                              component={CustomInput}
                              placeholder="Type description"
                            />
                          </Col>
                          <Col md="12 mb-3">
                            <Field
                              label="Selection"
                              name="selection"
                              type="select"
                              id={"questype"}
                              component={CustomInput}
                            >
                              <option value="">Select...</option>
                              <option value="1">single</option>
                              <option value="2">multi</option>
                            </Field>
                          </Col>
                          <Col md="12 mb-3">
                            <Field
                              name="photo"
                              label={"Upload Image"}
                              type="file"
                              component={FileUpload}
                              isRequired
                              isFull
                            />
                          </Col>

                          <Col md="12" className="mb-3">
                            <div className="table-analysis-header-part">
                              <FieldArray
                                name="options"
                                render={arrayHelpers => (
                                  <Row>
                                    {values?.options?.map((value, index) => (
                                      <React.Fragment key={index}>
                                        <Col
                                          md="12"
                                          className="position-relative mb-3"
                                        >
                                          <Label
                                            className="d-flex justify-content-between align-items-center gap-2"
                                            htmlFor={`options.${index}.option`}
                                          >
                                            <span className="text-nowrap">
                                              {`Question ${index + 1}`}
                                            </span>
                                          </Label>

                                          <Field
                                            name={`options.${index}.option`}
                                            type={"text"}
                                            id={`options.${index}.option`}
                                            // label={`Option ${index + 1}`}
                                            component={CustomInput}
                                            placeholder="Type Question"
                                            isArray
                                            className="mb-3"
                                          />
                                          <FieldArray
                                            name={`options[${index}].opt`}
                                            render={arrayHelpers => (
                                              <Row>
                                                {value?.opt?.map(
                                                  (value, idx) => (
                                                    <React.Fragment key={idx}>
                                                      <Col
                                                        md="12"
                                                        className="position-relative mb-3"
                                                      >
                                                        <Label
                                                          className="d-flex justify-content-between align-items-center gap-2"
                                                          htmlFor={`options[${index}].opt[${idx}]`}
                                                        >
                                                          <span className="text-nowrap">
                                                            {`option ${
                                                              idx + 1
                                                            }`}
                                                          </span>
                                                        </Label>

                                                        <Field
                                                          name={`options[${index}].opt[${idx}]`}
                                                          type={"text"}
                                                          id={`options[${index}].opt[${idx}]`}
                                                          // label={`Option ${index + 1}`}
                                                          component={
                                                            CustomInput
                                                          }
                                                          placeholder="Type option"
                                                          isArray
                                                        />

                                                        {idx > 0 ? (
                                                          <div
                                                            className="position-absolute"
                                                            style={{
                                                              top: "25px",
                                                              // transform : ""
                                                              width: "initial",
                                                              right: "1.5%",
                                                            }}
                                                            onClick={() =>
                                                              arrayHelpers.remove(
                                                                idx
                                                              )
                                                            }
                                                          >
                                                            <button
                                                              className="btn-sm removeShadow"
                                                              style={{
                                                                backgroundColor:
                                                                  "transparent",
                                                                border: "none",
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              <i className="bx bx-trash text-danger"></i>
                                                            </button>
                                                          </div>
                                                        ) : null}
                                                      </Col>
                                                    </React.Fragment>
                                                  )
                                                )}

                                                <Col md="12">
                                                  <button
                                                    type="button"
                                                    className="d-inline-block btn button-add mb-3"
                                                    onClick={() =>
                                                      arrayHelpers.push("")
                                                    }
                                                  >
                                                    <i className="bx bx-plus"></i>{" "}
                                                    Add Another Option
                                                  </button>
                                                </Col>
                                              </Row>
                                            )}
                                          />
                                          <Field
                                            name={`options.${index}.answer`}
                                            type={"text"}
                                            id={`options.${index}.answer`}
                                            label={`Answer ${index + 1}`}
                                            component={CustomInput}
                                            placeholder="Type Answer option"
                                            isArray
                                          />
                                        </Col>
                                      </React.Fragment>
                                    ))}

                                    <Col md="12">
                                      <button
                                        type="button"
                                        className="d-inline-block btn button-add"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            option: "",
                                            opt: [""],
                                            answer: "",
                                          })
                                        }
                                      >
                                        <i className="bx bx-plus"></i> Add
                                        Another Question
                                      </button>
                                    </Col>
                                  </Row>
                                )}
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* <FormikArray values={values} name="opt" labelName="Option" /> */}

                        <Row className="mb-3">
                          <Col md="12" className="d-flex justify-content-end">
                            <Button
                              color="warning"
                              outline
                              className="me-2 btn btn-outline-blue"
                              disabled={isSubmitting || uploading}
                              type="submit"
                            >
                              {isSubmitting
                                ? "Submitting..."
                                : questionId
                                ? "Update"
                                : "Save"}
                            </Button>
                            {!questionId ? (
                              <button
                                color="primary"
                                style={{ color: "white" }}
                                disabled={isSubmitting || uploading}
                                type="button"
                                onClick={() => {
                                  setIsAddMore(true)
                                  handleSubmit()
                                }}
                                className="btn button-blue"
                              >
                                {isSubmitting
                                  ? "Submitting..."
                                  : "Create more question"}
                              </button>
                            ) : null}
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardComponent>
              </Col>
              <Col md="6"></Col>
            </Row>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default GraphQuesCreate
