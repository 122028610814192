import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import React, { useEffect } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { getAllAnswer } from "store/ReviewAnswer/actions"
import InnerLayer from "../../Layout/InnerLayer"
import profileImage from "./img/profile.svg"
import "./review-answer.scss"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Review Answer",
    link: "/review-answer",
  },
  {
    title: `Writing Review`,
    link: "#",
  },
]

const ReviewAnswer = ({ history }) => {
  const dispatch = useDispatch()
  // const useSelector = useSelector();
  const { allAnswer, authToken, loading } = useSelector(state => ({
    allAnswer: state.review.allAnswer,
    authToken: state.Login.token,
    loading: state.review.loading,
  }))

  console.log("Review", allAnswer)

  useEffect(() => {
    dispatch(getAllAnswer(authToken))
  }, [])

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"Review Answer"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <div className="back-arrow-icon-content-wrap">
                <h4>
                  <span
                    onClick={() => history.goBack()}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bx bx-arrow-back"></i>
                  </span>
                  Student List
                </h4>
              </div>
              <div className="d-flex">
                <div className="search-box me-2">
                  <div className="position-relative">
                    <input
                      type="search"
                      placeholder="Search..."
                      // value={keyword}
                      // onChange={handleSearchChange}
                      className="form-control"
                    />
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>
                <div>
                  <div className="position-relative">
                    <Form.Select>
                      <option>All</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>
          </CardComponent>
        </Row>
        <div>
          <Row>
            {loading ? (
              <div>
                <Loader />
              </div>
            ) : allAnswer?.length ? (
              allAnswer?.map((data, idx) => (
                <Col lg={3} key={idx}>
                  <Link
                    to={`/review-answer/writing/${data?.setId?._id}/${data?.student?._id}`}
                  >
                    <div className="single-result-item-wrap">
                      <span>
                        <img src={profileImage} alt="" />
                      </span>
                      <span>
                        <h5
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          {data?.student?.fullName}{" "}
                          {data[1].adminCheck === 1 &&
                          data[2].adminCheck === 1 ? (
                            <span
                              style={{
                                width: "70px",
                                height: "30px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "rgb(52 201 52)",
                                color: "white",
                                borderRadius: "8px",
                              }}
                            >
                              {data[1].adminCheck === 1 &&
                              data[2].adminCheck === 1 ? (
                                <small className="fw-bold">
                                  {" "}
                                  score: {data?.mark}
                                </small>
                              ) : (
                                ""
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                        </h5>

                        <span>Set : {data?.setId?.setName}</span>
                        <p
                          className={data.status === 2 ? "reviewed-answer" : ""}
                        >
                          {data.status === 2 ? "Reviewed" : "Under Review"}
                        </p>
                      </span>
                    </div>
                  </Link>
                </Col>
              ))
            ) : (
              <div>No Data</div>
            )}
            {/* <Col lg={3}>
            <Link to="/review-answer/answer-list">
              <div className="single-result-item-wrap">
                <span>
                  <img src={profileImage} alt="" />
                </span>
                <span>
                  <h5>Floyd Miles</h5>
                  <span>Set : Set Test 1</span>
                  <p>Under Review</p>
                </span>
              </div>
            </Link>
          </Col>{" "}
          <Col lg={3}>
            <div className="single-result-item-wrap">
              <span>
                <img src={profileImage} alt="" />
              </span>
              <span>
                <h5>Floyd Miles</h5>
                <span>Set : Set Test 1</span>
                <p>Under Review</p>
              </span>
            </div>
          </Col>{" "}
          <Col lg={3}>
            <div className="single-result-item-wrap">
              <span>
                <img src={profileImage} alt="" />
              </span>
              <span>
                <h5>Floyd Miles</h5>
                <span>Set : Set Test 1</span>
                <p>Under Review</p>
              </span>
            </div>
          </Col>{" "}
          <Col lg={3}>
            <div className="single-result-item-wrap">
              <span>
                <img src={profileImage} alt="" />
              </span>
              <span>
                <h5>Floyd Miles</h5>
                <span>Set : Set Test 1</span>
                <p className="reviewed-answer">Reviewed</p>
              </span>
            </div>
          </Col> */}
          </Row>
          {/* <ReviewAnswerList /> */}
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(ReviewAnswer)
