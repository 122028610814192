
import { printToLetter } from '../../helpers/Custom/printNumberToLetter';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';


const LeftSide = ({ passage, question }) => {
  console.log('passage',passage, question);
  const { questions, currentIndex } = useSelector((state) => ({
    questions: state.FacilitatorQuesReducer.questions,
    currentIndex: state.FacilitatorQuesReducer.currentIndex,
    ansQuestion: state.FacilitatorQuesReducer.ansQuestion,
    ans: state.FacilitatorQuesReducer.ans,
  }));
  const findPreviousPassageLastQuestion = useMemo(() => {
    let questionPosition = 0;
    if (currentIndex > 0) {
      for (let i = 0; i < currentIndex; i++) {
        const previous = questions[i];
        const lastQuestion =
          previous?.questions?.[previous?.questions?.length - 1];
        questionPosition +=
          lastQuestion?.Que?.question?.[lastQuestion?.Que?.question?.length - 1]
            .questionNumber;
      }
    }
    return questionPosition;
  }, [questions, currentIndex]);
  const findQuestionsNumberRange = `${
    question?.[0]?.Que?.question?.[0]?.questionNumber +
    findPreviousPassageLastQuestion
  } - 
  ${
    question?.[question?.length - 1]?.Que?.question[
      question?.[question?.length - 1]?.Que?.question.length - 1
    ]?.questionNumber + findPreviousPassageLastQuestion
  }`;
  return (
    <React.Fragment>
      <div className="student-exam-ques-left-content">
        <div className="ques-passage-container">
          <div className="q-p-header">
            <h4>{passage?.readingPessageTopicName}</h4>
            <p>
              You should spend about 20 minutes on questions{' '}
              {findQuestionsNumberRange}, which are based on reading passage 1
              below.
            </p>
          </div>
          {(passage?.readingPessageDescription || []).map((data, idx) => {
            return (
              <div className="q-p-single-part mb-3" key={idx}>
                <div className="passage-single-left-part">
                  <h4>{printToLetter(idx + 1)}</h4>
                </div>
                <div className="passage-single-content-part">
                  <p>{data.passageInfo}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default LeftSide;
