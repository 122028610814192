import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { getStudentCount } from "store/actions"
import InnerLayer from "../../Layout/InnerLayer"
import UserTable from "./UserTable"
const statusData = [
  {
    name: "All",
    value: 1,
  },
  {
    name: "Active",
    value: 2,
  },
  {
    name: "Pending",
    value: 3,
  },
]
const UserList = () => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState(1)

  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [endDate, setEndDate] = useState(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  )
  const { authtoken, studentCount, studentCountLoading } = useSelector(
    state => ({
      authtoken: state.Login.token,
      studentCount: state.studentReducer.studentCount,
      studentCountLoading: state.studentReducer.studentCountLoading,
    })
  )

  const handleExcel = async () => {
    setLoadingExcel(true)
    let userData = []

    await get(`admin/get-students`, {
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(response => {
        console.log("response", response)
        response?.getData?.forEach((user, index) => {
          let data = {}
          data.UserName = user?.fullName
          data.Email = user?.email
          data.RegistrationDate = user?.createdAt?.slice(0, 10)
          data.status = user?.accountActivateToken ? "In active" : "Active"
          data.role = user?.role
          data.PhoneNumber = user?.phoneNumber
          data.University = user?.university?.universityName

          userData.push(data)
        })
      })
      .catch(error => {
        console.log("kkkk")
        // setResults((prev) => [...prev]);
      })

    downloadxls(userData)
  }
  const downloadxls = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, "User List.xlsx")
    setLoadingExcel(false)
  }
  const handleStatus = data => {
    console.log("daata", data)
    if (data?.value) {
      setStatus(data?.value)
    } else {
      setStatus(1)
    }
  }

  const handleStartDate = e => {
    const value = e.target.value

    setStartDate(value)
  }
  const handleEndDate = e => {
    const value = moment(e.target.value).add(1, "days").format("YYYY-MM-DD")
    console.log("value", value)

    setEndDate(value)
  }
  useEffect(() => {
    dispatch(getStudentCount(authtoken, startDate, endDate, status))
  }, [startDate, endDate, status])
  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Student List"
        mainTitle={"Dashboard"}
        subTitle={"Student List"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <CardComponent className="position-relative">
          <div className="date-order-excel d-flex">
            <AvForm className="range-excel download">
              <label htmlFor="range-excel download">From date</label>
              <AvField
                name="startTime"
                id="example-time-input"
                className="form-control "
                type="date"
                value={moment(new Date()).format("YYYY-MM-DD")}
                onChange={handleStartDate}
                errorMessage="Enter Start Time"
                validate={{ required: { value: true } }}
              ></AvField>
              <label htmlFor="range-excel download">To Date</label>
              <AvField
                name="startTime"
                id="example-time-input"
                className="form-control "
                type="date"
                value={moment(new Date()).format("YYYY-MM-DD")}
                onChange={handleEndDate}
                errorMessage="Enter Start Time"
                validate={{ required: { value: true } }}
              ></AvField>
              <Select
                name="status"
                className="select-form-width"
                classNamePrefix="select2-selection"
                placeholder="Select Status"
                cacheOptions
                getOptionLabel={e => e.name}
                getOptionValue={e => e.value}
                isClearable
                options={statusData}
                onChange={handleStatus}
              />
            </AvForm>
          </div>
        </CardComponent>
        {studentCountLoading ? (
          <Loader />
        ) : (
          <UserTable
            studentCount={studentCount}
            startDate={startDate}
            endDate={endDate}
            status={status}
          />
        )}
      </InnerLayer>
    </React.Fragment>
  )
}

export default UserList
