import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import moment from "moment"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
import { getFacilitatorSingleDay } from "store/Facilitator/actions"
import InnerLayer from "../../Layout/InnerLayer"
import profileIcon from "../ExamSet/Speaking/img/Group.png"
import clockIcon from "../ExamSet/Speaking/img/clock.svg"
const time = {
  10: `10:00 - 10:59`,
  11: `11:00 - 11:59`,
  12: `12:00 - 12:59`,
  1: `01:00 - 01:59`,
  2: `02:00 - 02:59`,
  3: `03:00 - 03:59`,
  4: `04:00 - 04:59`,
  5: `05:00 - 05:59`,
  6: `06:00 - 06:59`,
}

const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Teacher Exam",
    link: `/teacher-exam`,
  },
  {
    title: "View time slot",
    link: `#`,
  },
]

const FacilitatorSingleDay = ({ history }) => {
  const { date } = useParams()
  const dispatch = useDispatch()
  const { authToken, loading, FacilitatorSingleDay, teacherId, role } =
    useSelector(state => ({
      authToken: state.Login.token,

      teacherId: state.Login.id,
      role: state.Login.userrole,
      loading: state.FacilitatorReducer.loading,
      FacilitatorSingleDay: state.FacilitatorReducer.FacilitatorSingleDay,
    }))

  console.log(`teacherId, date`, teacherId, date)
  //   console.log(`loading`, singleDay)
  console.log("FacilitatorSingleDay", FacilitatorSingleDay)

  useEffect(() => {
    dispatch(getFacilitatorSingleDay(authToken, { teacherId, date, role }))
  }, [])

  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Facilitator"
        isMultiple
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>Time Slot</h4>
            </div>
          </CardComponent>
        </Row>
        {/* <Row>
          <CardComponent></CardComponent>
        </Row> */}

        <Row>
          {loading ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : FacilitatorSingleDay?.length > 0 ? (
            FacilitatorSingleDay.map((item, idx) => (
              <Col lg={4} className="mb-3" key={idx}>
                <div className="single-student-time-main-wrap">
                  <div className="single-exam-time-schedule">
                    <div className="single-exam-time-left-part">
                      <div className="single-exam-schedule-left-icon">
                        <img src={clockIcon} alt="" />
                      </div>
                      <div className="single-exam-schedule-left-content">
                        <h5>{time[item?.meetingTime]}</h5>
                        <p>
                          {moment(item?.date?.split("T")?.[0])?.format(
                            "DD MMM YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="single-exam-time-right-part">
                      {/* <button
                        className="btn btn-sm"
                        onClick={() =>
                          history.push(
                            `/teacher-exam/view-class/${date}/single-slot/${item?._id}`
                          )
                        }
                        disabled={!item?.studentId}
                      >
                        Join
                      </button> */}
                      {/* <button
                        className="btn btn-sm"
                        onClick={() =>
                          window.location.replace(item?.teacherId?.meetLink)
                        }
                        disabled={
                          item?.complete === 1 ||
                          !item?.studentId?._id ||
                          !findTime(item?.meetingTime, item, date)
                        }
                      >
                        Join here
                      </button> */}
                    </div>{" "}
                  </div>{" "}
                  {item?.studentId ? (
                    <div
                      className="single-exam-time-schedule"
                      onClick={() =>
                        history.push(
                          `/facilitator/view-class/${date}/single-slot/${item?._id}/${item?.packageId}/${item?.studentId?._id}`
                        )
                      }
                    >
                      <div className="single-exam-time-left-part">
                        <div className="single-exam-schedule-left-icon">
                          <img src={profileIcon} alt="" />
                        </div>
                        <div className="single-exam-schedule-left-content">
                          <h5>{item?.studentId?.fullName}</h5>
                          <p>Details</p>
                        </div>
                      </div>
                      <div className="single-exam-time-right-part-student">
                        <a>
                          <i className="bx bx-chevron-right"></i>
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="single-exam-time-schedule text-center">
                      <span className="text-center">No student admitted</span>
                    </div>
                  )}
                </div>
              </Col>
            ))
          ) : (
            <div className="text-center">
              <h3>No data found</h3>
            </div>
          )}
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(FacilitatorSingleDay)
