export const ADD_WRITTING_SET = "ADD_WRITTING_SET"
export const ADD_WRITTING_SET_SUCCESS = "ADD_WRITTING_SET_SUCCESS"
export const ADD_WRITTING_SET_FAIL = "ADD_WRITTING_SET_FAIL"

export const GET_WRITTING_SETS = "GET_WRITTING_SETS"
export const GET_WRITTING_SETS_SUCCESS = "GET_WRITTING_SETS_SUCCESS"
export const GET_WRITTING_SETS_FAIL = "GET_WRITTING_SETS_FAIL"

export const GET_WRITTING_SET_SINGLE = "GET_WRITTING_SET_SINGLE"
export const GET_WRITTING_SET_SINGLE_SUCCESS = "GET_WRITTING_SET_SINGLE_SUCCESS"
export const GET_WRITTING_SET_SINGLE_FAIL = "GET_WRITTING_SET_SINGLE_FAIL"

export const GET_WRITTING_SETS_NUMBER = "GET_WRITTING_SETS_NUMBER"
export const GET_WRITTING_SETS_NUMBER_SUCCESS =
  "GET_WRITTING_SETS_NUMBER_SUCCESS"
export const GET_WRITTING_SETS_NUMBER_FAIL = "GET_WRITTING_SETS_NUMBER_FAIL"

export const ADD_WRITTING_SETS_QUESTION = "ADD_WRITTING_SETS_QUESTION"
export const ADD_WRITTING_SETS_QUESTION_SUCCESS =
  "ADD_WRITTING_SETS_QUESTION_SUCCESS"
export const ADD_WRITTING_SETS_QUESTION_FAIL = "ADD_WRITTING_SETS_QUESTION_FAIL"
