import {
  ADD_IELTS_READING_MCQ_QUES,
  ADD_IELTS_READING_MCQ_QUES_FAIL,
  ADD_IELTS_READING_MCQ_QUES_SUCCESS,
  GET_IELTS_READING_MCQ_QUES,
  GET_IELTS_READING_MCQ_QUES_FAIL,
  GET_IELTS_READING_MCQ_QUES_SUCCESS,
  GET_IELTS_READING_QUES_NUM,
  GET_IELTS_READING_QUES_NUM_FAIL,
  GET_IELTS_READING_QUES_NUM_SUCCESS,
  GET_IELTS_READING_QUES_STATUS,
  GET_IELTS_READING_QUES_STATUS_FAIL,
  GET_IELTS_READING_QUES_STATUS_SUCCESS,
  STORE_QUESTION,
  UPDATE_IELTS_READING_QUES_STATUS,
  UPDATE_IELTS_READING_QUES_STATUS_FAIL,
  UPDATE_IELTS_READING_QUES_STATUS_SUCCESS,
} from "./actionTypes"

export const addIELTSReadingQuesMCQ = (data, history, authtoken, options) => ({
  type: ADD_IELTS_READING_MCQ_QUES,
  payload: { data, history, authtoken, options },
})

export const addIELTSReadingQuesMCQSuccess = data => ({
  type: ADD_IELTS_READING_MCQ_QUES_SUCCESS,
  payload: data,
})

export const addIELTSReadingQuesMCQFail = error => ({
  type: ADD_IELTS_READING_MCQ_QUES_FAIL,
  payload: error,
})
export const getIELTSReadingQuesMCQ = (authtoken, id, data) => ({
  type: GET_IELTS_READING_MCQ_QUES,
  payload: { authtoken, id, data },
})

export const getIELTSReadingQuesMCQSuccess = data => ({
  type: GET_IELTS_READING_MCQ_QUES_SUCCESS,
  payload: { data },
})

export const getIELTSReadingQuesMCQFail = error => ({
  type: GET_IELTS_READING_MCQ_QUES_FAIL,
  payload: error,
})

export const getIELTSReadingQuesNum = (authtoken, id, type) => ({
  type: GET_IELTS_READING_QUES_NUM,
  payload: { authtoken, id, type },
})

export const getIELTSReadingQuesNumSuccess = data => ({
  type: GET_IELTS_READING_QUES_NUM_SUCCESS,
  payload: { data },
})

export const getIELTSReadingQuesNumFail = error => ({
  type: GET_IELTS_READING_QUES_NUM_FAIL,
  payload: error,
})

export const updateIELTSReadingQuesStatus = (authtoken, id, type) => ({
  type: UPDATE_IELTS_READING_QUES_STATUS,
  payload: { authtoken, id, type },
})

export const updateIELTSReadingQuesStatusSuccess = data => ({
  type: UPDATE_IELTS_READING_QUES_STATUS_SUCCESS,
  payload: { data },
})

export const updateIELTSReadingQuesStatusFail = error => ({
  type: UPDATE_IELTS_READING_QUES_STATUS_FAIL,
  payload: error,
})

export const getIELTSReadingQuesStatus = (authtoken, id, type) => ({
  type: GET_IELTS_READING_QUES_STATUS,
  payload: { authtoken, id, type },
})

export const getIELTSReadingQuesStatusSuccess = data => ({
  type: GET_IELTS_READING_QUES_STATUS_SUCCESS,
  payload: { data },
})

export const getIELTSReadingQuesStatusFail = error => ({
  type: GET_IELTS_READING_QUES_STATUS_FAIL,
  payload: error,
})

export const storeQuestion = (name, data) => ({
  type: STORE_QUESTION,
  payload: { name, data },
})
