import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import InnerLayer from "../../Layout/InnerLayer"
import CardComponent from "../../components/Common/CardComponent"
//i18n
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Col, Row } from "reactstrap"
import {
  getDashboardAvgScore,
  getNetAmount,
  getStudentInfo,
} from "store/AdminDashboard/action"
import EnrollmentStates from "./EnrollmentStates"
import NetInCome from "./NetInCome"
import PopularPackage from "./PopularPackage"
import StudentEnrollment from "./StudentEnrollment"
import "./dashboard.scss"
import ListeningIcon from "./img/icon/ListeningIcon"
import OpenBookIcon from "./img/icon/OpenBookIcon"
import OverallIcon from "./img/icon/OverallIcon"
import SpeakingArea from "./img/icon/SpeakingArea"
import WritingIcon from "./img/icon/WritingIcon"
import totalRevenue from "./img/moneys.svg"
const Dashboard2 = () => {
  const dispatch = useDispatch()
  let [yearData, setYearData] = useState()
  const [bandScore, setBandScore] = useState(6)
  const {
    authToken,
    role,
    studentInfoData,

    studentInfoLoading,
    avgScore,
    netAmount,
    valueStatus,
    packageContribution,
    packageContributionLoading,
  } = useSelector(state => ({
    authToken: state.Login.token,
    role: state.Login.userrole,
    studentInfoData: state.AdminDashboardReducer.studentInfoData,
    netAmount: state.AdminDashboardReducer.netAmount,
    packageContribution: state.AdminDashboardReducer.packageContribution,
    packageContributionLoading:
      state.AdminDashboardReducer.packageContributionLoading,
    studentInfoLoading: state.AdminDashboardReducer.studentInfoLoading,
    avgScore: state.AdminDashboardReducer.avgScore,
    valueStatus: state.AdminDashboardReducer.valueStatus,
  }))
  console.log("value", valueStatus)
  console.log("studentInfoData", studentInfoData)
  console.log("studentInfoLoading", studentInfoLoading)

  useEffect(() => {
    dispatch(getStudentInfo(authToken))
  }, [])

  useEffect(() => {
    dispatch(getNetAmount(authToken))
  }, [])
  useEffect(() => {
    dispatch(getDashboardAvgScore(authToken, bandScore))
  }, [bandScore])

  // console.log(`dashboardData, loading, error`, dashboardData, loading, error)

  const [showToggle, setShowToggle] = useState(false)
  const [showToggle2, setShowToggle2] = useState(false)
  const [totalPackage, setTotalPackage] = useState(4)
  console.log(totalPackage)
  const handleBandScore = e => {
    setBandScore(e.target.value * 1)
  }
  console.log("kiki", Object.keys(studentInfoData))
  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={false}
        title="Dashboard"
        extraClass="dashboard-main"
      >
        <Row className="mt-3">
          <Col lg="4">
            <CardComponent>
              <div className="total-revenue-card-single-item">
                <img style={{ width: "25px" }} src={totalRevenue} alt="" />
                <h6>
                  Total Revenue:{" "}
                  <b>{netAmount?.totalAmount?.[0]?.myCount || 0} BDT</b>
                </h6>
              </div>
            </CardComponent>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col lg="6 mb-3">
            <CardComponent className={"student-per-package-single-card"}>
              <div className="dashboard-student-count-with-package">
                <div className="student-count-with-package-header">
                  <div className="count-with-package-header-left">
                    <h3>Students Per Package</h3>
                  </div>
                  {showToggle ? (
                    <div
                      className="count-with-package-header-right"
                      onClick={() => {
                        setTotalPackage(4)
                        setShowToggle(!showToggle)
                      }}
                    >
                      <i className="bx bx-chevron-up"></i>
                    </div>
                  ) : (
                    <div
                      className="count-with-package-header-right"
                      onClick={() => {
                        setTotalPackage(Object.entries(studentInfoData)?.length)
                        setShowToggle(!showToggle)
                      }}
                    >
                      <i className="bx bx-chevron-down"></i>
                    </div>
                  )}
                </div>
                <div className="dashboard-student-count-with-package-list">
                  {Object.entries(studentInfoData)
                    ?.slice(0, totalPackage)
                    ?.map((packageData, idx) => (
                      <div className="single-exam-name" key={idx}>
                        <p>{packageData[0]}</p>
                        <h4>{packageData[1]?.numberOfUserPurchased}</h4>
                      </div>
                    ))}
                </div>
              </div>
            </CardComponent>
          </Col>
          <Col lg="6 mb-3">
            <CardComponent className={"student-revenue-package-single-card"}>
              <div className="dashboard-student-count-with-package">
                <div className="student-count-with-package-header">
                  <div className="count-with-package-header-left">
                    <h3>Revenue (BDT)</h3>
                  </div>
                  {showToggle ? (
                    <div
                      className="count-with-package-header-right"
                      onClick={() => {
                        setTotalPackage(4)
                        setShowToggle(!showToggle)
                      }}
                    >
                      <i className="bx bx-chevron-up"></i>
                    </div>
                  ) : (
                    <div
                      className="count-with-package-header-right"
                      onClick={() => {
                        setTotalPackage(Object.entries(studentInfoData)?.length)
                        setShowToggle(!showToggle)
                      }}
                    >
                      <i className="bx bx-chevron-down"></i>
                    </div>
                  )}
                </div>
                <div className="dashboard-student-count-with-package-list">
                  {Object.entries(studentInfoData)
                    ?.slice(0, totalPackage)
                    ?.map((packageData, idx) => (
                      <div className="single-exam-name" key={idx}>
                        <p>{packageData[0]}</p>
                        <h4>{packageData[1]?.numberOfTotalMoney}</h4>
                      </div>
                    ))}
                </div>
              </div>
            </CardComponent>
          </Col>
        </Row>

        <Row className="mt-3">
          <CardComponent>
            <div className="student-avg-band-score-title">
              <div className="student-enrollment-header-inner-wrap mb-3">
                <h2>Band Wise Student</h2>
                <div className="enrollment-stats-filtering-right">
                  <Form.Select
                    name=""
                    id=""
                    onChange={e => handleBandScore(e)}
                    className="year-select-main-wrap"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                  </Form.Select>
                </div>
              </div>
            </div>
            <div className="ielts-xam-avarage-score-area-wrap">
              <div className="single-ielts-xam overall-area">
                <div className="single-ielts-xam-left-icon">
                  <OverallIcon />
                </div>
                <div className="single-ielts-xam-right-content">
                  <p>Overall</p>
                  <h4>{avgScore?.overAll}</h4>
                </div>
              </div>{" "}
              <div className="single-ielts-xam  reading-area">
                <div className="single-ielts-xam-left-icon">
                  <OpenBookIcon />
                </div>
                <div className="single-ielts-xam-right-content">
                  <p>Reading</p>
                  <h4>{avgScore?.countReading}</h4>
                </div>
              </div>{" "}
              <div className="single-ielts-xam speaking-area">
                <div className="single-ielts-xam-left-icon ">
                  <SpeakingArea />
                </div>
                <div className="single-ielts-xam-right-content">
                  <p>Speaking</p>
                  <h4>{avgScore?.countSpeaking}</h4>
                </div>
              </div>{" "}
              <div className="single-ielts-xam listening-area">
                <div className="single-ielts-xam-left-icon">
                  <ListeningIcon />
                </div>
                <div className="single-ielts-xam-right-content">
                  <p>Listening</p>
                  <h4>{avgScore?.countListening}</h4>
                </div>
              </div>{" "}
              <div className="single-ielts-xam writing-area">
                <div className="single-ielts-xam-left-icon">
                  <WritingIcon />
                </div>
                <div className="single-ielts-xam-right-content">
                  <p>Writing</p>
                  <h4>{avgScore?.countWriting}</h4>
                </div>
              </div>{" "}
            </div>
          </CardComponent>
        </Row>
        <Row>
          <Col xl="8 mb-3" className="mb-3">
            <StudentEnrollment
            // packageContribution={packageContribution}
            // handleYearData={handleYearData}
            // enrollmentData={enrollmentData}
            />
          </Col>
          <Col xl="4 mb-3" className="mb-3">
            <NetInCome
            // monthBasedSellingData={monthBasedSellingData}
            />
          </Col>
          <Col xl="6 mb-3" className="mb-3">
            <EnrollmentStates />
          </Col>{" "}
          <Col xl="6 mb-3" className="mb-3">
            <PopularPackage />
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

Dashboard2.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard2)
