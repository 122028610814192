
import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import dropfile from "../../assets/images/OBJECTS.svg";
// import { formatBytes } from "/helpers/Custom/formatBytes";
import {
  acceptStyle,
  activeStyle,
  baseStyle,
  rejectStyle,
} from "helpers/Custom/styleHelper";
import Loader from "./Loader";

const UploadFile = ({
  setSelectedFiles,
  onDropCallBack,
  dropZoneClass,
  exceptFile = {
    "image/jpg": [],
    "image/png": [],
    "image/jpeg": [],
  },
  isDisabled,
  isMultiple = false,
  isLoading,
  dropText,
  reRender,
  isBrowse = true,
  imageDefault = true,
  ...props
}) => {
  const onDropAccepted = useCallback((acceptedFiles, fileRejections) => {
    console.log("acceptedFiles", acceptedFiles, fileRejections)
    acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        // formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles && setSelectedFiles(acceptedFiles);
   
  }, []);
  // const singleFile = 1048576;
  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      console.log("acceptedFiles", acceptedFiles, fileRejections)
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          // formattedSize: formatBytes(file.size),
        })
      );
      // setSelectedFiles && setSelectedFiles(acceptedFiles);
      onDropCallBack && onDropCallBack(acceptedFiles, fileRejections);
    
    },
  
    [reRender]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept: exceptFile || "image/png, image/jpg, image/jpeg",
    noClick: true,
    noKeyboard: true,
    onDropAccepted,
    onDrop,
    minSize: 0,
    multiple: isMultiple,
    disabled: isDisabled,
    // maxSize: singleFile,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <React.Fragment>
      <div className={dropZoneClass}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {isLoading ? (
            <Loader text="Uploading..." {...props} />
          ) : (
            <React.Fragment>
              <div
                className="d-flex align-items-center gap-3 innerDiv"
                style={{ flexDirection: imageDefault ? "column" : "" }}
                onClick={open}
              >
                <div className="pointer_event" style={{ cursor: "pointer" }}>
                  <img
                    style={{ maxWidth: imageDefault ? "" : "21px" }}
                    src={dropfile}
                    alt="dropImage"
                  />
                </div>
                <h6 className="text-dark">{dropText || "Drop files here"}</h6>
                {isBrowse && (
                  <span>
                    <span className="text-muted">or </span>
                    <a href="#">Browse</a>
                  </span>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadFile;
