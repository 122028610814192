export const ADD_SCREEN = "ADD_SCREEN"
export const ADD_SCREEN_SUCCESS = "ADD_SCREEN_SUCCESS"
export const ADD_SCREEN_FAIL = "ADD_SCREEN_FAIL"


export const GET_SCREEN= "GET_SCREEN"
export const GET_SCREEN_SUCCESS = "GET_SCREEN_SUCCESS"
export const GET_SCREEN_FAIL = "GET_SCREEN_FAIL"

export const ACTIVE_SCREEN= "ACTIVE_SCREEN"
export const ACTIVE_SCREEN_SUCCESS = "ACTIVE_SCREEN_SUCCESS"
export const ACTIVE_SCREEN_FAIL = "ACTIVE_SCREEN_FAIL"


export const STORE_SCREEN_DATA = "STORE_SCREEN_DATA"