import { AvField, AvForm } from "availity-reactstrap-validation"
import moment from "moment"
import React, { useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  getReviewExam,
  getReviewExamSuccess,
  removeReviewExam,
} from "store/ReviewAnswer/actions"
import InnerLayer from "../../Layout/InnerLayer"
import NoTableData from "../../Layout/NoTableData"
import CardComponent from "../../components/Common/CardComponent"
import CustomTable from "../../components/Common/CustomTable"
import Loader from "../../components/Common/Loader"
import Request from "../../components/Common/Request"
import "./exam-data-review.scss"

const filter = [
  { name: "Listening", value: "listeninganswerschemas" },
  { name: "Reading", value: "readinganswers" },
  { name: "Writing", value: "writtinganswerschemas" },
]

const ExamDataReview = () => {
  const dispatch = useDispatch()
  // const useSelector = useSelector();
  const [examName, setExamName] = useState("")
  const [email, setEmail] = useState("")
  // console.log(`examName`, examName, email)
  const { authToken, loading, reviewExam, adding } = useSelector(state => ({
    allAnswer: state.review.allAnswer,
    authToken: state.Login.token,
    loading: state.review.load,
    reviewExam: state.review.reviewExam,
    adding: state.review.adding,
  }))

  console.log("Review", reviewExam)
  const handleSubmit = (e, error, values) => {
    e.preventDefault()
    if (error.length === 0) {
      // console.log(`values`, values)
      dispatch(
        getReviewExam(authToken, {
          userEmail: values.email,
          examName: values.exam,
        })
      )
    }
  }

  const viewExam = index => {
    const data = [...reviewExam]
    dispatch(
      getReviewExamSuccess(
        data?.map((data, i) => {
          if (i === index) {
            data.view = !data.view
          } else {
            data.view = false
          }
          return data
        })
      )
    )
  }

  const removeExam = data => {
    let newType = ""
    switch (examName) {
      case "listeninganswerschemas":
        newType = "listening"
        break
      case "readinganswers":
        newType = "reading"
        break
      case "writtinganswerschemas":
        newType = "writing"
    }
    dispatch(
      removeReviewExam(authToken, {
        answerId: data?._id,
        examName: newType,
        email,
      })
    )
  }

  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Exam data review"
        mainTitle={"Dashboard"}
        subTitle={"Exam data review"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <div className="exam-data-review-main-page">
          <div className="exam-data-content-wrap">
            <div className="exam-data-review-single-data">
              <AvForm onSubmit={handleSubmit}>
                <Row>
                  <Col lg={4}>
                    <AvField
                      type="text"
                      id="emailReviewData"
                      placeholder="example@gmail.com"
                      className="form-control"
                      name="email"
                      label="Email"
                      onChange={e => setEmail(e.target.value)}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Email is required",
                        },
                      }}
                    />
                  </Col>
                  <Col lg={3}>
                    <AvField
                      type="date"
                      id="emailReviewData"
                      className="form-control"
                      name="date"
                      label="Date"
                      value={moment().format("DD/MM/YYYY")}
                      validate={{ date: { format: "DD/MM/YYYY" } }}
                    />
                  </Col>
                  <Col lg={4}>
                    <AvField
                      type="select"
                      id="emailReviewData"
                      placeholder="Select Exam"
                      className="form-control"
                      name="exam"
                      label="Exam"
                      onChange={e => setExamName(e.target.value)}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Exam type is required",
                        },
                      }}
                    >
                      <option value="" selected>
                        Select exam
                      </option>
                      {filter.map((item, index) => (
                        <option value={item.value} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </AvField>
                  </Col>
                  <Col
                    lg={1}
                    className="text-center"
                    style={{ marginTop: "3px" }}
                  >
                    <Button disabled={loading} type="submit" className="mt-4">
                      {loading ? "Searching" : "Search"}
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </div>
        </div>

        {adding ? <Request /> : null}

        <Row className="mt-3">
          <CardComponent className="position-relative">
            <CustomTable
              paginationClass="paginationContainer text-right"
              tableHead={["Sr", "Name", "Email", "View Answer"]}
            >
              {loading ? (
                <NoTableData
                  colSpan="4"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "200px", width: `100%` }}
                >
                  <Loader />
                </NoTableData>
              ) : reviewExam?.length > 0 ? (
                reviewExam?.map((data, idx) => (
                  <React.Fragment key={idx}>
                    <tr>
                      <td scope="row">{idx + 1}</td>
                      <td>{data?.fullName}</td>
                      <td>{data?.email}</td>
                      <td>
                        <Button
                          className="btn button btn-sm"
                          style={{ borderRadius: "20px" }}
                          onClick={() => viewExam(idx)}
                        >
                          {data?.view ? "Hide" : "View Exams"}
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <div
                          style={{ maxHeight: "200px", overflowY: "scroll" }}
                        >
                          {data?.view && (
                            <CustomTable
                              paginationClass="paginationContainer text-right"
                              tableHead={[
                                "Sr",
                                "Exam Status",
                                "Time Taken",
                                "Action",
                              ]}
                            >
                              {data?.AnswerData?.length > 0 ? (
                                data?.AnswerData?.map((list, idx) => (
                                  <React.Fragment key={idx}>
                                    <tr>
                                      <th scope="row">{idx + 1}</th>
                                      <td>{list?.examStatus}</td>
                                      <td>{list?.timeTaken || 0}</td>
                                      <td>
                                        <Button
                                          className="btn button btn-sm btn-danger"
                                          color="danger"
                                          style={{ borderRadius: "20px" }}
                                          onClick={() => removeExam(list)}
                                        >
                                          Remove
                                        </Button>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))
                              ) : (
                                <NoTableData
                                  colSpan="4"
                                  className="d-flex justify-content-center align-items-center"
                                  style={{ minHeight: "200px", width: `100%` }}
                                >
                                  <span>No data here</span>
                                </NoTableData>
                              )}
                            </CustomTable>
                          )}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              ) : (
                <NoTableData
                  colSpan="4"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "200px", width: `100%` }}
                >
                  <span>No data here</span>
                </NoTableData>
              )}
            </CustomTable>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default ExamDataReview
