import { call, put, takeEvery } from "redux-saga/effects"
import { toaster } from "../../helpers/Custom/Toaster"
import { getData, patchData, postData } from "../../helpers/backend_helper"

import { storeQuestion } from "../Question/actions"
import {
  addGiftFail,
  addGiftSuccess,
  addReviewFail,
  addReviewSuccess,
  getBuyPackageListFail,
  getBuyPackageListSuccess,
  getCouponFail,
  getCouponSuccess,
  getRecommendedPackageFail,
  getRecommendedPackageSuccess,
  getReviewFail,
  getReviewSuccess,
  getSinglePackageDataFail,
  getSinglePackageDataSuccess,
  getUserPackage,
  getUserPackageFail,
  getUserPackageSuccess,
} from "./actions"
//from pacakge type
import {
  ADD_GIFT,
  ADD_REVIEW_RATINGS,
  GET_BUY_PACKAGE_LIST,
  GET_COUPON_CODE,
  GET_REVIEW,
  GET_SINGLE_PACKAGE_DATA,
  GET_USER_PACKAGE,
  RECOMMENDED_PACKAGE,
} from "./actionTypes"

function* fetchRecommendedPackages({ payload: { authtoken, id } }) {
  try {
    const url = `/package/get-recomendate/package/${id}`
    const response = yield call(getData, url, authtoken)
    // console.log(response);
    yield put(getRecommendedPackageSuccess(response?.getData))
  } catch (error) {
    const message =
      error?.response?.message?.data || "Get recommended package failed"
    toaster("error", message)
    yield put(getRecommendedPackageFail(message))
  }
}

function* fetchSinglePackage({ payload: { authtoken, packageID } }) {
  try {
    const url = `/package/getsinglepackage/${packageID}`
    const response = yield call(getData, url, authtoken)

    yield put(getSinglePackageDataSuccess(response))
  } catch (error) {
    const message =
      error?.response?.message?.data || "Get single package failed"
    toaster("error", message)
    yield put(getSinglePackageDataFail(message))
  }
}

function* fetchUserPackage({ payload: { authtoken, userID } }) {
  try {
    const url = "/order/get-user-own-package"
    const response = yield call(getData, url, authtoken)
    yield put(getUserPackageSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.message?.data || "Get package Failed"
    toaster("error", message)
    yield put(getUserPackageFail(message))
  }
}

function* onAddReview({ payload: { authtoken, data, id, setOpenModal } }) {
  try {
    const url = "/package/submit-ratings-by-student"
    const response = yield call(patchData, url, data, authtoken)
    toaster("success", response?.message || "Review added successfully")
    yield put(addReviewSuccess(response?.message))
    yield put(getUserPackage(authtoken, id))
    yield put(storeQuestion("isDone", false))
    setOpenModal && setOpenModal(false)
  } catch (error) {
    const message = error?.response?.message?.data || "Add Review Failed"
    toaster("error", message)
    yield put(addReviewFail(message))
  }
}

function* onGetReview({ payload: { authtoken, id } }) {
  try {
    const url = `/package/show-rating-by-student-package/${id}`
    const response = yield call(getData, url, authtoken)
    yield put(getReviewSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.message?.data || "Get Review Failed"
    toaster("error", message)
    yield put(getReviewFail(message))
  }
}

function* onAddGift({
  payload: {
    data,
    options: { authtoken, setOpenFirst, email, history, giftReceiverEmail },
  },
}) {
  // console.log(`data`, data, authtoken, setOpenFirst, email);
  try {
    const url = `/order/createOrder?orderType=gift&giftReceiverEmail=${giftReceiverEmail}&giftSenderEmail=${email}`
    const response = yield call(postData, url, data, authtoken)
    toaster("success", response?.message || "Gift sent successfully")
    yield put(addGiftSuccess(response?.message))
    // history.push('/my-package');
    setOpenFirst && setOpenFirst(false)
    window.location.href = response?.link
  } catch (error) {
    const message = error?.response?.message?.data || "Send gift Failed"
    toaster("error", message)
    yield put(addGiftFail(message))
  }
}

function* onGetPackageList({ payload: { authtoken } }) {
  try {
    const url = `/package/get-ownser-course-id`
    const response = yield call(getData, url, authtoken)
    yield put(getBuyPackageListSuccess(response?.objId))
  } catch (error) {
    const message = error?.response?.message?.data || "Get Review Failed"
    toaster("error", message)
    yield put(getBuyPackageListFail(message))
  }
}

function* onGetCoupon({ payload: { authtoken } }) {
  try {
    const url = `/get-all-coupon-for-other`
    const response = yield call(getData, url, authtoken)
    yield put(getCouponSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.message?.data || "Get Review Failed"
    toaster("error", message)
    yield put(getCouponFail(message))
  }
}

function* FacilitatorPackageSaga() {
  yield takeEvery(GET_SINGLE_PACKAGE_DATA, fetchSinglePackage)
  yield takeEvery(GET_USER_PACKAGE, fetchUserPackage)
  yield takeEvery(ADD_REVIEW_RATINGS, onAddReview)
  yield takeEvery(ADD_GIFT, onAddGift)
  yield takeEvery(GET_REVIEW, onGetReview)
  yield takeEvery(GET_BUY_PACKAGE_LIST, onGetPackageList)
  yield takeEvery(RECOMMENDED_PACKAGE, fetchRecommendedPackages)
  yield takeEvery(GET_COUPON_CODE, onGetCoupon)
}

export default FacilitatorPackageSaga
