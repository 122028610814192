import React from "react"
import { Table } from "reactstrap"
import Pagination from "./Pagination"

const CustomTable = ({
  data,
  pageNo,
  tableHead,
  setCurrentPage,
  paginationClass,
  isPagination,
  isCheckbox,
  handleAllSelect,
  reInitialize,
  disableCheckbox,
  children,
  tableClass,
  borderless,
  checkedAll,
  isInfinity = true,
  tbodyStyle,
}) => {
  return (
    <React.Fragment>
      <div className="table-responsive-custom-table" style={{ ...tbodyStyle }}>
        <Table
          className={
            "table table-hover table-centered table-nowrap  " + tableClass
          }
          borderless={borderless}
          // responsive
        >
          <thead className="table-light">
            <tr>
              {isCheckbox ? (
                <th
                  style={{
                    paddingLeft: "25px",
                  }}
                >
                  <input
                    type={"checkbox"}
                    name="all"
                    className="tableCheckBox"
                    onChange={handleAllSelect}
                    checked={checkedAll}
                    disabled={disableCheckbox}
                  />
                </th>
              ) : null}
              {tableHead.map((data, idx) => (
                <th
                  style={{
                    paddingLeft:
                      idx === 0 && isInfinity && !isCheckbox ? "25px" : "",
                  }}
                  key={idx}
                >
                  {data}
                </th>
              ))}
            </tr>
          </thead>
          <tbody
          // style={{ height: data?.length === 1 ? "150px" : "" }}
          >
            {children}
          </tbody>
        </Table>
      </div>
      {isPagination && data?.length !== 0 ? (
        <Pagination
          parentClass={`${paginationClass} py-3`}
          reInitialize={reInitialize}
          page={pageNo}
          setCurrentPage={setCurrentPage}
        />
      ) : null}
    </React.Fragment>
  )
}

export default CustomTable
