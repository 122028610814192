export const statsValueData = [
  {
    age: "18-24",
    male: 224,
    female: 200,
  },
  {
    age: "25-34",
    male: 424,
    female: 20,
  },
  {
    age: "35-44",
    male: 54,
    female: 20,
  },
  {
    age: "45-64",
    male: 64,
    female: 20,
  },
  {
    age: "65+",
    male: 74,
    female: 20,
  },
]
