import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_FAIL,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_MONTH_BASED_SELLING_DATA,
  GET_MONTH_BASED_SELLING_DATA_SUCCESS,
  GET_NEW_STUDENTS,
  GET_NEW_STUDENTS_SUCCESS,
  GET_POPULAR_PACKAGE,
  GET_POPULAR_PACKAGE_SUCCESS,
  GET_STUDENT_ENROLLMENTS_DATA,
  GET_STUDENT_ENROLLMENTS_DATA_SUCCESSS,
} from "./actionTypes"

const INIT_STATE = {
  dashboardData: {},
  enrollmentData: {},
  monthBasedSellingData: [],
  popularPackages: [],
  newStudents: [],
  loading: false,
  error: "",
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
      }
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: action.payload,
      }
    case GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        dashboardData: {},
        error: action.payload,
      }
    case GET_STUDENT_ENROLLMENTS_DATA:
      return {
        ...state,
        loading: true,
      }
      break
    case GET_STUDENT_ENROLLMENTS_DATA_SUCCESSS:
      return {
        ...state,
        loading: false,
        enrollmentData: action.payload.data,
      }
      break
    case GET_MONTH_BASED_SELLING_DATA:
      return {
        ...state,
        loading: true,
      }
      break
    case GET_MONTH_BASED_SELLING_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        monthBasedSellingData: action.payload.data,
      }
      break
    case GET_NEW_STUDENTS:
      return {
        ...state,
        loading: true,
      }
      break
    case GET_NEW_STUDENTS_SUCCESS:
      return {
        ...state,
        newStudents: action.payload.data,
      }
      break
    case GET_POPULAR_PACKAGE:
      return {
        ...state,
        loading: true,
      }
      break
    case GET_POPULAR_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        popularPackages: action.payload.data,
      }
      break
    default:
      return state
  }
}

export default Dashboard
