import { call, put, takeEvery } from "redux-saga/effects"
import { toaster } from "../../helpers/Custom/Toaster"
import { getData, patchData, postData } from "../../helpers/backend_helper"
import { ADD_SERVICE, GET_SERVICES, GET_SINGLE_SERVICE } from "./actionTypes"
import {
  addServiceFail,
  addServiceSuccess,
  getServiceFail,
  getServiceSuccess,
  getSingleServiceFail,
  getSingleServiceSuccess,
} from "./actions"

function* fetchServices({ payload: { authtoken } }) {
  try {
    const url = "/service/getallservices"
    const response = yield call(getData, url, authtoken)
    // console.log(response)
    yield put(getServiceSuccess(response))
  } catch (error) {
    const message = error.response?.data?.message || "Load Service failed"
    toaster("error", message)
    yield put(getServiceFail(message))
  }
}

function* addServices({ payload: { authtoken, data, history, id } }) {
  try {
    let response
    if (id) {
      const url = `/service/update-service/${id}`
      response = yield call(patchData, url, data, authtoken)
      toaster("success", "Service Update successfully!")
    } else {
      const url = "/service/createservice"
      response = yield call(postData, url, data, authtoken)
      toaster("success", "Add Service successful")
    }
    if (response) {
      history.push("/services")
    }
    yield put(addServiceSuccess())
  } catch (error) {
    const message = error?.response?.data?.message || "Update service failed"
    toaster("error", message)
    yield put(addServiceFail(message))
  }
}

function* getSingleServices({ payload: { authtoken, id } }) {
  try {
    const url = `/service/get-single-service/${id}`
    const response = yield call(getData, url, authtoken)
    yield put(getSingleServiceSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.data?.message
    toaster("error", message)
    yield put(getSingleServiceFail(message))
  }
}

function* ServiceSaga() {
  yield takeEvery(GET_SERVICES, fetchServices)
  yield takeEvery(ADD_SERVICE, addServices)
  yield takeEvery(GET_SINGLE_SERVICE, getSingleServices)
}

export default ServiceSaga
