export const ADD_QUANTITATIVE_QUES = "ADD_QUANTITATIVE_QUES"
export const ADD_QUANTITATIVE_QUES_SUCCESS = "ADD_QUANTITATIVE_QUES_SUCCESS"
export const ADD_QUANTITATIVE_QUES_FAIL = "ADD_QUANTITATIVE_QUES_FAIL"

export const GET_QUANTITATIVE_QUES = "GET_QUANTITATIVE_QUES"
export const GET_QUANTITATIVE_QUES_SUCCESS = "GET_QUANTITATIVE_QUES_SUCCESS"
export const GET_QUANTITATIVE_QUES_FAIL = "GET_QUANTITATIVE_QUES_FAIL"

export const GET_SINGLE_QUANTITATIVE_QUES = "GET_SINGLE_QUANTITATIVE_QUES"
export const GET_SINGLE_QUANTITATIVE_QUES_SUCCESS = "GET_SINGLE_QUANTITATIVE_QUES_SUCCESS"
export const GET_SINGLE_QUANTITATIVE_QUES_FAIL = "GET_SINGLE_QUANTITATIVE_QUES_FAIL"

export const UPDATE_QUANTITATIVE_QUES = "UPDATE_QUANTITATIVE_QUES"
export const UPDATE_QUANTITATIVE_QUES_SUCCESS = "UPDATE_QUANTITATIVE_QUES_SUCCESS"
export const UPDATE_QUANTITATIVE_QUES_FAIL = "UPDATE_QUANTITATIVE_QUES_FAIL"

export const STORE_QUANTITATIVE_DATA = "STORE_QUANTITATIVE_DATA"