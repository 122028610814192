export const ADD_GRAPHICAL_QUES = "ADD_GRAPHICAL_QUES"
export const ADD_GRAPHICAL_QUES_SUCCESS = "ADD_GRAPHICAL_QUES_SUCCESS"
export const ADD_GRAPHICAL_QUES_FAIL = "ADD_GRAPHICAL_QUES_FAIL"

export const ADD_TABLE_ANALYSIS_QUES = "ADD_TABLE_ANALYSIS_QUES"
export const ADD_TABLE_ANALYSIS_QUES_SUCCESS = "ADD_TABLE_ANALYSIS_QUES_SUCCESS"
export const ADD_TABLE_ANALYSIS_QUES_FAIL = "ADD_TABLE_ANALYSIS_QUES_FAIL"


export const ADD_TWO_PART_QUES = "ADD_TWO_PART_QUES"
export const ADD_TWO_PART_QUES_SUCCESS = "ADD_TWO_PART_QUES_SUCCESS"
export const ADD_TWO_PART_QUES_FAIL = "ADD_TWO_PART_QUES_FAIL"


export const ADD_MULTI_SOURCE = "ADD_MULTI_SOURCE"
export const ADD_MULTI_SOURCE_SUCCESS = "ADD_MULTI_SOURCE_SUCCESS"
export const ADD_MULTI_SOURCE_FAIL = "ADD_MULTI_SOURCE_FAIL"

export const GET_ALL_INTEGRATED = "GET_ALL_INTEGRATED"
export const GET_ALL_INTEGRATED_SUCCESS = "GET_ALL_INTEGRATED_SUCCESS"
export const GET_ALL_INTEGRATED_FAIL = "GET_ALL_INTEGRATED_FAIL"

export const GET_GRAPHICAL_QUES = "GET_GRAPHICAL_QUES"
export const GET_GRAPHICAL_QUES_SUCCESS = "GET_GRAPHICAL_QUES_SUCCESS"
export const GET_GRAPHICAL_QUES_FAIL = "GET_GRAPHICAL_QUES_FAIL"

export const GET_TWO_PART_QUES = "GET_TWO_PART_QUES"
export const GET_TWO_PART_QUES_SUCCESS = "GET_TWO_PART_QUES_SUCCESS"
export const GET_TWO_PART_QUES_FAIL = "GET_TWO_PART_QUES_FAIL"

export const GET_TABLE_ANALYSIS_QUES = "GET_TABLE_ANALYSIS_QUES"
export const GET_TABLE_ANALYSIS_QUES_SUCCESS = "GET_TABLE_ANALYSIS_QUES_SUCCESS"
export const GET_TABLE_ANALYSIS_QUES_FAIL = "GET_TABLE_ANALYSIS_QUES_FAIL"

export const GET_SINGLE_GRAPHICAL_QUES = "GET_SINGLE_GRAPHICAL_QUES"
export const GET_SINGLE_GRAPHICAL_QUES_SUCCESS = "GET_SINGLE_GRAPHICAL_QUES_SUCCESS"
export const GET_SINGLE_GRAPHICAL_QUES_FAIL = "GET_SINGLE_GRAPHICAL_QUES_FAIL"


export const GET_SINGLE_TWO_PART_QUES = "GET_SINGLE_TWO_PART_QUES"
export const GET_SINGLE_TWO_PART_QUES_SUCCESS = "GET_SINGLE_TWO_PART_QUES_SUCCESS"
export const GET_SINGLE_TWO_PART_QUES_FAIL = "GET_SINGLE_TWO_PART_QUES_FAIL"



export const GET_SINGLE_TABLE_ANALYSIS_QUES = "GET_SINGLE_TABLE_ANALYSIS_QUES"
export const GET_SINGLE_TABLE_ANALYSIS_QUES_SUCCESS = "GET_SINGLE_TABLE_ANALYSIS_QUES_SUCCESS"
export const GET_SINGLE_TABLE_ANALYSIS_QUES_FAIL = "GET_SINGLE_TABLE_ANALYSIS_QUES_FAIL"


export const GET_SINGLE_QUANTITATIVE_QUES = "GET_SINGLE_QUANTITATIVE_QUES"
export const GET_SINGLE_QUANTITATIVE_QUES_SUCCESS = "GET_SINGLE_QUANTITATIVE_QUES_SUCCESS"
export const GET_SINGLE_QUANTITATIVE_QUES_FAIL = "GET_SINGLE_QUANTITATIVE_QUES_FAIL"

export const UPDATE_QUANTITATIVE_QUES = "UPDATE_QUANTITATIVE_QUES"
export const UPDATE_QUANTITATIVE_QUES_SUCCESS = "UPDATE_QUANTITATIVE_QUES_SUCCESS"
export const UPDATE_QUANTITATIVE_QUES_FAIL = "UPDATE_QUANTITATIVE_QUES_FAIL"

export const STORE_QUANTITATIVE_DATA = "STORE_QUANTITATIVE_DATA"