import { call, put, takeEvery } from "redux-saga/effects"
import { toaster } from "../../helpers/Custom/Toaster"
import { getData, postData } from "../../helpers/backend_helper"
import {
  createUniversityFail,
  createUniversitySuccess,
  getDashboardAvgScoreFail,
  getDashboardAvgScoreSuccess,
  getEnrollmentStatusFail,
  getEnrollmentStatusSuccess,
  getGoogleAnalyticFail,
  getGoogleAnalyticSuccess,
  getNetAmountFail,
  getNetAmountSuccess,
  getPackageContributionFail,
  getPackageContributionSuccess,
  getStudentInfoFail,
  getStudentInfoSuccess,
  getUniversityList,
  getUniversityListFail,
  getUniversityListSuccess,
} from "./action"
import {
  CREATE_UNIVERSITY,
  GET_AVG_SCORE,
  GET_ENROLLMENT_STATUS,
  GET_GOOGLE_ANALYTICS,
  GET_NET_AMOUNT,
  GET_NUMBER_STUDENTS,
  GET_PACKAGE_CONTRIBUTION,
  GET_UNIVERSITY,
} from "./actionTypes"

function* onGetStudentInfo({ payload: { token } }) {
  try {
    const url = "package/count-no-of-students-and-revenue"
    const response = yield call(getData, url, token)
    yield put(getStudentInfoSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "get student info failed"
    yield put(getStudentInfoFail(message))
    toaster("error", message)
  }
}

function* onGetStudentAvgScore({ payload: { token, score } }) {
  try {
    const url = `package/count-each-module?score=${score}`
    const response = yield call(getData, url, token)
    yield put(getDashboardAvgScoreSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "get student marks failed"
    yield put(getDashboardAvgScoreFail(message))
    toaster("error", message)
  }
}
function* onGetNetAmount({ payload: { token } }) {
  try {
    const url = `package/show-dashboard-data?firstDate=2021-12-01&lastDate=2022-12-31`
    const response = yield call(getData, url, token)
    yield put(getNetAmountSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "get student marks failed"
    yield put(getNetAmountFail(message))
    toaster("error", message)
  }
}

function* onGetPackageContribution({ payload: { token, valueStatus } }) {
  try {
    const url = `package/get-package-value-volume?type=${valueStatus}`
    const response = yield call(getData, url, token)
    yield put(getPackageContributionSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "get student marks failed"
    yield put(getPackageContributionFail(message))
    toaster("error", message)
  }
}
function* onGetStudentEnroll({ payload: { token, timeline, singlePackage } }) {
  try {
    const url = `/package/month-or-week-based-data?type=${timeline}&packageId=${singlePackage}`
    const response = yield call(getData, url, token)
    yield put(getEnrollmentStatusSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "get student marks failed"
    yield put(getEnrollmentStatusFail(message))
    toaster("error", message)
  }
}

function* onAddUniversity({ payload: { data, token, history, setShow } }) {
  try {
    const url = `university/create-university`
    const response = yield call(postData, url, data, token)
    if (response) {
      yield put(createUniversitySuccess({}))
      console.log("setShow", setShow)
      setShow(false)
      yield put(getUniversityList(token))

      toaster("success", `University added Successfully`)
    }
  } catch (err) {
    const message = err?.response?.data?.message || `University creation failed`
    toaster("error", message)
    yield put(createUniversityFail(message))
  }
}
function* onGetUniversityList({ payload: { token } }) {
  try {
    const url = `university/get-all`
    const response = yield call(getData, url, token)
    yield put(getUniversityListSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "get student marks failed"
    yield put(getUniversityListFail(message))
    toaster("error", message)
  }
}
function* onGetGoogleAnalyticsData({ payload: { token, startDate, endDate } }) {
  try {
    const url = `google-analytics?startDate=${startDate}&endDate=${endDate}`
    const response = yield call(getData, url, token)
    yield put(getGoogleAnalyticSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "get student marks failed"
    yield put(getGoogleAnalyticFail(message))
    toaster("error", message)
  }
}
function* AdminDashboardSaga() {
  yield takeEvery(GET_NUMBER_STUDENTS, onGetStudentInfo)
  yield takeEvery(GET_AVG_SCORE, onGetStudentAvgScore)
  yield takeEvery(GET_NET_AMOUNT, onGetNetAmount)
  yield takeEvery(GET_PACKAGE_CONTRIBUTION, onGetPackageContribution)
  yield takeEvery(GET_ENROLLMENT_STATUS, onGetStudentEnroll)
  yield takeEvery(CREATE_UNIVERSITY, onAddUniversity)
  yield takeEvery(GET_UNIVERSITY, onGetUniversityList)
  yield takeEvery(GET_GOOGLE_ANALYTICS, onGetGoogleAnalyticsData)
}

export default AdminDashboardSaga
