/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from "react"
import ReactApexChart from "react-apexcharts"
import { Form } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import { getEnrollmentStatus, getPackages } from "store/actions"
const EnrollmentStates = () => {
  const dispatch = useDispatch()
  const [timeline, setTimeline] = useState("week")
  const [singlePackage, setSinglePackage] = useState("")

  const { authToken, studentEnroll, packageData } = useSelector(state => ({
    authToken: state.Login.token,
    role: state.Login.userrole,
    studentEnroll: state.AdminDashboardReducer.studentEnroll,
    packageData: state.packageReducer.allPackage?.AllPackages,
  }))
  console.log("total", studentEnroll)

  useEffect(() => {
    dispatch(getEnrollmentStatus(authToken, timeline, singlePackage))
  }, [timeline, singlePackage])
  useEffect(() => {
    dispatch(getPackages(authToken))
  }, [])

  const handlePackage = data => {
    console.log("data", data)
    if (data) {
      setSinglePackage(data?._id)
    } else {
      setSinglePackage("")
    }
  }
  console.log("single", singlePackage)
  const series = [
    {
      name: "Bdt",
      data: Object.values(studentEnroll || []),
      data:
        timeline === "month"
          ? [
              studentEnroll["12"] || 0,
              studentEnroll["01"] || 0,
              studentEnroll["02"] || 0,
              studentEnroll["03"] || 0,
              studentEnroll["04"] || 0,
              studentEnroll["05"] || 0,
              studentEnroll["06"] || 0,
              studentEnroll["07"] || 0,
              studentEnroll["08"] || 0,
              studentEnroll["09"] || 0,
              studentEnroll["10"] || 0,
              studentEnroll["11"] || 0,
            ] || []
          : [
              studentEnroll["1"] || 0,
              studentEnroll["2"] || 0,
              studentEnroll["3"] || 0,
              studentEnroll["4"] || 0,
              studentEnroll["5"] || 0,
              studentEnroll["6"] || 0,
              studentEnroll["7"] || 0,
            ] || [],
      color: "#4A3AFF",
    },
  ]
  console.log("timeline", timeline)
  const options = {
    chart: {
      // height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      // type: "datetime",
      categories:
        timeline === "month"
          ? [
              "Dec",
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
            ]
          : ["SU", "MO", "TU", "WED", "THU", "FRI", "SA"],
    },
    // tooltip: {
    //   x: {
    //     format: "dd/MM/yy HH:mm",
    //   },
    // },
  }
  const handleStatus = e => {
    console.log("ere", e.target.value)
    setTimeline(e.target.value)
    // dispatch(storeAdminDashboardData("valueStatus", e.target.value))
  }
  console.log("timeline", timeline)
  const calculateSum = () => {
    let totalSum
    if (timeline === "month") {
      totalSum =
        studentEnroll["01"] +
        studentEnroll["02"] +
        studentEnroll["03"] +
        studentEnroll["04"] +
        studentEnroll["05"] +
        studentEnroll["06"] +
        studentEnroll["07"] +
        studentEnroll["08"] +
        studentEnroll["09"] +
        studentEnroll["10"] +
        studentEnroll["11"] +
        studentEnroll["12"]
    } else {
      totalSum =
        studentEnroll["1"] +
        studentEnroll["2"] +
        studentEnroll["3"] +
        studentEnroll["4"] +
        studentEnroll["5"] +
        studentEnroll["6"] +
        studentEnroll["7"]
    }
    console.log("totalSum", totalSum)
    return totalSum
  }
  return (
    <div className="student-enrollment-chart">
      <div className="student-enrollment-header-inner-wrap">
        <h2>Enrollment Stats</h2>
        <div className="enrollment-stats-filtering-right form-select-enrollment-area">
          <div className="form-select-enrollment-single select-package-options">
            <Select
              cacheOptions
              getOptionLabel={e => e.packageName}
              getOptionValue={e => e._id}
              options={packageData}
              isClearable
              onChange={handlePackage}
              placeholder="Select package"
            />
          </div>
          <div className="form-select-enrollment-single">
            <Form.Select
              name=""
              id=""
              onChange={e => handleStatus(e)}
              className="year-select-main-wrap"
            >
              <option value="week">Weekly</option>
              <option value="month">Yearly</option>
            </Form.Select>
          </div>
        </div>
      </div>
      <div className="enrollmet-graph-controll-title-area">
        <h4>BDT {calculateSum() || 0} Tk.</h4>
        {/* <div className="enrollment-balance-control-view">
          <div className="balance-up-count">
            <span>12%</span>
            <i className="bx bx-up-arrow-alt"></i>
          </div>{" "}
          <div className="balance-down-count">
            <span>12%</span>
            <i className="bx bx-down-arrow-alt"></i>
          </div>
        </div>
        <span>vs. last day</span> */}
      </div>

      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
      />
    </div>
  )
}

export default EnrollmentStates
