import React from "react"

const SearchIcon = () => {
  return (
    <React.Fragment>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66927 11.9997C7.89994 11.9997 9.03127 11.5763 9.93394 10.8743L12.8646 13.805L13.8073 12.8623L10.8766 9.93167C11.5793 9.02834 12.0026 7.89701 12.0026 6.66634C12.0026 3.72567 9.60994 1.33301 6.66927 1.33301C3.7286 1.33301 1.33594 3.72567 1.33594 6.66634C1.33594 9.60701 3.7286 11.9997 6.66927 11.9997ZM6.66927 2.66634C8.87527 2.66634 10.6693 4.46034 10.6693 6.66634C10.6693 8.87234 8.87527 10.6663 6.66927 10.6663C4.46327 10.6663 2.66927 8.87234 2.66927 6.66634C2.66927 4.46034 4.46327 2.66634 6.66927 2.66634Z"
          fill="#495057"
        />
        <path
          d="M7.60658 5.72406C7.85925 5.97739 7.99858 6.31206 7.99858 6.66672H9.33192C9.33192 5.95672 9.05458 5.28739 8.54925 4.78139C7.53992 3.77339 5.78992 3.77339 4.78125 4.78139L5.72258 5.72539C6.22925 5.22006 7.10258 5.22139 7.60658 5.72406Z"
          fill="#495057"
        />
      </svg>
    </React.Fragment>
  )
}

export default SearchIcon
