import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
import {
  addCourseType,
  getSingleCourseType,
  getSingleCourseTypeSuccess,
} from "store/CourseType/actions"
import InnerLayer from "../../Layout/InnerLayer"
import CardComponent from "../../components/Common/CardComponent"
import Loader from "../../components/Common/Loader"
import { toaster } from "../../helpers/Custom/Toaster"
import { getCourse } from "../../store/Course/actions"

const CreateCourseType = ({ history, edit }) => {
  const { id } = useParams()
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Course Types",
      link: "/course-type",
    },
    {
      title: `${edit ? "Update" : "Create"} Course Types`,
      link: "#",
    },
  ]
  const dispatch = useDispatch()
  const { authToken, data, loading, adding, typeLoading, singleCourseType } =
    useSelector(state => ({
      authToken: state.Login.token,
      loading: state.course.loading,
      data: state.course.data,
      adding: state.courseType.adding,
      typeLoading: state.courseType.loading,
      singleCourseType: state.courseType.singleCourseType,
    }))
  // console.log(`loading`, typeLoading, singleCourseType)

  useEffect(() => {
    if (edit) {
      dispatch(getSingleCourseType(authToken, id))
    }
    dispatch(getCourse(authToken))

    return () => {
      dispatch(getSingleCourseTypeSuccess({}))
    }
  }, [])

  const handleSubmit = (event, errors, values) => {
    event.preventDefault()
    if (errors.length > 0) {
      toaster("warning", "Please enter all values")
      return
    }
    edit
      ? dispatch(addCourseType(values, authToken, history, id))
      : dispatch(addCourseType(values, authToken, history))
  }

  return (
    <Fragment>
      <InnerLayer
        isMultiple
        title={`${edit ? "Update" : "Create"} Course Types`}
        breadcrumbItems={breadcrumbItems}
      >
        <Row className="mt-3">
          <Col md="6" className="offset-md-3">
            <CardComponent>
              <h5>{`${edit ? "Update" : "Create"} Course Types`}</h5>
              {typeLoading ? (
                <div className="d-flex justify-content-center">
                  <Loader />
                </div>
              ) : (
                <AvForm className="mt-4" onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <AvField
                      name="courseTypeName"
                      type="text"
                      errorMessage="Type name"
                      placeholder="Type name"
                      label="Type Name"
                      value={singleCourseType?.courseTypeName}
                      required
                    />
                  </Row>
                  {edit ? null : (
                    <Row className="mb-3">
                      {loading ? (
                        <Loader />
                      ) : (
                        <AvField
                          type="select"
                          name="courseId"
                          errorMessage="Select course"
                          placeholder="Select course"
                          required
                          value={singleCourseType?.courseId}
                          disabled={edit}
                          label="Select Course"
                        >
                          <option value="">Select Course</option>
                          {data?.map((dt, idx) => (
                            <option key={idx} value={dt._id}>
                              {dt.courseName}
                            </option>
                          ))}
                        </AvField>
                      )}
                    </Row>
                  )}
                  <div>
                    <button
                      disabled={loading || adding}
                      type="submit"
                      className="btn btn-primary w-md"
                    >
                      {adding
                        ? "Submitting..."
                        : `${edit ? "Update" : "Create"} Types`}
                    </button>
                  </div>
                </AvForm>
              )}
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </Fragment>
  )
}

export default withRouter(CreateCourseType)
