/* eslint-disable react/react-in-jsx-scope */
const OverallIcon = () => {
  return (
    <svg
      width="46"
      height="43"
      viewBox="0 0 46 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4141 7.41968H18.1641C17.8405 7.41968 17.5781 7.68202 17.5781 8.00562V16.5994C17.5781 16.923 17.8405 17.1853 18.1641 17.1853H19.4141C19.7377 17.1853 20 16.923 20 16.5994V8.00562C20 7.68202 19.7377 7.41968 19.4141 7.41968Z"
        fill="#FF8A8E"
      />
      <path
        d="M11.3295 3.72186L12.3061 2.03042C12.5218 1.65675 12.3938 1.17894 12.0201 0.963195L11.3436 0.57257C10.9699 0.356828 10.4921 0.484875 10.2764 0.858547L9.2998 2.54999L11.3295 3.72186Z"
        fill="#FF8A8E"
      />
      <path
        d="M0.695986 13.7226C1.03079 13.9135 1.45802 13.7889 1.65071 13.4551L6.43904 5.16151C5.99236 5.05537 5.51564 5.14979 5.14052 5.43112L3.48165 6.67526C2.99892 7.03733 2.59782 7.49702 2.30454 8.02436L0.173955 11.8555C-0.191787 12.5131 0.0376266 13.3426 0.689307 13.7188C0.691533 13.7201 0.69376 13.7214 0.695986 13.7226Z"
        fill="#FF8A8E"
      />
      <path
        d="M4.17063 11.7224H6.71094L10.7441 4.73682L8.7143 3.56494L4.00098 11.7287C4.05711 11.725 4.11355 11.7224 4.17063 11.7224Z"
        fill="#FF8A8E"
      />
      <path
        d="M1.89206 15.3809L1.28585 16.4309C1.25733 16.4802 1.2362 16.5335 1.22307 16.589L0.66405 18.9535C0.557058 19.4061 1.10327 19.7214 1.44171 19.4025L3.20999 17.7362C3.25147 17.6971 3.28706 17.6521 3.31557 17.6027L3.79487 16.7725C2.95811 16.648 2.25358 16.1137 1.89206 15.3809Z"
        fill="#FF8A8E"
      />
      <path
        d="M8.83304 16.8003H5.13176L4.3584 18.1397C4.60707 18.3975 4.95578 18.5581 5.34219 18.5581C6.09726 18.5581 6.70937 17.946 6.70937 17.1909C6.70937 17.946 7.32148 18.5581 8.07656 18.5581C8.83164 18.5581 9.44374 17.946 9.44374 17.1909V17.1016C9.30191 17.0367 8.9771 16.8717 8.83304 16.8003Z"
        fill="#FF8A8E"
      />
      <path
        d="M11.1643 6.35132L8.06336 11.7222H8.98398C9.30758 11.7222 9.56992 11.9846 9.56992 12.3082C9.56992 12.6317 9.30765 12.8941 8.98406 12.8941H4.16992C3.41484 12.8941 2.80273 13.5062 2.80273 14.2613C2.80273 15.0164 3.41484 15.6285 4.16992 15.6285H9.10929L9.75734 15.9522C10.8663 16.5068 12.1086 16.8003 13.3497 16.8003C14.3779 16.8003 15.4409 16.5982 16.4059 16.2138V8.5971H15.4824L11.1643 6.35132Z"
        fill="#FF8A8E"
      />
      <path
        d="M3.05944 25.3601C3.0589 25.3601 3.0583 25.3601 3.05776 25.3601C2.8686 25.3601 2.69069 25.4339 2.55634 25.5679C2.42052 25.7034 2.3457 25.8838 2.3457 26.0758V36.8075C2.3457 37.2011 2.66712 37.522 3.06227 37.523C4.72814 37.527 7.51912 37.8742 9.44451 39.8891V28.6581C9.44451 28.5247 9.41044 28.3993 9.34613 28.2956C7.76586 25.7507 4.72908 25.364 3.05944 25.3601Z"
        fill="#FF8A8E"
      />
      <path
        d="M17.6544 36.8076V26.0758C17.6544 25.8838 17.5796 25.7034 17.4438 25.5679C17.3094 25.4339 17.1314 25.3601 16.9424 25.3601C16.9418 25.3601 16.9412 25.3601 16.9407 25.3601C15.2711 25.3641 12.2343 25.7508 10.654 28.2957C10.5897 28.3994 10.5557 28.5247 10.5557 28.6581V39.8891C12.4811 37.8742 15.272 37.527 16.9379 37.523C17.333 37.522 17.6544 37.2011 17.6544 36.8076Z"
        fill="#FF8A8E"
      />
      <path
        d="M19.2846 27.835H18.7657V36.8075C18.7657 37.8123 17.9471 38.6316 16.9407 38.6341C15.5277 38.6375 13.1979 38.9138 11.5479 40.4754C14.4016 39.7767 17.41 40.2309 19.1244 40.6216C19.3385 40.6704 19.5597 40.62 19.7312 40.4833C19.9022 40.347 20.0002 40.1431 20.0002 39.9243V28.5505C20.0003 28.156 19.6792 27.835 19.2846 27.835Z"
        fill="#FF8A8E"
      />
      <path
        d="M1.23448 36.8075V27.835H0.715558C0.321079 27.835 0 28.156 0 28.5505V39.9241C0 40.143 0.0980475 40.3468 0.268957 40.4831C0.440338 40.6198 0.661349 40.6703 0.87576 40.6214C2.59024 40.2307 5.59867 39.7765 8.45228 40.4752C6.80231 38.9136 4.47247 38.6374 3.05947 38.634C2.05321 38.6316 1.23448 37.8123 1.23448 36.8075Z"
        fill="#FF8A8E"
      />
      <path
        d="M36 13.75C34.1692 13.75 32.6797 12.2605 32.6797 10.4297V5.74219C32.6797 3.91137 34.1692 2.42188 36 2.42188C37.8308 2.42188 39.3203 3.91137 39.3203 5.74219V10.4297C39.3203 12.2605 37.8308 13.75 36 13.75Z"
        fill="#FF8A8E"
      />
      <path
        d="M41.4688 7.5H40.4922V8.67188H40.8828V10.4297C40.8828 13.1221 38.6924 15.3125 36 15.3125C33.3076 15.3125 31.1172 13.1221 31.1172 10.4297V8.67188H31.5078V7.5H30.5312C30.2077 7.5 29.9453 7.76234 29.9453 8.08594V10.4297C29.9453 13.7682 32.6614 16.4844 36 16.4844C39.3386 16.4844 42.0547 13.7682 42.0547 10.4297V8.08594C42.0547 7.76234 41.7923 7.5 41.4688 7.5Z"
        fill="#FF8A8E"
      />
      <path
        d="M38.0508 18.828H36.5859V17.6323C36.3926 17.6479 36.1973 17.6562 36 17.6562C35.8027 17.6562 35.6074 17.6479 35.4141 17.6323V18.828H33.9492C33.6256 18.828 33.3633 19.0904 33.3633 19.414C33.3633 19.7376 33.6256 19.9999 33.9492 19.9999H38.0508C38.3744 19.9999 38.6367 19.7376 38.6367 19.414C38.6367 19.0904 38.3744 18.828 38.0508 18.828Z"
        fill="#FF8A8E"
      />
      <path
        d="M40.7266 4.48636C41.0502 4.48636 41.3125 4.22403 41.3125 3.90042C41.3125 3.57682 41.0502 3.31448 40.7266 3.31448C40.403 3.31448 40.1406 3.57682 40.1406 3.90042C40.1406 4.22403 40.403 4.48636 40.7266 4.48636Z"
        fill="#FF8A8E"
      />
      <path
        d="M42.3838 6.14366C42.2338 6.14366 42.0838 6.08647 41.9695 5.97205C41.7406 5.74323 41.7406 5.37225 41.9695 5.14342C42.6548 4.45807 42.6548 3.34283 41.9695 2.65748C41.7406 2.42865 41.7406 2.05768 41.9695 1.82885C42.1983 1.60006 42.5693 1.60006 42.7981 1.82885C43.9403 2.97115 43.9403 4.82975 42.7981 5.97205C42.6837 6.08643 42.5337 6.14366 42.3838 6.14366Z"
        fill="#FF8A8E"
      />
      <path
        d="M44.041 7.80102C43.8911 7.80102 43.7411 7.74384 43.6267 7.62938C43.3979 7.40055 43.3979 7.02958 43.6267 6.80075C44.4014 6.02606 44.828 4.9961 44.828 3.90052C44.828 2.80497 44.4014 1.77497 43.6267 1.00028C43.3979 0.771495 43.3979 0.40048 43.6267 0.171652C43.8555 -0.0571765 44.2265 -0.0571765 44.4553 0.171652C45.4514 1.16767 45.9999 2.49192 45.9999 3.90052C45.9999 5.30911 45.4514 6.63337 44.4553 7.62938C44.3409 7.7438 44.191 7.80102 44.041 7.80102Z"
        fill="#FF8A8E"
      />
      <path
        d="M31.2734 4.48636C31.597 4.48636 31.8594 4.22403 31.8594 3.90042C31.8594 3.57682 31.597 3.31448 31.2734 3.31448C30.9498 3.31448 30.6875 3.57682 30.6875 3.90042C30.6875 4.22403 30.9498 4.48636 31.2734 4.48636Z"
        fill="#FF8A8E"
      />
      <path
        d="M29.6157 6.14371C29.4657 6.14371 29.3158 6.08652 29.2014 5.97207C28.0592 4.82976 28.0592 2.97117 29.2014 1.82886C29.4302 1.60007 29.8012 1.60004 30.03 1.82886C30.2589 2.05769 30.2589 2.42867 30.03 2.6575C29.3447 3.34285 29.3447 4.45808 30.03 5.14344C30.2589 5.37226 30.2589 5.74324 30.03 5.97207C29.9156 6.08648 29.7656 6.14371 29.6157 6.14371Z"
        fill="#FF8A8E"
      />
      <path
        d="M27.9589 7.80103C27.8089 7.80103 27.659 7.74384 27.5446 7.62939C26.5486 6.63338 26 5.30912 26 3.90052C26 2.49193 26.5486 1.16767 27.5446 0.171652C27.7734 -0.0571759 28.1444 -0.0571369 28.3732 0.171652C28.602 0.400481 28.602 0.771457 28.3732 1.00029C27.5985 1.77497 27.1719 2.80493 27.1719 3.90052C27.1719 4.99611 27.5985 6.02611 28.3732 6.8008C28.602 7.02959 28.602 7.4006 28.3732 7.62943C28.2588 7.74381 28.1088 7.80103 27.9589 7.80103Z"
        fill="#FF8A8E"
      />
      <path
        d="M35.9961 22.9905C30.4843 23.0161 26 27.521 26 33.0326C26 34.7064 26.4197 36.3565 27.2132 37.8186V35.1637C27.2132 34.6743 27.3874 34.2205 27.6927 33.8311C27.6674 33.5663 27.6544 33.2998 27.6544 33.0326C27.6544 28.429 31.3999 24.6663 35.9961 24.6449C40.6 24.6662 44.3455 28.429 44.3455 33.0326C44.3455 33.3285 44.3291 33.6234 44.2981 33.9164C44.5634 34.2861 44.7132 34.7098 44.7132 35.1637V37.9533C45.5549 36.4583 46 34.7578 46 33.0326C46 27.521 41.5157 23.0161 35.9961 22.9905Z"
        fill="#FF8A8E"
      />
      <path
        d="M27.8294 34.7349C27.7871 34.8733 27.7646 35.0167 27.7646 35.1637V37.7226V38.9136C27.7646 40.071 29.143 41.0092 30.8434 41.0092H32.1397V33.0681H30.8434C29.3589 33.0681 28.1202 33.7831 27.8294 34.7349Z"
        fill="#FF8A8E"
      />
      <path
        d="M44.1621 38.9139V37.6812V35.1639C44.1621 35.0736 44.1527 34.9847 44.1364 34.8974C43.9439 33.8659 42.651 33.0684 41.0834 33.0684H39.7871V41.0095H41.0834C42.7838 41.0095 44.1621 40.0712 44.1621 38.9139Z"
        fill="#FF8A8E"
      />
    </svg>
  )
}

export default OverallIcon
