export const ADD_ANALYTICAL_WRITING = "ADD_ANALYTICAL_WRITING"
export const ADD_ANALYTICAL_WRITING_SUCCESS = "ADD_ANALYTICAL_WRITING_SUCCESS"
export const ADD_ANALYTICAL_WRITING_FAIL = "ADD_ANALYTICAL_WRITING_FAIL"

export const GET_ANALYTICAL_WRITING = "GET_ANALYTICAL_WRITING"
export const GET_ANALYTICAL_WRITING_SUCCESS = "GET_ANALYTICAL_WRITING_SUCCESS"
export const GET_ANALYTICAL_WRITING_FAIL = "GET_ANALYTICAL_WRITING_FAIL"


export const GET_NUMBER_OF_ANALYTICAL_EXAM = "GET_NUMBER_OF_ANALYTICAL_EXAM"
export const GET_NUMBER_OF_ANALYTICAL_EXAM_SUCCESS = "GET_NUMBER_OF_ANALYTICAL_EXAM_SUCCESS"
export const GET_NUMBER_OF_ANALYTICAL_EXAM_FAIL = "GET_NUMBER_OF_ANALYTICAL_EXAM_FAIL"

export const GET_ANALYTICAL_ANS_DATA = "GET_ANALYTICAL_ANS_DATA"
export const GET_ANALYTICAL_ANS_DATA_SUCCESS = "GET_ANALYTICAL_ANS_DATA_SUCCESS"
export const GET_ANALYTICAL_ANS_DATA_FAIL = "GET_ANALYTICAL_ANS_DATA_FAIL"


export const SUBMIT_ANALYTICAL_MARK = "SUBMIT_ANALYTICAL_MARK"
export const SUBMIT_ANALYTICAL_MARK_SUCCESS = "SUBMIT_ANALYTICAL_MARK_SUCCESS"
export const SUBMIT_ANALYTICAL_MARK_FAIL = "SUBMIT_ANALYTICAL_MARK_FAIL"


export const GET_SINGLE_ANALYTICAL_QUES = "GET_SINGLE_ANALYTICAL_QUES"
export const GET_SINGLE_ANALYTICAL_QUES_SUCCESS = "GET_SINGLE_ANALYTICAL_QUES_SUCCESS"
export const GET_SINGLE_ANALYTICAL_QUES_FAIL = "GET_SINGLE_ANALYTICAL_QUES_FAIL"

export const STORE_ANALYTICAL_DATA = "STORE_ANALYTICAL_DATA"