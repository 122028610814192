import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { toCapitalize } from "helpers/Custom/toCapitalize"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Col, Media, Row } from "reactstrap"
import { getService } from "store/actions"
import InnerLayer from "../../Layout/InnerLayer"

const Services = ({ history }) => {
  const dispatch = useDispatch()
  const { services, authToken, loading } = useSelector(state => ({
    services: state.serviceReducer.services?.getDatas,
    authToken: state.Login.token,
    loading: state.serviceReducer.loading,
  }))

  useEffect(() => {
    dispatch(getService(authToken))
  }, [])
console.log('services', services);
  const handleSet = service => {
    console.log(`🦄 ~ file: index.js ~ line 24 ~ Services ~ service`, service)
    switch (service.serviceName?.trim()?.toLowerCase()) {
      case "reading":
        history.push({
          pathname: `/reading-set/${service?._id}`,
        })
        break
      case "listening":
        history.push({
          pathname: `/listening-set/${service?._id}`,
        })
        break
      case "writing":
        history.push({
          pathname: `/writing-set/${service?._id}`,
        })
        break
        case "writing academic":
          history.push({
            pathname: `/writing-set/${service?._id}`,
          })
          break
          case "reading academic":
            history.push({
              pathname: `/reading-set/${service?._id}`,
            })
            break
      default:
        console.log("Not found")
        break
    }
  }
  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Services List"
        mainTitle={"Dashboard"}
        subTitle={"Services List"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>Service List</h4>
              <div className="d-flex">
                <div className="search-box me-2">
                  <div className="position-relative">
                    <input
                      type="search"
                      placeholder="Search..."
                      // value={keyword}
                      // onChange={handleSearchChange}
                      className="form-control"
                    />
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>
                <div>
                  <div className="position-relative">
                    <Link to="/create-service">
                      <Button
                        type="button"
                        color="primary"
                        className="btn-circle me-2"
                      >
                        <i className="mdi mdi-plus me-1" />
                        Create Service
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </CardComponent>
        </Row>
        <Row>
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : (
            services?.map((service, idx) => (
              <Col
                md="3"
                key={idx}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleSet(service)
                }}
              >
                <CardComponent>
                  <Media>
                    <Media body>
                      <p className="text-muted fw-medium mb-2">Service Name</p>
                      <h4 className="mb-1 font-size-15">
                        {toCapitalize(service?.serviceName)}
                      </h4>
                      <span
                        onClick={e => {
                          e.stopPropagation()
                          history.push(`/edit-service/${service?._id}`)
                        }}
                      >
                        Edit
                      </span>
                    </Media>
                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                      <span className="avatar-title rounded-circle bg-primary">
                        <i className={"bx bx-bulb font-size-24"}></i>
                      </span>
                    </div>
                  </Media>
                </CardComponent>
              </Col>
            ))
          )}
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(Services)
