/** Get Dashboard Chart data */
export const ADD_COUPON = "ADD_COUPON"
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS"
export const ADD_COUPON_FAIL = "ADD_COUPON_FAIL"

export const GET_COUPON = "GET_COUPON"
export const GET_COUPON_SUCCESS = "GET_COUPON_SUCCESS"
export const GET_COUPON_FAIL = "GET_COUPON_FAIL"

export const GET_SINGLE_COUPON = "GET_SINGLE_COUPON"
export const GET_SINGLE_COUPON_SUCCESS = "GET_SINGLE_COUPON_SUCCESS"
export const GET_SINGLE_COUPON_FAIL = "GET_SINGLE_COUPON_FAIL"

export const UPDATE_COUPON = "UPDATE_COUPON"
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS"
export const UPDATE_COUPON_FAIL = "UPDATE_COUPON_FAIL"

export const DELETE_COUPON = "DELETE_COUPON"
