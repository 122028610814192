import PropTypes from "prop-types"
import React, { useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect, useDispatch, useSelector } from "react-redux"
import { useHistory, withRouter } from "react-router-dom"
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"
// users
import user1 from "../../../assets/images/Icon/Frame.svg"
import { logoutUser } from "../../../store/auth/login/actions"

const ProfileMenu = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { username } = useSelector(state => ({
    username: state.Login.username,
  }))

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const handleLogout = () => {
    dispatch(logoutUser(history))
    // history.push("/login")
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <span
            className="dropdown-item"
            onClick={() => props.history.push("/profile")}
          >
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </span>
          <div className="dropdown-divider" />
          <span
            onClick={handleLogout}
            className="dropdown-item "
            style={{ cursor: "pointer" }}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </span>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
