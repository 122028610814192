import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import UserProfile from "components/Common/UserProfile"
import { toCapitalize } from "helpers/Custom/toCapitalize"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getSingleStudent } from "store/actions"
import InnerLayer from "../../Layout/InnerLayer"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Student List",
    link: "/students-list",
  },
  {
    title: "View Student",
    link: "#",
  },
]

const ViewProfile = () => {
  const image =
    process.env.REACT_APP_IMAGE_ROOTURL ||
    "https://staging-ieltsx.sgp1.digitaloceanspaces.com/"
  const dispatch = useDispatch()
  const { id } = useParams()
  const { authtoken, student, loading } = useSelector(state => ({
    authtoken: state.Login.token,
    loading: state.studentReducer.loading,
    student: state.studentReducer.student,
  }))
  console.log(
    `🦄 ~ file: ViewProfile.js ~ line 25 ~ ViewProfile ~ student, loading`,
    student,
    loading
  )
  useEffect(() => {
    dispatch(getSingleStudent(id, authtoken))
  }, [])
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"View Student"}
        breadcrumbItems={breadcrumbItems}
      >
        <div style={{ maxWidth: "500px" }} className="mx-auto">
          <CardComponent>
            {loading ? (
              <div className="d-flex justify-content-center">
                <Loader />
              </div>
            ) : (
              <UserProfile
                title={"View Student"}
                data={{
                  name: student?.fullName || "N/A",
                  email: student?.email || "",
                  designation: toCapitalize(student?.role || "Student"),
                  phone: student?.phoneNumber || "",
                }}
                submitting={false}
                image={student?.photo ? `${image}${student?.photo}` : ""}
                profileImage={false}
                view
              />
            )}
          </CardComponent>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default ViewProfile
