import {
  GET_SINGLE_TEACHER,
  GET_SINGLE_TEACHER_FAIL,
  GET_SINGLE_TEACHER_SUCCESS,
  GET_TEACHERS,
  GET_TEACHERS_FAIL,
  GET_TEACHERS_SUCCESS,
  REGISTER_TEACHER,
  REGISTER_TEACHER_FAIL,
  REGISTER_TEACHER_SUCCESS,
} from "./actionTypes"

export const registerTeacher = (values, options) => {
  return {
    type: REGISTER_TEACHER,
    payload: { values, options },
  }
}

export const registerTeacherSuccess = message => {
  return {
    type: REGISTER_TEACHER_SUCCESS,
    payload: { message },
  }
}
export const registerTeacherFail = message => {
  return {
    type: REGISTER_TEACHER_FAIL,
    payload: { message },
  }
}

export const getTeachers = authtoken => {
  return {
    type: GET_TEACHERS,
    payload: { authtoken },
  }
}
export const getTeachersSuccess = teachers => {
  return {
    type: GET_TEACHERS_SUCCESS,
    payload: { teachers },
  }
}

export const getTeachersFail = message => {
  return {
    type: GET_TEACHERS_FAIL,
    payload: { message },
  }
}

export const getSingleTeacher = (teacherId, authtoken) => {
  return {
    type: GET_SINGLE_TEACHER,
    payload: { teacherId, authtoken },
  }
}
export const getSingleTeacherSuccess = teacher => {
  return {
    type: GET_SINGLE_TEACHER_SUCCESS,
    payload: { teacher },
  }
}
export const getSingleTeacherFail = message => {
  return {
    type: GET_SINGLE_TEACHER_FAIL,
    payload: { message },
  }
}
