"use strict"
import { call, put, takeEvery } from "redux-saga/effects"
import { postData } from "../../../helpers/backend_helper"
import { loginSuccess, loginUserError } from "./actions"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"

function* loginUser({ payload: { user, history } }) {
  try {
    const url = "/login"
    const loginUserResponse = yield call(postData, url, user)
    if (loginUserResponse.status === "success") {
      console.log(`loginUserResponse`, loginUserResponse)
      yield put(
        loginSuccess(
          loginUserResponse.UserLogin.data.fullName,
          loginUserResponse.token,
          loginUserResponse.UserLogin.data.email,
          loginUserResponse.UserLogin.data._id,
          loginUserResponse.UserLogin.data.role
        )
      )
      if (loginUserResponse?.UserLogin?.data?.role === "teacher") {
        history.push("/teacher-exam")
      } else {
        console.log("not you")
        history.push("/dashboard")
      }
    } else {
      yield put(loginUserError(loginUserResponse.message))
    }
  } catch (err) {
    const message = err.response?.data?.message || "Login Failed"
    console.log(`function*loginUser ~ message`, message)
    yield put(loginUserError(message))
  }
}

function* onLogoutUser({ payload: { history } }) {
  try {
    yield history.push("/login")
  } catch (error) {
    console.log("error data", error)
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, onLogoutUser)
}

export default authSaga
