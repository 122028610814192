import {
  ADD_COUPON,
  ADD_COUPON_FAIL,
  ADD_COUPON_SUCCESS,
  GET_COUPON,
  GET_COUPON_FAIL,
  GET_COUPON_SUCCESS,
  GET_SINGLE_COUPON,
  GET_SINGLE_COUPON_FAIL,
  GET_SINGLE_COUPON_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  loading: true,
  error: "",
  data: [],
  adding: false,
  singleCoupon: {},
}

const CouponReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_COUPON:
      return {
        ...state,
        adding: true,
      }
    case ADD_COUPON_SUCCESS:
      return {
        ...state,
        adding: false,
      }
    case ADD_COUPON_FAIL:
      return {
        ...state,
        adding: false,
        error: action.payload.data,
      }
    case GET_COUPON:
      return {
        ...state,
        // loading: true,
      }
    case GET_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: "",
      }
    case GET_COUPON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.data,
        data: {},
      }
    case GET_SINGLE_COUPON:
      return {
        ...state,
        // loading: true,
      }
    case GET_SINGLE_COUPON_SUCCESS:
      return {
        ...state,
        loading: false,
        singleCoupon: action.payload.data,
        error: "",
      }
    case GET_SINGLE_COUPON_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.data,
        singleCoupon: {},
      }

    default:
      return state
  }
}

export default CouponReducer
