import React from "react";
import CardComponent from "./CardComponent";


const QuestionTopCard = ({ history, title }) => {
  return (
    <CardComponent className={"mb-2 question_top"}>
      <div className="d-flex align-items-center">
        <i
          className="bx bx-left-arrow-alt cursor_pointers me-2 fs-4"
          onClick={() => history.goBack()}
          style={{ fontSize: "18px" }}
        ></i>
        <h6 className="mb-0">{title}</h6>
      </div>
    </CardComponent>
  );
};

export default QuestionTopCard;
