/* eslint-disable react/react-in-jsx-scope */

const WritingIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5406_39840)">
        <path
          d="M38.8281 14.8394H36.3281C35.6809 14.8394 35.1562 15.364 35.1562 16.0112V33.1987C35.1562 33.8459 35.6809 34.3706 36.3281 34.3706H38.8281C39.4753 34.3706 40 33.8459 40 33.1987V16.0112C40 15.364 39.4753 14.8394 38.8281 14.8394Z"
          fill="#5D5CDD"
        />
        <path
          d="M22.6591 7.44373L24.6122 4.06084C25.0437 3.3135 24.7876 2.35787 24.0402 1.92639L22.6871 1.14514C21.9398 0.713657 20.9841 0.96975 20.5527 1.71709L18.5996 5.09998L22.6591 7.44373Z"
          fill="#5D5CDD"
        />
        <path
          d="M1.39197 27.4453C2.06158 27.827 2.91604 27.5777 3.30143 26.9102L12.8781 10.323C11.9847 10.1108 11.0313 10.2996 10.281 10.8622L6.9633 13.3505C5.99783 14.0747 5.19565 14.9941 4.60908 16.0487L0.34791 23.7109C-0.383575 25.0262 0.0752533 26.6852 1.37861 27.4377C1.38307 27.4402 1.38752 27.4427 1.39197 27.4453Z"
          fill="#5D5CDD"
        />
        <path
          d="M8.34125 23.4448H13.4219L21.4881 9.47363L17.4286 7.12988L8.00195 23.4575C8.11422 23.45 8.22711 23.4448 8.34125 23.4448Z"
          fill="#5D5CDD"
        />
        <path
          d="M3.78412 30.7617L2.57169 32.8617C2.51466 32.9605 2.4724 33.067 2.44615 33.178L1.3281 37.9071C1.11412 38.8122 2.20654 39.4429 2.88341 38.8051L6.41997 35.4723C6.50294 35.3941 6.57411 35.3042 6.63115 35.2054L7.58974 33.5451C5.91622 33.296 4.50716 32.2273 3.78412 30.7617Z"
          fill="#5D5CDD"
        />
        <path
          d="M17.6661 33.6006H10.2635L8.7168 36.2795C9.21414 36.795 9.91156 37.1161 10.6844 37.1161C12.1945 37.1161 13.4187 35.8919 13.4187 34.3818C13.4187 35.8919 14.643 37.1161 16.1531 37.1161C17.6633 37.1161 18.8875 35.8919 18.8875 34.3818V34.2032C18.6038 34.0735 17.9542 33.7435 17.6661 33.6006Z"
          fill="#5D5CDD"
        />
        <path
          d="M22.3285 12.7026L16.1267 23.4444H17.968C18.6152 23.4444 19.1398 23.9691 19.1398 24.6163C19.1398 25.2634 18.6153 25.7881 17.9681 25.7882H8.33984C6.82969 25.7882 5.60547 27.0124 5.60547 28.5226C5.60547 30.0327 6.82969 31.257 8.33984 31.257H18.2186L19.5147 31.9045C21.7327 33.0135 24.2171 33.6006 26.6994 33.6006C28.7559 33.6006 30.8817 33.1965 32.8118 32.4276V17.1942H30.9648L22.3285 12.7026Z"
          fill="#5D5CDD"
        />
      </g>
      <defs>
        <clipPath id="clip0_5406_39840">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WritingIcon
