import { Field, Form, Formik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Button } from "reactstrap"

import { toaster } from "helpers/Custom/Toaster"
// import { addAptitudeQuestion } from "../../../Store/Admin/CorporateQuestions/CorporateQuestionSlice";
import "../../gmat.scss"

import { VerbalReadingPassageSchema } from "components/Schemas/QuestionSchemas"
// import QuestionTopCard from "./Common/QuestionTopCard";
import CardComponent from "components/Common/CardComponent"
import QuestionTopCard from "components/Common/QuestionTopCard"
import CustomInput from "components/InputComponent/CustomInput"
import QuillComponent from "components/InputComponent/QuillComponent"
import { createHash } from "helpers/Custom/Hash"
import { del } from "helpers/api_helper"
import {
  addVerbalQues,
  getVerbalPassageDetails,
} from "store/GMAT/Verbal/actions"
import InnerLayer from "../../../../Layout/InnerLayer"
import PageHeader from "../../../../Layout/PageHeader"
const section = [
  {
    name: "data Sufficency",
    value: 1,
  },
  {
    name: "problem Solving",
    value: 2,
  },
]
const difficultyLevel = [
  {
    name: "easy",
    value: 1,
  },
  {
    name: "medium",
    value: 2,
  },
  {
    name: "difficult",
    value: 3,
  },
]
const quesType = [
  {
    name: "single",
    value: 1,
  },
  {
    name: "multi",
    value: 2,
  },
]
const initialValues = {
  difficultyLevel: "",

  questionTitle: "",
  description: "",

  questionQuant: 1,
}

const ReadingPassageCreate = ({ history, api }) => {
  const textAreaRef = useRef(null)
  const { passageId, questionId, serviceId, level, type } = useParams()
  console.log("questionId", questionId)
  const [listValue, setListValue] = useState(null)
  const [isAddMore, setIsAddMore] = useState(false)
  const [request, setRequest] = useState(false)
  const [sectionData, setSection] = useState("")
  const title = ` Question List`
  const title2 = questionId ? `Edit  Question` : `Create Question`
  const breadcrumbItems = [
    {
      title: title,
      link: `/Quantative-ques-list`,
    },
    {
      title: title2,
      link: `#`,
    },
  ]
  const dispatch = useDispatch()
  const { singleVerbalQues, uploading, authToken, singleVerbalPassageDetails } =
    useSelector(state => ({
      loading: state.analyticalReducer.loading,
      adding: state.analyticalReducer.adding,
      questionNumber: state.analyticalReducer.questionNumber,
      singleVerbalQues: state.verbalReducer.singleVerbalQues,
      singleVerbalQuesLoading: state.verbalReducer.singleVerbalQuesLoading,
      questionGroup: state.analyticalReducer.questionGroup,
      uploading: state.analyticalReducer.uploading,
      authToken: state.Login.token,
      singleVerbalPassageDetails:
        state.verbalReducer.singleVerbalPassageDetails,
    }))
  console.log(`singleVerbalPassageDetails`, singleVerbalPassageDetails)

  useEffect(() => {
    if (passageId) {
      dispatch(getVerbalPassageDetails(authToken, passageId))
    }
  }, [passageId])

  useEffect(() => {
    console.log("hgjfvgn")
    if (passageId && !!singleVerbalPassageDetails) {
      const value = { ...listValue }
      value.difficultyLevel = singleVerbalPassageDetails?.difficultyLevel || ""
      value.questionQuant = singleVerbalPassageDetails?.questionQuant || ""

      value.questionTitle = singleVerbalPassageDetails?.questionTitle || ""
      value.description = singleVerbalPassageDetails?.description || ""

      value.section = null
      value.marks = null

      value.questype = null

      value.q = null

      value.options = null
      value.image = null
      value.rightAnswer = null
      // value.answer = singleQuantitativeQues?.answer?.[0];
      value.explanation = null
      setListValue(value)
    }
  }, [singleVerbalPassageDetails])

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    console.log("values", values)
    const { difficultyLevel, description, questionTitle, ...rest } = values
    const obj = {
      ...rest,
      section: 1,

      description: createHash(description),
      questionTitle: createHash(questionTitle),
      difficultyLevel: difficultyLevel * 1,
    }
    const option = {
      isMore: isAddMore,
      resetForm,
      setSubmitting,
      setIsAddMore,
      id: questionId,
      passageId,
      history,
      serviceId,
      type,
      passageId: passageId,
    }
    console.log(`obj`, obj, option)
    dispatch(addVerbalQues(obj, authToken, option))
    setSubmitting(false)
  }

  const removeFile = async (id, setFieldValue, name) => {
    let success = false
    setRequest(true)
    try {
      const url = `/data-dump/delete-file/${id}`
      const response = await del(url)
      if (response) {
        success = true
        setFieldValue(name, "")
      }
    } catch (err) {
      toaster("error", "Remove file failed")
    } finally {
      setRequest(false)
    }
    return success
  }

  function copyToClipboard(e) {
    textAreaRef.current.select()
    document.execCommand("copy")
    e.target.focus()
    toaster("success", "Url copied successful")
  }
  const handleSection = data => {
    if (data?.value) {
      setSection(data?.value)
    } else {
      setSection("")
    }
  }
  return (
    <React.Fragment>
      <InnerLayer>
        <PageHeader title={title2} breadcrumbItems={breadcrumbItems} />
        <Row className="create_questions">
          <Col md="10" className="offset-md-1">
            <QuestionTopCard
              history={history}
              title={`${questionId ? "Update" : "Create"} Verbal Passage`}
            />
            <CardComponent style={{ marginTop: "5px" }}>
              <h5>Type question</h5>
              <br />
              <Formik
                initialValues={listValue || initialValues}
                validationSchema={VerbalReadingPassageSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  setFieldValue,
                  isSubmitting,
                  handleSubmit,
                }) => (
                  <Form>
                    {console.log("values,", values, errors)}

                    <Row className="mb-3">
                      <Col md="12 mb-2">
                        <Field
                          label="Difficulty level"
                          name="difficultyLevel"
                          type="select"
                          id={"difficultyLevel"}
                          component={CustomInput}
                        >
                          <option value="">Select...</option>
                          <option value="1">Easy</option>
                          <option value="2">Medium</option>
                          <option value="3">difficult</option>
                        </Field>
                      </Col>

                      <Col md="12 mb-2">
                        <Field
                          label="Question Title"
                          name="questionTitle"
                          component={QuillComponent}
                        />
                      </Col>

                      <Col md="12 mb-2">
                        <Field
                          label="Description"
                          name="description"
                          component={QuillComponent}
                        />
                      </Col>
                    </Row>

                    <Col md="12 mb-2">
                      <Field
                        label="Total question"
                        name="questionQuant"
                        type="number"
                        id={"questionQuant"}
                        component={CustomInput}
                        placeholder="Type total question"
                      />
                    </Col>

                    <Row className="mb-3">
                      <Col md="12" className="d-flex justify-content-end">
                        <Button
                          color="warning"
                          outline
                          className="me-2 btn btn-outline-blue"
                          disabled={isSubmitting || uploading}
                          type="submit"
                        >
                          {isSubmitting
                            ? "Submitting..."
                            : questionId || passageId
                            ? "Update"
                            : "Save"}
                        </Button>
                        {!questionId && !passageId ? (
                          <button
                            color="primary"
                            style={{ color: "white" }}
                            disabled={isSubmitting || uploading}
                            type="button"
                            onClick={() => {
                              setIsAddMore(true)
                              handleSubmit()
                            }}
                            className="btn button-blue"
                          >
                            {isSubmitting
                              ? "Submitting..."
                              : "Create more Passage"}
                          </button>
                        ) : null}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default ReadingPassageCreate
