import { call, put, takeEvery } from "redux-saga/effects"
import { toaster } from "../../helpers/Custom/Toaster"
import {
  deleteData,
  getData,
  patchData,
  postData,
} from "../../helpers/backend_helper"
import {
  addPackageFail,
  addPackageSuccess,
  getPackages,
  getPackagesFail,
  getPackagesSuccess,
  getSinglePackageFail,
  getSinglePackageSuccess,
  removePackageFail,
  removePackageSuccess,
} from "./actions"
//from pacakge type
import {
  ADD_PACKAGE,
  DELETE_PACKAGE,
  GET_PACKAGES,
  GET_SINGLE_PACKAGE,
} from "./actionTypes"

function* AddPackages({ payload: { data, history, authtoken, id } }) {
  try {
    let response
    if (id) {
      const url = `/package/update-package/${id}`
      response = yield call(patchData, url, data, authtoken)
      toaster("success", "Package Update successfully!")
    } else {
      const url = "/package/createpackage"
      response = yield call(postData, url, data, authtoken)
      toaster("success", "Package added successfully!")
    }
    yield put(addPackageSuccess(response))
    history.push("/packages")
  } catch (error) {
    const message = error?.response?.data?.message || "Add package fail"
    yield put(addPackageFail(message))
    toaster("error", message)
  }
}

function* fetchAllPackages({ payload: { authtoken } }) {
  try {
    const url = "/package/getallpackages"
    const response = yield call(getData, url, authtoken)
    yield put(getPackagesSuccess(response))
    // toaster("success", "Package added successfully!")
  } catch (error) {
    const message = error.response?.data?.message
    toaster("error", message)
    yield put(getPackagesFail(message))
  }
}

function* fetchSinglePackage({ payload: { id, authtoken } }) {
  try {
    const url = `/package/getsinglepackage/${id}`
    const response = yield call(getData, url, authtoken)
    yield put(getSinglePackageSuccess(response))
  } catch (error) {
    const message = error.response?.data?.message
    toaster("error", message)
    yield put(getSinglePackageFail(message))
  }
}

function* deletePackages({ payload: { id, authtoken } }) {
  try {
    const url = `/package/delete-package/${id}`
    const response = yield call(deleteData, url, authtoken)
    yield put(removePackageSuccess(response))
    toaster("success", "Package removed successfully!")
    yield put(getPackages(authtoken))
  } catch (error) {
    const message = error.response?.data?.message
    toaster("error", message)
    yield put(removePackageFail(message))
  }
}

function* pacakgeSaga() {
  yield takeEvery(ADD_PACKAGE, AddPackages)
  yield takeEvery(DELETE_PACKAGE, deletePackages)
  yield takeEvery(GET_PACKAGES, fetchAllPackages)
  yield takeEvery(GET_SINGLE_PACKAGE, fetchSinglePackage)
}

export default pacakgeSaga
