import { call, put, takeEvery } from "redux-saga/effects"
import { toaster } from "../../helpers/Custom/Toaster"
import { getData, patchData, postData } from "../../helpers/backend_helper"
import {
  addListeningSetFail,
  addListeningSetSuccess,
  addListeningSliceFail,
  addListeningSliceSuccess,
  getListeningAudioFail,
  getListeningAudioSlice,
  getListeningAudioSliceFail,
  getListeningAudioSliceSuccess,
  getListeningAudioSuccess,
  getListeningSetsFail,
  getListeningSetsSuccess,
  getSingleListeningAudioSliceFail,
  getSingleListeningAudioSliceSuccess,
  getSingleListeningSetsFail,
  getSingleListeningSetsSuccess,
  uploadAudioFail,
  uploadAudioSuccess,
} from "./actions"
//for main
import {
  ADD_LISTENING_SET,
  ADD_LISTENING_SLICE,
  GET_LISTENING_AUDIO,
  GET_LISTENING_AUDIO_SINGLE_SLICE,
  GET_LISTENING_AUDIO_SLICE,
  GET_LISTENING_SETS,
  GET_LISTENING_SET_SINGLE,
  UPLOAD_AUDIO,
} from "./actionTypes"

function* workerGetListeningSets({ payload: { token } }) {
  try {
    const url = "/listeningset/get-listening-set"
    const response = yield call(getData, url, token)
    yield put(getListeningSetsSuccess(response?.data))
  } catch (error) {
    const message = error?.response?.data?.message || "Get listing set fail"
    toaster("error", message)
    yield put(getListeningSetsFail(message))
  }
}

function* workerAddListeningSet({
  payload: { values, history, token, serviceId, setId },
}) {
  try {
    const url1 = `/listeningset/update-reading-set/${setId}`
    const url = `/listeningset/create/${serviceId}`
    const response = yield call(
      setId ? patchData : postData,
      setId ? url1 : url,
      values,
      token
    )
    if (response.status == "success") {
      console.log("response", response)
      toaster("success", response.message)
      yield put(addListeningSetSuccess(response.message))
      history.push(`/listening-set/${serviceId}`)
    }
  } catch (error) {
    const message = error?.response?.data || "Add listing set fail"
    toaster("error", message)
    yield put(addListeningSetFail(message))
  }
}

function* workerGetSingleListeningSets({ payload: { serviceId, token } }) {
  try {
    const url = `/listeningset/get-single-set/${serviceId}`
    const response = yield call(getData, url, token)
    yield put(getSingleListeningSetsSuccess(response?.passage))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single listing set fail"
    toaster("error", message)
    yield put(getSingleListeningSetsFail(message))
  }
}

function* onGetListeningAudio({ payload: { setId, token, option } }) {
  try {
    const url = `/listeningaudio/get-audio/${setId}`
    const response = yield call(getData, url, token)
    yield put(getListeningAudioSuccess(response?.getData))
  } catch (error) {
    const message = error?.response?.data?.message || "Get listing audio fail"
    toaster("error", message)
    yield put(getListeningAudioFail(message))
  }
}

function* onUploadAudio({
  payload: {
    values,
    options: { setId, token, history, audioId },
  },
}) {
  try {
    const { file, audioTopicName } = values
    const fd = new FormData()
    fd.append("audio", file)
    fd.append("audioTopicName", audioTopicName)
    const url = audioId
      ? `/listeningaudio/update-audio/${audioId}`
      : `/listeningaudio/upload-audio/${setId}`
    const response = yield call(audioId ? patchData : postData, url, fd, token)
    yield put(uploadAudioSuccess(response?.getData))
    toaster("success", `${audioId ? "Update" : "Upload"} audio success`)
    history.goBack()
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${audioId ? "Update" : "Upload"} audio failed`
    toaster("error", message)
    yield put(uploadAudioFail(message))
  }
}

function* onAddAudioSlice({
  payload: {
    values,
    options: { setId, token, audioId, toggle },
  },
}) {
  try {
    const url = audioId
      ? `/listeningaudio/update-audio/${audioId}`
      : `/listeningaudio/create-audio-time-slice-for-each-question/${setId}`
    const response = yield call(
      audioId ? patchData : postData,
      url,
      values,
      token
    )
    yield put(addListeningSliceSuccess(response))
    toaster("success", `${audioId ? "Update" : "Upload"} audio slice success`)
    toggle()
    yield put(getListeningAudioSlice(token, setId))
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${audioId ? "Update" : "Upload"} audio slice failed`
    toaster("error", message)
    yield put(addListeningSliceFail(message))
  }
}

function* onGetListeningAudioSlice({ payload: { setId, token, option } }) {
  try {
    const url = `/listeningaudio/get-audio-slices/${setId}`
    const response = yield call(getData, url, token)
    yield put(getListeningAudioSliceSuccess(response?.getData))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get listing audio slice fail"
    toaster("error", message)
    yield put(getListeningAudioSliceFail(message))
  }
}

function* onGetSingleListeningAudioSlice({ payload: { sliceId, token } }) {
  try {
    const url = `/listeningaudio/get-single-audio/${sliceId}`
    const response = yield call(getData, url, token)
    yield put(getSingleListeningAudioSliceSuccess(response?.obj))
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single listing audio slice fail"
    toaster("error", message)
    yield put(getSingleListeningAudioSliceFail(message))
  }
}

function* listeningSet() {
  yield takeEvery(ADD_LISTENING_SET, workerAddListeningSet)
  yield takeEvery(GET_LISTENING_SETS, workerGetListeningSets)
  yield takeEvery(GET_LISTENING_SET_SINGLE, workerGetSingleListeningSets)
  yield takeEvery(GET_LISTENING_AUDIO, onGetListeningAudio)
  yield takeEvery(UPLOAD_AUDIO, onUploadAudio)
  yield takeEvery(ADD_LISTENING_SLICE, onAddAudioSlice)
  yield takeEvery(GET_LISTENING_AUDIO_SLICE, onGetListeningAudioSlice)
  yield takeEvery(
    GET_LISTENING_AUDIO_SINGLE_SLICE,
    onGetSingleListeningAudioSlice
  )
}
export default listeningSet
