import CardComponent from "components/Common/CardComponent";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { getFacilitatorSinglePackage } from "store/Facilitator/actions";
import { toCapitalize } from '../../helpers/Custom/toCapitalize';

const imageHelper = (data) => {
 
    switch (data.serviceName?.toLowerCase()?.trim()) {
      case 'reading':
        return 'reading.svg';
      case 'speaking':
        return 'speaking.svg';
      case 'writing':
        return 'writing.svg';
      case 'listening':
        return 'listening.svg';
      default:
        return 'reading.svg';
    }
  };

const FacilitatorSinglePackage = () => {
  const { packageId, studentId } = useParams();
  const history = useHistory()

  const dispatch = useDispatch();
  const handler = (data) => {
    console.log('adddeaaa', data);
    switch (data?.serviceName?.toLowerCase()?.trim()) {
      case 'reading':
        history.push(`/facilitator/${packageId}/${studentId}/reading`);
        break;
      case 'listening':
        history.push(`/facilitator/${packageId}/${studentId}/listening`);
        break;
      case 'writing':
        history.push(`/facilitator/${packageId}/${studentId}/writing`);
        break;
      case 'speaking':
        history.push(`/my-package/${id}/service/${data?._id}/speaking`);
        break;
      default:
        break;
    }
  };
  const { facilitatorSinglePackage, authtoken, userServiceLoading, verifyFacilitator } =
    useSelector((state) => ({
        authtoken: state.Login.token,
   
        loading: state.FacilitatorReducer.loading,
        facilitatorSinglePackage :state.FacilitatorReducer.facilitatorSinglePackage,
        id: state.Login.id,
     
    }));
 
    useEffect(() => {
        dispatch(getFacilitatorSinglePackage(authtoken, packageId));
        
      }, []);
  return (
    <React.Fragment>
      <div className="view-all-single-package-main-wrap">
        <Container>
          <div className="page-title-wrap">
       
          </div>
          <Row>
          {(facilitatorSinglePackage?.singlePackage?.packageService || [])?.map((data, key) => (
            <Col lg={6} md={6} key={key}>
              <div className="card-active-deactive-content">
                {/* <div className="active-pending-top-right-massage">
                  <div className="course-active-btn">
                    <span className="color-success-text">Active</span>
                  </div>
                </div> */}
                <CardComponent className={'text-center py-4'}>
                  <div className="mx-auto mb-3">
                    <img
                      src={require(`./img/${imageHelper(data)}`).default}
                      alt="examImage"
                    />
                  </div>
                  <div>
                    <h5 className="mb-3">{toCapitalize(data.serviceName)}</h5>
                    {/* <p className="mb-3">1st out of 7th attempt</p> */}
                    {/* <Link to={`/reading-exam/${data._id}`}> */}
                    <button
                       className="meet-button"
                      onClick={() => handler(data)}
                    >
                      Review
                    </button>
                    {/* </Link> */}
                  </div>
                </CardComponent>
              </div>
            </Col>
          ))}
     
        </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FacilitatorSinglePackage;
