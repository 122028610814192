import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { toCapitalize } from "helpers/Custom/toCapitalize"
import { imageBaseUrl } from "helpers/api_helper"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import { getTeachers } from "store/teacher/actions"
import InnerLayer from "../../Layout/InnerLayer"
import logo from "../../assets/images/Icon/Frame.svg"
import speakingIcon from "./img/microphone.svg"
import profileIcon from "./img/profile-circle.svg"
import "./teacher.scss"
const TeacherList = ({ history }) => {
  const dispatch = useDispatch()
  const { authToken, teachers, loading } = useSelector(state => ({
    authToken: state.Login.token,
    loading: state.teacherReducer.loading,
    teachers: state.teacherReducer.teachers,
  }))
  console.log(`teachers, loading`, teachers, loading)

  useEffect(() => {
    dispatch(getTeachers(authToken))
  }, [])
  const getPhotoUrl = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return logo
  }

  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={true}
        title="Teacher List"
        mainTitle={"Dashboard"}
        subTitle={"Teacher List"}
        wrapperClass="py-3 users"
        link={"/dashboard"}
      >
        <CardComponent>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Teacher List</h4>
            <div className="d-flex">
              <div className="search-box me-2">
                <div className="position-relative">
                  <input
                    type="search"
                    placeholder="Search..."
                    // value={keyword}
                    // onChange={handleSearchChange}
                    className="form-control"
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
              <div>
                <div className="position-relative">
                  <Link to="/register-teacher">
                    <Button
                      type="button"
                      color="primary"
                      className="btn-circle me-2"
                    >
                      <i className="mdi mdi-plus me-1" />
                      Register
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </CardComponent>

        {/* list */}

        <Row>
          {/* {JSON.stringify(teachers)} */}
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : teachers?.length > 0 ? (
            teachers?.map((data, idx) => (
              <Col mg={6} xl={3} key={idx} style={{ cursor: "pointer" }}>
                <Card className="text-center pt-4 position-relative">
                  <div
                    className="position-absolute"
                    style={{ top: "4%", right: "4%" }}
                  >
                    <span
                      className={"font-size-10 pill "}
                      style={{
                        backgroundColor:
                          data?.request == "active" ? "#34C38F" : "#F46A6A",
                      }}
                    >
                      {toCapitalize(data?.request)}
                    </span>
                  </div>
                  <div className="mx-auto">
                    <img
                      className="d-flex rounded-circle avatar-sm"
                      src={getPhotoUrl(data?.photo)}
                      alt="XamPro"
                    />
                  </div>
                  <CardBody style={{ textAlign: "center" }}>
                    <h5 className="font-size-15">
                      {toCapitalize(data?.fullName)}
                    </h5>
                    <div className="teacher-profile-and-speaking">
                      <Link
                        to={`/facilitator/${data?._id}`}
                        className="teacher-profile-bottom-wrap"
                      >
                        <span>
                          <img src={speakingIcon} alt="" />
                        </span>
                        <span>facilitator</span>
                      </Link>{" "}
                      <Link
                        to={`/speaking/${data?._id}`}
                        className="teacher-profile-bottom-wrap"
                      >
                        <span>
                          <img src={speakingIcon} alt="" />
                        </span>
                        <span>Speaking</span>
                      </Link>{" "}
                      <div
                        onClick={() => history.push(`/teacher/${data?._id}`)}
                        className="teacher-profile-bottom-wrap"
                      >
                        <span>
                          <img src={profileIcon} alt="" />
                        </span>
                        <span>Profile</span>
                      </div>
                    </div>
                    {/* <p className="mb-0 font-size-12">
                      {toCapitalize(data?.role)}
                    </p> */}
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <div className="text-center">
              <h5>No Teacher List</h5>
            </div>
          )}
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(TeacherList)
