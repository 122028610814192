import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { BreadcrumbItem, Col, Row } from "reactstrap"

const Breadcrumb = props => {
  return (
    <Row>
      <Col xs="12">
        <div className="d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-16">{props.breadcrumbItem}</h4>
          {props.isBreadCrumb && (
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <BreadcrumbItem>
                  <Link to={props.backLink}>{props.title}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Link to="#">{props.subTitle}</Link>
                </BreadcrumbItem>
              </ol>
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  isBreadCrumb: PropTypes.bool,
  backLink: PropTypes.string,
}

export default Breadcrumb
