import { combineReducers } from "redux"
// Authentication
import Login from "./auth/login/reducer"
import Profile from "./auth/profile/reducer"
import updatePass from "./auth/updatePassword/reducer"
import CouponReducer from "./Coupon/reducer"
import Course from "./Course/reducer"
import CourseType from "./CourseType/reducer"
//Dashboard
import Dashboard from "./dashboard/reducer"
// Front
import Layout from "./layout/reducer"
//listeningSet
import listeningSetReducer from "./Listening/reducers"
import ListeningQuesReducer from "./ListeningQuestion/reducers"
import packageReducer from "./package/reducers"
import QuesReducer from "./Reading/IELTS/Question/reducers"
// import Register from "./auth/register/reducer"
//readingSet
import readingReducer from "./Reading/IELTS/reducers"
import ReviewAnswer from "./ReviewAnswer/reducers"
import serviceReducer from "./service/reducers"
import speakingSetReducer from "./Speaking/reducers"
import speakingQues from "./SpeakingQuestion/reducers"
import studentReducer from "./student/reducers"
import teacherReducer from "./teacher/reducers"
//writtingSet
import writingSetReducer from "./Writting/reducers"
import FacilitatorReducer from './Facilitator/reducers'
import resultReducer from './Result/reducers'
import FacilitatorPackageReducer from './PackageResult/reducers'
import FacilitatorQuesReducer from './Question/reducers'
import BlogReducer from './Blog/reducer'
import bannerReducer from './Banner/reducer'
import AdminDashboardReducer from './AdminDashboard/reducer'
import FAQReducer from './FAQ/reducers'
import ChatReducer from './Chat/reducers'
import splashReducer from './Splash/reducers'
import analyticalReducer from './GMAT/Analytical/reducers'
import QuantitativeReducer from './GMAT/Quantative/reducers'
import verbalReducer from './GMAT/Verbal/reducers'
import IRReducer from './GMAT/IntegratedReasoning/reducers'
const rootReducer = combineReducers({
  Layout,
  Login,
  Profile,
  packageReducer,
  Dashboard,
  teacherReducer,
  serviceReducer,
  studentReducer,
  writingSet: writingSetReducer,
  readingReducer,
  QuesReducer,
  listeningSet: listeningSetReducer,
  course: Course,
  courseType: CourseType,
  listeningQuestion: ListeningQuesReducer,
  review: ReviewAnswer,
  speakingSet: speakingSetReducer,
  speakingQues,
  FacilitatorReducer,
  resultReducer,
  FacilitatorPackageReducer,
  FacilitatorQuesReducer,
  coupon: CouponReducer,
  updatePassword: updatePass,
  BlogReducer,
  bannerReducer,
  AdminDashboardReducer,
  FAQReducer,
  ChatReducer,
  splashReducer,
  analyticalReducer,
  QuantitativeReducer,
  verbalReducer,
  IRReducer

})

export default rootReducer
