import { del, get, patch, post } from "./api_helper"
import * as url from "./url_helper"

export const getServiceData = authtoken =>
  get(`${url.SERVICE_DATA}/getallservices`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

// IELTS SET LIST
export const addIeltsReadingSetData = (data, authtoken, serviceId) =>
  post(`${url.IELTS_READING_SET_DATA}/create/${serviceId}`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getIeltsReadingSetData = authtoken =>
  get(url.IELTS_READING_SET_DATA, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getIeltsSingleReadingSetData = (authtoken, readingSetID) =>
  get(`${url.IELTS_READING_SET_DATA}/${readingSetID}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

// IELTS PASSAGE

export const addIeltsReadingPassageData = (data, authtoken, readingSetID) =>
  post(
    `${url.IELTS_READING_PASSAGE_DATA}/${readingSetID}/passagecreate`,
    data,
    { headers: { Authorization: `Bearer ${authtoken}` } }
  )

export const getIeltsReadingPassageData = (authtoken, readingSetID) =>
  get(`${url.IELTS_READING_SET_DATA}/${readingSetID}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const addIeltsReadingMcqQuesData = (data, authtoken) =>
  post(`${url.IELTS_READING_MCQ_QUES}/questioncreate`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getIeltsSingleReadingPassageData = (authtoken, passageID) =>
  get(`${url.IELTS_READING_PASSAGE_DATA}/${passageID}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const addPackageData = (data, authtoken) =>
  post(`${url.PACKAGE_DATA}/createpackage`, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })
export const getPackageData = authtoken =>
  get(`${url.PACKAGE_DATA}`, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const getData = (url, authtoken) =>
  get(url, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const getNonAuthData = url => get(url)

export const postData = (url, data, authtoken) =>
  post(url, data, {
    headers: { Authorization: `Bearer ${authtoken}` },
  })

export const postDataWithConfig = (url, data, config) => post(url, data, config)

export const postNonAuthData = (url, data) => post(url, data)

export const patchData = (url, data, authToken) =>
  patch(url, data, {
    headers: { Authorization: `Bearer ${authToken}` },
  })

export const deleteData = (url, authToken) =>
  del(url, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
