import { ACTIVE_BANNER, ACTIVE_BANNER_FAIL, ACTIVE_BANNER_SUCCESS, ACTIVE_SCREEN, ACTIVE_SCREEN_FAIL, ACTIVE_SCREEN_SUCCESS, ADD_BANNER, ADD_BANNER_FAIL, ADD_BANNER_SUCCESS, ADD_SCREEN, ADD_SCREEN_FAIL, ADD_SCREEN_SUCCESS, GET_BANNER, GET_BANNER_FAIL, GET_BANNER_SUCCESS, GET_SCREEN, GET_SCREEN_FAIL, GET_SCREEN_SUCCESS, STORE_BANNER_DATA, STORE_SCREEN_DATA } from "./actionTypes";


export const addScreen = (data, token, history, screenId) => ({
    type: ADD_SCREEN,
    payload: { data, token, history, screenId },
  })
  
  export const addScreenSuccess = data => ({
    type: ADD_SCREEN_SUCCESS,
    payload: { data },
  })
  
  // charts data
  export const addScreenFail = data => ({
    type: ADD_SCREEN_FAIL,
    payload: { data },
  })
  export const getScreenList = (token) => ({
    type: GET_SCREEN,
    payload: { token },
  });
  
  export const getScreenListSuccess = (data) => ({
    type: GET_SCREEN_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getScreenListFail = (data) => ({
    type: GET_SCREEN_FAIL,
    payload: { data },
  });

  export const activeScreen = (data, token, history, id) => ({
    type: ACTIVE_SCREEN,
    payload: { data, token, history, id },
  })
  
  export const activeScreenSuccess = data => ({
    type: ACTIVE_SCREEN_SUCCESS,
    payload: { data },
  })
  
  // charts data
  export const activeScreenFail = data => ({
    type: ACTIVE_SCREEN_FAIL,
    payload: { data },
  })

  
export const storeScreenData = (name, data) => ({
  type: STORE_SCREEN_DATA,
  payload: { name, data },
});
 