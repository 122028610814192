export const ADD_VERBAL_QUES = "ADD_VERBAL_QUES"
export const ADD_VERBAL_QUES_SUCCESS = "ADD_VERBAL_QUES_SUCCESS"
export const ADD_VERBAL_QUES_FAIL = "ADD_VERBAL_QUES_FAIL"

export const GET_VERBAL_QUES = "GET_VERBAL_QUES"
export const GET_VERBAL_QUES_SUCCESS = "GET_VERBAL_QUES_SUCCESS"
export const GET_VERBAL_QUES_FAIL = "GET_VERBAL_QUES_FAIL"

export const GET_SINGLE_VERBAL_QUES = "GET_SINGLE_VERBAL_QUES"
export const GET_SINGLE_VERBAL_QUES_SUCCESS = "GET_SINGLE_VERBAL_QUES_SUCCESS"
export const GET_SINGLE_VERBAL_QUES_FAIL = "GET_SINGLE_VERBAL_QUES_FAIL"

export const UPDATE_VERBAL_QUES = "UPDATE_VERBAL_QUES"
export const UPDATE_VERBAL_QUES_SUCCESS = "UPDATE_VERBAL_QUES_SUCCESS"
export const UPDATE_VERBAL_QUES_FAIL = "UPDATE_VERBAL_QUES_FAIL"

export const GET_VERBAL_PASSAGE_DETAILS = "GET_VERBAL_PASSAGE_DETAILS"
export const GET_VERBAL_PASSAGE_DETAILS_SUCCESS = "GET_VERBAL_PASSAGE_DETAILS_SUCCESS"
export const GET_VERBAL_PASSAGE_DETAILS_FAIL = "GET_VERBAL_PASSAGE_DETAILS_FAIL"

export const ADD_VERBAL_PASSAGE_QUES = "ADD_VERBAL_PASSAGE_QUES"
export const ADD_VERBAL_PASSAGE_QUES_SUCCESS = "ADD_VERBAL_PASSAGE_QUES_SUCCESS"
export const ADD_VERBAL_PASSAGE_QUES_FAIL = "ADD_VERBAL_PASSAGE_QUES_FAIL"

export const GET_VERBAL_READING_SINGLE_QUES = "GET_VERBAL_READING_SINGLE_QUES"
export const GET_VERBAL_READING_SINGLE_QUES_SUCCESS = "GET_VERBAL_READING_SINGLE_QUES_SUCCESS"
export const GET_VERBAL_READING_SINGLE_QUES_FAIL = "GET_VERBAL_READING_SINGLE_QUES_FAIL"

export const STORE_VERBAL_DATA = "STORE_VERBAL_DATA"