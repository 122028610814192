import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { toaster } from "helpers/Custom/Toaster"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Button, CardTitle, Col, Row } from "reactstrap"
import {
  addService,
  getSingleService,
  getSingleServiceSuccess,
} from "store/actions"
import InnerLayer from "../../Layout/InnerLayer"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Service List",
    link: "/services",
  },
  {
    title: "Create Service",
    link: "#",
  },
]

const breadcrumbItems2 = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Service List",
    link: "/services",
  },
  {
    title: "Edit Service",
    link: "#",
  },
]

const CreateService = ({ history, edit }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { authToken, isLoading, loading, singleService } = useSelector(
    state => ({
      authToken: state.Login.token,
      isLoading: state.serviceReducer.isLoading,
      loading: state.serviceReducer.loading,
      singleService: state.serviceReducer.singleService,
    })
  )

  console.log(`authToken`, singleService, id)

  useEffect(() => {
    if (edit) {
      dispatch(getSingleService(id, authToken))
    }

    return () => {
      dispatch(getSingleServiceSuccess({}))
    }
  }, [])

  const handleSubmit = (event, errors, values) => {
    event.preventDefault()
    console.log(`values`, values)
    if (errors.length > 0) {
      toaster("error", "Please enter service name")
      return
    }
    if (edit) {
      dispatch(addService(values, history, authToken, id))
    } else {
      dispatch(addService(values, history, authToken))
    }
  }

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={edit ? "Edit Service" : "Create Service"}
        breadcrumbItems={edit ? breadcrumbItems2 : breadcrumbItems}
      >
        <Row>
          <Col xs="12">
            <CardComponent>
              <CardTitle>{edit ? "Edit" : "Create"} Service</CardTitle>
              {edit && loading ? (
                <Loader />
              ) : (
                <AvForm onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Col md="3">Service Name *</Col>
                    <Col md="9">
                      <AvField
                        name="serviceName"
                        type="text"
                        errorMessage="Type service name"
                        placeholder="Type your set number"
                        value={singleService?.serviceName}
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </Col>
                  </Row>
                  {edit ? null : (
                    <Row className="mb-3">
                      <Col md="3">Serial Number *</Col>
                      <Col md="9">
                        <AvField
                          type="select"
                          name="Serial"
                          errorMessage="Select Serial"
                          placeholder="Select Serial"
                          value={singleService?.Serial}
                          required
                          // label="Select Course"
                        >
                          <option value="">Select Serial</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </AvField>
                      </Col>
                    </Row>
                  )}
                  <Row className="mb-3">
                    <Col md="3"></Col>
                    <Col md="9">
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          disabled={isLoading}
                          type="submit"
                          color="primary"
                          className="btn "
                        >
                          {isLoading
                            ? "Submitting..."
                            : edit
                            ? "Update Service"
                            : "Add Service"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              )}
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(CreateService)
