import { ACTIVE_SCREEN, ACTIVE_SCREEN_FAIL, ACTIVE_SCREEN_SUCCESS, ADD_SCREEN, ADD_SCREEN_FAIL, ADD_SCREEN_SUCCESS, GET_SCREEN, GET_SCREEN_FAIL, GET_SCREEN_SUCCESS, STORE_SCREEN_DATA } from "./actionTypes"

  const INIT_STATE = {
    loading: true,
    error: "",
    data: [],
    adding: false,
    activeSplashLoading: false,
    splashList: [],
    editSplashData: [],
    splashLoading:false
  }
  
  const splashReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case ADD_SCREEN:
        return {
          ...state,
          adding: true,
        }
      case ADD_SCREEN_SUCCESS:
        return {
          ...state,
          adding: false,
        }
      case ADD_SCREEN_FAIL:
        return {
          ...state,
          adding: false,
          error: action.payload.data,
        }
        case GET_SCREEN:
          return {
            ...state,
            splashLoading: true,
          };
        case GET_SCREEN_SUCCESS:
          return {
            ...state,
            splashLoading: false,
            splashList: action.payload.data,
          };
        case GET_SCREEN_FAIL:
          return {
            ...state,
            error: action.payload,
            splashLoading: false,
          };
          case ACTIVE_SCREEN:
            return {
              ...state,
              activeSplashLoading: true,
            }
          case ACTIVE_SCREEN_SUCCESS:
            return {
              ...state,
              activeSplashLoading: false,
            }
          case ACTIVE_SCREEN_FAIL:
            return {
              ...state,
              activeSplashLoading: false,
              error: action.payload.data,
            }  
            case STORE_SCREEN_DATA:
              return {
                ...state,
                [action.payload.name]: action.payload.data,
              };
      default:
        return state
    }
  }
  
  export default splashReducer
  