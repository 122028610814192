import {
  ADD_GIFT,
  ADD_GIFT_FAIL,
  ADD_GIFT_SUCCESS,
  ADD_REVIEW_RATINGS,
  ADD_REVIEW_RATINGS_FAIL,
  ADD_REVIEW_RATINGS_SUCCESS,
  GET_BUY_PACKAGE_LIST_FAIL,
  GET_BUY_PACKAGE_LIST_SUCCESS,
  GET_COUPON_CODE_FAIL,
  GET_COUPON_CODE_SUCCESS,

  GET_REVIEW_FAIL,
  GET_REVIEW_SUCCESS,
  GET_SINGLE_PACKAGE_DATA_FAIL,
  GET_SINGLE_PACKAGE_DATA_SUCCESS,

  GET_USER_PACKAGE,
  GET_USER_PACKAGE_FAIL,
  GET_USER_PACKAGE_SUCCESS,
  RECOMMENDED_PACKAGE_FAIL,
  RECOMMENDED_PACKAGE_SUCCESS,
} from "./actionTypes";

const initialState = {
  allPackage: [],
  singlePackage: {},
  userPackage: [],
  value: {},
  message: "",
  error: "",
  packageLoading: true,
  singlePackageLoading: true,
  userPackageLoading: false,
  adding: false,
  loading: true,
  reviewData: {},
  buyPackageList: {},
  recommendedPackage: [],
  coupon: {},
};

const FacilitatorPackageReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_BUY_PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        buyPackageList: action.payload.data,
        loading: false,
      };
    case GET_BUY_PACKAGE_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_SINGLE_PACKAGE_DATA_SUCCESS:
      return {
        ...state,
        singlePackage: action.payload.data,
        singlePackageLoading: false,
        loading: false,
      };
    case GET_SINGLE_PACKAGE_DATA_FAIL:
      return {
        ...state,
        error: action.payload,
        singlePackageLoading: false,
        loading: false,
      };
    case RECOMMENDED_PACKAGE_SUCCESS:
      return {
        ...state,
        recommendedPackage: action.payload.data,
        // singlePackageLoading: false,
        // loading: false,
      };
    case RECOMMENDED_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        // singlePackageLoading: false,
        // loading: false,
      };
    case GET_USER_PACKAGE:
      return {
        ...state,
        userPackageLoading: true,
      };
    case GET_USER_PACKAGE_SUCCESS:
      return {
        ...state,
        userPackage: action.payload.data,
        userPackageLoading: false,
      };
    case GET_USER_PACKAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        userPackageLoading: false,
      };
    case ADD_REVIEW_RATINGS:
      return {
        ...state,
        adding: true,
      };
    case ADD_REVIEW_RATINGS_SUCCESS:
      return {
        ...state,
        adding: false,
      };
    case ADD_REVIEW_RATINGS_FAIL:
      return {
        ...state,
        adding: false,
      };

    case ADD_GIFT:
      return {
        ...state,
        adding: true,
      };
    case ADD_GIFT_SUCCESS:
      return {
        ...state,
        adding: false,
      };
    case ADD_GIFT_FAIL:
      return {
        ...state,
        adding: false,
      };

    case GET_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewData: action.payload.data,
      };
    case GET_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_COUPON_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        coupon: action.payload.data,
      };
    case GET_COUPON_CODE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default FacilitatorPackageReducer;
