import { GET_RESULT, GET_RESULT_FAIL, GET_RESULT_SUCCESS } from './actionTypes';

const INIT_STATE = {
  results: {},
  loading: false,
};

const resultReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RESULT:
      return {
        ...state,
        loading: true,
      };
    case GET_RESULT_SUCCESS:
      return {
        ...state,
        results: action.payload,
        loading: false,
      };
    case GET_RESULT_FAIL:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    default:
      return state;
  }
};

export default resultReducer;
