import React from "react"

const Request = () => {
  return (
    <React.Fragment>
      <div
        className="position-fixed h-100 w-100 top-0 left-0 d-flex justify-content-center align-items-center"
        style={{
          zIndex: "100000000",
          backgroundColor: "rgb(215 215 215 / 43%)",
        }}
      >
        <div>
          <i
            className="bx bx-loader-alt bx-spin bx-flip-horizontal"
            style={{ color: "#fff", fontSize: "50px" }}
          ></i>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Request
