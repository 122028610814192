import { GET_ALL_INTEGRATED, GET_ALL_INTEGRATED_FAIL, GET_ALL_INTEGRATED_SUCCESS, GET_GRAPHICAL_QUES, GET_GRAPHICAL_QUES_FAIL, GET_GRAPHICAL_QUES_SUCCESS, GET_SINGLE_GRAPHICAL_QUES, GET_SINGLE_GRAPHICAL_QUES_FAIL, GET_SINGLE_GRAPHICAL_QUES_SUCCESS, GET_SINGLE_TABLE_ANALYSIS_QUES, GET_SINGLE_TABLE_ANALYSIS_QUES_FAIL, GET_SINGLE_TABLE_ANALYSIS_QUES_SUCCESS, GET_SINGLE_TWO_PART_QUES, GET_SINGLE_TWO_PART_QUES_FAIL, GET_SINGLE_TWO_PART_QUES_SUCCESS, GET_TABLE_ANALYSIS_QUES, GET_TABLE_ANALYSIS_QUES_FAIL, GET_TABLE_ANALYSIS_QUES_SUCCESS, GET_TWO_PART_QUES, GET_TWO_PART_QUES_FAIL, GET_TWO_PART_QUES_SUCCESS } from "./actionTypes";

  const INIT_STATE = {
    loading: true,
    error: "",
    data: [],
    adding: false,
    graphicalQuestion: [],
    graphicalQuestionLoading:false,
    twoPartQuestion: [],
    twoPartQuestionLoading: false,

    tableAnalysisQuestion: [],
    tableAnalysisQuestionLoading: false,
    singleGraphicalQues: [],
    singleGraphicalQuesLoading: false,
    singleTableAnalysisQues: [],
    singleTableAnalysisQuesLoading: false,
    singleTwoPartQuesLoading: false,
    singleTwoPartQues: [],
    allIntegratedQuestion: [],
    allIntegratedQuesLoading: false
  }
  
  const IRReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_ALL_INTEGRATED:
        return {
          ...state,
          allIntegratedQuesLoading: true,
        };
      case GET_ALL_INTEGRATED_SUCCESS:
        return {
          ...state,
          allIntegratedQuesLoading: false,
          allIntegratedQuestion: action.payload.data,
        };
      case GET_ALL_INTEGRATED_FAIL:
        return {
          ...state,
          error: action.payload,
          allIntegratedQuesLoading: false,
        };
        case GET_GRAPHICAL_QUES:
          return {
            ...state,
            graphicalQuestionLoading: true,
          };
        case GET_GRAPHICAL_QUES_SUCCESS:
          return {
            ...state,
            graphicalQuestionLoading: false,
            graphicalQuestion: action.payload.data,
          };
        case GET_GRAPHICAL_QUES_FAIL:
          return {
            ...state,
            error: action.payload,
            graphicalQuestionLoading: false,
          };
          case GET_TWO_PART_QUES:
            return {
              ...state,
              twoPartQuestionLoading: true,
            };
          case GET_TWO_PART_QUES_SUCCESS:
            return {
              ...state,
              twoPartQuestionLoading: false,
              twoPartQuestion: action.payload.data,
            };
          case GET_TWO_PART_QUES_FAIL:
            return {
              ...state,
              error: action.payload,
              twoPartQuestionLoading: false,
            };

            case GET_TABLE_ANALYSIS_QUES:
              return {
                ...state,
                tableAnalysisQuestionLoading: true,
              };
            case GET_TABLE_ANALYSIS_QUES_SUCCESS:
              return {
                ...state,
                tableAnalysisQuestionLoading: false,
                tableAnalysisQuestion: action.payload.data,
              };
            case GET_TABLE_ANALYSIS_QUES_FAIL:
              return {
                ...state,
                error: action.payload,
                tableAnalysisQuestionLoading: false,
              };
          case GET_SINGLE_GRAPHICAL_QUES:
            return {
              ...state,
              singleGraphicalQuesLoading: true,
            };
          case GET_SINGLE_GRAPHICAL_QUES_SUCCESS:
            return {
              ...state,
              singleGraphicalQuesLoading: false,
              singleGraphicalQues: action.payload.data,
            };
          case GET_SINGLE_GRAPHICAL_QUES_FAIL:
            return {
              ...state,
              error: action.payload,
              singleGraphicalQuesLoading: false,
            };
            case GET_SINGLE_TWO_PART_QUES:
              return {
                ...state,
                singleTwoPartQuesLoading: true,
              };
            case GET_SINGLE_TWO_PART_QUES_SUCCESS:
              return {
                ...state,
                singleTwoPartQuesLoading: false,
                singleTwoPartQues: action.payload.data,
              };
            case GET_SINGLE_TWO_PART_QUES_FAIL:
              return {
                ...state,
                error: action.payload,
                singleTwoPartQuesLoading: false,
              };
              case GET_SINGLE_TABLE_ANALYSIS_QUES:
                return {
                  ...state,
                  singleTableAnalysisQuesLoading: true,
                };
              case GET_SINGLE_TABLE_ANALYSIS_QUES_SUCCESS:
                return {
                  ...state,
                  singleTableAnalysisQuesLoading: false,
                  singleTableAnalysisQues: action.payload.data,
                };
              case GET_SINGLE_TABLE_ANALYSIS_QUES_FAIL:
                return {
                  ...state,
                  error: action.payload,
                  singleTableAnalysisQuesLoading: false,
                };
               
             
           
         
          
      default:
        return state
    }
  }
  
  export default IRReducer
  