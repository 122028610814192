import {
  GET_SINGLE_PURCHASE,
  GET_SINGLE_PURCHASE_FAIL,
  GET_SINGLE_PURCHASE_SUCCESS,
  GET_SINGLE_STUDENT,
  GET_SINGLE_STUDENT_FAIL,
  GET_SINGLE_STUDENT_SUCCESS,
  GET_STUDENTS,
  GET_STUDENTS_SUCCESS,
  GET_STUDENT_COUNT,
  GET_STUDENT_COUNT_FAIL,
  GET_STUDENT_COUNT_SUCCESS,
  GET_STUDENT_LIST,
  GET_STUDENT_LIST_FAIL,
  GET_STUDENT_LIST_SUCCESS,
  GET_STUDENT_ORDER,
  GET_STUDENT_ORDER_FAIL,
  GET_STUDENT_ORDER_SUCCESS,
} from "./actionTypes"

export const getStudents = authtoken => {
  return {
    type: GET_STUDENTS,
    payload: { authtoken },
  }
}

export const getStudentsSuccess = students => {
  return {
    type: GET_STUDENTS_SUCCESS,
    payload: { students },
  }
}

export const getSingleStudent = (studentId, authtoken) => {
  return {
    type: GET_SINGLE_STUDENT,
    payload: { studentId, authtoken },
  }
}
export const getSingleStudentSuccess = student => {
  return {
    type: GET_SINGLE_STUDENT_SUCCESS,
    payload: { student },
  }
}
export const getSingleStudentFail = message => {
  return {
    type: GET_SINGLE_STUDENT_FAIL,
    payload: { message },
  }
}

export const getSinglePurchase = (authtoken, studentId) => {
  return {
    type: GET_SINGLE_PURCHASE,
    payload: { authtoken, studentId },
  }
}
export const getSinglePurchaseSuccess = student => {
  return {
    type: GET_SINGLE_PURCHASE_SUCCESS,
    payload: { student },
  }
}
export const getSinglePurchaseFail = message => {
  return {
    type: GET_SINGLE_PURCHASE_FAIL,
    payload: { message },
  }
}
export const getStudentOrder = (authtoken, startDate, endDate, packageName) => {
  return {
    type: GET_STUDENT_ORDER,
    payload: { authtoken, startDate, endDate, packageName },
  }
}
export const getStudentOrderSuccess = student => {
  return {
    type: GET_STUDENT_ORDER_SUCCESS,
    payload: { student },
  }
}
export const getStudentOrderFail = message => {
  return {
    type: GET_STUDENT_ORDER_FAIL,
    payload: { message },
  }
}

export const getStudentList = (authtoken, startDate, endDate, limit, skip, accountActivation) => {
  return {
    type: GET_STUDENT_LIST,
    payload: {authtoken, startDate, endDate, limit, skip, accountActivation },
  }
}
export const getStudentListSuccess = student => {
  return {
    type: GET_STUDENT_LIST_SUCCESS,
    payload: { student },
  }
}
export const getStudentListFail = message => {
  return {
    type: GET_STUDENT_LIST_FAIL,
    payload: { message },
  }
}
export const getStudentCount = (authtoken, startDate, endDate, accountActivation) => {
  return {
    type: GET_STUDENT_COUNT,
    payload: { authtoken, startDate, endDate, accountActivation },
  }
}
export const getStudentCountSuccess = student => {
  return {
    type: GET_STUDENT_COUNT_SUCCESS,
    payload: { student },
  }
}
export const getStudentCountFail = message => {
  return {
    type: GET_STUDENT_COUNT_FAIL,
    payload: { message },
  }
}
