import {
  ADD_SERVICE,
  ADD_SERVICE_FAIL,
  ADD_SERVICE_SUCCESS,
  GET_SERVICES,
  GET_SERVICES_FAIL,
  GET_SERVICES_SUCCESS,
  GET_SINGLE_SERVICE,
  GET_SINGLE_SERVICE_FAIL,
  GET_SINGLE_SERVICE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  services: [],
  error: {},
  loading: true,
  isLoading: false,
  singleService: {},
}

const serviceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICES:
      return {
        ...state,
        loading: true,
      }
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.payload,
        loading: false,
      }
    case GET_SERVICES_FAIL:
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      }
    case ADD_SERVICE:
      return {
        ...state,
        isLoading: true,
      }
    case ADD_SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case ADD_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case GET_SINGLE_SERVICE:
      return {
        ...state,
        loading: true,
      }
    case GET_SINGLE_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleService: action.payload,
      }
    case GET_SINGLE_SERVICE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default serviceReducer
