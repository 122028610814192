import moment from "moment"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row } from "reactstrap"
import * as XLSX from "xlsx"
import CustomTable from "../../Layout/CustomTable"
import NoTableData from "../../Layout/NoTableData"
import Loader from "../../components/Common/Loader"
import { get } from "../../helpers/api_helper"
import { getStudentList } from "../../store/actions"
const tableHead = [
  "#",
  "Name",
  "Email",
  "Purchased",
  "Date",
  "Status",
  "Action",
]
const UserTable = props => {
  console.log("props", props)
  const dispatch = useDispatch()
  const [exelDate, setExcelDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [loadingExcel, setLoadingExcel] = useState(false)
  const [userLoading, setUserLoading] = useState(false)
  const { authtoken, studentList, studentListLoading, studentCount } =
    useSelector(state => ({
      authtoken: state.Login.token,
      studentList: state.studentReducer.studentList,
      studentListLoading: state.studentReducer.studentListLoading,
      studentCount: state.studentReducer.studentCount,
    }))
  console.log("studentCount", studentCount)
  let skip = (currentPage - 1) * 10
  useEffect(() => {
    dispatch(
      getStudentList(
        authtoken,
        props?.startDate,
        props?.endDate,
        10,
        skip,
        props?.status
      )
    )
  }, [props?.startDate, props?.endDate, props?.status, skip])
  console.log("currentPage", currentPage)
  const handleExcel = async () => {
    setLoadingExcel(true)
    let userData = []

    await get(
      `download-students-data-with-range?startDate=${props?.startDate}&endDate=${props?.endDate}&accountActivation=${props?.status}`,
      { headers: { Authorization: `Bearer ${authtoken}` } }
    )
      .then(response => {
        console.log("response", response)

        response?.getData?.forEach((user, index) => {
          let data = {}
          data.UserName = user?.userInfo?.fullName
          data.Email = user?.userInfo?.email
          data.Phone = user?.userInfo?.phoneNumber
          data.purchaseDate = user?.createdAt?.slice(0, 10)
          data.packageName = user?.package?.packageName
          if (user?.userInfo?.Listening) {
            data.listeningExam = user?.userInfo?.Listening?.length
          }
          if (user?.userInfo?.Reading) {
            data.readingExam = user?.userInfo?.Reading?.length
          }
          if (user?.userInfo?.Speaking) {
            data.speakingExam = user?.userInfo?.Speaking?.length
          }
          if (user?.userInfo?.Writing) {
            data.writingExam = user?.userInfo?.Writing?.length
          }

          data.price = user?.discount
          userData.push(data)
        })
      })
      .catch(error => {
        console.log("kkkk")
        // setResults((prev) => [...prev]);
      })

    downloadxls(userData)
  }
  const downloadxls = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, `${exelDate} Purchase List.xlsx`)
    setLoadingExcel(false)
  }
  const userExcel = async () => {
    setUserLoading(true)
    let userData = []

    await get(
      `get-students-with-range?startDate=${props?.startDate}&endDate=${props?.endDate}&limit=${studentCount?.getData}&skip=${skip}&accountActivation=${props?.status}`,
      { headers: { Authorization: `Bearer ${authtoken}` } }
    )
      .then(response => {
        console.log("response", response)

        response?.getData?.forEach((user, index) => {
          let data = {}
          data.UserName = user?.fullName
          data.Email = user?.email
          data.Phone = user?.phoneNumber
          data.RegistrationDate = user?.createdAt?.slice(0, 10)
          data.status = user?.accountActivateToken ? "Pending" : "Active"

          data.purchase = user?.payment?.length

          userData.push(data)
        })
      })
      .catch(error => {
        console.log("kkkk")
        // setResults((prev) => [...prev]);
      })

    downloadxls2(userData)
  }
  const downloadxls2 = data => {
    console.log(XLSX.version)
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")
    XLSX.writeFile(wb, `${exelDate} User List.xlsx`)
    setUserLoading(false)
  }
  return (
    <div>
      <Row>
        <CustomTable
          paginationClass="paginationContainer text-right"
          data={studentList?.getData}
          pageNo={Math.ceil(props?.studentCount?.getData / 10) || 1}
          tableHead={tableHead}
          setCurrentPage={setCurrentPage}
          isPagination
          // setCurrentPage={setCurrentPage}
          // tbodyStyle={{ height: couponData?.length <= 3 ? "300px" : "" }}
        >
          {studentListLoading ? (
            <NoTableData
              colSpan="8"
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "300px", width: `100%` }}
            >
              <Loader />
            </NoTableData>
          ) : studentList?.getData ? (
            studentList?.getData?.map((data, idx) => (
              <tr key={idx}>
                <th scope="row" style={{ paddingLeft: "25px" }}>
                  {(currentPage - 1) * 10 + (idx + 1)}
                </th>
                <td>{data?.fullName}</td>
                <td>{data?.email}</td>
                <td>{data?.payment.length}</td>
                <td>{data?.createdAt?.slice(0, 10)}</td>
                <td>{data?.accountActivateToken ? "Pending" : "Active"}</td>
              </tr>
            ))
          ) : (
            <NoTableData
              colSpan="8"
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "300px", width: `100%` }}
            >
              <span>No data Available</span>
            </NoTableData>
          )}
        </CustomTable>
      </Row>
      <div className="text-center mb-4">
        <button
          className="btn btn-info m-auto w-25 fw-bold excel_button"
          disabled={userLoading}
          onClick={userExcel}
        >
          {userLoading ? "Downloading..." : "Download User List"}
        </button>
      </div>
      <div className="text-center">
        <button
          className="btn btn-info m-auto w-25 fw-bold excel_button"
          disabled={loadingExcel}
          onClick={handleExcel}
        >
          {loadingExcel ? "Downloading..." : "Download Purchase History"}
        </button>
      </div>
    </div>
  )
}

export default UserTable
