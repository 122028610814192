import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Col, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import { del, imageBaseUrl, post } from "../../helpers/api_helper";
import { getImageUrl } from "helpers/Custom/Questions";
import { toaster } from "helpers/Custom/Toaster";
// import { storeFilter } from "../../Store/Filter/filterSlice";
// import AudioSet from "../Admin/ExamPassage/Listening/AudioSet";
import UploadFile from "../Common/Upload";
import { useSelector } from "react-redux";

const FileUpload = ({
  label,
  id,
  field: { name },
  form: { touched, errors, setFieldValue, values },
  isRequired,
  acceptFile = {
    "image/jpg": [],
    "image/png": [],
    "image/jpeg": [],
  },
  imageId,
  uploadFor,
  isArray,
  uploadCallBack,
  isRemoveAble = true,
  textStartWrapper = "",
  textEndWrapper = "",
  ...props
}) => {
  const [progress, setProgress] = useState(0);
  const [reRender, setReRender] = useState(false);
  const dispatch = useDispatch();
  const { authToken } =
  useSelector(state => ({
    authToken: state.Login.token,
   
  }))
  useEffect(() => {
    setReRender((prev) => !prev);
  }, [values?.[name]]);
  // 'Brand', 'Product', 'Agency', 'User', 'Activation', 'Organization',
  const [request, setRequest] = useState(false);
  const [removing, setRemoving] = useState(false);
  const source = axios.CancelToken.source();
  // console.log(` cancel`, source);
  const onUploadFile = async (file) => {
    // console.log(`values?.[name]`, values?.[name]);
    // const format = file?.type?.split("/")?.[0];
    setRequest(true);
    // dispatch(storeFilter({ name: "uploading", data: true }));
    try {
      setProgress(0);
      uploadCallBack && uploadCallBack(true);
      if (values?.[name]) {
        await removeFile(values?.[name], setRequest, true);
      }
      const formData = new FormData();
      formData.append("photo", file);
      formData.append("name", file?.name);
      // formData.append("fileDestination", currentRoute);
      // for (let key of formData.entries()) {
      //   console.log(key);
      // }
      const url = "/image-upload";
      // API_URL +
      const response = await post(url, formData, {
        onUploadProgress: (data) => {
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
        headers: { Authorization: `Bearer ${authToken}` },
        cancelToken: source.token,
      });
      if (response) {
        setFieldValue(
          name,
          `${textStartWrapper}${response?.photo}${textEndWrapper}`
        );
      }
    } catch (err) {
      // console.log(`err`, err);
      toaster("error", "Upload video failed");
    } finally {
      setRequest(false);
      uploadCallBack && uploadCallBack(false);
      setProgress(0);
      // dispatch(storeFilter({ name: "uploading", data: false }));
    }
  };

  const removeFile = async (id, setRequest, upload) => {
    let success = false;
    if (!upload) {
      // dispatch(storeFilter({ name: "uploading", data: true }));
      setRequest(true);
    }
    try {
      const url = `/data-dump/delete-file/${id}`;
      const response = await del(url);
      // console.log(`removeFile ~ response`, response);
      if (response) {
        success = true;
        setFieldValue(name, "");
      }
    } catch (err) {
      toaster("error", "Remove file failed");
    } finally {
      if (!upload) {
        setRequest(false);
        // dispatch(storeFilter({ name: "uploading", data: false }));
      }
    }
    return success;
  };

  return (
    <React.Fragment>
      <FormGroup className={"w-100"}>
        {label ? (
          <Label htmlFor={id}>
            {label}
            {isRequired ? <span className="text-danger">{" *"}</span> : ""}
          </Label>
        ) : null}
        <UploadFile
          exceptFile={acceptFile}
          isLoading={request}
          onDropCallBack={(files, rejectFile) => {
            // console.log(`UploadContent ~ file`, files);
            if (rejectFile && rejectFile?.length > 0) {
              toaster("warning", rejectFile?.[0]?.errors?.[0]?.code);
            }
            if (files.length > 0) {
              onUploadFile(files?.[0], values);
            }
          }}
          reRender={reRender}
          progress={progress}
          onCancelApi={() => {
            setRequest(false);
            source.cancel();
          }}
          isProgress
          {...props}
        />
        {touched[name] && errors[name] && !props.disabled ? (
          <FormFeedback type="invalid" className="d-inline">
            {errors[name]}
          </FormFeedback>
        ) : null}
        {values?.[name] && !isArray ? (
          <div
            className="position-relative mb-2 mt-2"
            style={{ width: props?.audio || props.isFull ? "100%" : "200px" }}
          >
            {(!props.isDisabled || props.isHide) && isRemoveAble && (
              <i
                className="fs-2 text-danger position-absolute bx bx-trash"
                style={{
                  top: "2%",
                  right: "0%",
                  cursor: removing ? "" : "pointer",
                  zIndex: "1",
                }}
                onClick={() =>
                  !removing && removeFile(values[name], setRemoving)
                }
              ></i>
            )}
            {props.audio ? (
              null// <AudioSet track={imageBaseUrl + values?.[name]} />
            ) : props.video ? (
              <div
                style={{ height: props.videoHeight || "200px", width: "100%" }}
              >
                <video
                  className="video-styles w-100 h-100 rounded"
                  style={{ objectFit: "contain" }}
                  src={imageBaseUrl + values?.[name]}
                  controls={true}
                ></video>
              </div>
            ) : (
              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col md={12} className="col-auto 12">
                      <img
                        style={{ height: "150px", objectFit: "cover" }}
                        src={
                          imageBaseUrl +
                          (textStartWrapper
                            ? getImageUrl(values?.[name])
                            : values?.[name])
                        }
                        alt="img"
                      />
                    </Col>
                    <Col className="mt-1">
                      <Link to="#" className="text-muted font-weight-bold">
                        {textStartWrapper
                          ? getImageUrl(values?.[name])
                          : values?.[name]}
                      </Link>
                    </Col>
                  </Row>
                </div>
              </Card>
            )}
          </div>
        ) : null}
      </FormGroup>
    </React.Fragment>
  );
};

export default FileUpload;
