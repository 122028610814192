

export const GET_NUMBER_STUDENTS= "GET_NUMBER_STUDENTS"
export const GET_NUMBER_STUDENTS_SUCCESS = "GET_NUMBER_STUDENTS_SUCCESS"
export const GET_NUMBER_STUDENTS_FAIL = "GET_NUMBER_STUDENTS_FAIL"

export const GET_AVG_SCORE= "GET_AVG_SCORE"
export const GET_AVG_SCORE_SUCCESS = "GET_AVG_SCORE_SUCCESS"
export const GET_AVG_SCORE_FAIL = "GET_AVG_SCORE_FAIL"


export const GET_NET_AMOUNT= "GET_NET_AMOUNT"
export const GET_NET_AMOUNT_SUCCESS = "GET_NET_AMOUNT_SUCCESS"
export const GET_NET_AMOUNT_FAIL = "GET_NET_AMOUNT_FAIL"

export const GET_PACKAGE_CONTRIBUTION= "GET_PACKAGE_CONTRIBUTION"
export const GET_PACKAGE_CONTRIBUTION_SUCCESS = "GET_PACKAGE_CONTRIBUTION_SUCCESS"
export const GET_PACKAGE_CONTRIBUTION_FAIL = "GET_PACKAGE_CONTRIBUTION_FAIL"

export const GET_ENROLLMENT_STATUS= "GET_ENROLLMENT_STATUS"
export const GET_ENROLLMENT_STATUS_SUCCESS = "GET_ENROLLMENT_STATUS_SUCCESS"
export const GET_ENROLLMENT_STATUS_FAIL = "GET_ENROLLMENT_STATUS_FAIL"

export const CREATE_UNIVERSITY= "CREATE_UNIVERSITY"
export const CREATE_UNIVERSITY_SUCCESS = "CREATE_UNIVERSITY_SUCCESS"
export const CREATE_UNIVERSITY_FAIL = "CREATE_UNIVERSITY_FAIL"

export const GET_UNIVERSITY= "GET_UNIVERSITY"
export const GET_UNIVERSITY_SUCCESS = "GET_UNIVERSITY_SUCCESS"
export const GET_UNIVERSITY_FAIL = "GET_UNIVERSITY_FAIL"

export const GET_GOOGLE_ANALYTICS= "GET_GOOGLE_ANALYTICS"
export const GET_GOOGLE_ANALYTICS_SUCCESS = "GET_GOOGLE_ANALYTICS_SUCCESS"
export const GET_GOOGLE_ANALYTICS_FAIL = "GET_GOOGLE_ANALYTICS_FAIL"

export const STORE_ADMIN_DASHBOARD_DATA = "STORE_ADMIN_DASHBOARD_DATA"