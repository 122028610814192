import {
  ADD_REVIEW,
  ADD_REVIEW_FAIL,
  ADD_REVIEW_SUCCESS,
  CHECK_GRAMMAR,
  CHECK_GRAMMAR_FAIL,
  CHECK_GRAMMAR_SUCCESS,
  GET_ALL_ANSWER_FAIL,
  GET_ALL_ANSWER_SUCCESS,
  GET_PARTICULAR_SET_FAIL,
  GET_PARTICULAR_SET_SUCCESS,
  GET_REVIEW_EXAM,
  GET_REVIEW_EXAM_FAIL,
  GET_REVIEW_EXAM_SUCCESS,
  REMOVE_REVIEW_EXAM,
  REMOVE_REVIEW_EXAM_FAIL,
  REMOVE_REVIEW_EXAM_SUCCESS,
} from "./actionTypes"

const initialState = {
  loading: true,
  adding: false,
  load: false,
  message: "",
  error: "",
  allAnswer: [],
  particularSet: {},
  reviewExam: [],
}

const ReviewAnswer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_REVIEW:
      state = {
        ...state,
        adding: true,
      }
      break
    case ADD_REVIEW_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        adding: false,
      }
      break
    case ADD_REVIEW_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      }
      break
      case CHECK_GRAMMAR:
        state = {
          ...state,
          adding: true,
        }
        break
      case CHECK_GRAMMAR_SUCCESS:
        state = {
          ...state,
          message: action.payload.message,
          adding: false,
        }
        break
      case CHECK_GRAMMAR_FAIL:
        state = {
          ...state,
          error: action.payload.message,
          adding: false,
        }
        break

    case GET_ALL_ANSWER_FAIL:
      state = {
        ...state,
        loading: false,
      }
      break
    case GET_ALL_ANSWER_SUCCESS:
      state = {
        ...state,
        loading: false,
        allAnswer: action.payload.values,
      }
      break

    case GET_PARTICULAR_SET_FAIL:
      state = {
        ...state,
        loading: false,
      }
      break

    case GET_PARTICULAR_SET_SUCCESS:
      state = {
        ...state,
        loading: false,
        particularSet: action.payload.values,
      }
      break
    case GET_REVIEW_EXAM:
      state = {
        ...state,
        load: true,
      }
      break
    case GET_REVIEW_EXAM_FAIL:
      state = {
        ...state,
        load: false,
        error: action.payload.message,
      }
      break
    case GET_REVIEW_EXAM_SUCCESS:
      state = {
        ...state,
        load: false,
        reviewExam: action.payload.values,
      }
      break

    case REMOVE_REVIEW_EXAM:
      state = {
        ...state,
        adding: true,
      }
      break
    case REMOVE_REVIEW_EXAM_SUCCESS:
      state = {
        ...state,
        message: action.payload.message,
        adding: false,
      }
      break
    case REMOVE_REVIEW_EXAM_FAIL:
      state = {
        ...state,
        error: action.payload.message,
        adding: false,
      }
      break

    default:
      state = { ...state }
      break
  }

  return state
}

export default ReviewAnswer
