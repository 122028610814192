import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { imageBaseUrl } from "helpers/api_helper"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import { Row } from "reactstrap"
import {
  getSingleWritingSets,
  getSingleWritingSetsSuccess,
} from "store/actions"
import InnerLayer from "../../../Layout/InnerLayer"
import answerImage from "./Questions/img/130.png"
import editIcon from "./Questions/img/edit-2.svg"

const SingleWritingSet = ({ history }) => {
  const [open, setOpen] = useState(false)
  const [editData, setEditData] = useState({})
  const { serviceId, setId } = useParams()
  const dispatch = useDispatch()
  const { authToken, loading, writingSet, question } = useSelector(state => ({
    authToken: state.Login.token,
    adding: state.listeningSet.adding,
    loading: state.writingSet.loading,
    writingSet: state.writingSet.writingSet,
    question: state.writingSet.writingSet?.writtingQuestion?.questions,
  }))
  console.log(`listeningAudio`, writingSet)
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Service List",
      link: `/services`,
    },
    {
      title: "Writing Set",
      link: `/writing-set/${serviceId}`,
    },
    {
      title: `${writingSet?.setName || ""}`,
      link: "#",
    },
  ]
  useEffect(() => {
    dispatch(getSingleWritingSets(authToken, { serviceId, setId }))
    return () => {
      dispatch(getSingleWritingSetsSuccess({}))
    }
  }, [])

  const toggle = () => setOpen(!open)

  const handleEdit = data => {
    setEditData(data)
    toggle()
  }
  const getPhotoUrl = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return answerImage
  }

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={writingSet?.setName || `Set Name`}
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>Writing Set</h4>
              <div className="d-flex">
                {/* <div className="search-box me-2">toggle</div> */}
                <div>
                  {question?.length === 2 ? null : (
                    <Link
                      to={`/service/${serviceId}/writing/${setId}/create-question`}
                    >
                      <button
                        style={{
                          backgroundColor: "#0052CC",
                          color: "#fff",
                        }}
                        className=" btn me-1"
                        // disabled={isDone()}
                        onClick={toggle}
                      >
                        Create Question
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </CardComponent>
        </Row>

        <Row>
          <div className="preview-answer-container-main-wrap">
            <CardComponent>
              {loading ? (
                <div className="text-center">
                  <Loader />
                </div>
              ) : (
                <div>
                  <div className="preview-writing-ques">
                    <div className="writing-academic-ques">
                      <h5>Answer & Question</h5>
                      {question?.length ? (
                        <React.Fragment>
                          {question?.map((data, idx) => (
                            <div key={idx} className="mb-3">
                              <div className="writing-header-answer-top">
                                <h4>Writing Task {idx + 1}</h4>
                                <div className="d-flex align-items-center">
                                  <img
                                    src={editIcon}
                                    alt=""
                                    onClick={() =>
                                      history.push(
                                        `/service/${serviceId}/writing/${setId}/edit-question/${data?._id}`
                                      )
                                    }
                                  />
                                  <i className="bx bx-trash fs-3 text-danger"></i>
                                  {/* <div className="writing-ques-delete-button-bootom">
                                    <button>Delete</button>
                                  </div> */}
                                </div>
                              </div>
                              <p
                                dangerouslySetInnerHTML={{ __html: data?.q }}
                              ></p>
                              {data?.image ? (
                                <img
                                  src={getPhotoUrl(data?.image)}
                                  alt=""
                                  className="img-fluid"
                                />
                              ) : null}
                            </div>
                          ))}

                          <hr />
                        </React.Fragment>
                      ) : (
                        <div>
                          <span>
                            You have no created question{" "}
                            <Link
                              to={`/service/${serviceId}/writing/${setId}/create-question`}
                            >
                              Create a question
                            </Link>
                          </span>
                        </div>
                      )}
                    </div>
                    {/* <div className="writing-academic-ques mt-3">
                      <h5>Answer & Question</h5>
                      <div className="writing-header-answer-top">
                        <h4 className="py-2">Writing Task 1</h4>
                        <Link to="">
                          <img src={editIcon} alt="" />
                        </Link>
                      </div>
                      <p>
                        It is generally believed that some people are born with
                        certain talents, for instance for sport or music and
                        others are not. However, it is sometimes claimed that
                        any child can be taught to become a good sports person
                        or musician.
                      </p>
                    </div>
                    <hr /> */}
                  </div>
                </div>
              )}
            </CardComponent>
          </div>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(SingleWritingSet)
