import { ADD_ANALYTICAL_WRITING, ADD_ANALYTICAL_WRITING_FAIL, ADD_ANALYTICAL_WRITING_SUCCESS, GET_ANALYTICAL_ANS_DATA, GET_ANALYTICAL_ANS_DATA_FAIL, GET_ANALYTICAL_ANS_DATA_SUCCESS, GET_ANALYTICAL_WRITING, GET_ANALYTICAL_WRITING_FAIL, GET_ANALYTICAL_WRITING_SUCCESS, GET_NUMBER_OF_ANALYTICAL_EXAM, GET_NUMBER_OF_ANALYTICAL_EXAM_FAIL, GET_NUMBER_OF_ANALYTICAL_EXAM_SUCCESS, GET_SINGLE_ANALYTICAL_QUES, GET_SINGLE_ANALYTICAL_QUES_FAIL, GET_SINGLE_ANALYTICAL_QUES_SUCCESS, SUBMIT_ANALYTICAL_MARK, SUBMIT_ANALYTICAL_MARK_FAIL, SUBMIT_ANALYTICAL_MARK_SUCCESS } from "./actionTypes"

export const addAnalyticalWriting = (data, authToken, options) => ({
    type: ADD_ANALYTICAL_WRITING,
    payload: { data, authToken, options },
  })
  
  export const addAnalyticalWritingSuccess = data => ({
    type: ADD_ANALYTICAL_WRITING_SUCCESS,
    payload: { data },
  })

  export const addAnalyticalWritingFail = data => ({
    type: ADD_ANALYTICAL_WRITING_FAIL,
    payload: { data },
  })

  export const getAnalyticalWriting = (token, type) => ({
    type: GET_ANALYTICAL_WRITING,
    payload: { token, type },
  });
  
  export const getAnalyticalWritingSuccess = (data) => ({
    type: GET_ANALYTICAL_WRITING_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getAnalyticalWritingFail = (data) => ({
    type: GET_ANALYTICAL_WRITING_FAIL,
    payload: { data },
  });
  export const getSingleAnalyticalQues = (authToken, questionId) => ({
    type: GET_SINGLE_ANALYTICAL_QUES,
    payload: { authToken, questionId },
  });
  
  export const getSingleAnalyticalQuesSuccess = (data) => ({
    type: GET_SINGLE_ANALYTICAL_QUES_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getSingleAnalyticalQuesFail = (data) => ({
    type: GET_SINGLE_ANALYTICAL_QUES_FAIL,
    payload: { data },
  });


  export const getNumberOfAnalyticalAns = (authToken, examStatus) => ({
    type: GET_NUMBER_OF_ANALYTICAL_EXAM,
    payload: { authToken, examStatus },
  });
  
  export const getNumberOfAnalyticalAnsSuccess = (data) => ({
    type: GET_NUMBER_OF_ANALYTICAL_EXAM_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getNumberOfAnalyticalAnsFail = (data) => ({
    type: GET_NUMBER_OF_ANALYTICAL_EXAM_FAIL,
    payload: { data },
  });

  export const getAnalyticalAnsData = (authToken, skip, limit) => ({
    type: GET_ANALYTICAL_ANS_DATA,
    payload: { authToken, skip, limit  },
  });
  
  export const getAnalyticalAnsDataSuccess = (data) => ({
    type: GET_ANALYTICAL_ANS_DATA_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getAnalyticalAnsDataFail = (data) => ({
    type: GET_ANALYTICAL_ANS_DATA_FAIL,
    payload: { data },
  });


  export const submitAnalyticalMark = (data, authToken, options) => ({
    type: SUBMIT_ANALYTICAL_MARK,
    payload: { data, authToken, options },
  })
  
  export const submitAnalyticalMarkSuccess = data => ({
    type: SUBMIT_ANALYTICAL_MARK_SUCCESS,
    payload: { data },
  })

  export const submitAnalyticalMarkFail = data => ({
    type: SUBMIT_ANALYTICAL_MARK_FAIL,
    payload: { data },
  })