import { ADD_VERBAL_PASSAGE_QUES, ADD_VERBAL_PASSAGE_QUES_FAIL, ADD_VERBAL_PASSAGE_QUES_SUCCESS, ADD_VERBAL_QUES, ADD_VERBAL_QUES_FAIL, ADD_VERBAL_QUES_SUCCESS, GET_SINGLE_VERBAL_QUES, GET_SINGLE_VERBAL_QUES_FAIL, GET_SINGLE_VERBAL_QUES_SUCCESS, GET_VERBAL_PASSAGE_DETAILS, GET_VERBAL_PASSAGE_DETAILS_FAIL, GET_VERBAL_PASSAGE_DETAILS_SUCCESS, GET_VERBAL_QUES, GET_VERBAL_QUES_FAIL, GET_VERBAL_QUES_SUCCESS, GET_VERBAL_READING_SINGLE_QUES, GET_VERBAL_READING_SINGLE_QUES_FAIL, GET_VERBAL_READING_SINGLE_QUES_SUCCESS, UPDATE_VERBAL_QUES, UPDATE_VERBAL_QUES_FAIL, UPDATE_VERBAL_QUES_SUCCESS } from "./actionTypes";

export const addVerbalQues = (obj, authToken, option) => ({
    type: ADD_VERBAL_QUES,
    payload: { obj, authToken, option },
  })
  
  export const addVerbalQuesSuccess = data => ({
    type: ADD_VERBAL_QUES_SUCCESS,
    payload: { data },
  })

  export const addVerbalQuesFail = data => ({
    type: ADD_VERBAL_QUES_FAIL,
    payload: { data },
  })

  export const getAllVerbalQues = (token, type, level) => ({
    type: GET_VERBAL_QUES,
    payload: { token, type, level },
  });
  
  export const getAllVerbalQuesSuccess = (data) => ({
    type: GET_VERBAL_QUES_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getAllVerbalQuesFail = (data) => ({
    type: GET_VERBAL_QUES_FAIL,
    payload: { data },
  });

  export const getSingleVerbalQues = (authToken, questionId) => ({
    type: GET_SINGLE_VERBAL_QUES,
    payload: { authToken, questionId },
  });
  
  export const getSingleVerbalQuesSuccess = (data) => ({
    type: GET_SINGLE_VERBAL_QUES_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getSingleVerbalQuesFail = (data) => ({
    type: GET_SINGLE_VERBAL_QUES_FAIL,
    payload: { data },
  });
  export const updateVerbalQues = (data, token, history, screenId) => ({
    type: UPDATE_VERBAL_QUES,
    payload: { data, token, history, screenId },
  })
  
  export const updateVerbalQuesSuccess = data => ({
    type: UPDATE_VERBAL_QUES_SUCCESS,
    payload: { data },
  })

  export const updateVerbalQuesFail = data => ({
    type: UPDATE_VERBAL_QUES_FAIL,
    payload: { data },
  })

  export const getVerbalPassageDetails = (token, passageId) => ({
    type: GET_VERBAL_PASSAGE_DETAILS,
    payload: { token, passageId },
  });
  
  export const getVerbalPassageDetailsSuccess = (data) => ({
    type: GET_VERBAL_PASSAGE_DETAILS_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getVerbalPassageDetailsFail = (data) => ({
    type: GET_VERBAL_PASSAGE_DETAILS_FAIL,
    payload: { data },
  });


  export const addVerbalPassageQues = (obj, authToken, option) => ({
    type: ADD_VERBAL_PASSAGE_QUES,
    payload: { obj, authToken, option },
  })
  
  export const addVerbalPassageQuesSuccess = data => ({
    type: ADD_VERBAL_PASSAGE_QUES_SUCCESS,
    payload: { data },
  })

  export const addVerbalPassageQuesFail = data => ({
    type: ADD_VERBAL_PASSAGE_QUES_FAIL,
    payload: { data },
  })

  export const getSingleVerbalReadingQues = (authToken, questionId) => ({
    type: GET_VERBAL_READING_SINGLE_QUES,
    payload: { authToken, questionId },
  });
  
  export const getSingleVerbalReadingQuesSuccess = (data) => ({
    type: GET_VERBAL_READING_SINGLE_QUES_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getSingleVerbalReadingQuesFail = (data) => ({
    type: GET_VERBAL_READING_SINGLE_QUES_FAIL,
    payload: { data },
  });