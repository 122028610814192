export const REGISTER_TEACHER = "REGISTER_TEACHER"
export const REGISTER_TEACHER_SUCCESS = "REGISTER_TEACHER_SUCCESS"
export const REGISTER_TEACHER_FAIL = "REGISTER_TEACHER_FAIL"

export const GET_TEACHERS = "GET_TEACHERS"
export const GET_TEACHERS_SUCCESS = "GET_TEACHERS_SUCCESS"
export const GET_TEACHERS_FAIL = "GET_TEACHERS_FAIL"

export const GET_SINGLE_TEACHER = "GET_SINGLE_TEACHER"
export const GET_SINGLE_TEACHER_SUCCESS = "GET_SINGLE_TEACHER_SUCCESS"
export const GET_SINGLE_TEACHER_FAIL = "GET_SINGLE_TEACHER_FAIL"

export const UPDATE_TEACHER = "UPDATE_TEACHER"
export const UPDATE_TEACHER_SUCCESS = "UPDATE_TEACHER_SUCCESS"
export const UPDATE_TEACHER_FAIL = "UPDATE_TEACHER_FAIL"
