import { ADD_GRAPHICAL_QUES, ADD_GRAPHICAL_QUES_FAIL, ADD_GRAPHICAL_QUES_SUCCESS, ADD_MULTI_SOURCE, ADD_MULTI_SOURCE_FAIL, ADD_MULTI_SOURCE_SUCCESS, ADD_TABLE_ANALYSIS_QUES, ADD_TABLE_ANALYSIS_QUES_FAIL, ADD_TABLE_ANALYSIS_QUES_SUCCESS, ADD_TWO_PART_QUES, ADD_TWO_PART_QUES_FAIL, ADD_TWO_PART_QUES_SUCCESS, GET_ALL_INTEGRATED, GET_ALL_INTEGRATED_FAIL, GET_ALL_INTEGRATED_SUCCESS, GET_SINGLE_GRAPHICAL_QUES, GET_SINGLE_GRAPHICAL_QUES_FAIL, GET_SINGLE_GRAPHICAL_QUES_SUCCESS, GET_SINGLE_TABLE_ANALYSIS_QUES, GET_SINGLE_TABLE_ANALYSIS_QUES_FAIL, GET_SINGLE_TABLE_ANALYSIS_QUES_SUCCESS, GET_TABLE_ANALYSIS_QUES, GET_TABLE_ANALYSIS_QUES_FAIL, GET_TABLE_ANALYSIS_QUES_SUCCESS, GET_TWO_PART_QUES, GET_TWO_PART_QUES_FAIL, GET_TWO_PART_QUES_SUCCESS } from "./actionTypes";

export const addGraphicalQues = (obj, authToken, option) => ({
    type: ADD_GRAPHICAL_QUES,
    payload: { obj, authToken, option },
  })
  
  export const addGraphicalQuesSuccess = data => ({
    type: ADD_GRAPHICAL_QUES_SUCCESS,
    payload: { data },
  })

  export const addGraphicalQuesFail = data => ({
    type: ADD_GRAPHICAL_QUES_FAIL,
    payload: { data },
  })



  export const addTableAnalysisQues = (obj, authToken, option) => ({
    type: ADD_TABLE_ANALYSIS_QUES,
    payload: { obj, authToken, option },
  })
  
  export const addTableAnalysisQuesSuccess = data => ({
    type: ADD_TABLE_ANALYSIS_QUES_SUCCESS,
    payload: { data },
  })

  export const addTableAnalysisQuesFail = data => ({
    type: ADD_TABLE_ANALYSIS_QUES_FAIL,
    payload: { data },
  })

  export const addTwoPartQues = (obj, authToken, option) => ({
    type: ADD_TWO_PART_QUES,
    payload: { obj, authToken, option },
  })
  
  export const addTwoPartQuesSuccess = data => ({
    type: ADD_TWO_PART_QUES_SUCCESS,
    payload: { data },
  })

  export const addTwoPartQuesFail = data => ({
    type: ADD_TWO_PART_QUES_FAIL,
    payload: { data },
  })
  export const addMultiSource = (obj, authToken, option) => ({
    type: ADD_MULTI_SOURCE,
    payload: { obj, authToken, option },
  })
  
  export const addMultiSourceSuccess = data => ({
    type: ADD_MULTI_SOURCE_SUCCESS,
    payload: { data },
  })

  export const addMultiSourceFail = data => ({
    type: ADD_MULTI_SOURCE_FAIL,
    payload: { data },
  })

  export const getAllTableAnalysisQues = (token, section, type) => ({
    type: GET_TABLE_ANALYSIS_QUES,
    payload: { token, section, type },
  });
  
  export const getAllTableAnalysisQuesSuccess = (data) => ({
    type: GET_TABLE_ANALYSIS_QUES_SUCCESS,
    payload: { data },
  });
  

  export const getAllTableAnalysisQuesFail = (data) => ({
    type: GET_TABLE_ANALYSIS_QUES_FAIL,
    payload: { data },
  });


  export const getAllTwoPartQues = (token, section, type) => ({
    type: GET_TWO_PART_QUES,
    payload: { token, section, type },
  });
  
  export const getAllTwoPartQuesSuccess = (data) => ({
    type: GET_TWO_PART_QUES_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getAllTwoPartQuesFail = (data) => ({
    type: GET_TWO_PART_QUES_FAIL,
    payload: { data },
  });


  export const getSingleGraphicalQues = (authToken, questionId) => ({
    type: GET_SINGLE_GRAPHICAL_QUES,
    payload: { authToken, questionId },
  });
  
  export const getSingleGraphicalQuesSuccess = (data) => ({
    type: GET_SINGLE_GRAPHICAL_QUES_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getSingleGraphicalQuesFail = (data) => ({
    type: GET_SINGLE_GRAPHICAL_QUES_FAIL,
    payload: { data },
  });

  export const getSingleTableAnalysisQues = (authToken, questionId) => ({
    type: GET_SINGLE_TABLE_ANALYSIS_QUES,
    payload: { authToken, questionId },
  });
  
  export const getSingleTableAnalysisQuesSuccess = (data) => ({
    type: GET_SINGLE_TABLE_ANALYSIS_QUES_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getSingleTableAnalysisQuesFail = (data) => ({
    type: GET_SINGLE_TABLE_ANALYSIS_QUES_FAIL,
    payload: { data },
  });



  export const getAllIntegratedQues = (token, section, type) => ({
    type: GET_ALL_INTEGRATED,
    payload: { token, section, type },
  });
  
  export const getAllIntegratedQuesSuccess = (data) => ({
    type: GET_ALL_INTEGRATED_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getAllIntegratedQuesFail = (data) => ({
    type: GET_ALL_INTEGRATED_FAIL,
    payload: { data },
  });
