import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import { toaster } from "helpers/Custom/Toaster"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Alert, Col, Row } from "reactstrap"
import { addFaqType } from "store/actions"
import InnerLayer from "../../Layout/InnerLayer"
const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Faq type",
    link: "/faqType",
  },
  {
    //   title: `${id ? "Update" : "Create"} FAQ type`,
    title: `Create FAQ type`,
    link: "#",
  },
]
const CreateTypeFaq = ({ history }) => {
  const dispatch = useDispatch()
  const { authToken, loading, error, adding, singleCoupon, packageData } =
    useSelector(state => ({
      authToken: state.Login.token,

      adding: state.FAQReducer.adding,
    }))

  const handleSubmit = (event, errors, values) => {
    event.preventDefault()
    if (errors.length > 0) {
      toaster("warning", "Please Fill Up all the values")
      return
    }
    let body = {
      typeName: values?.typeName,
    }
    dispatch(addFaqType(body, history, authToken))

    console.log("hello", values)
  }

  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        // title={id ? "Edit FAQ Type" : "Create FAQ type"}
        title={"Create FAQ type"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row className="mt-3">
          <Col md="6" className="offset-md-3">
            <CardComponent>
              {/* <h5>{id ? "Update" : "Create"} FAQ type</h5> */}
              <h5>Create FAQ type</h5>
              {error ? <Alert color="danger">{error}</Alert> : null}

              <AvForm className="mt-4" onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <AvField
                    name="typeName"
                    label="FAQ type"
                    type="text"
                    errorMessage="Type FAQ type"
                    placeholder="Type FAQ type"
                    //   value={singleCoupon?.couponCode}
                    //   disabled={id}
                    validate={{
                      required: { value: true },
                    }}
                  />
                </Row>

                <div>
                  <button
                    disabled={adding}
                    type="submit"
                    className="btn btn-primary w-md"
                  >
                    {adding ? "Submitting..." : "Create FAQ type"}
                  </button>
                </div>
              </AvForm>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default CreateTypeFaq
