import { call, put, takeEvery } from "redux-saga/effects"
import { toaster } from "../../../helpers/Custom/Toaster"
import { getData, patchData, postData } from "../../../helpers/backend_helper"
import {
  ADD_QUANTITATIVE_QUES,
  GET_QUANTITATIVE_QUES,
  GET_SINGLE_QUANTITATIVE_QUES,
  UPDATE_QUANTITATIVE_QUES,
} from "./actionTypes"
import {
  addQuantitativeQuesFail,
  addQuantitativeQuesSuccess,
  getAllQuantitativeQuesFail,
  getAllQuantitativeQuesSuccess,
  getSingleQuantitativeQues,
  getSingleQuantitativeQuesFail,
  getSingleQuantitativeQuesSuccess,
  updateQuantitativeQuesFail,
  updateQuantitativeQuesSuccess,
} from "./actions"

function* onAddQuantitativeQuestion({ payload: { obj, authToken, option } }) {
  try {
    console.log("banner", option)
    const url = option?.id
      ? `gmat/quantative/update-gmat/${option?.id}`
      : `gmat/quantative/gmat-question-create`
    const response = yield call(
      option?.id ? patchData : postData,
      url,
      obj,
      authToken
    )

    if (response) {
      toaster(
        "success",
        `Quantitative question ${option?.id ? "updated" : "added"} successfully`
      )
      option?.setSubmitting(false)
      option?.resetForm()
      if (option?.isMore) {
        yield put(addQuantitativeQuesSuccess())
        option?.setIsAddMore(false)
      } else {
        yield put(addQuantitativeQuesSuccess())
        yield put(getSingleQuantitativeQues(authToken, option?.id))
        // history.push(`/Quantative-ques-list`);
      }
    }
    // history.push("/author-list")
  } catch (err) {
    const message =
      err?.response?.data?.message || `Quantitative question creation failed`
    toaster("error", message)
    option?.setSubmitting(false)
    yield put(addQuantitativeQuesFail(message))
  }
}
function* onGetQuantitativeQuestion({ payload: { token, section, type } }) {
  try {
    const url = `/gmat/quantative/get-gmat/${section}/${type}`
    const response = yield call(getData, url, token)
    yield put(getAllQuantitativeQuesSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed"
    yield put(getAllQuantitativeQuesFail(message))
    toaster("error", message)
  }
}
function* onGetSingleQuantitativeQuestion({
  payload: { authToken, questionId },
}) {
  console.log("saga", questionId)
  try {
    const url = `gmat/quantative/get-single-gmat/${questionId}`
    const response = yield call(getData, url, authToken)
    yield put(getSingleQuantitativeQuesSuccess(response?.getData))
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed"
    yield put(getSingleQuantitativeQuesFail(message))
    toaster("error", message)
  }
}

function* onUpdateQuantitativeQuestion({
  payload: { data, token, history, screenId },
}) {
  try {
    console.log("banner", screenId)
    const url = screenId
      ? `gmat/create-gmat-writing-question`
      : `gmat/create-gmat-writing-question`
    const response = yield call(
      screenId ? patchData : postData,
      url,
      data,
      token
    )
    if (response) {
      yield put(updateQuantitativeQuesSuccess({}))
      //   yield put(getScreenList(token));
      toaster("success", `Analytical writing created Successfully`)
    }
    // history.push("/author-list")
  } catch (err) {
    const message =
      err?.response?.data?.message || `Analytical writing creation failed`
    toaster("error", message)
    yield put(updateQuantitativeQuesFail(message))
  }
}

function* QuantitativeQuesSaga() {
  yield takeEvery(ADD_QUANTITATIVE_QUES, onAddQuantitativeQuestion)
  yield takeEvery(GET_QUANTITATIVE_QUES, onGetQuantitativeQuestion)

  yield takeEvery(GET_SINGLE_QUANTITATIVE_QUES, onGetSingleQuantitativeQuestion)
  yield takeEvery(UPDATE_QUANTITATIVE_QUES, onUpdateQuantitativeQuestion)
}

export default QuantitativeQuesSaga
