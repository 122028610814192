import { ErrorMessage } from "formik";
import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import TextError from "./TextError";

const CustomInput = ({
  field: { onChange, onBlur, ...rest },
  form: { touched, errors },
  label,
  id,
  isPassword,
  handleViewPassword,
  onChangeCallback,
  onBlurCallback,
  isRequired,
  isArray,
  ...props
}) => (
  <React.Fragment>
    <FormGroup className={"w-100"}>
      {label ? (
        <Label htmlFor={id}>
          {label}
          {isRequired ? <span className="text-danger">{" *"}</span> : ""}
        </Label>
      ) : null}
      <div
        style={{ border: isPassword ? "1px solid #ced4da" : "none" }}
        className={
          isPassword
            ? "d-flex align-items-center rounded"
            : "d-flex align-items-center"
        }
      >
        <Input
          id={id}
          style={{ border: isPassword ? "none" : "" }}
          invalid={
            touched[rest.name] && errors[rest.name] && !props.disabled
              ? true
              : false
          }
          onChange={(e) => {
            const data = e;
            onChangeCallback && onChangeCallback(data);
            onChange(e);
          }}
          onBlur={(e) => {
            const data = e;
            onBlurCallback && onBlurCallback(data);
            onBlur && onBlur(e);
          }}
          {...rest}
          {...props}
        />
        {isPassword ? (
          <span
            className="me-2"
            style={{
              backgroundColor: "white",
              cursor: "pointer",
              border: isPassword ? "none" : "",
            }}
            onClick={handleViewPassword}
          >
            <i
              className={
                props.type === "text" ? "far fa-eye-slash" : "far fa-eye"
              }
            ></i>
          </span>
        ) : null}
      </div>
      {isArray ? (
        <ErrorMessage name={rest.name} component={TextError} />
      ) : touched[rest.name] && errors[rest.name] && !props.disabled ? (
        <FormFeedback type="invalid" className="d-inline">
          {errors[rest.name]}
        </FormFeedback>
      ) : null}
    </FormGroup>
  </React.Fragment>
);

export default CustomInput;
