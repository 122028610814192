import Loader from 'components/Common/Loader';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import { storeQuestion } from 'store/Question/actions';

import LeftSide from '../../../components/ReadingExam/LeftSide';

import RightSide from './RightSide';

const ReadingAns = ({ ans }) => {
  console.log('ansss', ans);
  const dispatch = useDispatch();
  const { questions, currentIndex, loading } = useSelector((state) => ({
    currentPassage: state.FacilitatorQuesReducer.currentPassage,
    authtoken: state.Login.token,
    quesLoading: state.FacilitatorQuesReducer.quesLoading,
    questions: state.resultReducer.results?.setId?.readingPassageModel,
    currentIndex: state.FacilitatorQuesReducer.currentIndex,
    loading: state.resultReducer.loading,
  }));

  const handleNext = () => {
    dispatch(storeQuestion('currentIndex', currentIndex + 1));
  };
  const handleBack = () => {
    dispatch(storeQuestion('currentIndex', currentIndex - 1));
  };

  const currentData = questions?.[currentIndex];
  console.log(`currentData`, currentData, questions, currentIndex);
  return (
    <React.Fragment>
      <div className={'question-right-area-hidden-show-class'}>
        <div className="exam-body-main" style={{ height: '100vh' }}>
          <div className="container-fluid">
            <Row>
              <div className={'col-lg-6 p-0'}>
                <div
                  className="passage-left-area-main-wrap"
                  style={{ height: '100vh' }}
                >
                  <div className="reading-passage-wrap left">
                    {loading ? (
                      <div className="text-center  pt-5">
                        <Loader />
                      </div>
                    ) : (
                      <LeftSide passage={currentData} />
                    )}
                  </div>
                </div>
              </div>
              <div className={'col-lg-6 p-0'}>
                <div>
                  <div
                    className="passage-right-area-main-wrap"
                    style={{ height: '100vh' }}
                  >
                    <div className="reading-passage-wrap right mb-5">
                      {loading ? (
                        <div className="text-center  pt-5">
                          <Loader />
                        </div>
                      ) : (
                        <RightSide
                          ans={ans}
                          question={currentData?.questions}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
      <div className="exam-ques-footer-main-wrap position-relative">
        <div className="ques-footer-top">
          <div className="exam-f-left">
            <h5>Section {currentIndex + 1}</h5>
          </div>
          <div className="exam-f-right">
            <div className="exam-h-marks exam-h-t-right-button d-flex justify-content-center align-items-center">
              {currentIndex > 0 ? (
                <button
                  className="btn me-2"
                  style={{ backgroundColor: '#fff', color: '#000' }}
                  onClick={handleBack}
                >
                  <i className="fa fa-angle-left"></i> Previous
                </button>
              ) : null}
              {currentIndex < questions?.length - 1 ? (
                <button
                  className="btn btn-primary"
                  style={{ whiteSpace: 'nowrap' }}
                  onClick={handleNext}
                >
                  Next Section <i className="fa fa-angle-right"></i>
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReadingAns;
