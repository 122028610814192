import { Field, Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Col, Label, Row } from "reactstrap"

import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import QuestionTopCard from "components/Common/QuestionTopCard"
import CustomInput from "components/InputComponent/CustomInput"
import FileUpload from "components/InputComponent/FileUpload"
import QuillComponent from "components/InputComponent/QuillComponent"
import { createWritingQuesSchema } from "components/Schemas/Service"
import { createHash, removeHash } from "helpers/Custom/Hash"
import Select from "react-select"
import { getCourseType } from "store/CourseType/actions"
import {
  addAnalyticalWriting,
  getSingleAnalyticalQues,
} from "store/GMAT/Analytical/actions"
import InnerLayer from "../../../Layout/InnerLayer"
import PageHeader from "../../../Layout/PageHeader"

const initialValues = {
  image: "",
  questionTitle: "",
  q: "",

  difficultyLevel: "",
}

const CreateAnalyticalQues = ({ history }) => {
  const { serviceId, type, questionId, section } = useParams()
  const [courseType, selectCourseType] = useState("")
  const dispatch = useDispatch()
  const [values, setValues] = useState(null)
  const title = ` Question Type`
  const title2 = questionId ? `Edit  Question` : `Create Question`
  const breadcrumbItems = [
    {
      title: title,
      link: `/analytical-writing-type`,
    },
    {
      title: title2,
      link: `#`,
    },
  ]
  const {
    authToken,
    data,
    singleQuantitativeQuesLoading,
    singleAnalyticalQues,
  } = useSelector(state => ({
    authToken: state.Login.token,
    loading: state.courseType?.loading,
    data: state?.courseType?.data,
    // error: state.courseType.error,
    singleQuantitativeQuesLoading:
      state.analyticalReducer.singleQuantitativeQuesLoading,
    singleAnalyticalQues: state.analyticalReducer.singleAnalyticalQues,
  }))

  useEffect(() => {
    dispatch(getCourseType(authToken))
  }, [])
  useEffect(() => {
    if (questionId) {
      dispatch(getSingleAnalyticalQues(authToken, questionId))
    }
  }, [questionId])
  useEffect(() => {
    if (Object.keys(singleAnalyticalQues).length) {
      selectCourseType(singleAnalyticalQues?.courseType?._id)
      const oldValues = { ...values }
      oldValues.courseType = singleAnalyticalQues?.courseType?._id || ""
      oldValues.image = singleAnalyticalQues?.image || ""
      oldValues.difficultyLevel = singleAnalyticalQues?.difficultyLevel || ""
      oldValues.questionType = singleAnalyticalQues?.questionType || ""
      oldValues.questionTitle = singleAnalyticalQues?.questionTitle || ""
      oldValues.image = singleAnalyticalQues?.image || ""
      oldValues.q = singleAnalyticalQues?.q
        ? removeHash(singleAnalyticalQues?.q)
        : ""
      setValues(oldValues)
    }
  }, [singleAnalyticalQues])
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    console.log("values323", values)
    const { q, difficultyLevel, questionType, ...rest } = values
    console.log("values123", values)
    const obj = {
      ...rest,
      q: createHash(q),
      courseType,
      difficultyLevel: difficultyLevel * 1,
      questionType: section * 1,
    }
    const option = {
      setSubmitting,
      resetForm,
      id: questionId,
      history,
      serviceId,
      type,
    }
    dispatch(addAnalyticalWriting(obj, authToken, option))
    console.log("obj", obj)
    setSubmitting(false)
  }

  const handleCourseType = data => {
    if (data?._id) {
      selectCourseType(data?._id)
    } else {
      selectCourseType("")
    }
  }

  console.log("courseType", courseType)
  return (
    <InnerLayer>
      <PageHeader title={title2} breadcrumbItems={breadcrumbItems} />
      <Row className="create_questions">
        <Col md="10" className="offset-md-1">
          <QuestionTopCard
            history={history}
            title={`${questionId ? "Update" : "Create"} Analytical question`}
          />
          <CardComponent className="user_table_card -table-padding-remove p-3">
            {questionId && singleQuantitativeQuesLoading ? (
              <Loader />
            ) : (
              <Formik
                initialValues={values || initialValues}
                validationSchema={createWritingQuesSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({ values, errors, isSubmitting }) => (
                  <Form>
                    {console.log("values", values, errors)}
                    <Row>
                      <Col md="12 mb-2">
                        <div className="mb-3">
                          <Label className="control-label">
                            Select Course Type
                          </Label>
                          <Select
                            cacheOptions
                            getOptionLabel={e => e.courseTypeName}
                            getOptionValue={e => e._id}
                            options={data}
                            isClearable
                            onChange={handleCourseType}
                          />
                        </div>
                      </Col>
                      <Col md="12 mb-2">
                        <Field
                          name="questionTitle"
                          label={"Question Title"}
                          type="text"
                          component={CustomInput}
                          placeholder="Type title"
                        />
                      </Col>

                      <Col md="12 mb-2">
                        <Field
                          name="q"
                          label={"Write Question"}
                          type="text"
                          component={QuillComponent}
                          // placeholder="Type title"
                        />
                      </Col>
                      <Col md="12">
                        <Field
                          label="Difficulty level"
                          name="difficultyLevel"
                          type="select"
                          id={"difficultyLevel"}
                          component={CustomInput}
                        >
                          <option value="">Select...</option>
                          <option value="1">Easy</option>
                          <option value="2">Medium</option>
                          <option value="3">difficult</option>
                        </Field>
                      </Col>

                      <Col md="12 mb-2">
                        <Field
                          name="image"
                          label={"Upload Image"}
                          type="file"
                          component={FileUpload}
                          isRequired
                          isFull
                        />
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-info"
                        type={"submit"}
                        disabled={isSubmitting}
                      >
                        {isSubmitting
                          ? "Submitting..."
                          : questionId
                          ? "Update"
                          : "Create Question"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </CardComponent>
        </Col>
      </Row>
    </InnerLayer>
  )
}

export default CreateAnalyticalQues
