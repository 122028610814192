import CardComponent from "components/Common/CardComponent"
import CustomInput from "components/Common/CustomInput"
import Loader from "components/Common/Loader"
import TextError from "components/InputComponent/TextError"
import {
  headingMatchingSchema,
  trueFalseSchema,
} from "components/Schemas/QuestionSchemas"
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import {
  addIELTSReadingQuesMCQ,
  getIELTSReadingQuesMCQ,
  getIELTSReadingQuesNum,
} from "store/actions"
import InnerLayer from "../../../../Layout/InnerLayer"
import { HeadingMatching } from "../ViewPassage"

const initialValues = {
  q: "",
  answer: "",
  explanation: "",
  options: [""],
}

const data = ["True", "False", "NotGiven"]

const HeadMatching = ({ history, edit, location }) => {
  const { serviceId, setId, passageId, questionId, id } = useParams()
  console.log(`🦄 ~ file: MCQ.js ~ line 24 ~ MCQ ~ passageID`, passageId)
  const [listValue, setListValue] = useState(null)
  const [isAddMore, setIsAddMore] = useState(false)
  const dispatch = useDispatch()
  const { authToken, loading, adding, questionNumber, singleQuestion, ques } =
    useSelector(state => ({
      authToken: state.Login.token,
      loading: state.QuesReducer.loading,
      adding: state.QuesReducer.adding,
      services: state.serviceReducer.services,
      questionNumber: state.QuesReducer.questionNumber,
      singleQuestion: state.QuesReducer.singleQuestion,
      ques: state.QuesReducer.ques,
    }))
  const findData = ques?.find(dt => dt?.Que?.questype === HeadingMatching) || {}
  // console.log(
  //   `🦄 ~ file: Multiple.js ~ line 34 ~ Multiple ~ singleQuestion`,
  //   singleQuestion,
  //   questionNumber,
  //   ques,
  //   findData
  // )

  useEffect(() => {
    if (edit && !!singleQuestion) {
      const value = { ...listValue }
      const option = findData?.Que?.options
      value.q = singleQuestion?.q
      value.opt = singleQuestion?.opt
      value.answer = singleQuestion?.answer?.[0]
      value.explanation = singleQuestion?.explanation
      value.options = option
      setListValue(value)
      // setQuestionNumber(singleQuestion?.questionNumber)
    }
  }, [singleQuestion, findData])

  useEffect(() => {
    const type = location.pathname.split("/").pop()
    if (edit) {
      const data = {
        type,
        objectId: id,
        questionId,
      }
      dispatch(getIELTSReadingQuesMCQ(authToken, passageId, data))
    } else {
      dispatch(getIELTSReadingQuesNum(authToken, passageId, type))
      dispatch(getIELTSReadingQuesMCQ(authToken, passageId))
    }
  }, [])

  const onSubmit = (values, { resetForm }) => {
    const { answer, options, ...rest } = values
    const type = location.pathname.split("/").pop()
    let obj = {
      ...rest,
      answer: [answer],
      readingPessage: passageId,
      questionSerial: questionNumber,
      questype: type,
    }
    if (Object.keys(findData).length === 0) {
      obj.options = options
    }
    const option = {
      isMore: isAddMore,
      resetForm,
      setIsAddMore,
      questionId,
      type,
      passageId,
    }
    if (edit) {
      dispatch(addIELTSReadingQuesMCQ(obj, history, authToken, option))
    } else {
      dispatch(addIELTSReadingQuesMCQ(obj, history, authToken, option))
    }
    console.log(`🦄 ~ file: MCQ.js ~ line 44 ~ MCQ ~ values`, obj, option)
  }
  return (
    <React.Fragment>
      <InnerLayer
        isBreadCrumb={false}
        title="Create heading & matching Question"
      >
        <Row className="mt-3">
          <Col md="10" className="offset-md-1">
            <CardComponent className={"mb-0"}>
              <div className="d-flex align-items-center">
                <i
                  className="bx bx-left-arrow-alt me-2"
                  onClick={() => history.goBack()}
                  style={{ fontSize: "18px" }}
                ></i>
                <h6 className="mb-0">Create heading & matching</h6>
              </div>
            </CardComponent>
            <CardComponent style={{ marginTop: "5px" }}>
              <Row>
                <Col md="1">
                  <div className="question_number d-flex justify-content-center align-items-start">
                    {questionNumber}
                  </div>
                </Col>
                <Col md="11">
                  <h5>Type 1no. question</h5>
                  <br />
                  {loading && edit ? (
                    <div className="d-flex justify-content-center">
                      <Loader />
                    </div>
                  ) : (
                    <Formik
                      initialValues={listValue || initialValues}
                      validationSchema={
                        Object.keys(findData).length > 0
                          ? trueFalseSchema
                          : headingMatchingSchema
                      }
                      onSubmit={onSubmit}
                      enableReinitialize={true}
                    >
                      {({ values, errors }) => (
                        <Form>
                          {console.log(errors)}
                          <Row className="mb-3">
                            {Object.keys(findData).length === 0 || edit ? (
                              <Col md="12">
                                <Field
                                  label="Question Title"
                                  name="q"
                                  type="text"
                                  id={"question"}
                                  component={CustomInput}
                                  placeholder="Type Heading"
                                />
                              </Col>
                            ) : (
                              <React.Fragment>
                                <Col md="6">
                                  <Field
                                    label="Question Title"
                                    name="q"
                                    type="text"
                                    id={"question"}
                                    component={CustomInput}
                                    placeholder="Type Heading"
                                  />
                                </Col>
                                <Col md="6">
                                  <Field
                                    name="answer"
                                    id={"answer"}
                                    label="Right answer"
                                    type="text"
                                    placeholder="Type right answer"
                                    component={CustomInput}
                                  ></Field>
                                </Col>
                              </React.Fragment>
                            )}
                          </Row>
                          {Object.keys(findData).length === 0 || edit ? (
                            <Row className="mb-3">
                              <FieldArray
                                name="options"
                                render={arrayHelpers => (
                                  <Row>
                                    {values?.options?.map((value, index) => (
                                      <Col md="6" key={index}>
                                        <div className="mb-2 position-relative">
                                          <Field
                                            name={`options.${index}`}
                                            type={"text"}
                                            id={index}
                                            label={`Type options ${
                                              index > 0 ? index + 1 : ""
                                            }`}
                                            component={CustomInput}
                                            placeholder="Type Type options"
                                          />
                                          {index > 0 ? (
                                            <div
                                              className="position-absolute"
                                              style={{ top: "0", right: "0" }}
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              <Button
                                                className="btn-danger btn-sm "
                                                style={{
                                                  background: "transparent",
                                                  border: "none",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                <i
                                                  className="bx bx-trash"
                                                  style={{ color: "#999" }}
                                                ></i>
                                              </Button>
                                            </div>
                                          ) : null}
                                          <ErrorMessage
                                            name={`options.${index}`}
                                            component={TextError}
                                          />
                                        </div>
                                      </Col>
                                    ))}
                                    <Col md="12">
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="d-inline-block"
                                        style={{
                                          background: "#E5F0FF",
                                          color: "#485ec4",
                                          border: "none",
                                          width: "initial",
                                        }}
                                        onClick={() => arrayHelpers.push("")}
                                      >
                                        Add more option
                                      </Button>
                                    </Col>
                                  </Row>
                                )}
                              />
                            </Row>
                          ) : (
                            <Row className="mb-1">
                              {findData?.Que?.options?.map((dt, idx) => (
                                <Col md="6" className="mb-2" key={idx}>
                                  <Field
                                    value={dt}
                                    type={"text"}
                                    id={idx}
                                    disabled
                                    label={`Type options ${
                                      idx > 0 ? idx + 1 : ""
                                    }`}
                                    component={CustomInput}
                                    placeholder="Type Type options"
                                  />
                                </Col>
                              ))}
                            </Row>
                          )}
                          {Object.keys(findData).length === 0 || edit ? (
                            <Row className="mb-3">
                              <Col md="12">
                                <Field
                                  name="answer"
                                  id={"answer"}
                                  label="Right answer"
                                  type="text"
                                  placeholder="Type right answer"
                                  component={CustomInput}
                                ></Field>
                              </Col>
                            </Row>
                          ) : null}
                          <Row className="mb-3">
                            <Col md="12">
                              <Field
                                name="explanation"
                                id={"Explanation"}
                                label="Explanation"
                                placeholder="Type explanation"
                                type="textarea"
                                rows={"5"}
                                component={CustomInput}
                                //   disabled={edit}
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="12" className="d-flex justify-content-end">
                              <Button
                                color="primary"
                                className="me-2"
                                disabled={adding}
                                type="submit"
                                style={{
                                  background: "transparent",
                                  color: "#485ec4",
                                  border: "1px solid #485ec4",
                                }}
                              >
                                {adding
                                  ? "Submitting..."
                                  : edit
                                  ? "Update"
                                  : "Save"}
                              </Button>
                              {!edit ? (
                                <Button
                                  color="primary"
                                  disabled={adding}
                                  type="submit"
                                >
                                  {adding
                                    ? "Submitting..."
                                    : "Create more question"}
                                </Button>
                              ) : null}
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  )}
                </Col>
              </Row>
            </CardComponent>
          </Col>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(HeadMatching)
