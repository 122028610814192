import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, withRouter } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import { getListeningSets } from "store/actions"
import InnerLayer from "../../../Layout/InnerLayer"
import CardComponent from "../../../components/Common/CardComponent"
import Loader from "../../../components/Common/Loader"
import NoData from "../../../components/Common/NoData"
import { toCapitalize } from "../../../helpers/Custom/toCapitalize"

const ListeningSet = ({ history }) => {
  const { id } = useParams()
  const param = useParams()
  //   console.log(`🦄 ~ file: Index.js ~ line 11 ~ ListSet ~ param`, param)
  const dispatch = useDispatch()
  // const useSelector = useSelector();
  const { listeningSets, authToken, loading } = useSelector(state => ({
    listeningSets: state.listeningSet.listeningSets,
    authToken: state.Login.token,
    loading: state.listeningSet.loading,
  }))

  // console.log(listeningSets, loading)

  useEffect(() => {
    dispatch(getListeningSets(authToken))
  }, [])
  const breadcrumbItems = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Service List",
      link: "/services",
    },
    {
      title: `listening`,
      link: "#",
    },
  ]
  return (
    <React.Fragment>
      <InnerLayer
        isMultiple
        title={"List Set"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>Listening Set</h4>
              <div className="d-flex">
                <div className="search-box me-2">
                  <div className="position-relative">
                    <input
                      type="search"
                      placeholder="Search..."
                      //   value={keyword}
                      //   onChange={handleSearchChange}
                      className="form-control"
                    />
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>
                <div>
                  <div className="position-relative">
                    <Link to={`/service/${id}/create-listening-set`}>
                      <Button
                        type="button"
                        color="primary"
                        className="btn-circle me-2"
                      >
                        <i className="mdi mdi-plus me-1" />
                        Create Set
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </CardComponent>
        </Row>
        <Row>
          {/* storeData Render */}
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : listeningSets?.length > 0 ? (
            (listeningSets || []).map((data, key) => (
              <Col
                md="3"
                key={key}
                onClick={() =>
                  history.push(`/service/${id}/listening/${data._id}`)
                }
              >
                <CardComponent className="mini-stats-wid card-info-list position-relative">
                  <div
                    className="position-absolute"
                    style={{ top: "6%", right: "4%" }}
                  >
                    <span
                      className={"font-size-10 pill"}
                      style={{
                        backgroundColor:
                          data?.status?.toLowerCase() === "active"
                            ? "#34C38F"
                            : "#F46A6A",
                      }}
                    >
                      {toCapitalize(data?.status)}
                    </span>
                  </div>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium mb-1">Set Name</p>
                      <h5 className="mb-1">{data.setName}</h5>
                      <div className="d-flex justify-content-between">
                        <span>(Type: {data?.courseType?.courseTypeName})</span>{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={e => {
                            e.stopPropagation()
                            history.push(
                              `/service/${id}/edit-listening-set/${data._id}/`
                            )
                          }}
                        >
                          Edit
                        </span>
                      </div>
                    </div>
                  </div>
                </CardComponent>
              </Col>
            ))
          ) : (
            <div>
              <NoData>
                <p className="mb-0">Currently you have no Set.</p>
                <p>
                  For next step first{" "}
                  <Link to={`/service/${id}/create-listing-set`}>
                    Create Set
                  </Link>
                </p>
              </NoData>
            </div>
          )}
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(ListeningSet)
