import React, { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Modal } from "react-responsive-modal"
import { useParams, withRouter } from "react-router-dom"
import Select from "react-select"
import { Button, Row } from "reactstrap"
import CustomTable from "../../../Layout/CustomTable"
import InnerLayer from "../../../Layout/InnerLayer"
import NoTableData from "../../../Layout/NoTableData"
import CardComponent from "../../../components/Common/CardComponent"
import Loader from "../../../components/Common/Loader"
import TableDropdown from "../../../components/Common/TableDropdown"
import { removeHash } from "../../../helpers/Custom/Hash"
import { imageBaseUrl } from "../../../helpers/api_helper"
import { getAllIntegratedQues } from "../../../store/GMAT/IntegratedReasoning/actions"
const tableHead = ["#", "Question", "level", "Date", ""]

const levelType = [
  {
    name: "Easy",
    value: 1,
  },
  {
    name: "Medium",
    value: 2,
  },
  {
    name: "Difficult",
    value: 3,
  },
]
const IRAllQuesList = ({ history }) => {
  const dispatch = useDispatch()
  const [type, setType] = useState(1)
  const [viewDetails, setViewDetails] = useState(false)
  const { section } = useParams()
  const [currentPage, setCurrentPage] = useState(1)
  const { allIntegratedQuestion, allIntegratedQuesLoading, authToken } =
    useSelector(state => ({
      allIntegratedQuesLoading: state.IRReducer.allIntegratedQuesLoading,
      allIntegratedQuestion: state.IRReducer.allIntegratedQuestion,
      authToken: state.Login.token,
    }))
  console.log("allIntegratedQuestion", allIntegratedQuestion)
  useEffect(() => {
    dispatch(getAllIntegratedQues(authToken, section, type))
  }, [type, section])
  const getPhotoUrl = photo => {
    if (photo) {
      return photo?.startsWith("h") ? photo : imageBaseUrl + photo
    }
    return logo
  }
  const handleType = data => {
    if (data) {
      setType(data?.value)
    } else {
      setType("")
    }
  }
  const handleSet = section => {
    console.log("type", type)
    switch (section) {
      case "1":
        history.push({
          pathname: `/table-analysis/${section}/create`,
        })
        break
      case "2":
        history.push({
          pathname: `/table-analysis/${section}/create`,
        })
        break
      case "3":
        history.push({
          pathname: `/two-part-analysis-2/${section}/create`,
        })
        break
      case "4":
        history.push({
          pathname: `/two-part-analysis-2/${section}/create`,
        })
        break
      case "5":
        history.push({
          pathname: `/GraphQues/${section}/create`,
        })
        break
      case "6":
        history.push({
          pathname: `/multi-source-reasoning/${section}/create`,
        })
        break

      default:
        console.log("Not found")
        break
    }
  }
  const handleUpdateQues = (data, section) => {
    console.log("data", data)
    console.log("section", section)
    switch (section) {
      case "1":
        history.push(`/table-analysis/${data?._id}/edit/${section}`)
        break
      case "2":
        history.push(`/table-analysis/${data?._id}/edit/${section}`)
        break
      case "3":
        history.push(`/two-part/${data?._id}/edit/${section}`)
        break
      case "4":
        history.push(`/two-part/${data?._id}/edit/${section}`)
        break
      case "5":
        history.push(`/graph/${data?._id}/edit/${section}`)
        break
      default:
        break
    }
  }
  const handleDetails = () => {
    setViewDetails(true)
  }
  return (
    <React.Fragment>
      <Modal
        open={viewDetails}
        classNames={{
          overlay: "customOverlay",
          modal: "Xam-Confirm-Modal-main-wrap",
        }}
        center
      >
        <div className="text-center">
          {/* <img src={submit} alt="" /> */}
          <p className="mt-4 mb-4">
            Please make sure you have answered all the questions before
            submitting your response.
          </p>
        </div>
      </Modal>
      <InnerLayer
        isBreadCrumb={true}
        title="IR Question"
        mainTitle={"Quantitative Type"}
        subTitle={"IR Question Question"}
        wrapperClass="py-3 users"
        link={"/Quantative-ques-type"}
      >
        <CardComponent>
          <div className="d-flex justify-content-between align-items-center">
            <h4>Question List</h4>
            <div className="d-flex">
              <div className="search-box me-2">
                <div className="position-relative">
                  <input
                    type="search"
                    placeholder="Search..."
                    // value={keyword}
                    // onChange={handleSearchChange}
                    className="form-control"
                  />
                  <i className="bx bx-search-alt search-icon" />
                </div>
              </div>
              <div>
                <div className="position-relative">
                  <Button
                    type="button"
                    color="primary"
                    className="btn-circle me-2"
                    onClick={() => {
                      handleSet(section)
                    }}
                  >
                    <i className="mdi mdi-plus me-1" />
                    Create Question
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </CardComponent>

        {/* list */}
        <Row>
          <CardComponent className="position-relative">
            <div
              style={{
                width: "200px",
                marginLeft: "auto",
                marginBottom: "10px",
              }}
            >
              <Select
                name="status"
                className="select-form-width"
                classNamePrefix="select2-selection"
                placeholder="Select level"
                cacheOptions
                getOptionLabel={e => e.name}
                getOptionValue={e => e.value}
                isClearable
                options={levelType}
                onChange={handleType}
              />
            </div>
            <CustomTable
              paginationClass="paginationContainer text-right"
              data={allIntegratedQuestion}
              pageNo={1}
              tableHead={tableHead}
              setCurrentPage={setCurrentPage}
              isPagination
              // setCurrentPage={setCurrentPage}
              // tbodyStyle={{ height: couponData?.length <= 3 ? "300px" : "" }}
            >
              {allIntegratedQuesLoading ? (
                <NoTableData
                  colSpan="8"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <Loader />
                </NoTableData>
              ) : allIntegratedQuestion?.length > 0 ? (
                allIntegratedQuestion?.map((data, idx) => (
                  <tr key={idx}>
                    <th scope="row" style={{ paddingLeft: "25px" }}>
                      {(currentPage - 1) * 10 + (idx + 1)}
                    </th>

                    {/* <td dangerouslySetInnerHTML={{
                      __html: data?.questionHeading
                        ? removeHash(data?.questionHeading)
                        : "",
                    }}></td> */}
                    {/* <td>Table Analysis</td> */}
                    <td
                      dangerouslySetInnerHTML={{
                        __html: data?.questionHeading
                          ? removeHash(data?.questionHeading)
                          : "",
                      }}
                    ></td>
                    <td>
                      {data?.questionDifficulties === 1
                        ? "easy"
                        : data?.difficultyLevel === 2
                        ? "medium"
                        : "hard"}
                    </td>
                    <td>{data?.createdAt?.slice(0, 10)}</td>
                    <td>
                      <TableDropdown>
                        {/* <Dropdown.Item
                          className="d-flex align-items-center gap-2"
                          onClick={() => {
                            handleUpdateQues(data, section)
                          }}
                        >
                          <i className="bx bx-edit fs-5"></i> Edit
                        </Dropdown.Item> */}
                        <Dropdown.Item
                          className="d-flex align-items-center gap-2"
                          onClick={() => {
                            history.push(`/answer-details/${data?._id}`)
                          }}
                        >
                          <i className="bx bx-edit fs-5"></i> View
                        </Dropdown.Item>
                      </TableDropdown>
                    </td>
                  </tr>
                ))
              ) : (
                <NoTableData
                  colSpan="8"
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "300px", width: `100%` }}
                >
                  <span>No data Available</span>
                </NoTableData>
              )}
            </CustomTable>
          </CardComponent>
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(IRAllQuesList)
