import * as Yup from "yup"

const createBlogSchema = Yup.object({
    postTitle: Yup.string().required("This value is Required"),
    author: Yup.string().required("This value is Required"),


    postDescription: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
 
})

export { createBlogSchema }
