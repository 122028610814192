import {
  ADD_LISTENING_SET,
  ADD_LISTENING_SET_FAIL,
  ADD_LISTENING_SET_SUCCESS,
  ADD_LISTENING_SLICE,
  ADD_LISTENING_SLICE_FAIL,
  ADD_LISTENING_SLICE_SUCCESS,
  GET_LISTENING_AUDIO,
  GET_LISTENING_AUDIO_FAIL,
  GET_LISTENING_AUDIO_SINGLE_SLICE,
  GET_LISTENING_AUDIO_SINGLE_SLICE_FAIL,
  GET_LISTENING_AUDIO_SINGLE_SLICE_SUCCESS,
  GET_LISTENING_AUDIO_SLICE,
  GET_LISTENING_AUDIO_SLICE_FAIL,
  GET_LISTENING_AUDIO_SLICE_SUCCESS,
  GET_LISTENING_AUDIO_SUCCESS,
  GET_LISTENING_SETS,
  GET_LISTENING_SETS_FAIL,
  GET_LISTENING_SETS_SUCCESS,
  GET_LISTENING_SET_SINGLE,
  GET_LISTENING_SET_SINGLE_FAIL,
  GET_LISTENING_SET_SINGLE_SUCCESS,
  UPLOAD_AUDIO,
  UPLOAD_AUDIO_FAIL,
  UPLOAD_AUDIO_SUCCESS,
} from "./actionTypes"

export const addListeningSet = (values, history, token, serviceId, setId) => {
  return {
    type: ADD_LISTENING_SET,
    payload: { values, history, token, serviceId, setId },
  }
}
export const addListeningSetSuccess = message => {
  return {
    type: ADD_LISTENING_SET_SUCCESS,
    payload: { message },
  }
}

export const addListeningSetFail = message => {
  return {
    type: ADD_LISTENING_SET_FAIL,
    payload: { message },
  }
}

export const uploadAudioFile = (values, options) => {
  return {
    type: UPLOAD_AUDIO,
    payload: { values, options },
  }
}
export const uploadAudioSuccess = message => {
  return {
    type: UPLOAD_AUDIO_SUCCESS,
    payload: { message },
  }
}

export const uploadAudioFail = message => {
  return {
    type: UPLOAD_AUDIO_FAIL,
    payload: { message },
  }
}

export const getListeningSets = token => {
  return {
    type: GET_LISTENING_SETS,
    payload: { token },
  }
}

export const getListeningSetsSuccess = data => {
  return {
    type: GET_LISTENING_SETS_SUCCESS,
    payload: { data },
  }
}

export const getListeningSetsFail = message => ({
  type: GET_LISTENING_SETS_FAIL,
  payload: { message },
})

export const getSingleListeningSets = (token, serviceId) => {
  return {
    type: GET_LISTENING_SET_SINGLE,
    payload: { token, serviceId },
  }
}

export const getSingleListeningSetsSuccess = data => {
  return {
    type: GET_LISTENING_SET_SINGLE_SUCCESS,
    payload: { data },
  }
}

export const getSingleListeningSetsFail = message => ({
  type: GET_LISTENING_SET_SINGLE_FAIL,
  payload: { message },
})

//  AUDIN

export const getListeningAudio = (token, setId, option) => {
  return {
    type: GET_LISTENING_AUDIO,
    payload: { token, setId, option },
  }
}

export const getListeningAudioSuccess = data => {
  return {
    type: GET_LISTENING_AUDIO_SUCCESS,
    payload: { data },
  }
}

export const getListeningAudioFail = message => ({
  type: GET_LISTENING_AUDIO_FAIL,
  payload: { message },
})

export const addListeningSlice = (values, options) => {
  return {
    type: ADD_LISTENING_SLICE,
    payload: { values, options },
  }
}
export const addListeningSliceSuccess = message => {
  return {
    type: ADD_LISTENING_SLICE_SUCCESS,
    payload: { message },
  }
}

export const addListeningSliceFail = message => {
  return {
    type: ADD_LISTENING_SLICE_FAIL,
    payload: { message },
  }
}

export const getListeningAudioSlice = (token, setId, option) => {
  return {
    type: GET_LISTENING_AUDIO_SLICE,
    payload: { token, setId, option },
  }
}

export const getListeningAudioSliceSuccess = data => {
  return {
    type: GET_LISTENING_AUDIO_SLICE_SUCCESS,
    payload: { data },
  }
}

export const getListeningAudioSliceFail = message => ({
  type: GET_LISTENING_AUDIO_SLICE_FAIL,
  payload: { message },
})

export const getSingleListeningAudioSlice = (token, sliceId, option) => {
  return {
    type: GET_LISTENING_AUDIO_SINGLE_SLICE,
    payload: { token, sliceId, option },
  }
}

export const getSingleListeningAudioSliceSuccess = data => {
  return {
    type: GET_LISTENING_AUDIO_SINGLE_SLICE_SUCCESS,
    payload: { data },
  }
}

export const getSingleListeningAudioSliceFail = message => ({
  type: GET_LISTENING_AUDIO_SINGLE_SLICE_FAIL,
  payload: { message },
})
