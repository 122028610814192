import { ADD_AUTHOR, ADD_AUTHOR_FAIL, ADD_AUTHOR_SUCCESS, ADD_BLOG, ADD_BLOG_FAIL, ADD_BLOG_SUCCESS, GET_AUTHOR, GET_AUTHOR_FAIL, GET_AUTHOR_SUCCESS, GET_BLOG_POST, GET_BLOG_POST_FAIL, GET_BLOG_POST_SUCCESS } from "./actionTypes"

export const addAuthor = (data, token, history) => ({
    type: ADD_AUTHOR,
    payload: { data, token, history },
  })
  
  export const addAuthorSuccess = data => ({
    type: ADD_AUTHOR_SUCCESS,
    payload: { data },
  })
  
  // charts data
  export const addAuthorFail = data => ({
    type: ADD_AUTHOR_FAIL,
    payload: { data },
  })
  export const getAuthorList = (token) => ({
    type: GET_AUTHOR,
    payload: { token },
  });
  
  export const getAuthorListSuccess = (data) => ({
    type: GET_AUTHOR_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getAuthorListFail = (data) => ({
    type: GET_AUTHOR_FAIL,
    payload: { data },
  });

  export const addBlog = (data, token, history) => ({
    type: ADD_BLOG,
    payload: { data, token, history },
  })
  
  export const addBlogSuccess = data => ({
    type: ADD_BLOG_SUCCESS,
    payload: { data },
  })
  
  // charts data
  export const addBlogFail = data => ({
    type: ADD_BLOG_FAIL,
    payload: { data },
  })
  export const getBlogPost = (token, id) => ({
    type: GET_BLOG_POST,
    payload: { token, id },
  });
  
  export const getBlogPostSuccess = (data) => ({
    type: GET_BLOG_POST_SUCCESS,
    payload: { data },
  });
  
  // charts data
  export const getBlogPostFail = (data) => ({
    type: GET_BLOG_POST_FAIL,
    payload: { data },
  });