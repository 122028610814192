import { AvField, AvForm } from "availity-reactstrap-validation"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import moment from "moment"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
import { getSpeakingSets } from "store/Speaking/actions"
import InnerLayer from "../../Layout/InnerLayer"
import speakingIcon from "../ExamSet/Speaking/img/microphone.svg"

const breadcrumbItems = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Exam List",
    link: "#",
  },
]

const TeacherExam = () => {
  const [month, setMonth] = React.useState(moment().format("MM"))
  const [year, setYear] = React.useState(moment().format("YYYY"))
  // console.log(`SpeakingMain ~ month`, month, year)
  const dispatch = useDispatch()
  const { authToken, profile, loading, speakingSets, teacherId, role } =
    useSelector(state => ({
      authToken: state.Login.token,
      teacherId: state.Login.id,
      role: state.Login.userrole,
      speakingSet: state.speakingSet.speakingSet,
      speakingSets: state.speakingSet.speakingSets,
      profile: state.Profile?.data,
    }))
  console.log(`loading`, profile, speakingSets, teacherId)

  const handleChangeMonth = e => {
    const { value } = e.target
    if (value !== "") {
      setMonth(value)
    }
  }
  const handleChangeYear = e => {
    const { value } = e.target
    if (value !== "") {
      setYear(value)
    }
  }

  useEffect(() => {
    dispatch(
      getSpeakingSets(authToken, {
        teacherId,
        startDate: `${year}-${month}-01`,
        endDate: `${year}-${month}-31`,
        role: role,
      })
    )
  }, [year, month])

  return (
    <React.Fragment>
      <InnerLayer
        wrapperClass="py-3 users"
        isMultiple
        title={"Exam list"}
        breadcrumbItems={breadcrumbItems}
      >
        <Row>
          <CardComponent>
            <div className="d-flex justify-content-between align-items-center">
              <h4>Time Slot</h4>
              <div className="d-flex">
                <div>
                  <AvForm className="d-flex gap-2">
                    <AvField
                      type="select"
                      name="year"
                      onChange={handleChangeYear}
                      value={year}
                    >
                      <option value="">Select Month</option>
                      <option value="2026">2026</option>
                      <option value="2025">2025</option>
                      <option value="2024">2024</option>
                      <option value="2023">2023</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                    </AvField>
                    <AvField
                      name="month"
                      type="select"
                      onChange={handleChangeMonth}
                      value={month}
                    >
                      <option value="">Select Month</option>
                      <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </AvField>
                  </AvForm>
                </div>
              </div>
            </div>
          </CardComponent>{" "}
        </Row>
        <Row>
          {loading ? (
            <div className="text-center">
              <Loader />
            </div>
          ) : speakingSets.length > 0 ? (
            speakingSets?.map((speakingSet, i) => (
              <Col lg={3} className="mb-3" key={i}>
                <Link to={`/teacher-exam/view-class/${speakingSet?._id}`}>
                  <div className="single-exam-schedule">
                    <div className="single-exam-schedule-left-icon">
                      <img src={speakingIcon} alt="" />
                    </div>
                    <div className="single-exam-schedule-left-content">
                      <h6>{speakingSet?._id}</h6>
                      <p>{speakingSet?.count} time slot</p>
                    </div>
                  </div>
                </Link>
              </Col>
            ))
          ) : (
            <CardComponent>
              <div className="empty-time-slot-main-wrap">No Data</div>
            </CardComponent>
          )}
        </Row>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(TeacherExam)
