/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */
import { Card, ListGroup } from "react-bootstrap"
import { statsValueData } from "./statsValue"

const PopularPackage = () => {
  const handleGetMaleAge = data => {
    const newValue = data * 0.1
    return newValue
  }

  return (
    <div className="student-enrollment-chart populer-package">
      <div className="stats-area-title-graph">
        <h2>Stats</h2>
      </div>
      <div className="new-student-list-items-wrap">
        <div className="stats-chart-area-wrap">
          {statsValueData.map((stat, idx) => (
            <div className="stats-single-bar">
              <div className="stats-single-bar-left">
                <h5>{stat.age}</h5>
              </div>
              <div className="stats-single-bar-middle">
                <div
                  className="stats-female"
                  // title={stat?.female}
                  style={{ width: `${handleGetMaleAge(stat?.female)}%` }}
                >
                  <div className="state-female-hovar-tooltip">
                    <Card>
                      <Card.Header>Featured</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>{stat?.female}</ListGroup.Item>
                      </ListGroup>
                    </Card>
                  </div>
                </div>
                <div
                  className="stats-male"
                  title={stat?.female}
                  style={{ width: `${handleGetMaleAge(stat?.male)}%` }}
                >
                  <div className="state-male-hovar-tooltip">
                    <Card>
                      <Card.Header>Featured</Card.Header>
                      <ListGroup variant="flush">
                        <ListGroup.Item>{stat?.female}</ListGroup.Item>
                      </ListGroup>
                    </Card>
                  </div>
                </div>
                {/* {handleGetMaleAge(stat?.male)} */}
              </div>
              <div className="stats-single-bar-right">55%</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PopularPackage
