import {
  ADD_SERVICE,
  ADD_SERVICE_FAIL,
  ADD_SERVICE_SUCCESS,
  GET_SERVICES,
  GET_SERVICES_FAIL,
  GET_SERVICES_SUCCESS,
  GET_SINGLE_SERVICE,
  GET_SINGLE_SERVICE_FAIL,
  GET_SINGLE_SERVICE_SUCCESS,
} from "./actionTypes"

export const getService = authtoken => ({
  type: GET_SERVICES,
  payload: { authtoken },
})

export const getServiceSuccess = data => ({
  type: GET_SERVICES_SUCCESS,
  payload: data,
})

export const getServiceFail = error => ({
  type: GET_SERVICES_FAIL,
  payload: error,
})

export const addService = (data, history, authtoken, id) => ({
  type: ADD_SERVICE,
  payload: { authtoken, data, history, id },
})

export const addServiceSuccess = data => ({
  type: ADD_SERVICE_SUCCESS,
  payload: data,
})

export const addServiceFail = error => ({
  type: ADD_SERVICE_FAIL,
  payload: error,
})

export const getSingleService = (id, authtoken) => ({
  type: GET_SINGLE_SERVICE,
  payload: { authtoken, id },
})

export const getSingleServiceSuccess = data => ({
  type: GET_SINGLE_SERVICE_SUCCESS,
  payload: data,
})

export const getSingleServiceFail = error => ({
  type: GET_SINGLE_SERVICE_FAIL,
  payload: error,
})
