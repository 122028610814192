import {
  ADD_COUPON,
  ADD_COUPON_FAIL,
  ADD_COUPON_SUCCESS,
  GET_COUPON,
  GET_COUPON_FAIL,
  GET_COUPON_SUCCESS,
  GET_SINGLE_COUPON,
  GET_SINGLE_COUPON_FAIL,
  GET_SINGLE_COUPON_SUCCESS,
  UPDATE_COUPON,
  UPDATE_COUPON_FAIL,
  UPDATE_COUPON_SUCCESS,
} from "./actionTypes"

export const addCoupon = (data, token, history, id) => ({
  type: ADD_COUPON,
  payload: { data, token, history, id },
})

export const addCouponSuccess = data => ({
  type: ADD_COUPON_SUCCESS,
  payload: { data },
})

// charts data
export const addCouponFail = data => ({
  type: ADD_COUPON_FAIL,
  payload: { data },
})

export const getCoupon = token => ({
  type: GET_COUPON,
  payload: { token },
})

export const getCouponSuccess = data => ({
  type: GET_COUPON_SUCCESS,
  payload: { data },
})

// charts data
export const getCouponFail = data => ({
  type: GET_COUPON_FAIL,
  payload: { data },
})

export const getSingleCoupon = (token, id) => ({
  type: GET_SINGLE_COUPON,
  payload: { token, id },
})

export const getSingleCouponSuccess = data => ({
  type: GET_SINGLE_COUPON_SUCCESS,
  payload: { data },
})

// charts data
export const getSingleCouponFail = data => ({
  type: GET_SINGLE_COUPON_FAIL,
  payload: { data },
})

export const updateCoupon = (token, status, id) => ({
  type: UPDATE_COUPON,
  payload: { token, status, id },
})

export const updateCouponSuccess = data => ({
  type: UPDATE_COUPON_SUCCESS,
  payload: { data },
})

// charts data
export const updateCouponFail = data => ({
  type: UPDATE_COUPON_FAIL,
  payload: { data },
})
