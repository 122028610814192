import {
  ADD_COURSE_TYPE,
  ADD_COURSE_TYPE_FAIL,
  ADD_COURSE_TYPE_SUCCESS,
  GET_COURSE_TYPE,
  GET_COURSE_TYPE_FAIL,
  GET_COURSE_TYPE_SUCCESS,
  GET_SINGLE_COURSE_TYPE,
  GET_SINGLE_COURSE_TYPE_FAIL,
  GET_SINGLE_COURSE_TYPE_SUCCESS,
} from "./actionTypes"

export const addCourseType = (data, token, history, id) => ({
  type: ADD_COURSE_TYPE,
  payload: { data, token, history, id },
})

export const addCourseTypeSuccess = data => ({
  type: ADD_COURSE_TYPE_SUCCESS,
  payload: { data },
})

// charts data
export const addCourseTypeFail = data => ({
  type: ADD_COURSE_TYPE_FAIL,
  payload: { data },
})

export const getCourseType = token => ({
  type: GET_COURSE_TYPE,
  payload: { token },
})

export const getCourseTypeSuccess = data => ({
  type: GET_COURSE_TYPE_SUCCESS,
  payload: { data },
})

// charts data
export const getCourseTypeFail = data => ({
  type: GET_COURSE_TYPE_FAIL,
  payload: { data },
})

export const getSingleCourseType = (token, id) => ({
  type: GET_SINGLE_COURSE_TYPE,
  payload: { token, id },
})

export const getSingleCourseTypeSuccess = data => ({
  type: GET_SINGLE_COURSE_TYPE_SUCCESS,
  payload: { data },
})

// charts data
export const getSingleCourseTypeFail = data => ({
  type: GET_SINGLE_COURSE_TYPE_FAIL,
  payload: { data },
})
