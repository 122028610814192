
export const ADD_FAQ_INFO = "ADD_FAQ_INFO"
export const ADD_FAQ_INFO_SUCCESS = "ADD_FAQ_INFO_SUCCESS"
export const ADD_FAQ_INFO_FAIL = "ADD_FAQ_INFO_FAIL"


export const UPDATE_FAQ = "UPDATE_FAQ"
export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS"
export const UPDATE_FAQ_FAIL = "UPDATE_FAQ_FAIL"


export const UPDATE_FAQ_TYPE = "UPDATE_FAQ_TYPE"
export const UPDATE_FAQ_TYPE_SUCCESS = "UPDATE_FAQ_TYPE_SUCCESS"
export const UPDATE_FAQ_TYPE_FAIL = "UPDATE_FAQ_TYPE_FAIL"

export const GET_FAQ_INFO = "GET_FAQ_INFO"
export const GET_FAQ_INFO_SUCCESS = "GET_FAQ_INFO_SUCCESS"
export const GET_FAQ_INFO_FAIL = "GET_FAQ_INFO_FAIL"

export const GET_SINGLE_FAQ_INFO = "GET_SINGLE_FAQ_INFO"
export const GET_SINGLE_FAQ_INFO_SUCCESS = "GET_SINGLE_FAQ_INFO_SUCCESS"
export const GET_SINGLE_FAQ_INFO_FAIL = "GET_SINGLE_FAQ_INFO_FAIL"


export const GET_SINGLE_FAQ_TYPE = "GET_SINGLE_FAQ_TYPE"
export const GET_SINGLE_FAQ_TYPE_SUCCESS = "GET_SINGLE_FAQ_TYPE_SUCCESS"
export const GET_SINGLE_FAQ_TYPE_FAIL = "GET_SINGLE_FAQ_TYPE_FAIL"


export const ADD_FAQ_TYPE = "ADD_FAQ_TYPE"
export const ADD_FAQ_TYPE_SUCCESS = "ADD_FAQ_TYPE_SUCCESS"
export const ADD_FAQ_TYPE_FAIL = "ADD_FAQ_TYPE_FAIL"

export const GET_FAQ_TYPE = "GET_FAQ_TYPE"
export const GET_FAQ_TYPE_SUCCESS = "GET_FAQ_TYPE_SUCCESS"
export const GET_FAQ_TYPE_FAIL = "GET_FAQ_TYPE_FAIL"