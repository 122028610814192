import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';

const MultiSelectType = ({
  multipleQuesType,
  handleOnChange,
  idx,
  questionNumber,
  ans,
  view,
  notePad,
  handleChangeNotePad,
  newIdx,
}) => {
  const findQuestionsNumberRange = `${
    multipleQuesType?.[0]?.questionNumber + questionNumber
  }-
  ${
    multipleQuesType?.[multipleQuesType?.length - 1]?.questionNumber +
    questionNumber
  }`;
  const [viewExplain, setViewExplain] = useState(false);
  const [toggle, setToggle] = useState();
  return (
    <React.Fragment>
      <div className="ques-serial">
        <h5>Question {findQuestionsNumberRange}</h5>
        {!view ? (
          <div className="notepade-area-wrap-write-text">
            <div
              className="student-note-btn mt-3"
              onClick={() => setToggle(!toggle)}
            >
              {toggle ? (
                <p>
                  <i className="fa-solid fa-pen"></i>
                  hide Notepad
                </p>
              ) : (
                <p>
                  <i className="fa-solid fa-pen"></i>
                  Show Notepad
                </p>
              )}
            </div>
            <Form
              className={
                toggle
                  ? 'navbar_mobile_menu show my-2'
                  : 'navbar_mobile_menu hide my-2'
              }
            >
              <Form.Group>
                <Form.Control
                  as="textarea"
                  value={notePad?.[newIdx]}
                  onChange={(e) => handleChangeNotePad(e, newIdx)}
                  rows={3}
                />
              </Form.Group>
            </Form>
          </div>
        ) : null}
        <p>Choose the appropriate letter, A, B, C or D.</p>
        <p>
          Write your answers in boxes {findQuestionsNumberRange} on your answer
          sheet.
        </p>
        {(multipleQuesType || [])?.map((data, index) => {
          const number = data.questionNumber + questionNumber;
          return (
            <div
              className="answer-checkbox-mainwrap"
              key={index}
              id={`question${number}`}
            >
              <div className="checkbox-ques-serial-no">
                <div className="ques-sl-no-main">
                  <h5>{number}</h5>
                </div>
                <p>{data.q}</p>
              </div>
              {(data.opt || []).map((option, key) => {
                return (
                  <div className="form-check mb-3" key={key}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={ans?.[number]?.answer?.includes(option)}
                      value={option}
                      readOnly={view}
                      name={`question${data?.questionNumber}`}
                      onChange={(e) => handleOnChange(e, data, number)}
                      id={`exampleRadios${number + key}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`exampleRadios${number + key}`}
                    >
                      {option}
                    </label>
                  </div>
                );
              })}
              {view ? (
                <React.Fragment>
                  <div className="d-flex align-items-center py-2">
                    <span>
                      Answer :{' '}
                      <strong>
                        {ans?.[number]?.answer?.map(
                          (data, idx) => `${data}  ${idx === 1 ? '' : ','}`
                        )}
                      </strong>
                    </span>
                    <div>
                      <Button
                        type="button"
                        color="primary"
                        onClick={() => {
                          if (viewExplain !== `view${number}`) {
                            setViewExplain(`view${number}`);
                          } else {
                            setViewExplain('');
                          }
                        }}
                        style={{
                          background: 'transparent',
                          color: '#485ec4',
                          border: '1px solid #485ec4',
                        }}
                        className="btn btn-sm m-1 gapsInput"
                      >
                        {viewExplain === `view${number}`
                          ? 'Hide'
                          : 'See Explanation'}
                      </Button>
                    </div>
                    {ans?.[number]?.mark === 1 ? (
                      <span className="correct-ans">
                        <i className="bx bx-check"></i>
                      </span>
                    ) : (
                      <span className="false-ans">
                        <i className="bx bx-x"></i>
                      </span>
                    )}
                  </div>
                  {viewExplain === `view${number}` ? (
                    <div className="border rounded-2 p-3 my-2">
                      {data?.explanation}
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default MultiSelectType;
