/** Get Dashboard Chart data */
export const ADD_COURSE = "ADD_COURSE"
export const ADD_COURSE_SUCCESS = "ADD_COURSE_SUCCESS"
export const ADD_COURSE_FAIL = "ADD_COURSE_FAIL"

export const GET_COURSE = "GET_COURSE"
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS"
export const GET_COURSE_FAIL = "GET_COURSE_FAIL"

export const GET_SINGLE_COURSE = "GET_SINGLE_COURSE"
export const GET_SINGLE_COURSE_SUCCESS = "GET_SINGLE_COURSE_SUCCESS"
export const GET_SINGLE_COURSE_FAIL = "GET_SINGLE_COURSE_FAIL"

export const DELETE_COURSE = "DELETE_COURSE"
